import { AnalyticsPayload } from '@play-co/replicant';
import { extractCreativeAssetID } from './chatbot.templates';
import { creativeVersion } from './chatbot.ruleset';

export function getChatbotPayload(
  {
    feature,
    $subFeature,
    mediaUrl,
    nonCuratedCreativeId,
    payload,
  }: {
    feature: string;
    $subFeature: string;
    mediaUrl?: string;
    nonCuratedCreativeId?: string;
    payload?: any;
  },
  extraProps?: Record<string, string | number | boolean | undefined>,
): AnalyticsPayload {
  return {
    $channel: 'CHATBOT',
    feature,
    $subFeature,
    creativeAssetID: extractCreativeAssetID(mediaUrl, nonCuratedCreativeId),
    creativeVersion,
    payload,
    ...extraProps,
  };
}
