import './GlowingImage.scss';

interface Props {
  image: string;
  blur: number;
  intensity?: number;
  noGlow?: boolean;
  whiteGlow?: boolean;
  size?: string;
  borderRadius?: number;
  className?: string;
}

export const GlowingImage = ({
  image,
  blur,
  intensity = 1,
  size,
  borderRadius,
  noGlow = false,
  whiteGlow = false,
  className = undefined,
}: Props) => {
  return (
    <div
      className={`modal-header-icon ${className || ''}`}
      style={{
        width: size ? `${size}` : 'unset',
        height: size ? `${size}` : 'unset',
      }}
    >
      <img
        src={image}
        className="glow"
        style={{
          width: size ? `${size}` : 'unset',
          height: size ? `${size}` : 'unset',
          borderRadius: borderRadius ? `${borderRadius}px` : '0',

          filter: whiteGlow
            ? `blur(${blur}px) saturate(${
                10 * intensity
              }) brightness(0) invert(1)`
            : `blur(${blur}px) saturate(${10 * intensity}) brightness(${
                2 * intensity
              })`,

          display: noGlow ? 'none' : 'initial',
        }}
      />

      <img
        src={image}
        style={{
          width: size ? `${size}` : 'unset',
          height: size ? `${size}` : 'unset',
          borderRadius: borderRadius ? `${borderRadius}px` : '0',
        }}
      />
    </div>
  );
};
