import { createSharedStateSchema, SB, WithMeta } from '@play-co/replicant';

export const followingsSharedStateSchema = createSharedStateSchema({
  global: {
    schema: SB.object({
      followings: SB.array(SB.string()),
    }),
  },
});

export type FollowingsState = SB.ExtractType<
  typeof followingsSharedStateSchema.global.schema
>;

export type MutablefollowingsState = WithMeta<FollowingsState, {}>;
