import { assets } from '../../../assets/assets';
import { ElementUIState } from '../../Controllers/UIController/UITypes';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

/**
 * Don't forget to call `Grant15kState` module as next step
 */
export class WaitFiveSeconds extends TutorialModule {
  id = 'WaitFiveSeconds' as 'WaitFiveSeconds';

  execute = async () => {
    setTimeout(() => {
      this.onComplete();
    }, 5000);

    return super.execute();
  };

  // getAnalyticsOpts() {
  //   return {
  //     key: 'FTUE_mine_coins_gift_dialog',
  //     data: {
  //       gift_amount: 15_000,
  //     },
  //   };
  // }
}
