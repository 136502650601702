interface Props {
  url?: string;
  title: string;
  icon: string;
  className: string;
}

export const TradingSocialBtn = ({ className, icon, title, url }: Props) => {
  if (!url) {
    return null;
  }

  const onClick = () => {
    window.open(url, '_blank')?.focus();
  };

  return (
    <div className={`btn about-item ${className}`} onClick={onClick}>
      <div className="icon">
        <img src={icon} />
      </div>
      {title}
    </div>
  );
};
