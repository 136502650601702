import { ErrorCode, isHandledError } from '../../replicant/response';
import { TonConnectError, SendTransactionRequest } from '@tonconnect/sdk';
import { TonProvider } from './TonProvider';
import { ONE_DAY_MS } from '../../replicant/features/game/ruleset/contract';
import { BusinessController } from '../Controllers/BusinessController';
import { ConnectedWallet, TonConnectUI } from '@tonconnect/ui';
import gameConfig from '../../replicant/features/game/game.config';
import { analytics } from '@play-co/gcinstant';
import { BuyTokenInput, CreateContractInput } from './types';
import { cai, waitFor } from '../utils';
import { Optional } from '../types';
import { qpConfig } from '../config';
import { Cell, contractAddress, fromNano, toNano } from '@ton/core';
import { calculateTokensReceived, withRetry } from './utils';
import { ChainName } from '../../replicant/features/onchainTxs/onchainTxs.schema';
import { HP } from '../../replicant/lib/HighPrecision';

const POOL_INTERVAL = 500;
const TIMEOUT_TRIES = 25; // 12.5s
const WAIT_WALLET_ADDRESS_TIMEOUT = 4; //2s

enum TonEvents {
  OnBalanceUpdate = 'OnBalanceUpdate',
  OnWalletConnectUpdate = 'OnWalletConnectUpdate',
}

export class TonController extends BusinessController<TonEvents> {
  public static TestUIEnabled = qpConfig.testTON;

  public tonConnectUI!: TonConnectUI;

  private provider = new TonProvider();

  private confirmationWaiter?: Promise<any>;

  public get walletAddress() {
    return this.provider.walletAddress;
  }

  private _balance?: string;

  public get balance() {
    return this._balance ?? '0';
  }

  public get connected() {
    return this.tonConnectUI.connected;
  }

  init = async () => {
    const stage = process.env.REACT_APP_STAGE || 'dev';
    this.tonConnectUI = new TonConnectUI({
      manifestUrl: `${gameConfig.playUrl}/tonconnect/${stage}/tonconnect-manifest.json`,
    });

    this.tonConnectUI.onStatusChange(
      async (status: ConnectedWallet | null) => {
        await this.app.replicantClientPromise;

        if (status) {
          this.app.replicant.invoke.setWalletInfo({
            app_name: status.appName,
            address: status.account.address,
          });

          analytics.setUserProperties({
            walletAddress: status.account.address,
          });

          this.provider.setWalletAddress(status.account.address);
          this.getBalance();
        } else {
          this._balance = undefined;
          this.provider.setWalletAddress(undefined);
        }
        this.sendEvents(TonEvents.OnWalletConnectUpdate);
      },
      (error: TonConnectError) => {
        // console.error(`Failed to connect Ton Wallet: ${error.message}`);
        analytics.pushError('WalletConnectError', {
          name: error.name,
          message: error.message,
        });
      },
    );

    this.app.maybeFixBrokenWalletConnectQuest();
  };

  private onError = (msg: any, code: ErrorCode) => {
    console.error(msg);
    return new Error(code);
  };

  public getBalance = async () => {
    const rawBalance = await this.provider.getBalance();
    const prevBalance = this._balance;
    this._balance = fromNano(rawBalance);
    if (this._balance !== prevBalance) {
      this.sendEvents(TonEvents.OnBalanceUpdate);
    }
    return this._balance;
  };

  private waitForWalletAddress = async (retries = 0): Promise<void> => {
    if (retries > WAIT_WALLET_ADDRESS_TIMEOUT) {
      return Promise.reject();
    }
    await waitFor(POOL_INTERVAL);
    if (this.walletAddress) {
      return Promise.resolve();
    }
    return this.waitForWalletAddress(++retries);
  };

  connect = async () => {
    if (!this.tonConnectUI.connected || !this.provider.walletAddress) {
      this.provider.setWalletAddress(this.tonConnectUI.account?.address);
      await this.tonConnectUI.openModal();
      cai('connect', {});
      return this.waitForWalletAddress();
    }
    return Promise.resolve();
  };

  private sendTransaction = async (
    txRequest: SendTransactionRequest,
    { memeId, chain = 'jetton' }: { memeId: string; chain?: ChainName },
  ) => {
    try {
      const { boc } = await this.tonConnectUI.sendTransaction(txRequest);
      const cell = Cell.fromBase64(boc);
      const buffer = cell.hash();
      const txHash = buffer.toString('hex');
      console.log('Transaction Hash:', txHash);

      // this.app.invoke.asyncRecordOnchainTxs({ memeId, chain, txHashes: [txHash] });

      // @TODO: Here is a good place to start an async check for transaction complete
      return txHash;
    } catch (e) {
      throw e;
    }
  };

  private handleJettonContractAddress = (memeId: string) => {
    return async () => {
      try {
        const jettonContractAddress =
          await this.provider.getJettonContractAddressFromMemeId(memeId);

        if (jettonContractAddress) {
          this.app.invoke.onJettonContractMinted({
            memeId: memeId,
            jettonContractAddress,
          });
          return jettonContractAddress;
        }
        return undefined;
      } catch (e: any) {
        // @TODO: Show error in UI
        throw e;
      } finally {
        this.confirmationWaiter = undefined;
      }
    };
  };

  disconnect = async () => {
    await this.tonConnectUI.disconnect();
  };

  private confirmCreateContract = (txHash: string) => {
    return () =>
      this.provider
        .getTxWithHash(txHash)
        .then((res) => {
          if (!res.success) {
            throw new Error(`Tx failed`);
          }
          // Observed the buy action has 6 transactions but in theory after the first 3 we can consider it success
          if (res.actions < 3) {
            return undefined;
          }
          return 'success';
        })
        .catch(() => {
          // @TODO: Handle failure
        });
  };

  createContract = async (input: CreateContractInput) => {
    console.log('createContract', { input });

    // When creating the Jetton contract we want to have gemz_ prefix
    input.metadata.symbol = `gemz_${input.metadata.symbol}`;

    await this.connect();

    try {
      if (!this.tonConnectUI.connected) {
        throw this.onError(
          ErrorCode.OC_TON_NOT_READY,
          ErrorCode.OC_TON_NOT_READY,
        );
      }

      const createJettonContractTx =
        await this.provider.getCreateJettonContractTx(input, this.app.now());

      const txHash = await this.sendTransaction(createJettonContractTx, {
        memeId: input.memeId,
      });

      withRetry(this.handleJettonContractAddress(input.memeId), {
        maxRetries: 20,
      });
      this.confirmationWaiter = withRetry(this.confirmCreateContract(txHash), {
        maxRetries: 20, // 20s
      });
    } catch (e: any) {
      if (!isHandledError(e)) {
        console.error(e);
      }

      throw e;
    }
  };

  private confirmBuyToken = (txHash: string) => {
    return () =>
      this.provider.getTxWithHash(txHash).then((res) => {
        if (!res.success) {
          throw new Error(`Tx failed`);
        }
        // Observed the buy action has 6 transactions but in theory after the first 3 we can consider it success
        if (res.actions < 3) {
          return undefined; // retry
        }
        return 'success';
      });
  };

  buyToken = async (input: BuyTokenInput) => {
    await this.connect();
    cai('buyToken 2', { input });

    const { meme, tokenAmount, tonAmount } = input;

    const buyTokenTx = await this.provider.getBuyTokenTx(
      {
        memeId: meme.id,
        ownerAddress: meme.creatorWalletAddress,
        amount: tokenAmount,
        metadata: {
          name: meme.name,
          // When creating the Jetton contract we want to have gemz_ prefix
          symbol: `gemz_${meme.ticker}`,
          description: meme.description.description,
          image: meme.image,
        },
      },
      tonAmount,
      this.app.now(),
    );

    const txHash = await this.sendTransaction(buyTokenTx, { memeId: meme.id });

    this.confirmationWaiter = withRetry(this.confirmBuyToken(txHash), {
      maxRetries: 60, // 1min (30s avg)
    }).catch(() => {
      this.app.ui.showError({
        title: 'Transaction Failed',
        message: `Failed to buy meme.\n${txHash}`,
      });
    });

    if (!meme.jettonContractAddress) {
      withRetry(this.handleJettonContractAddress(meme.id), { maxRetries: 20 });
    }
  };

  sellToken = async (memeId: string, amount: bigint) => {
    await this.connect();

    const sellTokenTx = await this.provider.getSellTokenTx(
      {
        amount,
        memeId,
      },
      this.app.now(),
    );

    const txHash = await this.sendTransaction(sellTokenTx, { memeId });
    // @TODO: see how many transactions we get from selling
    this.confirmationWaiter = withRetry(this.confirmBuyToken(txHash), {
      maxRetries: 60, // 1min (30s avg)
    }).catch(() => {
      this.app.ui.showError({
        title: 'Transaction Failed',
        message: `Failed to sell meme.\n${txHash}`,
      });
    });
  };

  getMemeBalance = async (memeId: string) => {
    const meme = await this.app.memes.getToken(memeId);
    console.log('getMemeBalance', { memeId, meme });
    if (!meme || !meme.jettonContractAddress) {
      return HP(0);
    }
    return HP(await this.provider.getJettonBalance(meme.jettonContractAddress));
  };

  getTx = async (hash: string) => {
    const tx = await this.provider.getTx(hash);
    console.log({ tx });
    return tx?.hash;
  };

  getHolders = async (contractAddress: string) => {
    const holders = await this.provider.getHolders(contractAddress);
    console.log({ holders });
    return holders.total;
  };

  getBuyPrice = async (contractAddress: string, amount: string) => {
    return HP(await this.provider.getJettonBuyPrice(contractAddress, amount));
  };

  getListingTokens = (ton: string) => {
    return this.provider.getListingTokens(ton);
  };

  getBuyTokensPerTon = (contractAddress: string, ton: string) => {
    return this.provider.getBuyTokens(contractAddress, ton);
  };

  getSellTokensPerTon = async (contractAddress: string, tokens: bigint) => {
    return this.provider.getSellTokens(contractAddress, tokens);
  };

  // This is used to render the test page
  public ui = {
    connect: this.connect,
    disconnect: this.disconnect,
    createContract: this.createContract,
    buyToken: this.buyToken,
    sellToken: this.sellToken,
    getJettonContractAddress: this.provider.getJettonContractAddressFromMemeId,
    getMemeBalance: this.getMemeBalance,
    getJettonWalletAddress: this.provider.getJettonWalletAddress,
    getJettonBuyPrice: this.provider.getJettonBuyPrice,
    // getTxs: this.provider.getTransactions,
    getTx: this.getTx,
    getHolders: this.getHolders,
  };
}
