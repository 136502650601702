import {
  createSharedStateMessages,
  createSharedStateMessage,
  SB,
  createMessage,
} from '@play-co/replicant';
import { createMessages } from '../../createMessages';
import { teamSharedStateSchema } from './teams.schema';
import { clampScore } from '../../utils/numbers';

const { addMember: addMemberMessage } = createSharedStateMessages(
  teamSharedStateSchema,
)({
  addMember: createSharedStateMessage(
    SB.object({
      score: SB.number(),
      timestamp: SB.number(),
    }),
    (state, { score }) => {
      // Update the members count.
      state.global.membersCount++;
      state.global.score = clampScore(state.global.score + score);
    },
  ),
});

// @warning: never remove/rename shared state messages
export const teamsMessages = createSharedStateMessages(teamSharedStateSchema)({
  // do not remove/rename
  joinTeam: addMemberMessage,

  // do not remove/rename
  addMember: addMemberMessage,

  // do not remove/rename
  removeMember: createSharedStateMessage(
    SB.object({
      userId: SB.string().optional(),
      score: SB.number(),
      timestamp: SB.number(),
      reduceMemberCount: SB.boolean().optional(),
    }),
    (state, { userId, score, reduceMemberCount }, meta) => {
      userId = userId ?? meta.senderId;

      state.global.score = clampScore(state.global.score - score);
      if (reduceMemberCount) {
        state.global.membersCount--;
      }
    },
  ),

  // do not remove/rename
  updateTeamProfile: createSharedStateMessage(
    SB.object({
      profile: SB.object({
        name: SB.string().optional(),
        photo: SB.string().optional(),
        description: SB.string().optional(),
        inviteLink: SB.string().optional(),
        search: SB.string().optional(),
      }),
      timestamp: SB.number(),
    }),
    (state, args, meta) => {
      // Update the team profile.
      Object.assign(state.global.profile, args.profile);
    },
  ),

  /**
   * @deprecated Use the `score` counter instead.
   *
   * TODO: unused, remove in a future release.
   */
  addScore: createSharedStateMessage(
    SB.object({
      score: SB.number(),
    }),
    (state, { score }) => {
      state.global.score = clampScore(state.global.score + score);
    },
  ),

  // do not remove/rename
  setMigrationData: createSharedStateMessage(
    SB.object({
      score: SB.number(),
    }),
    (state, { score }, meta) => {
      state.global.migration = { score };
    },
  ),
});

export const teamMemberMessages = createMessages({
  kickFromTeam: createMessage(SB.object({}), (state, _) => {
    delete state.team_id;
  }),
});
