import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../config';
import { MutableState } from '../schema';

export async function removeDuplicatedFriends(
  state: MutableState,
  _api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  if (!state.friendIds) {
    // the friend migrator happens after this migrator, therefore this should not happen
    throw new Error(`Ran removeDuplicatedFriends migrator without friendIds`);
  }

  const friendIds = state.friendIds;
  const duplicationFreeFriendIds: string[] = [];
  for (let i = 0; i < friendIds.length; i += 1) {
    const friendId = friendIds[i];
    // duplicated friend ids came one after the other
    if (
      friendId === duplicationFreeFriendIds[duplicationFreeFriendIds.length - 1]
    ) {
      continue;
    }

    duplicationFreeFriendIds.push(friendId);
  }

  state.friendIds = duplicationFreeFriendIds;
}
