import { PowerUp } from '../replicant/features/powerups/types';
import { getLastRocketman } from '../replicant/features/game/game.getters';
import { Booster, Buff, StartSessionResponse } from './types';
import { League } from '../replicant/features/game/ruleset/league';

const getPlayer = () => ({
  energy: 1000,
  energyPerSecond: 1,
  energyLimit: 1000,
  rocketmanMultiplier: 1,
  lastRocketmanStart: 1715210856700,
  pointsPerTap: 3,
  score: 436,
  balance: 436,
  league: League.league1,
  isFirstSession: true,
  platformStorage: {},
});

const getMockTeam = () => ({
  id: '-4192078186',
  score: 10,
  members: 1,
  name: 'Cai Team 4',
  creatorId: '6540606306',
  photo: 'https://notgemz-game.s3.amazonaws.com/media/-4192078186.jpg',
  description: 'Please work live :D',
  inviteLink: 'https://t.me/+TjAvWd8f0W4wYmIx',
  rank: 1,
  players: [],
});

const startSession = async (): Promise<StartSessionResponse> => {
  return {
    player: getPlayer(),
    startTime: 1715221083068,
    // @todo: remove (POST SEASON 2 MIGRATION)
    // botEarnings: 100,
    // inviteDrawerDuration: 0,
    // powerUpBonus: 0,
    // unclaimedReferralRewards: 0,
    // Enable this to see in a team
    // team: getMockTeam(),
  };
};

const friends = async () => [
  {
    team: 'FooBar',
    picture: '',
    username: 'Foo',
    league: League.league1,

    score: 10000,
    contribution: 10,
  },

  {
    team: 'FooBar',
    picture: '',
    username: 'Foo',
    league: League.league1,

    score: 10000,
    contribution: 10,
  },

  {
    team: 'FooBar',
    picture: '',
    username: 'Foo',
    league: League.league1,

    score: 10000,
    contribution: 10,
  },
];

const leaderboard = async () => [
  {
    id: '-4192078186',
    score: 1000,
    members: 1,
    name: 'Cai Team 4 - with a really long name to test ellipsis stuff',
    creatorId: '6540606306',
    photo: 'https://notgemz-game.s3.amazonaws.com/media/-4192078186.jpg',
    description: 'Please work live :D',
    inviteLink: 'https://t.me/+TjAvWd8f0W4wYmIx',
    rank: 1,
    players: [],
    search: undefined,
  },
  {
    id: '-4130903336',
    score: 300,
    members: 0,
    name: 'Cai Dota Team - with a really long name to test ellipsis stuff',
    creatorId: '6540606306',
    photo: 'https://notgemz-game.s3.amazonaws.com/media/-4130903336.jpg',
    description: 'We shall conquer the world&#33;',
    inviteLink: 'https://t.me/+SHFI_M8wMJliM2Yx',
    rank: 2,
    players: [],
  },
];

const listTeams = async () => [
  {
    id: '-4192078186',
    score: 1000,
    members: 1,
    name: 'Cai Team 4 - with a really long name to test ellipsis stuff',
    creatorId: '6540606306',
    photo: 'https://notgemz-game.s3.amazonaws.com/media/-4192078186.jpg',
    description: 'Please work live :D',
    inviteLink: 'https://t.me/+TjAvWd8f0W4wYmIx',
    rank: 1,
    players: [],
  },
  {
    id: '-4130903336',
    score: 300,
    members: 0,
    name: 'Cai Dota Team - with a really long name to test ellipsis stuff',
    creatorId: '6540606306',
    photo: 'https://notgemz-game.s3.amazonaws.com/media/-4130903336.jpg',
    description: 'We shall conquer the world&#33;',
    inviteLink: 'https://t.me/+SHFI_M8wMJliM2Yx',
    rank: 2,
    players: [],
  },
];

const getEarnAchievementStates = async () => ({
  InviteBonus: false,
  FollowX: false,
  FollowXJW: false,
  JoinCommunity: false,
  Invite5: false,
  Invite10: false,
  Invite100: false,
});

const getTeam = async (teamId: string) => getMockTeam();

const joinTeam = async (userId: string, teamId: string) => ({
  player: getPlayer(),
  team: getMockTeam(),
});

const leaveTeam = (userId: string) => ({
  player: getPlayer(),
  team: getMockTeam(),
});

const getShop = async (userId: string) => {
  return {
    buffs: {
      Rocketman: {
        available: 3,
        maxUses: 3,
        timeLeft: -1715192362020, // todo(Cai): is this alright? is it the same as full energy?
      },
      FullEnergy: {
        timeLeft: -1715192362020,
      },
    },
    boosters: {
      MultiTap: {
        price: 8000,
        level: 3,
        maxedOut: false,
      },
      RechargeLimit: {
        price: 2000,
        level: 1,
        maxedOut: false,
      },
      RechargeSpeed: {
        price: 2000,
        level: 1,
        maxedOut: false,
      },
      AutoTap: {
        price: 20000,
        level: 0,
        maxedOut: false,
      },
    },
  };
};

const buyBooster = (userId: string, booster: Booster) => ({
  player: getPlayer(),
});

const buyBuff = (userId: string, buff: Buff) => ({
  player: getPlayer(),
});

// const getPlatformStorage = (userId: string) => getPlayer().platformStorage;

// const setPlatformStorage = (userId: string, storage: Object) =>
//   (getPlayer().platformStorage = storage);
// const getLeagueLeaderboard = async (league: League, currentUserId: string) => [
//   {
//     rank: 1,
//     username: 'foo',
//     picture: '',
//     score: 0,
//     league: League.league1,
//   },
// ];

const getLanguageCode = () => 'en';

export const mockGameApi = {
  startSession,
  // addBadges: async () => {},

  // listTeams,
  // getEarnAchievementStates,
  // leaderboard,
  // friends,
  // getTeam,
  // leaveTeam,
  // joinTeam,
  // getShop,
  // buyBooster,
  // buyBuff,
  // getPlatformStorage,
  // setPlatformStorage,
  // getLeagueLeaderboard,
  getLanguageCode,
};

export const mockSocialApi = {
  getFriends: async () => ({
    friendCount: 3,
    friends: [
      {
        id: '0',
        username: 'Mock friend 01',
        contribution: 150,
        picture: '',
        team: 'foo',
        score: 2000,
        league: League.league2,
      },

      {
        id: '1',
        username: 'Mock friend 02',
        contribution: 400,
        picture: '',
        team: 'foo',
        score: 3000,
        league: League.league3,
      },

      {
        id: '2',
        username: 'Mock friend 03',
        contribution: 100,
        picture: '',
        team: 'foo',
        score: 1000,
        league: League.league1,
      },
    ],
  }),
};
