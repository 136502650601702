import { AppController } from '../AppController';
import { MemeFilters } from './types';
import { MemesEvents } from './MemesController';
import { MemeList } from './MemeList';
import { BusinessController } from '../BusinessController';

interface MemeListCtrlOpts<Filter, MemeClass> {
  sendEvent: (evt: MemesEvents) => void;
  filters: Filter[];
  defaultFilter: Filter;
  MemeListClass: MemeClass;
  /**
   * 'undefined': will skip all pretching
   * 'waitFirst': will wait current filter preftch then prefetch rest async
   * 'async': will prefetch all async
   */
  prefetch?: 'waitFirst' | 'async';
  id: string;
}

export class MemeListController<
  F extends MemeFilters,
> extends BusinessController<'', MemeListCtrlOpts<F, typeof MemeList>> {
  private lists: Record<F, MemeList> = {} as Record<F, MemeList>;

  private _listFilters: F[] = [];

  private currentFilter: F = this._listFilters[0];

  private isUsingDefaultFilter = true;

  private get currentList() {
    return this.lists[this.currentFilter];
  }

  public get listing() {
    return this.currentList?.listing;
  }

  public get firstItem() {
    return this.currentList?.firstItem;
  }

  public get filter() {
    return this.currentFilter;
  }

  constructor(
    app: AppController,
    private opts: MemeListCtrlOpts<F, typeof MemeList>,
  ) {
    super(app);
    const { filters, sendEvent, MemeListClass, defaultFilter } = opts;
    this.currentFilter = defaultFilter;
    this.lists = filters.reduce((res, filter) => {
      // Instantiate
      res[filter] = new MemeListClass(this.app, {
        filter,
        onUpdate: sendEvent,
      });
      return res;
    }, {} as Record<F, MemeList>);
    this._listFilters = filters;
  }

  // Expected to be instantiated and called only in `MemesController`
  public init = async () => {
    (Object.values(this.lists) as MemeList[]).forEach((list) => {
      list.init();
    });

    if (!this.opts.prefetch) {
      return;
    }

    const lists = Object.keys(this.lists) as F[];

    let promises = lists.map((l) => {
      const list = this.lists[l];
      return list.requestUpdate('refresh');
    });

    if (this.opts.prefetch === 'waitFirst') {
      // Make sure the first is awaited on
      await this.currentList.requestUpdate('refresh');
      // Remove the one we already fetched from promise list
      promises = lists
        .filter((l) => l !== this.currentFilter)
        .map((l) => {
          const list = this.lists[l];
          return list.requestUpdate('refresh');
        });
    }

    Promise.all(promises);
  };
  // @TODO: Refactor in such a way the warning below is not necessary
  /**
   * @warning This function is meant to be used only by `MemesController`
   */
  public setFilter = async (filter: F | MemeFilters) => {
    const newFilter =
      this.isUsingDefaultFilter || filter !== this.currentFilter;
    this.currentFilter = filter as F;
    if (newFilter) {
      this.isUsingDefaultFilter = false;
      await this.currentList.requestUpdate('refresh');
      this.opts.sendEvent(MemesEvents.OnFilterChange);
    }
    return this;
  };

  public refreshTargetList = async (target: F) => {
    const list = this.lists[target];
    if (list) {
      await list.requestUpdate('refresh');
    }
  };

  public getItem = (tokenId: string) => {
    return this.currentList?.getItem(tokenId);
  };

  public getIsMyFilter = (filter: MemeFilters) => {
    return this._listFilters.includes(filter as F);
  };

  public paginate = () => {
    return this.currentList.paginate();
  };

  public isEndOfList = () => {
    return this.currentList.isEndOfList();
  };

  public search = (term: string) => {
    return this.currentList.search(term);
  };

  public refresh = () => {
    this.currentList.requestUpdate('refresh');
  };

  public getSearchTermLength = () => {
    return this.currentList.getSearchTermLength();
  };

  public getItemRenderZone = (index: number): 'Hot' | 'Cold' => {
    if (index < 0) {
      return 'Cold';
    }

    const currentSlideIndex = this.app.memes.slideIndex;
    const endOfTheList =
      currentSlideIndex === this.app.memes.currentList.listing.length - 1;
    const startOfTheList = currentSlideIndex === 0;

    const prevSlideIndex = currentSlideIndex - 1;
    const nextSlideIndex = currentSlideIndex + 1;

    const iAmTheFirstToken = index === 0;
    const iAmTheLastToken =
      index === this.app.memes.currentList.listing.length - 1;

    if (index >= prevSlideIndex && index <= nextSlideIndex) {
      return 'Hot';
    }

    if (iAmTheFirstToken && endOfTheList) {
      return 'Hot';
    }

    if (iAmTheLastToken && startOfTheList) {
      return 'Hot';
    }

    return 'Cold';
  };

  /**
   * Use only internally
   */
  public getList = (filter: F | MemeFilters): MemeList => {
    return this.lists[filter as F];
  };
}
