import {
  getMyOffchainTokenPointsAccumulated,
  getMyOffchainTokenPointAmount,
} from '../../../replicant/features/tradingMeme/tradingMeme.getters';
import { Optional, WithProxy } from '../../types';
import { BusinessController } from '../BusinessController';
import { TMGEvents } from './TMGController';

/**
 * TokenMiniGame is the base class for all TMG (Token Mini Games) features;
 * It normalises event dispatching proxied via TMGController (so consuming components listen to the same controller instead of each feature)
 * It normalises the context of current selected trading token by exposing `tokenId`
 * @TODO Add here any other common functionality among all TMG features
 */
export class TokenMiniGame<
  T extends Record<string, any>,
> extends BusinessController<TMGEvents, WithProxy<TMGEvents>> {
  protected sendEvent!: (evt: TMGEvents) => void;

  protected get tokenId() {
    return this.app.memes.currentMeme.meme?.id;
  }

  public async init({ sendEvtProxy }: WithProxy<TMGEvents>) {
    this.sendEvent = sendEvtProxy;

    this.onInitComplete();
  }

  protected track = (evt: string, extraProps: Record<string, any> = {}) => {
    const token = this.app.memes.currentMeme.meme;
    if (!token) {
      return;
    }

    this.app.track(evt, {
      cardID: token.id,
      memecard_ticker: token.ticker,
      memecard_name: token.name,
      memecard_creator: token.creatorName,
      token_amount_owned: getMyOffchainTokenPointAmount(
        this.app.state,
        token.id,
      ),
      points_ever_owned: getMyOffchainTokenPointsAccumulated(
        this.app.state,
        token.id,
      ),
      ...extraProps,
    });
  };

  public get state(): Optional<T> {
    return {} as T;
  }
}
