import { teamSharedStateSchema } from './teams.schema';
import { createSharedState } from '@play-co/replicant';
import { teamsMessages } from './teams.messages';
import { teamsComputedProperties } from './teams.properties';

export const teamSharedStates = createSharedState(
  teamSharedStateSchema,
  teamsMessages,
  teamsComputedProperties,
);

export type TeamSharedStates = typeof teamSharedStates;
