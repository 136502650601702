import { MutableState } from '../../schema';
import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../../config';
import { getFollowingsSubStateId } from './followings.getters';

export function createFollowingsSubState(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  const followingsSubStateId = getFollowingsSubStateId(state);

  api.sharedStates.friends.create(followingsSubStateId);

  state.followingsSubStateId = followingsSubStateId;
}

export async function addFollowing(
  state: MutableState,
  followingId: string,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  if (state.followingsSubStateId === undefined) {
    createFollowingsSubState(state, api);
  }

  const followingsSubStateId = getFollowingsSubStateId(state);
  const followingsSubState = await api.sharedStates.followings.fetch(
    followingsSubStateId,
  );

  if (!followingsSubState) {
    throw new Error(
      `Failed to fetch followings substate of id ${followingsSubStateId}`,
    );
  }

  if (followingsSubState.global.followings.includes(followingId)) {
    return;
  }

  api.sharedStates.followings.postMessage.addFollowing(followingsSubStateId, {
    followingId,
  });

  api.postMessage.follow(followingId, {});

  state.followingsCount += 1;
}

export async function removeFollowing(
  state: MutableState,
  followingId: string,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  if (state.followingsSubStateId === undefined) {
    // no following substate
    return;
  }

  const followingsSubStateId = getFollowingsSubStateId(state);
  const followingsSubState = await api.sharedStates.followings.fetch(
    followingsSubStateId,
  );

  if (!followingsSubState) {
    throw new Error(
      `Failed to fetch followings substate of id ${followingsSubStateId}`,
    );
  }

  if (!followingsSubState.global.followings.includes(followingId)) {
    return;
  }

  api.sharedStates.followings.postMessage.removeFollowing(
    followingsSubStateId,
    {
      followingId,
    },
  );

  api.postMessage.unfollow(followingId, {});

  state.followingsCount -= 1;
}
