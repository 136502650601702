import { cheatsActions } from './features/cheats/cheats.actions';
import { followingsActions } from './features/followings/followings.actions';
import { gameActions } from './features/game/game.actions';
import { tradingMemeActions } from './features/tradingMeme/tradingMeme.actions';
import { teamActions } from './features/teams/teams.actions';
import { onchainTxsActions } from './features/onchainTxs/onchainTxs.actions';
import { onchainHoldersActions } from './features/onchainHolders/onchainHolders.actions';

export const actions = {
  ...gameActions,
  ...teamActions,
  ...tradingMemeActions,
  ...followingsActions,
  ...onchainTxsActions,
  ...onchainHoldersActions,
  ...(process.env.STAGE !== 'prod'
    ? cheatsActions
    : ({} as typeof cheatsActions)),
};

export default actions;
