import './Spinner.scss';
import { assets } from '../../../assets/assets';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { UIEvents } from '../../../data/Controllers/UIController/UITypes';
import { useEffect, useRef, useState } from 'react';

export const Spinner = () => {
  useAppUpdates({
    id: 'Spinner',
    listener: app.ui.attachEventListener(UIEvents.OnSpinnerUpdate),
  });

  const spinnerRef = useRef<any>();
  const [visible, setVisible] = useState(app.ui.spinnerActive);

  useEffect(() => {
    if (app.ui.spinnerActive) {
      setVisible(app.ui.spinnerActive);
    } else {
      const transition = spinnerRef.current;
      transition?.addEventListener('transitionend', () => {
        setVisible(app.ui.spinnerActive);
      });
    }
  }, [app.ui.spinnerActive]);

  return (
    <div
      ref={spinnerRef}
      className={`spinner ${app.ui.spinnerActive ? 'fadein' : 'fadeout'}`}
    >
      {visible && (
        <div className="spinner-container">
          <div className="loading rotating">
            <img src={assets.s2_migration_warning_loading} />
          </div>
        </div>
      )}
    </div>
  );
};
