import { SB } from '@play-co/replicant';
import { earningsSchema } from '../player.schema';
import { State } from '../../../schema';
import { tests } from '../../../ruleset';
import { t } from 'i18next';

export type EarningKey = keyof SB.ExtractType<typeof earningsSchema.earnings>;
export const earningRewards: Record<EarningKey, number> = {
  tutorialRocketQuest: 1_000,
  joinCommunity: 20_00,
  inviteFriends2: 40_000,
  inviteFriends5: 150_000,
  inviteFriends10: 300_000,
  inviteFriends100: 3_000_000,
  walletConnect: 100_000,
  // special
  followOnYoutube: 50_000,
  joinAnnouncement: 20_000,
  joinInstagram: 20_000,
  joinTiktok: 20_000,
  followOnX: 20_000,
  followOnXJW: 20_000,
};

export const earningCondition: Record<
  EarningKey,
  (state: State) => string | undefined
> = {
  tutorialRocketQuest: () => undefined,
  followOnX: () => undefined,
  followOnXJW: () => undefined,
  joinCommunity: () => undefined,
  inviteFriends2: (state: State) =>
    state.friendCount < 2
      ? t('earn_more_friends_error', { friends: 2 - state.friendCount })
      : undefined,
  inviteFriends5: (state: State) =>
    state.friendCount < 5
      ? t('earn_more_friends_error', { friends: 5 - state.friendCount })
      : undefined,
  inviteFriends10: (state: State) =>
    state.friendCount < 10
      ? t('earn_more_friends_error', { friends: 10 - state.friendCount })
      : undefined,
  inviteFriends100: (state: State) =>
    state.friendCount < 100
      ? t('earn_more_friends_error', { friends: 100 - state.friendCount })
      : undefined,
  walletConnect: (state: State) => {
    return state.wallet.length ? undefined : t('earn_connect_wallet_error');
  },
  followOnYoutube: (state: State) => {
    return state.followOnYoutubeTimestamp
      ? undefined
      : t('earn_follow_youtube_error');
  },
  joinAnnouncement: (state: State) => {
    // @TODO: Temp fake check until we fix the feature
    return undefined;
    // return state.earnings.joinAnnouncement
    //   ? undefined
    //   : t('earn_join_announce_error');
  },
  joinInstagram: () => undefined,
  joinTiktok: () => undefined,
};
