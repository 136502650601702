import { assets } from '../../../assets/assets';
import { EarnAchievementType } from '../../../data/types';
import { useEffect, useState } from 'react';
import Timer from '../../shared/Timer/Timer';

interface Props {
  // @TODO: Look how to improve type here
  id: EarnAchievementType | string;
  image: string;
  title: string;
  subtitle?: string;
  score: string;
  disabled: boolean;
  onClick: (id: EarnAchievementType | string) => void;
  claimed: boolean;
  timer?: number; // time in ms
  isPromo?: boolean;
}

export const EarnCard = ({
  id,
  image,
  title,
  score,
  disabled,
  onClick,
  claimed,
  timer,
  isPromo,
}: Props) => {
  const onCardClick = () => {
    if (disabled) return;
    onClick(id);
  };

  const [showTimer, setShowTimer] = useState<boolean>(!!timer);
  const [timerValue, setTimerValue] = useState<Date>();
  useEffect(() => {
    if (timer) {
      const timerValue = new Date();
      timerValue.setMilliseconds(timerValue.getMilliseconds() + timer);
      setTimerValue(timerValue);
      setShowTimer(true);
    }
  }, [timer]);

  // uncomment to debug promo styling
  // isPromo = Math.random() < 0.5;

  // todo: we should use some kind of transition/animation when a card changes state to disabled

  return (
    <div
      className={`earn-card ${disabled && 'disabled'} ${
        isPromo ? 'promo' : ''
      }`}
      onClick={onCardClick}
    >
      {isPromo && <img className="promo-image" src={assets.earn_promo} />}
      <img
        className={`earn-card-icon  ${isPromo ? 'promo' : ''}`}
        src={image}
      />
      <div className="earn-card-info">
        <div className="earn-card-info-title">{title}</div>
        {!showTimer && (
          <div className="earn-card-info-points">
            <img className="earn-card-info-points-icon" src={assets.coin} />
            <div className="earn-card-info-points-value">{score}</div>
          </div>
        )}
        {showTimer && timerValue && (
          <div className="earn-card-info-points">
            <div className="earn-card-info-points-value">
              Checking{' '}
              <Timer
                expiryTimestamp={timerValue}
                onExpire={() => setShowTimer(false)}
              />
            </div>
          </div>
        )}
      </div>
      {claimed ? (
        <img className="earn-card-arrow" src={assets.earn_check} />
      ) : (
        <img className="earn-card-arrow" src={assets.button_arrow_right} />
      )}
    </div>
  );
};
