import { createSharedStateSchema, SB, WithMeta } from '@play-co/replicant';

export const holderSchema = SB.object({
  playerId: SB.string(),
  holdingAmount: SB.string(),
});

export const holdersSchema = SB.array(holderSchema);

export type Holding = SB.ExtractType<typeof holderSchema>;

export const onchainHoldersSharedStateSchema = createSharedStateSchema({
  global: {
    schema: SB.object({
      memeId: SB.string(),
      holders: holdersSchema,
    }),
  },
});

export type onchainHoldersState = SB.ExtractType<
  typeof onchainHoldersSharedStateSchema.global.schema
>;

export type MutableonchainHoldersState = WithMeta<onchainHoldersState, {}>;
