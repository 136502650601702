import { useTranslation } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import {
  FarmingSearchResult,
  ProfileEvents,
} from '../../../data/Controllers/ProfileController';
import { TradingSearchResult } from '../../../replicant/features/tradingMeme/tradingMeme.properties';
import {
  formatPrice,
  largeIntegerToLetter,
} from '../../../replicant/utils/numbers';
import { Tabs, TabStyle } from '../../shared/Tabs/Tabs';
import { Page } from '../Page';
import { ProfileItem } from './ProfileItem/ProfileItem';
import './ProfilePage.scss';
import { TonController } from '../../../data/TonProvider/TonController';
import { CreatorImage } from '../../shared/CreatorImage/CreatorImage';
import { getUserStartingSeason } from '../../../replicant/features/game/game.getters';

// todo: right now we have exported ProfileTab and local ProfileTabV2
// todo: we need to unify both into a single one, to be used all around the app
export type ProfileTabV2 = 'Created' | 'Wallet' | 'Points';

// -----------------------------------------------------------------------------
// This method is used in order to set special responsive dimensions
// of tabs container, for Profile only

function getSectionHeightRelativeToScreen(
  margin: number = 0,
  extra: number = 0,
) {
  var style = getComputedStyle(document.body);
  const prop = style.getPropertyValue('--safe-height');

  let h = '100svh';
  if (prop) h = `calc(${prop} - 28px)`; // mobile has an extra of 28px

  return `calc(${h} - ${margin}px - 274px - 54px + ${extra}px)`;
}

function getWalletConnected() {
  // return true;
  return app.ton.tonConnectUI.connected;
}

// ====================================================================
// #region Page

export const ProfilePage = () => {
  const { t } = useTranslation();

  useAppUpdates({
    id: 'ProfilePage',
    listener: app.views.ProfilePage.attachEventListener(),
  });

  const { visible, hide } = app.views.ProfilePage;

  useAppUpdates({
    id: 'ProfilePage/ProfileEvents.OnUpdate',
    listener: app.profile.attachEventListener(ProfileEvents.OnUpdate),
    dep: visible,
  });

  // todo carles: update profile state with v2 tabs
  // const tab = app.ui.state.profile.ownedOrCreatedOrFarming;
  // const isShowingFarming = getTMGFarmingIsShowing(app.state);
  // useEffect(() => {
  //   // When we leave the page reset the tab to owned
  //   if (!visible) {
  //     app.ui.setProfileState({ ownedOrCreatedOrFarming: 'Owned' });
  //   }
  // }, [visible]);

  const selectTab = (cat: ProfileTabV2) => {
    // record profile tab state in ui controller
    // todo carles: update profile state with v2 tabs
    // app.ui.setProfileState({ ownedOrCreatedOrFarming: cat });
    app.track('profile_tab_switch', {
      tab_name: cat,
    });
  };

  if (!visible) {
    return null;
  }

  // get current profile
  const { current } = app.profile;
  if (!current) {
    hide();
    return null;
  }

  // console.warn('>>> current profile', current);

  // is this our own profile or someone else's?
  const isSelf = current.isSelf;

  // get height of tabs content
  const sectionHeight = getSectionHeightRelativeToScreen(0, isSelf ? 0 : 42);

  return (
    <Page id="profile" visible={visible}>
      <ProfileHeader isSelf={isSelf} />

      {/* tabs */}
      <Tabs<ProfileTabV2>
        tabStyle={TabStyle.Underline}
        height={40}
        initial={'Created'}
        // autoSelected={tab}
        onTabClick={selectTab}
        tabs={[
          {
            id: 'Created' as ProfileTabV2,
            name: t('profile_tab_created'), // 'Created',
            component: (
              <ProfileTokenList
                tab="Created"
                list={current.tokensCreated}
                sectionHeight={sectionHeight}
              />
            ),
          },
          // todo carles: for now Tokens/Points tabs will both display 'owned' tokens
          {
            id: 'Wallet' as ProfileTabV2,
            name: 'Wallet', // t('profile_tab_owned'), // 'Owned',
            component: (
              <ProfileTokenList
                tab="Wallet"
                list={current.tokensOwned}
                sectionHeight={sectionHeight}
              />
            ),
          },
          {
            id: 'Points' as ProfileTabV2,
            name: 'Points', // t('profile_tab_owned'), // 'Owned',
            component: (
              <ProfileTokenList
                tab="Points"
                list={current.tokensOwned}
                sectionHeight={sectionHeight}
              />
            ),
          },
        ]}
      />
    </Page>
  );
};

// ====================================================================
// #region Header

const ProfileHeader = ({ isSelf }: { isSelf: boolean }) => {
  const { share, current } = app.profile;

  if (!current) {
    return null;
  }

  // get portfolio value and percent diff from profile controller
  const portfolioValue = current.portfolio.value;
  const portfolioChange = current.portfolio.diff;
  const portfolioChangeAbsoluteValue = (portfolioChange * portfolioValue) / 100;

  // const profitLoss = current.portfolio.profitLoss;
  // const tradingVolume = current.portfolio.tradingVolume;

  const onTapTonTest = () => {
    TonController.TestUIEnabled = true;
    app.tempReloadApp();
  };

  const onTapInfo = () => {
    // open drawer that display user rewards for s1 and s2
    app.ui.drawer.show({
      id: 'drawerProfileSeasonInfo',
    });
  };

  // players that started playing on s1 or s2 will have an info button
  const startingSeason = getUserStartingSeason(app.state);
  const hasInfoButton = startingSeason <= 2;

  return (
    /* header */

    <div className="profile-header">
      <div className="profile-header-top">
        {/* ton test button */}
        <div className="profile-ton-test" onClick={onTapTonTest}>
          TON Test
        </div>

        {/* info button */}
        <div
          className={`btn profile-header-top-button-circle info ${
            hasInfoButton ? '' : 'invisible'
          }`}
          onClick={onTapInfo}
        >
          <img src={assets.icon_info} />
        </div>

        {/* creator image */}
        <CreatorImage size={56} image={current.picture} />

        {/* share button */}
        <div
          className="btn profile-header-top-button-circle share"
          onClick={share}
        >
          <img src={assets.icon_sidebar_share} />
        </div>
      </div>

      {/* author name */}
      <div className="profile-header-info">
        <div className="profile-header-title">
          <div className="profile-header-title-label">{current.name}</div>
        </div>

        {/* connect/disconnect button */}
        {isSelf && <ButtonWallet />}

        {/* portfolio value */}
        <div className="profile-header-value-container">
          <div className="profile-header-value">
            ${largeIntegerToLetter(Math.round(portfolioValue), 3)}
          </div>
          <LabelValueChange
            value={portfolioChangeAbsoluteValue}
            percent={portfolioChange}
          />
        </div>
      </div>
    </div>
  );
};

// ====================================================================
// #region Wallet
// wallet button with connect/disconnect states

const ButtonWallet = () => {
  // does the use have a connected wallet
  const isConnected = getWalletConnected();

  const onConnectWallet = async () => {
    if (app.ton.tonConnectUI.connected) {
      return;
    }
    console.warn('>>> Connect wallet');
    await app.ton.tonConnectUI.openModal();
    // rerender here and if the value from tonConnectUi is updated the button state will reflect it
    app.views.ProfilePage.rerender();
  };

  const onDisconnectWallet = async () => {
    if (!app.ton.tonConnectUI.connected) {
      return;
    }

    await app.ton.tonConnectUI.disconnect();
    // todo carles: how do we exactly disconnet the wallet?
    console.warn('>>> Disconnect wallet');
    // rerender here and if the value from tonConnectUi is updated the button state will reflect it
    app.views.ProfilePage.rerender();
  };

  return (
    <div className="profile-wallet">
      {isConnected ? (
        <div
          className="btn btn-normal profile-button-wallet disconnect-wallet"
          onClick={onDisconnectWallet}
        >
          <div className="column">{'0x1234...123'}</div>
          <div className="separator" />
          <div className="column">
            <img src={assets.icon_link_off_black} />
          </div>
        </div>
      ) : (
        <div
          className="btn btn-normal profile-button-wallet connect-wallet"
          onClick={onConnectWallet}
        >
          <div className="column">{'Connect Wallet'}</div>
        </div>
      )}
    </div>
  );
};

// ====================================================================
// #region Change

const LabelValueChange = ({
  value,
  percent,
}: {
  value: number;
  percent?: number;
}) => {
  // value = 100;
  // percent = 50;
  if (!percent) percent = 0;

  const color = percent > 0 ? 'green' : 'red';
  const sign = percent > 0 ? '+' : '';

  return (
    <div className="label-value-change">
      {/* value */}
      <div className={`value ${color}`}>${largeIntegerToLetter(value, 3)}</div>

      {/* percent */}
      {percent && (
        <>
          <div className={`value solid ${color}`}>
            {sign}
            {formatPrice(Math.round(percent))}%{' '}
          </div>
        </>
      )}
    </div>
  );
};

// ====================================================================
// #region TokenList

interface ProfileTokenListProps {
  tab: ProfileTabV2;
  list: (FarmingSearchResult | TradingSearchResult)[];
  sectionHeight: string;
}

export const ProfileTokenList = ({
  tab,
  list,
  sectionHeight,
}: ProfileTokenListProps) => {
  console.warn('>>> ProfileTokenList', list);

  const isEmpty = list.length === 0;
  const isConnected = getWalletConnected();

  if (isEmpty || !isConnected) {
    return (
      <ProfileEmptyList
        tab={tab}
        disconnected={!isConnected}
        sectionHeight={sectionHeight}
      />
    );
  }

  return (
    <div
      className="profile-items-list-container"
      style={{ height: sectionHeight }}
    >
      <div className="profile-items-list">
        {list.map((item, index) => {
          return (
            <ProfileItem
              category={tab}
              key={index + '_' + item.id}
              offchainTokenData={item}
            />
          );
        })}
      </div>
    </div>
  );
};

// ====================================================================
// #region EmptyList

interface ProfileEmptyProps {
  tab: ProfileTabV2;
  sectionHeight: string;
  disconnected: boolean;
}

export const ProfileEmptyList = ({
  tab,
  sectionHeight,
  disconnected,
}: ProfileEmptyProps) => {
  function getCaptions() {
    switch (tab) {
      case 'Created':
        return {
          title: 'You have created 0 memes',
          message:
            'Memes you create will display here\nTap the button below to create now, it’s FREE!',
          button: 'Create',
          onClick: () => app.memes.factory.createNewToken(),
        };
      case 'Wallet':
        if (disconnected) {
          return {
            title: 'Wallet not connected',
            message:
              'Memes you own will display here\nTap "Connect Wallet" below your profile picture',
          };
        }
        return {
          title: 'You have 0 memes',
          message: 'Memes you own will display here\nGet memes now!',
          button: 'Get memes',
          onClick: async () => await app.nav.goToTiktokFeed(undefined, 'Hot'),
        };
      case 'Points':
        return {
          title: 'You have 0 points',
          message:
            'Earned meme points will display here\nDiscover memes and earn points now!',
          button: 'Earn points',
          onClick: async () => await app.nav.goToTiktokFeed(undefined, 'Hot'),
        };
    }
  }

  const data = getCaptions();

  return (
    <div className="profile-empty" style={{ height: sectionHeight }}>
      <div className="profile-empty-info">
        <div className="profile-empty-title">{data.title}</div>
        <div className="profile-empty-message">{data.message}</div>
      </div>
      {data.button && (
        <div
          className="btn btn-normal profile-empty-button"
          onClick={data.onClick}
        >
          {data.button}
        </div>
      )}
    </div>
  );
};

// ====================================================================
