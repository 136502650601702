import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../config';
import { MutableState } from '../schema';

/**
 * Adds the current player balance to all price points in the portfolio trends.
 * This migration is necessary to include the player's balance in the total portfolio value,
 * providing a more accurate representation of the player's assets over time.
 */
export async function clearOldFarming(
  state: MutableState,
  _:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  Object.values(state.trading.miniGames.state).forEach((game) => {
    game.miningStart = undefined;
  });
}
