import { useEffect, useState } from 'react';
import './Animated.scss';

export const Animated = ({
  children,
  visible,
  time = 250,
  anim = {
    in: 'fade-in',
    out: 'fade-out',
  },
  clickthrough = false,
}: {
  children: React.ReactNode;
  visible: boolean;
  time?: number;
  anim?: {
    in: string;
    out: string;
  };
  clickthrough?: boolean;
}) => {
  const [state, setState] = useState(visible ? anim.in : 'hidden');

  useEffect(() => {
    if (visible) {
      setState(anim.in);
    } else if (state === anim.in) {
      setState(anim.out);
      setTimeout(() => {
        // setState('hidden');
      }, time);
    }
  }, [visible]);

  if (state === 'hidden') {
    return null;
  }

  const className = `animated-wrapper ${state}`;

  return (
    <div
      className={className}
      style={clickthrough ? { pointerEvents: 'none' } : undefined}
    >
      <div className="anim-content-wrapper">{children}</div>
    </div>
  );
};
