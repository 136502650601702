import { assets } from '../../../../assets/assets';
import {
  formatPrice,
  largeIntegerToLetter,
} from '../../../../replicant/utils/numbers';
import './RoiBox.scss';

type TimeIntervalMode = '1D' | '1W' | '1M' | 'All';

function getTimeIntervalCaption(mode: TimeIntervalMode) {
  switch (mode) {
    case '1D':
      return 'Today';
    case '1W':
      return 'This Month';
    case '1M':
      return 'This Week';
    case 'All':
      return 'All Time';
    default:
      return 'Unknown';
  }
}

export const RoiBox = (opts: {
  id: string;
  name: string;
  value: number;
  percent: number;
  timeIntervalMode: TimeIntervalMode;
}) => {
  const color = opts.percent ? (opts.percent > 0 ? 'green' : 'red') : 'white';

  return (
    <div className={`roi-box ${opts.id}`}>
      <div className="roi-box-coins">
        <div className="coin">
          <img src={assets.coin} />
        </div>
        <div className="value">
          {largeIntegerToLetter(Math.round(opts.value), 3)}
        </div>
      </div>

      <div className="roi-box-footer">
        <div className="label">{opts.name}</div>
        {Boolean(opts.percent) && (
          <div className={`arrow ${color}`}>
            <img
              src={
                opts.percent > 0 ? assets.arrow_up_green : assets.arrow_down_red
              }
            />
          </div>
        )}
        <div className={`percent ${color}`}>
          {formatPrice(Math.round(opts.percent))}%{' '}
          {getTimeIntervalCaption(opts.timeIntervalMode)}
        </div>
      </div>
    </div>
  );
};
