import {
  Address,
  beginCell,
  Cell,
  contractAddress,
  fromNano,
  SenderArguments,
  toNano,
} from '@ton/core';
import {
  buildOnchainMetadata,
  stringToBigInt,
  storeBuilders,
  calculateTokensReceived,
  calculateTotalMintPrice,
} from './utils';
import { CHAIN } from '@tonconnect/sdk';
import {
  Base64,
  CreateContractInput,
  GetContractAddressByOffchainIdResponse,
  Getters,
  Signature,
  StackTuple,
  BuyTokenInput,
  SellTokenInput,
  BuyTokenTxInput,
} from './types';
// @TODO: Ideally remove these dependencies to make the Provider "pure"
import { MIN_IN_MS } from '../../replicant/utils/time';
import { apiRequest } from '../api';
import { ErrorCode, isHandledError } from '../../replicant/response';
import {
  DAILY_CHECKIN_CONTRACT_ADDRESS,
  DAILY_CHECKIN_CONTRACT_LABEL,
  DAILY_CHECKIN_CONTRACT_TRANSFER_VALUE,
} from '../../replicant/features/game/ruleset/contract';
import TonWeb from 'tonweb';
import { Optional } from '../types';
import { JettonWallet, TonClient } from '@ton/ton';
import { GemzJetton } from './tact_GemzJetton';
import { Administrator } from './tact_Administrator';
import { cai } from '../utils';
import { TxType } from '../../replicant/features/tradingMeme/types';

// Put them in order they were create and revert to show them latest to oldest
export const adminContractAddresses = [
  'EQD78QRViePVIBhd3Ma2HkfmfyzhJ57y1SbtO_tb_Lrw9p87',
  'EQD_GoAgmR_ZALZ30JXGM_jNiEAW7uCCnO-pynGgKuyYRXv7',
  'EQDZ6ABNEUVT9rtHn-hvHeW-lkyz50lR8GOHDAeLydNV_ZqV',
  'EQCK_yeGF7sG_gBHnW6Ja-HjZRoLc0tNo4_KJx6UWGR6LSWi',
  'EQDwVTiBsO4JVCxH5_6xoRReAZG0dSm_kdCS9zyULJPUmzQM',
  'EQAFMw6KPs0pBMRb-Ic7xld2qFxOIBVxy8NNNmaYij3WK7dW',
  'EQArcoIqXsntua5c0ZUyTzQP-jasXv7klYNUYvmPWcPsXNc1',
  'EQCm_9kyAJDdo4cEgGreg0vmOylcIB_W0LdLvAhSmHyMuLTe',
  'EQCXfLSZlSAO9gWkUKfgGWq_JThsx4vdmQlsqsFHF2wtF6MB',
  'EQBuSgPBTYjpUE6pGCocnfYcNH5Vaoa-733IJA51CX9L8_A7',
].reverse();

const HTTP_RPC_URL = 'https://toncenter.com/api/v2/jsonRPC';
const TEMP_API_KEY =
  '170d64f612e0e84b147c309ffad9d973d5a37869614c212eb59a981302ba2c11';

// const FORCE_WALLET = 'UQAjBoPgAJsLGcUli15j5jKvz9DebmXwciSMYCY5qww6giAp';
const FORCE_WALLET = undefined;

export class TonProvider {
  public static config = {
    jetton: {
      deployContractFee: 0.01,
    },
  };

  public static adminContractAddress = adminContractAddresses[0];

  private _walletAddress?: string = FORCE_WALLET;

  public get walletAddress() {
    return this._walletAddress;
  }

  /**
   * @lazy use pubClient instead
   */
  private _pubClient?: TonWeb;
  // Lazy instantiate the first time we use the pubClient
  private get pubClient(): TonWeb {
    if (!this._pubClient) {
      this._pubClient = new TonWeb(new TonWeb.HttpProvider(HTTP_RPC_URL));
    }
    return this._pubClient;
  }

  public client = new TonClient({
    endpoint: HTTP_RPC_URL,
    apiKey: TEMP_API_KEY,
  });

  public setWalletAddress = (walletAddress?: string) => {
    cai('setWalletAddress', { walletAddress });
    if (!FORCE_WALLET) {
      this._walletAddress = walletAddress;
    }
  };

  private onError = (msg: any, code: ErrorCode) => {
    console.error(msg);
    return new Error(code);
  };

  // 5min
  private getExpiry = (now: number, ttl = 5 * MIN_IN_MS) => {
    return now + ttl;
  };

  private sanitizeDataToSign = (
    data: Record<string, any>,
  ): Record<string, string> => {
    return Object.keys(data).reduce((res, cur) => {
      res[cur] = data[cur].toString();
      return res;
    }, {} as Record<string, string>);
  };

  private getSignature = async (
    method: 'deployJettonContract' | 'buyToken' | 'sellToken',
    data: Record<string, any>,
  ): Promise<Signature> => {
    try {
      const {
        result: { nonceB64, signatureB64 },
      } = await apiRequest<{
        result: {
          nonceB64: string;
          signatureB64: string;
          timestamp: string;
        };
      }>('https://oracle.pnk.one/sign').post({
        method,
        data: this.sanitizeDataToSign(data),
      });

      const bocBuffer = Buffer.from(nonceB64, 'base64');
      // Deserialize the Buffer to a Cell
      const nonce = Cell.fromBoc(bocBuffer)[0];

      const sigBuffer = Buffer.from(signatureB64, 'base64');
      const signature = beginCell().storeBuffer(sigBuffer).asSlice();

      return {
        nonce,
        signature,
      };
    } catch (err) {
      throw this.onError(err, ErrorCode.OC_TON_SIGNATURE_FAILED);
    }
  };

  private formatTx = ({ to, value, body }: SenderArguments, now: number) => {
    const address = to.toString();
    const amount = value.toString();
    const payload = body?.toBoc().toString('base64');
    return {
      validUntil: this.getExpiry(now),
      messages: [{ address, amount, payload }],
      network: CHAIN.MAINNET,
    };
  };

  private getAdminSenderArgs = (
    body: Cell,
    value: number | string | bigint = 0.5,
  ): SenderArguments => {
    return {
      to: Address.parse(TonProvider.adminContractAddress),
      value: toNano(value),
      body,
    };
  };
  // @TODO: return HP
  public getBalance = () => {
    if (!this.walletAddress) {
      return '0'; // should we return something else to indicate not connected?
    }
    const walletAddress = Address.parse(this.walletAddress);
    return this.client.getBalance(walletAddress);
  };

  public getCheckInTx = (now: number) => {
    const args: SenderArguments = {
      to: Address.parse(DAILY_CHECKIN_CONTRACT_ADDRESS),
      value: toNano(DAILY_CHECKIN_CONTRACT_TRANSFER_VALUE),
      body: beginCell()
        .storeUint(0, 32)
        .storeStringTail(DAILY_CHECKIN_CONTRACT_LABEL)
        .endCell(),
    };

    const tx = this.formatTx(args, now);

    return tx;
  };

  public getCreateJettonContractTx = async (
    input: CreateContractInput,
    now: number,
  ) => {
    try {
      if (!this.walletAddress) {
        throw this.onError(
          ErrorCode.OC_WALLET_ADDRESS_MISSING,
          ErrorCode.OC_WALLET_ADDRESS_MISSING,
        );
      }

      let metadata: Cell;

      const { memeId, tonAmount, ...metadataInput } = input;

      const data = metadataInput.metadata;

      console.log({ metadataInput, data });

      const tonNanoAmount = tonAmount ? toNano(tonAmount) : undefined;
      const tokenAmount = tonAmount ? this.getListingTokens(tonAmount) : -1;

      try {
        metadata = buildOnchainMetadata(data);
      } catch (e: any) {
        throw this.onError(e, ErrorCode.OC_JETTON_METADATA_FAILED);
      }

      // @TODO: MAKE NONCE TYPE SAFE
      const sig = await this.getSignature('deployJettonContract', {
        ownerAddress: Address.parse(this.walletAddress),
        memeId: BigInt(memeId),
        buyAmount: tokenAmount,
      });

      const message = {
        $$type: 'JettonDeploy',
        queryId: BigInt(Date.now()),
        metadata,
        ...sig,
      } as const;

      const amount = tonNanoAmount
        ? fromNano(tonNanoAmount + toNano('0.5'))
        : '0.5';

      const body = beginCell()
        .store(storeBuilders.createJettonContract(message))
        .endCell();
      const args = this.getAdminSenderArgs(body, amount);
      const tx = this.formatTx(args, now);

      console.log(tx);
      return tx;
    } catch (e: any) {
      if (!isHandledError(e)) {
        console.error(e);
      }
      throw e;
    }
  };

  public getBuyTokenTx = async (
    input: BuyTokenTxInput,
    tonAmount: string,
    now: number,
  ) => {
    if (!this.walletAddress) {
      throw this.onError(
        new Error('Cannot get token tx without walletAddress'),
        ErrorCode.OC_WALLET_ADDRESS_MISSING,
      );
    }

    const sig = await this.getSignature('buyToken', {
      ownerAddress: Address.parse(this.walletAddress),
      memeId: BigInt(input.memeId),
      amount: input.amount,
    });

    let metadata: Cell;

    const { ...metadataInput } = input;

    try {
      metadata = buildOnchainMetadata(metadataInput.metadata);
    } catch (e: any) {
      throw this.onError(e, ErrorCode.OC_JETTON_METADATA_FAILED);
    }

    const message = {
      $$type: 'AdminCallTransferJetton',
      queryId: BigInt(Date.now()),
      metadata,
      ...sig,
    } as const;

    const amount = fromNano(toNano(tonAmount) + toNano('0.5'));

    console.log({
      input,
      message,
      amount,
    });

    const body = beginCell().store(storeBuilders.buyToken(message)).endCell();
    const args = this.getAdminSenderArgs(body, amount);
    const tx = this.formatTx(args, now);
    return tx;
  };

  public getSellTokenTx = async (input: SellTokenInput, now: number) => {
    const sig = await this.getSignature('sellToken', input);

    const message = {
      $$type: 'AdminCallSellJetton',
      queryId: BigInt(Date.now()),
      ...sig,
    } as const;

    const body = beginCell().store(storeBuilders.sellToken(message)).endCell();
    const args = this.getAdminSenderArgs(body);
    const tx = this.formatTx(args, now);
    return tx;
  };

  // ==============================================================
  // ====================== CONTRACT GETTERS ======================
  // ==============================================================

  private getAdminContract = () => {
    const adminContractAddress = Address.parse(
      TonProvider.adminContractAddress,
    );
    const adminContract = this.client.open(
      Administrator.fromAddress(adminContractAddress),
    );
    return adminContract;
  };

  private getJettonContract = (contractAddress: string) => {
    const jettonContractAddress = Address.parse(contractAddress);
    const jettonContract = this.client.open(
      GemzJetton.fromAddress(jettonContractAddress),
    );
    return jettonContract;
  };

  private getJettonWallet = async (contractAddress: string) => {
    const jettonWalletAddress = await this.getJettonWalletAddress(
      contractAddress,
    );
    if (!jettonWalletAddress) {
      return;
    }
    const jettonWallet = this.client.open(
      JettonWallet.create(jettonWalletAddress),
    );
    return jettonWallet;
  };

  getJettonContractAddressFromMemeId = async (memeId: string) => {
    try {
      const adminContract = this.getAdminContract();
      const contractAddress =
        await adminContract.getGetContractAddressByOffchainId(BigInt(memeId));
      if (!contractAddress) {
        return undefined;
      }
      const address = Address.normalize(contractAddress);
      return address;
    } catch (error) {
      throw error;
    }
  };

  getJettonWalletAddress = async (contractAddress: string) => {
    if (!this.walletAddress) {
      return;
    }
    const userAddress = Address.parse(this.walletAddress);
    const jettonContract = this.getJettonContract(contractAddress);
    const jettonWalletAddress = await jettonContract.getGetWalletAddress(
      userAddress,
    );
    return jettonWalletAddress;
  };

  getJettonBalance = async (contractAddress: string) => {
    const jettonWallet = await this.getJettonWallet(contractAddress);
    if (!jettonWallet) {
      return BigInt(0);
    }
    const jettonBalance = await jettonWallet.getBalance();
    return jettonBalance;
  };

  getJettonSupply = async (contractAddress: string) => {
    const jettonContract = await this.getJettonContract(contractAddress);
    const jettonSupply = await jettonContract.getTotalSupply();
    return jettonSupply;
  };

  getJettonBuyPrice = async (contractAddress: string, amountNano: string) => {
    const jettonContractAddress = Address.parse(contractAddress);
    const gemzJettonContract = this.client.open(
      GemzJetton.fromAddress(jettonContractAddress),
    );
    const amount = toNano(amountNano);
    const price = await gemzJettonContract.getTokenBuyPrice(amount);
    return fromNano(price);
  };

  /**
   * @TODO figure out how to work out the transactions, will it even be needed?
   * @deprecated I don't think we need this anymore use getTransaction instead
   */
  getTransactions = async () => {
    if (!this.walletAddress) {
      return;
    }
    const userAddress = Address.parse(this.walletAddress);
    const txs = await this.client.getTransactions(userAddress, {
      limit: 1000,
      to_lt: '10/12/2024',
    });
    console.log({ txs });
  };

  getTx = (hash: string) => {
    if (!this.walletAddress) {
      return undefined;
    }
    const walletAddress = Address.parse(this.walletAddress);
    return this.client.getTransaction(walletAddress, '1', hash);
  };

  getTxWithHash = async (hash: string) => {
    const url = `https://preview.toncenter.com/api/v3/events?msg_hash=${hash}`;
    const response = await fetch(url);
    interface Response {
      events: [{ actions: [{ success: boolean }] }];
    }
    const data: Response = await response.json();
    console.log({ data });
    const actions = data?.events?.[0]?.actions ?? [];
    return {
      raw: data,
      actions: actions.length,
      success: actions.every((a) => a.success),
    };
  };

  getHolders = async (contractAddress: string) => {
    // @TODO: Handle pagination
    const url = `https://tonapi.io/v2/jettons/${contractAddress}/holders?limit=50&offset=0`;
    interface Response {
      addresses: [
        {
          address: string;
          owner: {
            address: string;
            is_scam: boolean;
            is_wallet: boolean;
          };
          balance: string;
        },
      ];
      total: number;
    }
    const data = await apiRequest<Response>(url).get();

    return {
      total: data.total,
      holders: data.addresses.reduce((res, cur) => {
        const address1 = Address.normalize(cur.address);
        const ownerAddress = Address.normalize(cur.owner.address);
        res[address1] = {
          address1,
          ownerAddress,
          balance: fromNano(cur.balance),
        };
        return res;
      }, {} as Record<string, { address1: string; ownerAddress: string; balance: string }>),
    };
  };

  getContractDetails = () => {
    // https://tonapi.io/v2/jettons/EQCk48QxlvtVZmxLvYPchPixejdPWYiQnV8m0xoj3-9FF7zY
  };

  getTONToUSD = async (tonAmount: string) => {
    interface Response {
      amount_from: number;
      currency_from: 'TON';
      currency_to: 'UDS';
      price: number;
      rate: number;
    }
    const url = `https://walletbot.me/api/v1/transfers/price_for_fiat/?crypto_currency=TON&local_currency=USD&amount=${tonAmount}`;
    return apiRequest<Response>(url).get();
  };

  private curveConfig = {
    maxTargetPrice: BigInt(475640),
    initialPrice: BigInt(1),
    maxSupply: BigInt(1000000000),
  };

  // Use this for estimate
  private getTONToToken = (ton: string, supply: bigint) => {
    return calculateTokensReceived(
      supply,
      toNano(ton),
      this.curveConfig.initialPrice,
      this.curveConfig.maxTargetPrice,
      this.curveConfig.maxSupply,
    );
  };

  getTokenToTON = (tokens: bigint, supply: bigint, txType: TxType) => {
    return calculateTotalMintPrice(
      supply,
      tokens,
      this.curveConfig,
      txType === 'buy',
    );
  };

  getListingTokens = (ton: string) => {
    console.log('getListingTokens', { ton });
    return this.getTONToToken(ton, BigInt(3000000));
  };

  getBuyTokens = async (contractAddress: string, ton: string) => {
    const tokenSupply = await this.getJettonSupply(contractAddress);
    return this.getTONToToken(ton, tokenSupply);
  };

  getJettonLiquidity = async (contractAddress: string) => {
    const supply = await this.getJettonSupply(contractAddress);
    const ton = this.getTokenToTON(supply, supply, 'sell');
    return this.getTONToUSD(fromNano(ton));
  };

  getSellTokens = async (contractAddress: string, tokens: bigint) => {
    const supply = await this.getJettonSupply(contractAddress);
    const ton = this.getTokenToTON(tokens, supply, 'sell');
    return ton;
  };
}
