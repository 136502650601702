import { MutableState } from '../../schema';
import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../../config';
import { scheduleBotNotification } from '../chatbot/chatbot.modifiers';
import { getFriendsSubStateId, hasFriendSubState } from './friends.getters';

export function scheduleFriendJoinedMessage(
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  friendName: string,
  bonus: number,
) {
  if (state.notificationBacklog.friendJoined) {
    const previousFriendCount =
      state.notificationBacklog.friendJoined.friendCount ??
      state.notificationBacklog.friendJoined.payload.length;
    state.notificationBacklog.friendJoined.friendCount =
      previousFriendCount + 1;
  } else {
    state.notificationBacklog.friendJoined = {
      triggerTime: api.date.now(),
      payload: [
        {
          friendName: friendName,
          bonus: bonus,
        },
      ],
      friendCount: 1,
    };
  }

  scheduleBotNotification(state, api);
}

export function createFriendsSubState(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
  forceCreate: boolean = false,
) {
  if (!hasFriendSubState(state) || forceCreate) {
    const friendsSubStateId = getFriendsSubStateId(state);
    api.sharedStates.friends.create(friendsSubStateId);
    state.friendsSubStateId = friendsSubStateId;
  }
}

export function migrateFriendIdsToSubstate(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
  forceCreate: boolean,
) {
  if (!state.friendIds || hasFriendSubState(state)) {
    // already migrated
    return;
  }

  if (state.friendIds.length === 0) {
    if (!forceCreate) {
      // no need to migrate or create the friends substate
      state.friendCount = 0;
      delete state.friendIds;
      return;
    }
  }

  createFriendsSubState(state, api);

  const friendIdsToMigrate = state.friendIds;
  if (friendIdsToMigrate.length > 0) {
    const friendsSubStateId = getFriendsSubStateId(state);

    api.sharedStates.friends.postMessage.migrateFriends(friendsSubStateId, {
      friendIds: friendIdsToMigrate,
    });
  }

  state.friendCount = friendIdsToMigrate.length;

  delete state.friendIds;
}

export function addFriend(
  state: MutableState,
  friendId: string,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  createFriendsSubState(state, api);

  const friendsSubStateId = getFriendsSubStateId(state);

  api.sharedStates.friends.postMessage.addFriend(friendsSubStateId, {
    friendId,
  });

  state.friendCount += 1;
}
