import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import {
  CurrencyType,
  TxType,
} from '../../../../replicant/features/tradingMeme/types';

export function getCurrencyName(currencyType: CurrencyType, txType?: TxType) {
  let name = currencyType === 'tokens' ? 'TON' : 'POINTS';

  if (txType && txType === 'sell') {
    const token = app.memes.currentMeme.meme;
    name = '$' + token?.ticker || '';
  }

  return name;
}

export function getCurrencyIcon(currencyType: CurrencyType, txType?: TxType) {
  let image =
    currencyType === 'tokens' ? assets.tab_icon_token : assets.tab_icon_coin;

  if (txType && txType === 'sell') {
    const token = app.memes.currentMeme.meme;
    image = token?.image || '';
  }

  return image;
}
