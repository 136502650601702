// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Badge-triggers"

import { Value } from './badgeTriggers.types';

// prettier-ignore
const badgeTriggers: Value[] = [
  {
    "id": "pu_specials_bitcoin_etf",
    "startTime": "2024-07-02T14:48:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_specials_stablecoins",
    "startTime": "2024-07-09T01:00:00.000Z",
    "type": "cards",
    "whitelisted": 0
  },
  {
    "id": "pu_specials_memecoins",
    "startTime": "2024-07-09T01:00:00.000Z",
    "type": "cards",
    "whitelisted": 0
  },
  {
    "id": "pu_specials_bull_market",
    "startTime": "2024-07-10T01:00:00.000Z",
    "type": "cards",
    "whitelisted": 0
  },
  {
    "id": "pu_specials_ethereum_merge",
    "startTime": "2024-07-10T01:00:00.000Z",
    "type": "cards",
    "whitelisted": 0
  },
  {
    "id": "pu _specials_defi_explosion",
    "startTime": "2024-07-10T01:00:00.000Z",
    "type": "cards",
    "whitelisted": 0
  },
  {
    "id": "pu_specials_layer2s",
    "startTime": "2024-07-10T01:00:00.000Z",
    "type": "cards",
    "whitelisted": 0
  },
  {
    "id": "pu_companions_awkward_ceo",
    "startTime": "2024-07-10T01:00:00.000Z",
    "type": "cards",
    "whitelisted": 0
  },
  {
    "id": "pu_specials_gaming_NFTs_gift",
    "startTime": "2024-07-13T00:00:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_specials_poaps",
    "startTime": "2024-07-15T00:01:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_specials_derivatives",
    "startTime": "2024-07-13T09:31:00.000Z",
    "type": "cards",
    "whitelisted": 0
  },
  {
    "id": "pu_specials_metaverse",
    "startTime": "2024-07-19T01:00:00.000Z",
    "type": "cards",
    "whitelisted": 0
  },
  {
    "id": "pu_specials_satoshis_silence",
    "startTime": "2024-07-19T01:00:00.000Z",
    "type": "cards",
    "whitelisted": 0
  },
  {
    "id": "pu_specials_magnate_club",
    "startTime": "2024-07-22T00:00:00.000Z",
    "type": "cards",
    "whitelisted": 0
  },
  {
    "id": "pu_services_blockchain_security_audit",
    "startTime": "2024-07-24T15:29:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_services_crypto_cloud_boost",
    "startTime": "2024-07-24T15:29:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_services_crypto_compliance_check",
    "startTime": "2024-07-24T15:29:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_services_crypto_concierge",
    "startTime": "2024-07-24T15:29:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_services_decentralized_data_storage",
    "startTime": "2024-07-24T15:29:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_services_hodl_strategy_consultant",
    "startTime": "2024-07-24T15:29:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_services_rig_optimization_service",
    "startTime": "2024-07-24T15:29:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_services_smart_contract_builder",
    "startTime": "2024-07-24T15:29:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_services_transaction_fee_negotiator",
    "startTime": "2024-07-24T15:29:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "pu_services_virtual_mining_coach",
    "startTime": "2024-07-24T15:29:00.000Z",
    "type": "cards",
    "whitelisted": 1
  },
  {
    "id": "visit_collab_cex"
  }
];
export default badgeTriggers;
