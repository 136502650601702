import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppUpdates,
  app,
} from '../../../../../data/Controllers/AppController';
import { MemesEvents } from '../../../../../data/Controllers/Memes/MemesController';
import { displayPointAmount } from '../../../../../replicant/utils/numbers';

interface CreateBtnProps {
  noCoinsClass?: string;
}

export const TradingTokenFooterCreateButton = ({
  noCoinsClass,
}: CreateBtnProps) => {
  useAppUpdates({
    id: 'TradingTokenFooterButton',
    listener: app.memes.trading.attachEventListener(
      MemesEvents.TradingOnPriceUpdate,
    ),
  });

  const { t } = useTranslation();

  const { tx, txCurrency } = app.memes.trading;

  const [isLoading, setIsLoading] = useState(false);

  if (!tx) return null;

  const { receive, isValid, send } = tx;
  const isValidOrNotInvesting = isValid || send.eq(0);

  const createOffchainTokenConfirm = async () => {
    if (!isValidOrNotInvesting) {
      return;
    }
    setIsLoading(true);
    app.memes.factory.createOffchainToken().finally(() => {
      setIsLoading(false);
    });
  };

  const isListingAndInvesting = receive.gt(0);
  const btnLabelKey = isListingAndInvesting
    ? 'trade_token_button_list_invest'
    : 'trade_token_button_list';
  const btnLabel = t(btnLabelKey);
  const freeLabel = t('free');
  const disableBtn = !isValidOrNotInvesting || isLoading;
  const useFreeLabel = app.state.tokenCreationCredits > 0;
  // Update this line to use the ticker of the token being created
  const tickerName = app.memes.factory.newTokenForm.data?.ticker ?? '#';
  const pointAmount = displayPointAmount(receive.toNumber(), 4, true);
  const tokens = app.ton.getListingTokens(tx.receive.toString());
  const tokenAmount = tokens.toString();

  const amount = txCurrency === 'tokens' ? tokenAmount : pointAmount;

  console.log({
    pointAmount,
    tokens,
    tokenAmount,
    amount,
  });

  return (
    <div
      className={`btn btn-normal button-buy ${
        disableBtn && 'disabled'
      } ${noCoinsClass}`}
      onClick={createOffchainTokenConfirm}
    >
      <div className="content-buy">
        <div className="label">
          {useFreeLabel && freeLabel} {btnLabel}
        </div>
        {isListingAndInvesting && (
          <>
            <div
              className="value"
              dangerouslySetInnerHTML={{ __html: amount }}
            ></div>
            <div className="label">${tickerName}</div>
          </>
        )}
      </div>
    </div>
  );
};
