// DO NOT EDIT MANUALLY! This file is generated from Airtable: "PowerUp-BondingCurve"

import { Value } from './powerUpBondingCurve.types';

// prettier-ignore
const powerUpBondingCurve: Value[] = [
  {
    "powerUpId": "pu_companions_ux_designer",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 120,
      "multiplier1": 1.3,
      "multiplier2": 0.325,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_specials_bear_market_blues",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_gear_backpack",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_oil_immersed_rigs",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_hardhat",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_water_cooled_pc",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_reflective_vest",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_sturdy_boots",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_thermal_paste",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_climbing_rope",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_ups_backup",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_first_aid_kit",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_protective_gloves",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_hardware_wallet",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_harness",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_knee_pads",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_satellite_modem",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_hand_radio",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_water_bottle",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_lamp",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_tool_bag",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_cold_weather_gear",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_pickaxe",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_gas_mask",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_ear_protection",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_o2_tank",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_hashrate_hammer",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_blockchain_blaster",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_miners_motherboard",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_quantum_quota",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_power_surge_protector",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_crypto_currency_crusher",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_ether_extractor",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_nano_node_network",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_hashrate_enhancer_helmet",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_gear_lighning_ledger",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_ux_designer",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_curious_inlaws",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_hardware_hacker",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_skeptical_partner",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_venture_capitalist",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_crypto_daytrader",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_techbro",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_tech_journalist",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_blockchain_evangelist",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_wired_pm",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_agile_coach",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_early_adopter_sibling",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_serial_entrepreneur",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_cryptoconference_junkie",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_data_scientist",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_digital_nomad",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_concerned_enviromentalist",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_awkward_ceo",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_intern",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_nft_influencer",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_companions_reclusive_backend_dev",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_services_mine_masters",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_blockbuster_excavation",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_nft_gallery",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_rig_rentals",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_swap_services",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_crypto_crane",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_mule_rentals",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_hash_hounds",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_mine_canaries",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_hash_haven",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_block_bouncers",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_hash_hackers",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_rig_menders",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_token_tutors",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_blockchain_security_audit",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_crypto_cloud_boost",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_crypto_compliance_check",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_crypto_concierge",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_decentralized_data_storage",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_hodl_strategy_consultant",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_rig_optimization_service",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_smart_contract_builder",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_services_transaction_fee_negotiator",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03375,
      "multiplier2": 0.0009
    }
  },
  {
    "powerUpId": "pu_specials_first_eth_validator",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_mined_first_bitcoin",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_bitcoin_etf",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_inhouse_physical_therapist",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_eth_etf",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_denver_eth_conference",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_remembering_doge",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_consensus_conference",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_airdrop",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_gemz_on_x",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_dao",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_friendship_chain",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_tutorial_1",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_new_horizons",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_derivatives",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_magnate_club",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_fresh_start",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.0275625,
      "multiplier2": 0.00084375
    }
  },
  {
    "powerUpId": "pu_specials_edcon2024",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_gear_backpack",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 60,
      "multiplier1": 0.1,
      "multiplier2": 0.5,
      "multiplier3": 0.4625
    }
  },
  {
    "powerUpId": "pu_gear_oil_immersed_rigs",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 35,
      "multiplier1": 1.3,
      "multiplier2": 0.325,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_hardhat",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 45,
      "multiplier1": 1.469,
      "multiplier2": 0.36725,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_water_cooled_pc",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 45,
      "multiplier1": 1.469,
      "multiplier2": 0.36725,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_gear_reflective_vest",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 60,
      "multiplier1": 0.5,
      "multiplier2": 0.125,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_sturdy_boots",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 80,
      "multiplier1": 0.6,
      "multiplier2": 0.15,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_thermal_paste",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 60,
      "multiplier1": 1.3,
      "multiplier2": 0.325,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_climbing_rope",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 65,
      "multiplier1": 1,
      "multiplier2": 0.25,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_ups_backup",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 75,
      "multiplier1": 1.5,
      "multiplier2": 0.375,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_first_aid_kit",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 65,
      "multiplier1": 1.2,
      "multiplier2": 0.3,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_protective_gloves",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 55,
      "multiplier1": 1.4,
      "multiplier2": 0.35,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_hardware_wallet",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 55,
      "multiplier1": 0.9,
      "multiplier2": 0.225,
      "multiplier3": 0.425
    }
  },
  {
    "powerUpId": "pu_gear_harness",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 95,
      "multiplier1": 1.2,
      "multiplier2": 0.3,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_knee_pads",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 100,
      "multiplier1": 1.2,
      "multiplier2": 0.3,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_satellite_modem",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 200,
      "multiplier1": 1.5,
      "multiplier2": 0.375,
      "multiplier3": 0.42
    }
  },
  {
    "powerUpId": "pu_gear_hand_radio",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 300,
      "multiplier1": 3,
      "multiplier2": 0.6,
      "multiplier3": 0.8
    }
  },
  {
    "powerUpId": "pu_gear_water_bottle",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 150,
      "multiplier1": 1.6,
      "multiplier2": 0.4,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_lamp",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 155,
      "multiplier1": 3,
      "multiplier2": 0.75,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_gear_tool_bag",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 350,
      "multiplier1": 1.9,
      "multiplier2": 0.475,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_gear_cold_weather_gear",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 350,
      "multiplier1": 2,
      "multiplier2": 0.5,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_pickaxe",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 140,
      "multiplier1": 1.5,
      "multiplier2": 0.375,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_gas_mask",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 220,
      "multiplier1": 1.8,
      "multiplier2": 0.8,
      "multiplier3": 0.7
    }
  },
  {
    "powerUpId": "pu_gear_ear_protection",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 205,
      "multiplier1": 0.9,
      "multiplier2": 0.225,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_o2_tank",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 250,
      "multiplier1": 1.2,
      "multiplier2": 0.3,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_hashrate_hammer",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 250,
      "multiplier1": 1.5,
      "multiplier2": 0.375,
      "multiplier3": 0.395
    }
  },
  {
    "powerUpId": "pu_gear_blockchain_blaster",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 450,
      "multiplier1": 2,
      "multiplier2": 0.5,
      "multiplier3": 0.9
    }
  },
  {
    "powerUpId": "pu_gear_miners_motherboard",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 350,
      "multiplier1": 4,
      "multiplier2": 1,
      "multiplier3": 0.425
    }
  },
  {
    "powerUpId": "pu_gear_quantum_quota",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 275,
      "multiplier1": 1.8,
      "multiplier2": 0.45,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_power_surge_protector",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 600,
      "multiplier1": 3,
      "multiplier2": 0.75,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_crypto_currency_crusher",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 330,
      "multiplier1": 4,
      "multiplier2": 1,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_gear_ether_extractor",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 500,
      "multiplier1": 0.7,
      "multiplier2": 0.175,
      "multiplier3": 0.455
    }
  },
  {
    "powerUpId": "pu_gear_nano_node_network",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 500,
      "multiplier1": 1.2,
      "multiplier2": 0.3,
      "multiplier3": 0.4
    }
  },
  {
    "powerUpId": "pu_gear_hashrate_enhancer_helmet",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 800,
      "multiplier1": 2.4,
      "multiplier2": 0.6,
      "multiplier3": 0.41
    }
  },
  {
    "powerUpId": "pu_gear_lighning_ledger",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 250,
      "multiplier1": 1.4,
      "multiplier2": 0.35,
      "multiplier3": 0.485
    }
  },
  {
    "powerUpId": "pu_companions_ux_designer",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 120,
      "multiplier1": 1.3,
      "multiplier2": 0.325,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_curious_inlaws",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 350,
      "multiplier1": 1.4,
      "multiplier2": 0.35,
      "multiplier3": 0.455
    }
  },
  {
    "powerUpId": "pu_companions_hardware_hacker",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 180,
      "multiplier1": 1.5,
      "multiplier2": 0.375,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_skeptical_partner",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 280,
      "multiplier1": 7.5,
      "multiplier2": 1.875,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_venture_capitalist",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 400,
      "multiplier1": 1.3,
      "multiplier2": 0.325,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_crypto_daytrader",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 450,
      "multiplier1": 0.9,
      "multiplier2": 0.225,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_techbro",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 800,
      "multiplier1": 2,
      "multiplier2": 0.5,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_tech_journalist",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 500,
      "multiplier1": 2,
      "multiplier2": 0.5,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_blockchain_evangelist",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 900,
      "multiplier1": 3,
      "multiplier2": 0.75,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_wired_pm",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 220,
      "multiplier1": 1.8,
      "multiplier2": 0.45,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_agile_coach",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 1100,
      "multiplier1": 1.1,
      "multiplier2": 0.275,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_companions_early_adopter_sibling",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 700,
      "multiplier1": 1.1,
      "multiplier2": 0.275,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_serial_entrepreneur",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 800,
      "multiplier1": 2.4,
      "multiplier2": 0.6,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_cryptoconference_junkie",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 110,
      "multiplier1": 1.1,
      "multiplier2": 0.275,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_companions_data_scientist",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 850,
      "multiplier1": 2.1,
      "multiplier2": 0.525,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_digital_nomad",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 900,
      "multiplier1": 2.5,
      "multiplier2": 0.625,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_companions_concerned_enviromentalist",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 650,
      "multiplier1": 5,
      "multiplier2": 1.25,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_companions_awkward_ceo",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 700,
      "multiplier1": 2,
      "multiplier2": 0.5,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_intern",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 800,
      "multiplier1": 1.9,
      "multiplier2": 0.475,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_companions_nft_influencer",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 1100,
      "multiplier1": 1.3,
      "multiplier2": 0.325,
      "multiplier3": 0.45
    }
  },
  {
    "powerUpId": "pu_companions_reclusive_backend_dev",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 1800,
      "multiplier1": 1.1,
      "multiplier2": 0.275,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_services_mine_masters",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 350,
      "multiplier1": 2.4,
      "multiplier2": 0.6,
      "multiplier3": 0.455
    }
  },
  {
    "powerUpId": "pu_services_blockbuster_excavation",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 400,
      "multiplier1": 3.1,
      "multiplier2": 0.775,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_nft_gallery",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 220,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_rig_rentals",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 400,
      "multiplier1": 0.8,
      "multiplier2": 0.2,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_swap_services",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 440,
      "multiplier1": 1.4,
      "multiplier2": 0.35,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_crypto_crane",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 350,
      "multiplier1": 3,
      "multiplier2": 0.75,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_mule_rentals",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 400,
      "multiplier1": 3,
      "multiplier2": 0.75,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_hash_hounds",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 330,
      "multiplier1": 4,
      "multiplier2": 1,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_mine_canaries",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 350,
      "multiplier1": 6,
      "multiplier2": 1.5,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_hash_haven",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 380,
      "multiplier1": 4,
      "multiplier2": 1,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_block_bouncers",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 240,
      "multiplier1": 3,
      "multiplier2": 0.75,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_hash_hackers",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 490,
      "multiplier1": 10,
      "multiplier2": 2.5,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_rig_menders",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 600,
      "multiplier1": 7.8,
      "multiplier2": 1.95,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_token_tutors",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 120,
      "multiplier1": 2.4,
      "multiplier2": 0.6,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_blockchain_security_audit",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 300,
      "multiplier1": 4,
      "multiplier2": 1,
      "multiplier3": 0.47
    }
  },
  {
    "powerUpId": "pu_services_crypto_cloud_boost",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 200,
      "multiplier1": 2.4,
      "multiplier2": 0.6,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_crypto_compliance_check",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 200,
      "multiplier1": 3,
      "multiplier2": 1.2,
      "multiplier3": 0.6
    }
  },
  {
    "powerUpId": "pu_services_crypto_concierge",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 500,
      "multiplier1": 3.5,
      "multiplier2": 0.875,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_services_decentralized_data_storage",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 100,
      "multiplier1": 7,
      "multiplier2": 1.75,
      "multiplier3": 0.475
    }
  },
  {
    "powerUpId": "pu_services_hodl_strategy_consultant",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 350,
      "multiplier1": 7,
      "multiplier2": 1.75,
      "multiplier3": 0.48
    }
  },
  {
    "powerUpId": "pu_services_rig_optimization_service",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 120,
      "multiplier1": 1.3,
      "multiplier2": 0.3,
      "multiplier3": 0.48
    }
  },
  {
    "powerUpId": "pu_services_smart_contract_builder",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 140,
      "multiplier1": 7,
      "multiplier2": 1.75,
      "multiplier3": 0.455
    }
  },
  {
    "powerUpId": "pu_services_transaction_fee_negotiator",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 255,
      "multiplier1": 0.7,
      "multiplier2": 0.175,
      "multiplier3": 0.43
    }
  },
  {
    "powerUpId": "pu_specials_first_eth_validator",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 130,
      "multiplier1": 1,
      "multiplier2": 0.25,
      "multiplier3": 0.485
    }
  },
  {
    "powerUpId": "pu_specials_mined_first_bitcoin",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 210,
      "multiplier1": 2,
      "multiplier2": 0.5,
      "multiplier3": 0.47
    }
  },
  {
    "powerUpId": "pu_specials_bitcoin_etf",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 400,
      "multiplier1": 0.5,
      "multiplier2": 0.125,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_inhouse_physical_therapist",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 400,
      "multiplier1": 1.5,
      "multiplier2": 0.375,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_eth_etf",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 120,
      "multiplier1": 30,
      "multiplier2": 7.5,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_denver_eth_conference",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 350,
      "multiplier1": 2.1,
      "multiplier2": 0.525,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_remembering_doge",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 160,
      "multiplier1": 7,
      "multiplier2": 1.75,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_consensus_conference",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 210,
      "multiplier1": 3,
      "multiplier2": 0.75,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_airdrop",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 260,
      "multiplier1": 3.5,
      "multiplier2": 0.875,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_gemz_on_x",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 345,
      "multiplier1": 11,
      "multiplier2": 2.75,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_dao",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 340,
      "multiplier1": 1.4,
      "multiplier2": 0.35,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_friendship_chain",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 360,
      "multiplier1": 1.5,
      "multiplier2": 0.375,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_tutorial_1",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 240,
      "multiplier1": 1.5,
      "multiplier2": 0.375,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_new_horizons",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 120,
      "multiplier1": 1.4,
      "multiplier2": 0.35,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_edcon2024",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 200,
      "multiplier1": 1.4,
      "multiplier2": 0.35,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_metaverse",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 80,
      "multiplier1": 1.4,
      "multiplier2": 0.35,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_phygitals",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 300,
      "multiplier1": 1.4,
      "multiplier2": 0.35,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_lambo",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 250,
      "multiplier1": 1.4,
      "multiplier2": 0.35,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_bear_market_blues",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 350,
      "multiplier1": 1.4,
      "multiplier2": 0.35,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_derivatives",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 410,
      "multiplier1": 1.5,
      "multiplier2": 0.375,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_magnate_club",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 60000,
      "multiplier1": 2,
      "multiplier2": 1.1,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_specials_binance_bonanza",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 10000,
      "multiplier1": 1.5,
      "multiplier2": 0.375,
      "multiplier3": 0.46
    }
  },
  {
    "powerUpId": "pu_specials_fresh_start",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 95,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_specials_metaverse",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_specials_phygitals",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_specials_lambo",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_specials_dao",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_specials_binance_bonanza",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.0525,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_ETH_vs_SEC",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 300,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_specials_stablecoins",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 400,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_specials_memecoins",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 450,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_specials_bull_market",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 460,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_specials_ethereum_merge",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 500,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu _specials_defi_explosion",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 520,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_specials_layer2s",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 480,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_specials_gaming_NFTs",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 600,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_specials_poaps",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 550,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_specials_satoshis_silence",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 600,
      "multiplier1": 12,
      "multiplier2": 3,
      "multiplier3": 0.435
    }
  },
  {
    "powerUpId": "pu_specials_ETH_vs_SEC",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.0525,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_stablecoins",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_specials_memecoins",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.0525,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_bull_market",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_specials_ethereum_merge",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.0525,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu _specials_defi_explosion",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_specials_layer2s",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.039375,
      "multiplier1": 0.0275625,
      "multiplier2": 0.00084375
    }
  },
  {
    "powerUpId": "pu_specials_gaming_NFTs",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_specials_poaps",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.0525,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_specials_satoshis_silence",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_services_virtual_mining_coach",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0.0525,
      "multiplier1": 0.03675,
      "multiplier2": 0.001125
    }
  },
  {
    "powerUpId": "pu_services_virtual_mining_coach",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 415,
      "multiplier1": 10,
      "multiplier2": 3,
      "multiplier3": 0.55
    }
  },
  {
    "powerUpId": "pu_specials_socialfi",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 600,
      "multiplier1": 10,
      "multiplier2": 3,
      "multiplier3": 0.55
    }
  },
  {
    "powerUpId": "pu_specials_socialfi",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 5,
      "multiplier": 3
    }
  },
  {
    "powerUpId": "pu_specials_memetoken_madness",
    "curveId": "giftOnlyCost",
    "variables": {
      "levelsBetweenStepChange": 4,
      "multiplier": 2
    }
  },
  {
    "powerUpId": "pu_specials_memetoken_madness",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 500,
      "multiplier1": 8,
      "multiplier2": 4,
      "multiplier3": 0.35
    }
  },
  {
    "powerUpId": "pu_specials_welcome_back_bundle\n\n",
    "curveId": "polynomialCost",
    "variables": {
      "constant": 0,
      "multiplier1": 0,
      "multiplier2": 0
    }
  },
  {
    "powerUpId": "pu_specials_welcome_back_bundle\n\n",
    "curveId": "polynomialEarn",
    "variables": {
      "constant": 55,
      "multiplier1": 0.15,
      "multiplier2": 0.45,
      "multiplier3": 0.41
    }
  }
];
export default powerUpBondingCurve;
