import { SEC_IN_MS } from '../replicant/utils/time';
import { PureController } from './Controllers/PureController';
import { waitFor } from './utils';

export enum InternetEvents {
  OnChange = 'OnChange',
}

type InternetStatus = 'online' | 'offline';

interface InternetCfgOpts {
  disablePooling?: boolean;
  pollingRate?: number;
}

const DEFAULT_POLL_RATE = 1 * SEC_IN_MS;

export class InternetController extends PureController<InternetEvents> {
  private _status: InternetStatus = window.navigator.onLine
    ? 'online'
    : 'offline';

  private imgElement = document.createElement('img');

  private lastSeenOnline = 0;
  private offlineSince = 0;
  public get offlineDuration() {
    return this.offlineSince;
  }

  public get online() {
    return this._status === 'online';
  }

  constructor(
    private opts: InternetCfgOpts = {
      disablePooling: false,
      pollingRate: DEFAULT_POLL_RATE,
    },
  ) {
    super();
    this.init();
  }

  private init = () => {
    window.addEventListener('online', this.onInternetOnline);
    window.addEventListener('offline', this.onInternetOffline);
    const pollingEnabled = !this.opts.disablePooling;
    if (pollingEnabled) {
      this.startPolling();
    }
  };

  private onInternetOnline = () => {
    this.offlineSince = Date.now() - this.lastSeenOnline;
    this.lastSeenOnline = Date.now();
    if (this._status === 'online') {
      return;
    }
    this._status = 'online';
    this.sendEvents(InternetEvents.OnChange);
  };

  private onInternetOffline = () => {
    if (this._status === 'offline') {
      return;
    }
    this._status = 'offline';
    this.sendEvents(InternetEvents.OnChange);
  };

  private startPolling = async () => {
    try {
      await this.isGoogleOnline();
      this.onInternetOnline();
    } catch (e) {
      this.onInternetOffline();
    } finally {
      await waitFor(this.opts.pollingRate || DEFAULT_POLL_RATE);
      this.startPolling();
    }
  };

  /*
    We can't call fetch on the google URL directly because of CORS, so instead
    we load the ico into a <img /> tag and wait on the load resolution to identify
    weather we could load it or not. if we did, we have internet, if we didnt
    we are offline.
  */
  private isGoogleOnline = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.imgElement.onerror = () => {
        return reject();
      };
      this.imgElement.onload = () => {
        return resolve();
      };
      this.imgElement.src =
        'https://www.google.com/favicon.ico?_=' + new Date().getTime();
    });
  };
}
