import { createAsyncGetters } from '../../createAsyncGetters';
import { TradingSearchResult } from './offchainTrading.properties';
import { OffchainTokenStatus } from './offchainTrading.schema';
import { HP } from '../../lib/HighPrecision';

export type TradingTokenSearchField = 'availableAt' | 'supply' | 'hotTxsCount';

export const offchainTradingAsyncGetters = createAsyncGetters({
  getLegacyOffchainTokensFromOpenSearch: async (
    searchParams: {
      offchainTokenIds: string[];
      status?: string[];
      availableAt?: number;
    },
    api,
  ) => {
    // Ensure condition.version is optional in its type definition
    type Condition = {
      id: { isOneOf: string[] };
      status: { isAnyOf: string[] };
      availableAt: { greaterThanOrEqual?: number };
    };

    const condition: Condition = {
      id: {
        isOneOf: searchParams.offchainTokenIds,
      },
      status: {
        isAnyOf: searchParams.status
          ? searchParams.status
          : [
              OffchainTokenStatus.Created,
              OffchainTokenStatus.Moderated,
              OffchainTokenStatus.ModeratedOS,
              OffchainTokenStatus.Reported,
            ],
      },
      availableAt: {
        greaterThanOrEqual: searchParams.availableAt || 0,
      },
    };

    let results: TradingSearchResult[] = [];
    results = (
      await api.sharedStates.offchainTrading.search({
        where: condition,
        limit: searchParams.offchainTokenIds.length,
      })
    ).results;
    return results;
  },
});
