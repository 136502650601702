import { resolveProfilePicture } from "../replicant/features/game/game.getters";
import { State } from "../replicant/schema";

export function getTelegramUserName(state: State) {
  const telegramUser = Telegram?.WebApp?.initDataUnsafe?.user;
  return (
    telegramUser?.username ||
    telegramUser?.first_name ||
    telegramUser?.last_name ||
    state.username ||
    state.id
  );
}

export function getTelegramUserPhoto(state: State, userId: string) {
  const telegramUser = Telegram?.WebApp?.initDataUnsafe?.user;
  const photo = telegramUser?.photo_url || state.profile.photo;
  return resolveProfilePicture(userId, photo);
}
