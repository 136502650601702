import { ReplicantMigrator } from '@play-co/replicant';
import { getMyOffchainTokenIds } from './features/tradingMeme/tradingMeme.getters';

// IMPORTANT: Do *NOT* import anything.

const migrator = new ReplicantMigrator();

// Do *NOT* modify existing migrations.

/**
 * Remove the `friends` property replaced by `friendIds`
 */
migrator.addMigration(1, (state) => {
  delete state.friends;
});

/**
 * Rename the `tokenAmount` property to `pointAmount`
 */
migrator.addMigration(2, (state) => {
  const offchainTokens = state.trading.offchainTokens;

  for (let tokenId in offchainTokens) {
    offchainTokens[tokenId].pointAmount = offchainTokens[tokenId].tokenAmount;
  }
});

/**
 * @todo: delete `tokenAmount` property in a future release
 */
// migrator.addMigration(2, (state) => {
//   const offchainTokens = state.trading.offchainTokens;

//   for (let tokenId in offchainTokens) {
//     delete offchainTokens[tokenId].tokenAmount;
//   }
// });

// Add migrations right above this line. Do *NOT* modify existing migrations.

export default migrator;
