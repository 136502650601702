import { State } from '../../schema';

export function constructFriendSubStateId(userId: string) {
  return `${userId}-friends`;
}

export function getFriendsSubStateId(state: State) {
  return constructFriendSubStateId(state.id);
}

export function hasFriendSubState(state: State) {
  // the friendsSubtStateId was added later
  // friendCount should have only be incremented if the player already had a friend sub state
  return state.friendsSubStateId || state.friendCount > 0;
}
