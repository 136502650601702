import './DrawerTradingComingsoon.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { GlowingImage } from '../../../shared/GlowingImage/GlowingImage';

export const DrawerTradingComingsoon = () => {
  const { t } = useTranslation();

  return (
    <div className="drawer-trading-comingsoon">
      <div className="drawer-trading-comingsoon-header">
        <GlowingImage
          image={assets.trading_coming_soon}
          blur={70}
          intensity={1}
          size={'180px'}
        />

        <div className="drawer-trading-comingsoon-phaze">{`Phase 2`}</div>
        <div className="drawer-trading-comingsoon-title">{`Coming Soon`}</div>
        <div className="drawer-trading-comingsoon-subtitle">{`Stay tuned anon`}</div>
      </div>

      <div className="footer-buttons">
        <div className={`btn btn-normal`} onClick={app.ui.drawer.close}>
          {t('trading_coming_soon_button')}
        </div>
      </div>
    </div>
  );
};
