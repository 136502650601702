import {
  createSharedStateMessages,
  createSharedStateMessage,
  SB,
} from '@play-co/replicant';
import { friendsSharedStateSchema } from './friends.schema';
import { MAX_FRIEND_COUNT } from '../game/ruleset/friends';

// @warning: never remove/rename shared state messages
export const friendsMessages = createSharedStateMessages(
  friendsSharedStateSchema,
)({
  addFriend: createSharedStateMessage(
    SB.object({
      friendId: SB.string(),
    }),
    (state, { friendId }, meta) => {
      const friendIds = state.global.friendIds;

      // make sure the friend id is not already included
      if (friendIds.includes(friendId)) {
        return;
      }

      // the player cannot have more than a given amount of friends
      // but can still receive bonuses from referrals
      if (friendIds.length < MAX_FRIEND_COUNT) {
        friendIds.push(friendId);
      }
    },
  ),
  migrateFriends: createSharedStateMessage(
    SB.object({
      friendIds: SB.array(SB.string()),
    }),
    (state, { friendIds }, meta) => {
      const existingFriendIds = state.global.friendIds;
      if (existingFriendIds.length > 0) {
        // this should not happen if migrateFriends is called before any friend is added
        const friendIdSet = new Set(friendIds);
        for (const id of existingFriendIds) {
          if (!friendIdSet.has(id)) {
            friendIdSet.add(id);

            // add missing friends to the list
            friendIds.push(id);
          }
        }
      }

      state.global.friendIds = friendIds;
    },
  ),
});
