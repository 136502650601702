import {
  createSharedStateSchema,
  matchableString,
  SB,
} from '@play-co/replicant';
import { migrator } from './teams.migrator';

export const teamProfileSchema = SB.object({
  name: SB.string(),
  photo: SB.string().optional(),
  description: SB.string(),
  inviteLink: SB.string(),
  search: matchableString().optional(),
});

export const teamSharedStateSchema = createSharedStateSchema({
  global: {
    counters: ['score' as const],

    schema: SB.object({
      profile: teamProfileSchema,

      score: SB.int(), // MUST ONLY BE USED WITH THE SCORE COUNTER

      membersCount: SB.int(), // TODO replace with a counter

      // Stores data at migration time. Will be undefined if the team has not been migrated.
      migration: SB.object({
        score: SB.int(),
      }).optional(),

      // The following 2 properties are replaced by system state fields.
      // TODO: remove from schema in a later release.
      createdAt: SB.int().optional(),
      modifiedAt: SB.int().optional(),
    }),
    migrator,
  },
});

export type TeamProfile = SB.ExtractType<typeof teamProfileSchema>;
export type TeamState = SB.ExtractType<
  typeof teamSharedStateSchema.global.schema
>;
