import { ChangeEvent, useState } from 'react';
import './WipeUser.scss';

const envs = {
  Alpha: 'alpha',
  Bravo: 'bravo',
  Dev: 'dev',
  Staging: 'staging',
  'PROD!!!': 'prod',
};
type EnvKey = keyof typeof envs;
const envNames = Object.keys(envs) as EnvKey[];

const users = {
  Brice: '5796798150',
  Cai: '6540606306',
  CaiSamsung: '7436753538',
  Carles: '6572248057',
  Ian: '6170825979',
  JB: '6705915763',
  Yulia: '116583682',
};
type UserKey = keyof typeof users;
const userNames = Object.keys(users) as UserKey[];

export const WipeUser = () => {
  const [envName, setEnvName] = useState(envNames[0]);

  const [user, setUser] = useState(userNames[0]);

  const [status, setStatus] = useState('');

  const onEnvChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setEnvName(e.target.value as EnvKey);
  };

  const onUserChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUser(e.target.value as UserKey);
  };

  const onWipe = () => {
    const env = envs[envName];
    const userId = users[user];
    if (!env || !userId) {
      return;
    }
    const url = `https://gemzcoin-dev.us-east-1.replicant.gc-internal.net/gemzcoin-${env}/latest/nukeUser?id=${userId}&secret=iamsure`;
    setStatus('Loading...');
    fetch(url)
      .then(() => {
        setStatus('Wiped!');
        setTimeout(() => {
          setStatus('');
        }, 3000);
      })
      .catch(() => {
        setStatus('Something went wrong!');
      });
  };

  return (
    <div className="cms-card wipe-user">
      <h1>Wipe data</h1>
      <p>Env</p>
      <select value={envName} onChange={onEnvChange} className="env-picker">
        {envNames.map((e) => (
          <option key={e}>{e}</option>
        ))}
      </select>
      <p>User</p>
      <select value={user} onChange={onUserChange} className="user-picker">
        {userNames.map((e) => (
          <option key={e}>{e}</option>
        ))}
      </select>
      {status && <p>{status}</p>}
      <button onClick={onWipe}>Wipe</button>
    </div>
  );
};
