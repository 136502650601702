import { FetchOptions, Fetcher } from '@play-co/replicant';

import type {
  Chat,
  ForceReply,
  InlineKeyboardMarkup,
  ReplyKeyboardMarkup,
  ReplyKeyboardRemove,
} from '../../telegram.bot.types';

// /**
//  * Make a request to Telegram's Bot API.
//  * @see https://core.telegram.org/bots/api#making-requests
//  **/
export async function makeBotRequest<TResult = unknown>(
  methodName: string,
  body: any,
  fetch: Fetcher['fetch'],
): Promise<TResult> {
  const fetchOptions: FetchOptions = {
    url: `https://api.telegram.org/bot${process.env.TELEGRAM_BOT_TOKEN}/${methodName}`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : undefined,
  };
  const response = await fetch(fetchOptions);

  if (response.statusCode < 200 || response.statusCode >= 400) {
    const error = Error(
      `Telegram API error response: ${response.statusCode} ${response.body}`,
    ) as Error & { fetchOptions: FetchOptions };
    error.fetchOptions = fetchOptions;

    throw error;
  }

  const responseBody = JSON.parse(response.body) as {
    ok: boolean;
    result: TResult;
  };

  if (!responseBody.ok) {
    const error = Error(
      `Telegram API error: ${response.statusCode} ${response.body}`,
    ) as Error & { fetchOptions: FetchOptions };
    error.fetchOptions = fetchOptions;

    throw error;
  }

  return responseBody.result;
}

const numRegex = /^[0-9]+$/;
/**
 * Gets the ChatBot user ID from the bot token. Has some safeguards to prevent
 * showing the whole token accidentally.
 * @returns
 */
export function getChatBotUserId(): string {
  const parts = process.env.TELEGRAM_BOT_TOKEN?.split(':');
  // somehow missing or falsy
  if (!parts?.[0]) {
    return '[not found]';
  }

  // somehow not purely numerical, so don't show in case
  // it's the non-numerical portion of the token
  if (!numRegex.test(parts[0])) {
    return '[NaN]';
  }

  return parts[0];
}

export interface SendMessageOptions {
  /** Message text. */
  text: string;

  /** ID of the target. */
  chatId: number | string;

  /** Parse mode of the message. */
  parseMode?: 'Markdown' | 'HTML'; // Remove MarkdownV2 because it seems that anything set to it won't work

  /** Reply markup */
  replyMarkup?:
    | InlineKeyboardMarkup
    | ReplyKeyboardMarkup
    | ReplyKeyboardRemove
    | ForceReply;

  fetch: Fetcher['fetch'];
}

export interface SendMessageResult {
  message_id: number;
  // only add other props when needed
}

// https://core.telegram.org/bots/api#sendmessage
export async function sendMessage(
  opts: SendMessageOptions,
): Promise<SendMessageResult> {
  return await makeBotRequest<SendMessageResult>(
    'sendMessage',
    {
      chat_id: opts.chatId,
      text: opts.text,
      parse_mode: opts.parseMode,
      reply_markup: opts.replyMarkup,
    },
    opts.fetch,
  );
}

export interface GetChatMemberOpts {
  chatId: number | string;
  userId: number | string;
  disableNotification?: boolean;
  fetch: Fetcher['fetch'];
}

// https://core.telegram.org/bots/api#getchatmember
export async function getChatMember(opts: GetChatMemberOpts) {
  return await makeBotRequest(
    'getChatMember',
    {
      chat_id: opts.chatId,
      user_id: +opts.userId,
    },
    opts.fetch,
  );
}

export interface DefaultResult {
  ok: boolean;
  result: boolean;
}

export interface PinMessageOptions {
  chatId: number | string;
  messageId: number;
  disableNotification?: boolean;
  fetch: Fetcher['fetch'];
}

export async function pinMessage(
  opts: PinMessageOptions,
): Promise<DefaultResult> {
  return await makeBotRequest(
    'pinChatMessage',
    {
      chat_id: opts.chatId,
      message_id: opts.messageId,
      disable_notification: opts.disableNotification,
    },
    opts.fetch,
  );
}

export interface UnpinMessageOptions {
  chatId: number | string;
  messageId: number;
  fetch: Fetcher['fetch'];
}

export async function unpinMessage(
  opts: UnpinMessageOptions,
): Promise<DefaultResult> {
  return await makeBotRequest(
    'unpinChatMessage',
    {
      chat_id: opts.chatId,
      message_id: opts.messageId,
    },
    opts.fetch,
  );
}

// https://core.telegram.org/bots/api#getchat
export async function getChat(chatId: string, fetch: Fetcher['fetch']) {
  const result = await makeBotRequest('getChat', { chat_id: chatId }, fetch);

  return result as Chat;
}

interface CreateIAPLink {
  sku: string;
  price: {
    label: string;
    amount: number;
  };
  title: string;
  description: string;
}

export async function createIAPLink(
  opts: CreateIAPLink,
  fetch: Fetcher['fetch'],
) {
  const payload = {
    sku: opts.sku,
    createdAt: Date.now(),
  };
  const prices = [opts.price];
  return await makeBotRequest(
    'createInvoiceLink',
    {
      title: opts.title,
      description: opts.description,
      payload,
      provider_token: '', // '' for Telegram Stars
      currency: 'XTR',
      prices,
    },
    fetch,
  );
}
