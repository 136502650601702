import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import './AppErrorFallback.scss';

export function AppErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  useEffect(() => {
    console.error('React app crash:', error);
  }, [error]);

  return (
    <div className="error-fallback" role="alert">
      <p>Something went wrong. Please try refreshing.</p>
      {error?.friendlyMessage && <pre>Error: {error.friendlyMessage}</pre>}
    </div>
  );
}
