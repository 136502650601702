import { MutableState } from '../schema';
import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../config';

export const addLastTokenCreatedTimestamp = (
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) => {
  if (
    Object.keys(state.trading.offchainTokens).length == 0 ||
    state.trading.lastTokenCreatedTimestamp
  ) {
    return;
  }
  state.trading.lastTokenCreatedTimestamp = api.date.now();
};
