import './DrawerTiktokHolders.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { TradingTokenHoldersItem } from '../../TradingTokenPage/TradingTokenTabs/TradingTokenTabsContent';
import { useEffect, useState } from 'react';
import { TradingTokenHolder } from '../../../../replicant/features/tradingMeme/types';

export const DrawerTiktokHolders = () => {
  const { t } = useTranslation();

  // todo: holders take a while to render. is it not possible to get them instantly before drawer opens?
  const [holders, setHolders] = useState<TradingTokenHolder[]>([]);

  const token = app.memes.currentMeme.meme;

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        app.memes.trading.getHolders().then(setHolders);
      }, 2000);
    }
  }, [token?.supply]);

  const onMinimize = () => {
    app.ui.drawer.close();
  };

  return (
    <div className="drawer-tiktok-holders">
      {/* header - left title and minimize button */}
      <div className="drawer-tiktok-holders-header">
        <div className="drawer-tiktok-holders-header-title">
          ${token?.ticker} {t('tiktok_holders')}
        </div>
        <div
          className="btn drawer-tiktok-holders-header-close"
          onClick={onMinimize}
        >
          <img src={assets.button_arrow_down} />
        </div>
      </div>

      {/* holders list */}
      <div className="items-area holders">
        <div className="items-list holders">
          {holders.map((item, index) => (
            <TradingTokenHoldersItem key={'holder_' + index} data={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
