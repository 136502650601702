import React from 'react';
import ReactDOM from 'react-dom/client';

// error hijacking import should happen before any other internal dependency (especially the analytics)
import { hijackUnhandledPromiseErrors } from './data/hijackUnhandledPromiseErrors';

hijackUnhandledPromiseErrors();

import Application from './Application';
import './i18n';
import reportWebVitals from './reportWebVitals';
import './styles/global.scss';
import './styles/buttons.scss';
import './styles/components.scss';
import './styles/animations.scss';
import { qpConfig } from './data/config';
import { Cms } from './CMSApp/Cms';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    {qpConfig.useCMS ? <Cms /> : <Application />}
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
