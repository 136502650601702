import {
  createSharedStateMessages,
  createSharedStateMessage,
  SB,
} from '@play-co/replicant';
import {
  TradingState,
  offchainTradingSharedStateSchema,
  offchainTokenDetailsSchema,
  PriceSlice,
  PriceTrends,
  FailedTxReason,
  StatsSlice,
  OffchainTokenStatus,
} from './offchainTrading.schema';
import {
  getBuyEstimate,
  getCanBuy,
  getCanSell,
  getCreateEstimate,
  getCurvePrice,
  getSellEstimate,
  scoreToPoints,
} from './offchainTrading.getters';
import {
  failedTxLifespan,
  fixedSliceTimeWindows,
  FixedSliceTimeWindows,
  maxTokenAllTimeSliceCount,
  maxTxCount,
  tokenPriceSliceConfigs,
  SliceConfig,
  fixedSliceStatsTimeWindows,
  FixedSliceStatsTimeWindows,
  tokenStatsSliceConfigs,
  giftTokenCoinAmount,
} from './offchainTrading.ruleset';
import { HighPrecision, HP } from '../../lib/HighPrecision';

function addPriceToTrend(
  trend: PriceSlice[],
  sliceConfig: SliceConfig,
  price: string,
  time: number,
) {
  let firstPrice = price;
  while (trend.length > 0) {
    firstPrice = trend[0].price;
    if (trend[0].time >= time - sliceConfig.window) {
      break;
    }

    // slice is out of the time window
    // time to kick some butts
    trend.shift();
  }

  const windowStartTime =
    sliceConfig.interval *
    Math.floor((time - sliceConfig.window) / sliceConfig.interval);
  if (trend.length === 0 || trend[trend.length - 1].time !== windowStartTime) {
    trend.unshift({
      time: windowStartTime,
      price: firstPrice,
    });
  }

  // setting the price for the NEXT price slice
  // the reason is that the last transaction of a slice sets the entry price of the price slice coming after it
  const sliceTime =
    sliceConfig.interval * Math.floor(time / sliceConfig.interval);
  const nextSliceTime = sliceTime + sliceConfig.interval;
  if (trend[trend.length - 1].time < nextSliceTime) {
    // create next slice
    trend.push({
      time: nextSliceTime,
      price,
    });
  } else if (trend[trend.length - 1].time < sliceTime) {
    // last slice is already the next slice
    trend[trend.length - 1].price = price;
  } else {
    // ignore price point
  }
}

function addPriceToTrends(trends: PriceTrends, price: string, time: number) {
  for (let i = 0; i < fixedSliceTimeWindows.length; i += 1) {
    const timeWindow = fixedSliceTimeWindows[i];
    const sliceConfig =
      tokenPriceSliceConfigs[timeWindow as FixedSliceTimeWindows];
    const trend = trends[timeWindow as FixedSliceTimeWindows];
    addPriceToTrend(trend, sliceConfig, price, time);
  }

  // update slices for the allTime/variable time window
  const allTimeTrend = trends.allTime;
  if (
    allTimeTrend.length === 0 ||
    allTimeTrend[allTimeTrend.length - 1].time < time
  ) {
    allTimeTrend.push({ time, price });
  }

  // @note: only one iteration of the loop should happen
  while (allTimeTrend.length > maxTokenAllTimeSliceCount) {
    const timeWindow =
      allTimeTrend[allTimeTrend.length - 1].time - allTimeTrend[0].time;

    let smallestInterval = timeWindow;
    let smallestIntervalSliceIdx = -1;
    let previousTime = allTimeTrend[0].time;
    for (let i = 1; i < allTimeTrend.length - 1; i += 1) {
      const slice = allTimeTrend[i];

      // @todo?
      // prioritize merges of distance point in times by multipltying interval by a distance coefficient,
      // it virtually makes distant intervals smaller
      // const distanceCoeff = Math.pow(i / allTimeTrend.length, 0.5);
      // const interval = distanceCoeff * (slice.time - previousTime);

      const interval = slice.time - previousTime;
      if (interval < smallestInterval) {
        smallestInterval = interval;
        smallestIntervalSliceIdx = i;
      }
      previousTime = slice.time;
    }

    trends.allTime.splice(smallestIntervalSliceIdx, 1);
  }
}

function addStats(
  stats: StatsSlice[],
  sliceConfig: SliceConfig,
  volume: string,
  holderCount: number,
  time: number,
) {
  const window = sliceConfig.window;
  while (stats.length > 0) {
    const firstStats = stats[0];
    if (firstStats.time >= time - window) {
      break;
    }

    // slice is out of the time window
    // time to kick some butts
    stats.shift();
  }

  const interval = sliceConfig.interval;

  // setting the stats for the NEXT price slice
  // the reason is that the last transaction of a slice sets the entry price of the price slice coming after it
  const sliceTime = interval * Math.floor(time / interval);
  const sliceIdx = stats.findIndex((statsSlice) => {
    return statsSlice.time >= sliceTime;
  });

  if (sliceIdx === -1) {
    // no slice on which to add stats
    // create next slice
    stats.push({
      time: sliceTime,
      volume,
      holderCount,
    });
  } else {
    // there is a slice on which to update stats
    const existingStatsSlice = stats[sliceIdx];
    if (existingStatsSlice.time === sliceTime) {
      // existing slice corresponds to current slice time
      existingStatsSlice.volume = HP(existingStatsSlice.volume)
        .add(volume)
        .toString();
      existingStatsSlice.holderCount = holderCount;
    } else {
      // existing slice is after current slice time (existingStatsSlice.time > sliceTime)
      stats.push({
        time: sliceTime,
        volume,
        holderCount,
      });

      stats.sort((a, b) => {
        return a.time - b.time;
      });
    }
  }
}

function addTxStats(state: TradingState, currencyAmount: string, time: number) {
  const stats = state.stats;
  const holderCount = state.holderCount;

  for (let i = 0; i < fixedSliceStatsTimeWindows.length; i += 1) {
    const timeWindow = fixedSliceStatsTimeWindows[i];
    const sliceConfig =
      tokenStatsSliceConfigs[timeWindow as FixedSliceStatsTimeWindows];
    const windowStats = stats[timeWindow as FixedSliceStatsTimeWindows];
    addStats(windowStats, sliceConfig, currencyAmount, holderCount, time);
  }
}

function curateTransactions2(state: TradingState) {
  // limit number of transactions on state
  if (state.txs.length > maxTxCount) {
    state.txs = state.txs.slice(state.txs.length - maxTxCount);
  }
}

function curateTransactions(state: TradingState, time: number) {
  // limit number of transactions on state
  if (state.txs.length > maxTxCount) {
    state.txs = state.txs.slice(state.txs.length - maxTxCount);
  }

  const price = getCurvePrice(HP(state.supply)).toString();

  // update trends/price slices
  const trends = state.trends;

  if (trends.day7.length === 0) {
    // migrating prices to day7 and day30
    const allTimeTrend = trends.allTime;
    const sliceConfigDay7 = tokenPriceSliceConfigs.day7;
    const sliceConfigDay30 = tokenPriceSliceConfigs.day30;
    for (let i = 0; i < allTimeTrend.length; i += 1) {
      const { price: slicePrice, time: sliceTime } = allTimeTrend[i];
      addPriceToTrend(trends.day7, sliceConfigDay7, slicePrice, sliceTime);
      addPriceToTrend(trends.day30, sliceConfigDay30, slicePrice, sliceTime);
    }

    delete trends.hour1;
    delete trends.hour6;
  }

  addPriceToTrends(trends, price, time);
}

function updateDataPoints(state: TradingState, time: number) {
  const price = getCurvePrice(HP(state.supply)).toString();

  // update trends/price slices
  const trends = state.trends;

  if (trends.day7.length === 0) {
    // migrating prices to day7 and day30
    const allTimeTrend = trends.allTime;
    const sliceConfigDay7 = tokenPriceSliceConfigs.day7;
    const sliceConfigDay30 = tokenPriceSliceConfigs.day30;
    for (let i = 0; i < allTimeTrend.length; i += 1) {
      const { price: slicePrice, time: sliceTime } = allTimeTrend[i];
      addPriceToTrend(trends.day7, sliceConfigDay7, slicePrice, sliceTime);
      addPriceToTrend(trends.day30, sliceConfigDay30, slicePrice, sliceTime);
    }

    delete trends.hour1;
    delete trends.hour6;
  }

  addPriceToTrends(trends, price, time);
  addTxStats(state, state.txs[state.txs.length - 1].currencyAmount, time);
}

function updateHoldingStats(
  state: TradingState,
  holderCountChange: 0 | 1 | -1,
  tokensHeldBeforeTx: string | undefined,
  txTokenDiff: HighPrecision,
) {
  state.holderCount += holderCountChange;

  if (tokensHeldBeforeTx !== undefined) {
    if (state.holderCount === 0) {
      state.sumOfHoldingsSquare = '0';
    } else {
      const sumOfHoldingsSquareBeforeTx = HP(state.sumOfHoldingsSquare);

      const tokensHeldBeforeTxNum = HP(tokensHeldBeforeTx);
      const tokensHeldNow = tokensHeldBeforeTxNum.plus(txTokenDiff);
      const sumOfHoldingsSquare = sumOfHoldingsSquareBeforeTx
        .minus(tokensHeldBeforeTxNum.pow(2))
        .plus(tokensHeldNow.pow(2));

      state.sumOfHoldingsSquare = sumOfHoldingsSquare.toString();
    }
  }
}

function addFailedTx(
  state: TradingState,
  createdAt: number,
  userId: string,
  reason?: FailedTxReason,
) {
  // filter out outdated failex transactions
  const failedTxs = state.failedTxs.filter((failedTx) => {
    return failedTx.createdAt > createdAt - failedTxLifespan;
  });

  failedTxs.push({
    createdAt,
    userId,
    reason,
  });

  state.failedTxs = failedTxs;
}

function addToSupply(state: TradingState, pointAmount: HighPrecision) {
  state.supply = HP(state.supply).add(pointAmount).toString();

  const pointsDistributed = HP(state.pointsDistributed).add(pointAmount);
  state.pointsDistributed = pointsDistributed.toString();

  if (pointsDistributed.lt(state.supply)) {
    // this acts as a migrator to update the pointsDistributed on existing memes
    state.pointsDistributed = state.supply;
  }
}

function giftToken(
  state: TradingState,
  data: {
    timestamp: number;
    buyerId: string;
    buyerName?: string;
    buyerImage?: string;
    msgVersion: number;
    expectedTxIdx: number;
    coinAmount: number | string;
  },
) {
  const {
    timestamp,
    buyerId,
    buyerName,
    buyerImage,
    msgVersion,
    expectedTxIdx,
    coinAmount,
  } = data;

  const isExpectedState = state.txs.length === expectedTxIdx;

  if (!isExpectedState) {
    const reason = isExpectedState ? undefined : 'concurrencyIssue';
    addFailedTx(state, timestamp, buyerId, reason);
    return;
  }

  const currencyAmountNum = HP(coinAmount);
  const currencyAmount = currencyAmountNum.toString();
  const pointAmount = getBuyEstimate(state, currencyAmountNum);

  addToSupply(state, pointAmount);

  state.txs.push({
    txType: 'buy',
    userId: buyerId,
    userName: buyerName,
    userImage: buyerImage,
    createdAt: timestamp,
    currencyAmount,
    pointAmount: pointAmount.toString(),
    currency: msgVersion >= 3 ? 'gift' : undefined,
  });

  curateTransactions2(state);

  if (msgVersion >= 2) {
    updateHoldingStats(state, 1, '0', pointAmount);
  } else {
    // the coin amount should not be used to update the holding stats, only the token amount!!
    updateHoldingStats(state, 1, '0', currencyAmountNum);
  }

  updateDataPoints(state, timestamp);
}

// All props should be optional
const periodicUpdateSchema = SB.object({
  sharesToAdd: SB.number().optional(),
  pointsToAdd: SB.int().optional(),
});

export type PeriodicUpdate = SB.ExtractType<typeof periodicUpdateSchema>;

// @warning: never remove/rename shared state messages
export const offchainTradingMessages = createSharedStateMessages(
  offchainTradingSharedStateSchema,
)({
  /**
   * This actually updates the offchainToken (create is done via api request) but since we require
   * creator info I called it 'createOffchainToken'
   */
  createOffchainToken2: createSharedStateMessage(
    SB.object({
      details: offchainTokenDetailsSchema,
      timestamp: SB.int(),
      currencyAmount: SB.string(),
      // @todo: make it mandatory after migration from season 2 is complete
      version: SB.int().optional(),
      isDev: SB.boolean().optional(),
    }),
    (state, { details, timestamp, currencyAmount, isDev }, meta) => {
      // Set offchainToken details
      state.global.details = details;

      const supply = getCreateEstimate(HP(currencyAmount));

      state.global.supply = supply.toString();
      state.global.pointsDistributed = supply.toString();
      state.global.txs.push({
        txType: 'buy',
        userId: details.creatorId,
        userName: details.creatorName,
        userImage: details.creatorImage,
        createdAt: timestamp,
        currencyAmount,
        pointAmount: supply.toString(),
      });

      if (supply.gt(0)) {
        updateHoldingStats(state.global, 1, '0', supply);
      }

      updateDataPoints(state.global, meta.timestamp);

      if (isDev) {
        state.global.status = OffchainTokenStatus.Moderated;
      }
    },
  ),
  editOffchainToken: createSharedStateMessage(
    SB.object({
      telegramChannelLink: SB.string().optional(),
      telegramChatLink: SB.string().optional(),
      twitterLink: SB.string().optional(),
    }),
    (state, { telegramChannelLink, telegramChatLink, twitterLink }, meta) => {
      state.global.details.telegramChannelLink = telegramChannelLink;
      state.global.details.telegramChatLink = telegramChatLink;
      state.global.details.twitterLink = twitterLink;
    },
  ),

  attemptBuyOffchainToken2: createSharedStateMessage(
    SB.object({
      // @todo: Remove "checkCanBuy" property after migration from season 2 is complete
      checkCanBuy: SB.boolean().optional(),
      timestamp: SB.int(),
      expectedTxIdx: SB.int(),
      buyerId: SB.string(),
      buyerName: SB.string().optional(),
      buyerImage: SB.string().optional(),
      currencyAmount: SB.string(), // how much currency wants to invest
      pointAmountEstimate: SB.string(),
      pointAmountHeldBeforeTx: SB.string(),
      driftPct: SB.number(),
      isNewHolder: SB.boolean(),
    }),
    (
      state,
      {
        timestamp,
        expectedTxIdx,
        buyerId,
        buyerName,
        buyerImage,
        currencyAmount,
        pointAmountEstimate,
        pointAmountHeldBeforeTx,
        driftPct,
        isNewHolder,
      },
      meta,
    ) => {
      let currencyAmountNum = HP(currencyAmount);
      let pointAmountEstimateNum = HP(pointAmountEstimate);
      const canBuy = getCanBuy(state.global, {
        currencyAmount: currencyAmountNum,
        pointAmountEstimate: pointAmountEstimateNum,
        driftPct,
      });

      const isExpectedState = state.global.txs.length === expectedTxIdx;
      const createdAt = timestamp ?? meta.timestamp;
      if (!canBuy || !isExpectedState) {
        const reason = isExpectedState ? undefined : 'concurrencyIssue';
        addFailedTx(state.global, createdAt, buyerId, reason);
        return;
      }

      const pointAmount = getBuyEstimate(state.global, currencyAmountNum);

      addToSupply(state.global, pointAmount);

      state.global.txs.push({
        txType: 'buy',
        userId: buyerId,
        userName: buyerName,
        userImage: buyerImage,
        createdAt,
        currencyAmount,
        pointAmount: pointAmount.toString(),
      });

      curateTransactions2(state.global);

      const holderCountChange = isNewHolder ? 1 : 0;
      updateHoldingStats(
        state.global,
        holderCountChange,
        pointAmountHeldBeforeTx,
        pointAmountEstimateNum,
      );

      updateDataPoints(state.global, meta.timestamp);
    },
  ),

  attemptSellOffchainToken2: createSharedStateMessage(
    SB.object({
      // @todo: Remove "checkCanSell" property after migration from season 2 is complete
      checkCanSell: SB.boolean().optional(),
      timestamp: SB.int(),
      expectedTxIdx: SB.int(),
      sellerId: SB.string(),
      sellerName: SB.string().optional(),
      sellerImage: SB.string().optional(),
      pointAmount: SB.string(), // how many tokens wants to sell
      currencyAmountEstimate: SB.string(), // how much the user has been show it would receive
      pointAmountHeldBeforeTx: SB.string(),
      driftPct: SB.number(), // how much off mark can it be and still be acceptable
      sellingAllTokens: SB.boolean(),
    }),
    (
      state,
      {
        timestamp,
        expectedTxIdx,
        sellerId,
        sellerName,
        sellerImage,
        pointAmount,
        currencyAmountEstimate,
        pointAmountHeldBeforeTx,
        driftPct,
        sellingAllTokens,
      },
      meta,
    ) => {
      const pointAmountNum = HP(pointAmount);
      const currencyAmountEstimateNum = HP(currencyAmountEstimate);
      const sellPrice = getSellEstimate(state.global, pointAmountNum);

      if (!sellPrice.gt(0)) {
        addFailedTx(state.global, timestamp, sellerId);
        return;
      }

      const canSell = getCanSell(state.global, {
        pointAmount: pointAmountNum,
        currencyAmountEstimate: currencyAmountEstimateNum,
        driftPct,
      });
      const isExpectedState =
        expectedTxIdx === undefined ||
        state.global.txs.length === expectedTxIdx;
      if (!canSell || !isExpectedState) {
        const reason = isExpectedState ? undefined : 'concurrencyIssue';
        addFailedTx(state.global, timestamp, sellerId, reason);
        return;
      }

      const currentSupply = HP(state.global.supply);
      const newSupply = currentSupply.minus(pointAmountNum);
      state.global.supply = newSupply.toString();
      state.global.txs.push({
        txType: 'sell',
        userId: sellerId,
        userName: sellerName,
        userImage: sellerImage,
        createdAt: timestamp,
        currencyAmount: sellPrice.toString(),
        pointAmount,
      });

      curateTransactions2(state.global);

      const holderCountChange = sellingAllTokens ? -1 : 0;
      updateHoldingStats(
        state.global,
        holderCountChange,
        pointAmountHeldBeforeTx,
        pointAmountNum.mul(-1),
      );

      updateDataPoints(state.global, timestamp);
    },
  ),

  updateStatus: createSharedStateMessage(
    SB.object({ status: SB.string(), image: SB.string().optional() }),
    (state, { status, image }, info) => {
      if (
        !Object.values(OffchainTokenStatus).includes(
          status as OffchainTokenStatus,
        )
      ) {
        throw new Error('Invalid status');
      }
      state.global.status = status;
      if (image) {
        state.global.details.image = image;
      }
    },
  ),

  addImageUrl: createSharedStateMessage(
    SB.object({ image: SB.string() }),
    (state, { image }, info) => {
      state.global.details.image = image;
    },
  ),

  setSumOfHoldingsSquare: createSharedStateMessage(
    SB.object({ sumOfHoldingsSquare: SB.string() }),
    (state, { sumOfHoldingsSquare }, info) => {
      state.global.sumOfHoldingsSquare = sumOfHoldingsSquare;
    },
  ),

  periodicUpdate: createSharedStateMessage(
    periodicUpdateSchema,
    (state, { sharesToAdd }, info) => {
      if (sharesToAdd !== undefined) {
        state.global.shares += sharesToAdd;
      }
    },
  ),

  awardMemeTokenGift2: createSharedStateMessage(
    SB.object({
      timestamp: SB.int(),
      buyerId: SB.string(),
      buyerName: SB.string().optional(),
      buyerImage: SB.string().optional(),
      msgVersion: SB.int(),
      expectedTxIdx: SB.int(),
    }),
    (state, data, meta) => {
      giftToken(state.global, {
        ...data,
        coinAmount: giftTokenCoinAmount,
      });
    },
  ),
  awardPointAmount: createSharedStateMessage(
    SB.object({
      timestamp: SB.int(),
      buyerId: SB.string(),
      buyerName: SB.string().optional(),
      buyerImage: SB.string().optional(),
      msgVersion: SB.int(),
      expectedTxIdx: SB.int(),
      coinAmount: SB.string(),
    }),
    (state, data, meta) => {
      giftToken(state.global, data);
    },
  ),
  exchangeScoreForPoints: createSharedStateMessage(
    SB.object({
      timestamp: SB.int(),
      score: SB.int(),
      buyerId: SB.string(),
      buyerName: SB.string().optional(),
      buyerImage: SB.string().optional(),
      pointsAccumulated: SB.string(),
      pointAmountHeldBeforeTx: SB.string(),
      expectedTxIdx: SB.int(),
      isNewHolder: SB.boolean(),
    }),
    (
      state,
      {
        score,
        buyerId,
        buyerName,
        buyerImage,
        timestamp,
        pointsAccumulated,
        pointAmountHeldBeforeTx,
        expectedTxIdx,
        isNewHolder = false,
      },
      meta,
    ) => {
      const isExpectedState =
        expectedTxIdx === undefined ||
        state.global.txs.length === expectedTxIdx;

      if (!isExpectedState) {
        const reason = isExpectedState ? undefined : 'concurrencyIssue';
        addFailedTx(state.global, timestamp, buyerId, reason);
        return;
      }

      const supplyNum = HP(state.global.supply);
      const { points, equivalentCurrencyAmount } = scoreToPoints(
        supplyNum,
        pointsAccumulated,
        state.global.pointsDistributed,
        score,
      );

      const currencyAmount = equivalentCurrencyAmount.toString();

      addToSupply(state.global, points);

      state.global.txs.push({
        txType: 'buy',
        userId: buyerId,
        userName: buyerName,
        userImage: buyerImage,
        createdAt: timestamp,
        currencyAmount,
        pointAmount: points.toString(),
        currency: 'points',
      });
      curateTransactions2(state.global);
      updateHoldingStats(
        state.global,
        isNewHolder ? 1 : 0,
        pointAmountHeldBeforeTx,
        points,
      );

      updateDataPoints(state.global, timestamp);
    },
  ),

  // deprecation below this line

  // @deprecated
  createOffchainToken: createSharedStateMessage(
    SB.object({
      details: offchainTokenDetailsSchema,
      timestamp: SB.int().optional(),
      currencyAmount: SB.string(),
      isDev: SB.boolean().optional(),
    }),
    (state, { details, timestamp, currencyAmount, isDev }, meta) => {
      // Set offchainToken details
      state.global.details = details;

      const supply = getCreateEstimate(HP(currencyAmount));
      const createdAt = timestamp ?? meta.timestamp;

      state.global.txs.push({
        txType: 'buy',
        userId: details.creatorId,
        userName: details.creatorName,
        userImage: details.creatorImage,
        createdAt,
        currencyAmount,
        pointAmount: supply.toString(),
      });

      state.global.supply = supply.toString();

      curateTransactions(state.global, meta.timestamp);

      if (supply.gt(0)) {
        updateHoldingStats(state.global, 1, '0', supply);
      }

      if (isDev) {
        state.global.status = OffchainTokenStatus.Moderated;
      }
    },
  ),

  // @deprecated
  attemptBuyOffchainToken: createSharedStateMessage(
    SB.object({
      // @todo: remove the check boolean after Sept 15th
      checkCanBuy: SB.boolean().optional(),
      timestamp: SB.int().optional(),
      expectedTxIdx: SB.int().optional(),
      buyerId: SB.string(),
      buyerName: SB.string().optional(),
      buyerImage: SB.string().optional(),
      currencyAmount: SB.string(), // how much currencyAmount wants to invest
      pointAmountEstimate: SB.string(),
      pointAmountHeldBeforeTx: SB.string().optional(),
      driftPct: SB.number(),
      isNewHolder: SB.boolean(),
    }),
    (
      state,
      {
        checkCanBuy,
        timestamp,
        expectedTxIdx,
        buyerId,
        buyerName,
        buyerImage,
        currencyAmount,
        pointAmountEstimate,
        pointAmountHeldBeforeTx,
        driftPct,
        isNewHolder,
      },
      meta,
    ) => {
      let currencyAmountNum = HP(currencyAmount);
      let pointAmountEstimateNum = HP(pointAmountEstimate);
      const canBuy = getCanBuy(state.global, {
        currencyAmount: currencyAmountNum,
        pointAmountEstimate: pointAmountEstimateNum,
        driftPct,
      });
      const isExpectedState =
        expectedTxIdx === undefined ||
        state.global.txs.length === expectedTxIdx;
      const createdAt = timestamp ?? meta.timestamp;
      if ((checkCanBuy && !canBuy) || !isExpectedState) {
        const reason = isExpectedState ? undefined : 'concurrencyIssue';
        addFailedTx(state.global, createdAt, buyerId, reason);
        return;
      }
      // For the sake of saving only the value invested into the offchainToken, excludeFee
      const pointAmount = getBuyEstimate(state.global, currencyAmountNum);

      const supplyNum = HP(state.global.supply);
      state.global.supply = supplyNum.add(pointAmount).toString();
      state.global.txs.push({
        txType: 'buy',
        userId: buyerId,
        userName: buyerName,
        userImage: buyerImage,
        createdAt,
        currencyAmount,
        pointAmount: pointAmount.toString(),
      });

      curateTransactions(state.global, meta.timestamp);

      const holderCountChange = isNewHolder ? 1 : 0;
      updateHoldingStats(
        state.global,
        holderCountChange,
        pointAmountHeldBeforeTx,
        pointAmountEstimateNum,
      );
    },
  ),

  // @deprecated
  attemptSellOffchainToken: createSharedStateMessage(
    SB.object({
      // @todo: remove the check boolean after Sept 15th
      checkCanSell: SB.boolean().optional(),
      timestamp: SB.int().optional(),
      expectedTxIdx: SB.int().optional(),
      sellerId: SB.string(),
      sellerName: SB.string().optional(),
      sellerImage: SB.string().optional(),
      pointAmount: SB.string(), // how many tokens wants to sell
      currencyAmountEstimate: SB.string(), // how much the user has been show it would receive
      pointAmountHeldBeforeTx: SB.string().optional(),
      driftPct: SB.number(), // how much off mark can it be and still be acceptable
      sellingAllTokens: SB.boolean(),
    }),
    (
      state,
      {
        checkCanSell,
        timestamp,
        expectedTxIdx,
        sellerId,
        sellerName,
        sellerImage,
        pointAmount,
        currencyAmountEstimate,
        pointAmountHeldBeforeTx,
        driftPct,
        sellingAllTokens,
      },
      meta,
    ) => {
      const pointAmountNum = HP(pointAmount);
      const currencyAmountEstimateNum = HP(currencyAmountEstimate);
      const sellPrice = getSellEstimate(state.global, pointAmountNum);
      const createdAt = timestamp ?? meta.timestamp;

      if (!sellPrice.gt(0)) {
        addFailedTx(state.global, createdAt, sellerId);
        return;
      }

      const canSell = getCanSell(state.global, {
        pointAmount: pointAmountNum,
        currencyAmountEstimate: currencyAmountEstimateNum,
        driftPct,
      });
      const isExpectedState =
        expectedTxIdx === undefined ||
        state.global.txs.length === expectedTxIdx;
      if ((checkCanSell && !canSell) || !isExpectedState) {
        const reason = isExpectedState ? undefined : 'concurrencyIssue';
        addFailedTx(state.global, createdAt, sellerId, reason);
        return;
      }

      const currentSupply = HP(state.global.supply);
      const newSupply = currentSupply.minus(pointAmountNum);
      state.global.supply = newSupply.toString();

      curateTransactions(state.global, meta.timestamp);

      const holderCountChange = sellingAllTokens ? -1 : 0;
      updateHoldingStats(
        state.global,
        holderCountChange,
        pointAmountHeldBeforeTx,
        pointAmountNum.mul(-1),
      );

      state.global.txs.push({
        txType: 'sell',
        userId: sellerId,
        userName: sellerName,
        userImage: sellerImage,
        createdAt: createdAt,
        currencyAmount: sellPrice.toString(),
        pointAmount,
      });
    },
  ),

  // @deprecated
  awardMemeTokenGift: createSharedStateMessage(
    SB.object({
      timestamp: SB.int().optional(),
      buyerId: SB.string(),
      buyerName: SB.string().optional(),
      buyerImage: SB.string().optional(),
    }),
    (state, { buyerId, buyerName, buyerImage, timestamp }, meta) => {
      const currencyAmountNum = HP(giftTokenCoinAmount);
      const currencyAmount = currencyAmountNum.toString();
      const pointAmount = getBuyEstimate(state.global, currencyAmountNum);
      const supplyNum = HP(state.global.supply);
      state.global.supply = supplyNum.add(pointAmount).toString();
      state.global.txs.push({
        txType: 'buy',
        userId: buyerId,
        userName: buyerName,
        userImage: buyerImage,
        createdAt: timestamp ?? meta.timestamp,
        currencyAmount,
        pointAmount: pointAmount.toString(),
      });
      curateTransactions(state.global, meta.timestamp);
      updateHoldingStats(state.global, 1, '0', currencyAmountNum);

      // updateDataPoints(state.global, meta.timestamp);
    },
  ),
});
