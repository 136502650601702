import Confetti from 'react-confetti';
import './ConfettiEffect.scss';
import { useEffect, useState } from 'react';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { Gravity } from '../../../data/types';

const ConfettiEffect = (opts: {
  show: boolean;
  gravity?: Gravity;
  onFinish?: () => void;
}) => {
  const show = opts.show;
  const [gravity, setGravity] = useState({ from: 0.5, to: 0.5 } as Gravity);

  useEffect(() => {
    const { from, to } = opts.gravity || { from: 0.05, to: 0.5 };
    setGravity({ from, to });
  }, [opts.gravity]);

  return (
    <div className="confetti-effect">
      <Confetti
        run={true}
        width={visualViewport?.width}
        height={visualViewport?.height}
        gravity={show ? gravity.from : gravity.to}
        // wind={show ? 0 : 0.5}
        friction={show ? 0.99 : 1}
        numberOfPieces={show ? 80 : 0}
      />
    </div>
  );
};

export default ConfettiEffect;

export const ModalConfetti = () => {
  const { visible, hide, data } = app.ui.confetti;

  useAppUpdates({
    id: 'Popup',
    listener: app.ui.confetti.attachEventListener(),
  });

  useEffect(() => {
    if (data?.duration) {
      const timer = setTimeout(() => {
        hide(true);
      }, data.duration);
      return () => {
        clearInterval(timer);
      };
    }
  }, [data?.duration]);

  if (!visible) {
    return null;
  }

  return <ConfettiEffect show={visible || true} gravity={data?.gravity} />;
};
