import type { ReplicantError } from '@play-co/replicant';
import * as Sentry from '@sentry/browser';
import { env } from './config';
import config from '../replicant/features/game/game.config';
import { isTonConnectSdkError } from './hijackUnhandledPromiseErrors';
import { analytics } from '@play-co/gcinstant';

export function initSentry() {
  if (env === 'local') {
    return;
  }

  const sentryOpts: Sentry.BrowserOptions = {
    dsn: config.sentryDNS,
    autoSessionTracking: true,
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    environment: `${env}-client`,

    // Truncate breadcrumbs to avoid exceeding Sentry's 100KB request size limit:
    beforeBreadcrumb: (bc) => {
      // Filter out Amplitude request breadcrumbs:
      if (bc.type === 'http' && bc.data?.url === 'https://api.amplitude.com') {
        return null;
      }

      // Truncate long console messages:
      if (bc.category === 'console' && (bc.message?.length ?? 0) > 1000) {
        if (!bc.data) bc.data = {};
        bc.data.arguments = [];
        bc.message = bc.message?.substr(0, 997) + '...';
      }
      // Truncate long URLs like asset data URLs:
      else if (bc.type === 'http' && bc.data?.url?.length > 1000) {
        if (!bc.data) bc.data = {};
        bc.data.url = bc.data.url?.substr(0, 997) + '...';
      }

      return bc;
    },

    beforeSend: (event, hint) => {
      // Skip reporting `invoking_while_out_of_sync` errors:
      if (isInvokeWhileOutOfSyncPromiseRejection(hint)) {
        return null;
      }

      // Skip reporting TonConnectSdkError to sentry, but send to Amplitude
      // Note: the reason to had it sent to Amplitude in here is because Sentry catches more errors than the `unhandledrejection` event
      if (isHintTonConnectSdkError(hint)) {
        const originalException = hint.originalException;
        analytics.pushError(
          'TonConnectSdkError',
          typeof originalException === 'string'
            ? { message: originalException }
            : (hint.originalException as any),
        );
        return null;
      }

      return event;
    },
  };

  Sentry.init(sentryOpts);
}

function isInvokeWhileOutOfSyncPromiseRejection(
  hint: Sentry.EventHint,
): boolean {
  const originalException = hint.originalException as any;

  return (
    originalException?.['code'] === 'replication_error' &&
    originalException?.['subCode'] === 'invoking_while_out_of_sync'
  );
}

function isHintTonConnectSdkError(hint: Sentry.EventHint): boolean {
  const originalException = hint.originalException as any;
  return isTonConnectSdkError(originalException);
}

export function setSentryUser(user: { id: string; username: string }) {
  Sentry.setUser(user);
}

export function captureGenericError(
  effect: string,
  cause: any,
  context?: Parameters<(typeof Sentry)['captureException']>[1],
) {
  // Report error to console in development
  if (env !== 'prod') {
    // eslint-disable-next-line no-console
    console.error(effect, cause);
  }

  if (cause instanceof Error) {
    // The cause can have read-only properties, so we need to create a new Error
    const newError = new Error(`${effect} (${cause.message})`);
    newError.name = cause.name;
    newError.stack = cause.stack;
    Sentry.captureException(newError, context);
  } else {
    const exception = cause
      ? Error(`${effect} (non-Error: ${JSON.stringify(cause)})`)
      : Error(effect);

    (exception as any).framesToPop = 1;

    Sentry.captureException(exception, context);
  }
}

const errorCounts: Record<string, number> = {};

function isMeaningful(erroCount: number) {
  const logBefore = Math.round(Math.log(erroCount - 1) / Math.log(10));
  const logAfter = Math.round(Math.log(erroCount) / Math.log(10));

  return logBefore !== logAfter;
}

// todo: to review if all right
export function captureReplicantError(err: ReplicantError) {
  let errorCount = 1;
  if (err.subCode) {
    errorCount = (errorCounts[err.subCode] ?? 0) + 1;
    errorCounts[err.subCode] = errorCount;

    // @note: because of hackers a lot of errors are spamming the servers
    // only errors crossing logarithm thresholds are considered meaningful
    // i.e 1st error, 10th error, 100th error, etc...
    if (!isMeaningful(errorCount)) {
      return;
    }
  }

  // Use an Error with a custom name for nice display in Sentry
  const exception = Error(`${err.code} (${err.message}) (occ:${errorCount})`);
  exception.name = 'ReplicantError';
  (exception as any).framesToPop = 1;

  // Prevent different error codes from being combined together
  Sentry.withScope((scope) => {
    scope.setFingerprint(['{{ default }}', err.code, err.subCode || '']);

    // In production, err.message is the requestId and we can correlate it with the server-side
    // error based on the requestId.
    if (env !== 'prod') {
      scope.setTag('requestId', err.message);
    }

    Sentry.captureException(exception);
  });
}
