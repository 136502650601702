import {
  createScheduledActions as _createScheduledActions,
  SB,
  ScheduledActions,
} from '@play-co/replicant';
import { asyncGetters } from './asyncGetters';
import { computedProperties } from './computedProperties';
import messages from './messages';
import { ruleset } from './ruleset';
import stateSchema from './schema';
import { sharedStates } from './sharedStates';

export const createScheduledActions = <T extends { [key: string]: SB.Schema }>(
  actionSchemaMap: T,
) =>
  _createScheduledActions(stateSchema, actionSchemaMap, {
    messages,
    computedProperties,
    asyncGetters,
    ruleset,
    sharedStates,
  });

type ScheduledActionHandlers<TScheduledActions extends ScheduledActions> = {
  [K in keyof TScheduledActions]: TScheduledActions[K]['action'];
};

export const extractScheduledActionHandlers = <
  TScheduledActions extends ScheduledActions,
>(
  actions: TScheduledActions,
) =>
  Object.entries(actions).reduce(
    (actions, [name, { action }]) => ({
      ...actions,
      [name]: action,
    }),
    {} as ScheduledActionHandlers<TScheduledActions>,
  );
