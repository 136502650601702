import { createMessages } from '@play-co/replicant';
import stateSchema from './schema';
import { gameMessages } from './features/game/game.messages';
import { teamMemberMessages } from './features/teams/teams.messages';

export const messages = createMessages(stateSchema)({
  ...gameMessages,
  ...teamMemberMessages,
});

export default messages;
