import { stage } from '../../../replicant/features/game/game.config';
import memeFeedAds from '../../../replicant/features/powerups/airtable/memeFeedAds';
import { MemeFeedAdConfig } from '../../../replicant/features/quests/types';
import { app } from '../AppController';
import { loadContent } from '../../loader';
import { MemeFilters } from './types';

const memeAdFrequency = stage === 'prod' ? 100 : 100; // per thousand

// function gcd(a: number, b: number) {
//   // Euclidean algorithm loop
//   while (b !== 0) {
//     const temp = b;
//     b = a % b;
//     a = temp;
//   }
//   return a;
// }

// function findCoprime(n: number, seed: number): number {
//   // Start from 2 (since gcd(1, n) is always 1)
//   for (let b = 2; b < n - 1; b++) {
//     const a = (seed + b) % n;
//     if (a < Math.ceil(n * 0.1) || Math.floor(n * 0.9) < a) {
//       // we want to avoid coprime values that will lead to obvious patterns
//       continue;
//     }

//     if (gcd(a, n) === 1) {
//       return a;
//     }
//   }

//   // If no coprime number is found in the range, return 1
//   return 1;
// }

function shuffleArray(adConfigs: MemeFeedAdConfig[]) {
  // shuffles the array in a way that guarantees that each element in adConfigs exists in shuffledArray
  const shuffledAdControllers = adConfigs.map(
    (adConfig) => new MemeFeedAdController(adConfig),
  );
  for (let i = shuffledAdControllers.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledAdControllers[i], shuffledAdControllers[j]] = [
      shuffledAdControllers[j],
      shuffledAdControllers[i],
    ];
  }
  return shuffledAdControllers;
}

export class MemeFeedAdController {
  private viewed: boolean = false;

  private loadRequest: Promise<any> | null = null;

  private viewPromise: Promise<any> | null = null;

  private contentFailedToLoad: boolean = false;

  private viewStartTime: number = Infinity;

  constructor(private adConfig: MemeFeedAdConfig) {}

  get memeAdConfig() {
    return this.adConfig;
  }

  public wasViewed() {
    return this.viewed;
  }

  private async loadContent() {
    try {
      await loadContent(this.adConfig.contentUrl);
    } catch (error) {
      this.contentFailedToLoad = true;
    }
  }

  public load() {
    if (this.loadRequest !== null) {
      return;
    }

    this.loadRequest = this.loadContent();
    return this.loadRequest;
  }

  get lockInDuration() {
    return this.adConfig.lockInDuration ?? 0;
  }

  get timeLeftUntilViewed() {
    const now = Date.now();
    const viewDuration = now - this.viewStartTime;

    return Math.max(
      0,
      Math.min(this.lockInDuration, this.lockInDuration - viewDuration),
    );
  }

  public async view() {
    if (this.loadRequest === null) {
      await this.load();
    } else {
      await this.loadRequest;
    }

    if (this.contentFailedToLoad) {
      return;
    }

    const lockInDuration = this.lockInDuration;
    if (lockInDuration === 0) {
      return;
    }

    if (this.viewPromise === null) {
      app.track('mfa_viewed', {
        id: this.adConfig.id,
        feature: this.adConfig.feature,
      });

      this.viewStartTime = Date.now();
      this.viewPromise = new Promise((resolve) =>
        setTimeout(resolve, lockInDuration),
      );
    }

    await this.viewPromise;
  }
}

class DeterministicSelector {
  private a: number = 0;
  private c: number = 0;

  constructor(private n: number = 1000) {
    // const coprimeSeed = Math.floor(n * Math.random());
    // this.a = findCoprime(n, coprimeSeed);

    // if (this.a === 1) {
    //   // given n did not allow to find a proper coprime
    //   while (this.a === 1) {
    //     this.a = findCoprime(this.n, coprimeSeed);
    //     this.n += 1;
    //   }
    // }

    // hard-coding n and a as it seems difficult to generate an "a" that makes the pattern look random to a human
    this.a = 449;

    this.c = Math.floor(Math.random() * this.n);
  }

  get elementCount() {
    return this.n;
  }

  public getElementIdx(pickingIdx: number) {
    return (this.a * pickingIdx + this.c) % this.n;
  }
}

export class MemeFeedAds {
  private activeMemeFeedAds: MemeFeedAdConfig[] = [];
  private memeFeedAdUnshuffledRotation: MemeFeedAdConfig[] = [];
  private memeFeedAdRotationsByFilter: Partial<
    Record<MemeFilters, MemeFeedAdController[][]>
  > = {};

  private feedItemPainters: Partial<
    Record<MemeFilters, DeterministicSelector>
  > = {};

  public init = () => {
    const now = app.now();
    this.activeMemeFeedAds = memeFeedAds.reduce(
      (activeMemeFeedAds, memeFeedAdConfig: MemeFeedAdConfig) => {
        if (memeFeedAdConfig.startTime && memeFeedAdConfig.endTime) {
          if (
            now < memeFeedAdConfig.startTime ||
            memeFeedAdConfig.endTime < now
          ) {
            return activeMemeFeedAds;
          }
        }

        if (memeFeedAdConfig.frequency <= 0) {
          return activeMemeFeedAds;
        }

        if (stage === 'prod' && memeFeedAdConfig.test) {
          return activeMemeFeedAds;
        }

        activeMemeFeedAds.push(memeFeedAdConfig);

        return activeMemeFeedAds;
      },
      [],
    );

    let cumulativeFrequency = 0;
    this.activeMemeFeedAds.forEach((memeFeedAdConfig) => {
      const frequency = memeFeedAdConfig.frequency;
      for (let i = 0; i < frequency; i += 1) {
        this.memeFeedAdUnshuffledRotation[i + cumulativeFrequency] =
          memeFeedAdConfig;
      }
      cumulativeFrequency += frequency;
    });
  };

  public getMemeAdController = (filter: MemeFilters, pickingIdx: number) => {
    // does a rotation list exists for that filter?
    let memeFeedAdRotations = this.memeFeedAdRotationsByFilter[filter];
    if (memeFeedAdRotations === undefined) {
      memeFeedAdRotations = this.memeFeedAdRotationsByFilter[filter] = [];
    }

    // does the rotation exists for that display index?
    const rotationSize = this.memeFeedAdUnshuffledRotation.length;
    const rotationIdx = Math.floor(pickingIdx / rotationSize);
    if (memeFeedAdRotations[rotationIdx] === undefined) {
      // @goal: shuffle the meme feed ads so that they alternate in a way that match their respective frequency
      memeFeedAdRotations[rotationIdx] = shuffleArray(
        this.memeFeedAdUnshuffledRotation,
      );
    }

    const idxInRotation = pickingIdx - rotationIdx * rotationSize;
    return memeFeedAdRotations[rotationIdx][idxInRotation];
  };

  public isFeedItemAnAd = (filter: MemeFilters, feedItemIdx: number) => {
    if (feedItemIdx === 0) {
      // first element is never an add
      return false;
    }

    let feedItemPainter = this.feedItemPainters[filter];
    if (!feedItemPainter) {
      feedItemPainter = this.feedItemPainters[filter] =
        new DeterministicSelector();
    }

    const feedItemColor = feedItemPainter.getElementIdx(feedItemIdx - 1);
    return feedItemColor < memeAdFrequency;
  };
}
