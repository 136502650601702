import { offchainTradingSharedStates } from './features/offchainTrading/offchainTrading.sharedStates';
import { tradingMemeSharedState } from './features/tradingMeme/tradingMeme.sharedStates';
import { teamSharedStates } from './features/teams/teams.sharedStates';
import { friendsSharedStates } from './features/friends/friends.sharedStates';
import { followingsSharedStates } from './features/followings/followings.sharedStates';
import { onchainTxsSharedStates } from './features/onchainTxs/onchainTxs.sharedStates';
import { onchainHoldersSharedStates } from './features/onchainHolders/onchainHolders.sharedStates';

export const sharedStates = {
  teams: teamSharedStates,
  friends: friendsSharedStates,
  followings: followingsSharedStates,
  offchainTrading: offchainTradingSharedStates,
  tradingMeme: tradingMemeSharedState,
  onchainTxs: onchainTxsSharedStates,
  onchainHolders: onchainHoldersSharedStates,
};

export default sharedStates;
