import './TiktokPlayAndFarmUI.scss';
import { useEffect, useState } from 'react';
import { isMobile, isMobileEmulatedByBrowser } from '../../../../data/device';
import { app } from '../../../../data/Controllers/AppController';
import { TMGEvents } from '../../../../data/Controllers/TokenMiniGames/TMGController';
import { useManyAppUpdates } from '../../../../data/hooks';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';
import { insertIconIntoText } from '../../../../data/utils';
import { useTranslation } from 'react-i18next';
// import { FarmingWidget } from '../components/FarmingWidget';
import {
  getTMGTappingMaxTickets,
  getTMGFarmingIsShowing,
} from '../../../../replicant/features/tradingMeme/tradingMeme.getters';

// ==============================================================
// #region PlayAndFarm

interface TiktokPlayAndFarmProps {
  visible: boolean;
  uiAlpha: number;
  canShowTapGame: boolean;
}

export const TiktokPlayAndFarmUI: React.FC<TiktokPlayAndFarmProps> = ({
  visible,
  uiAlpha,
  canShowTapGame,
}) => {
  useManyAppUpdates({
    id: 'TiktokTapGame',
    events: [
      {
        listener: app.tmg.attachEventListener(TMGEvents.OnTappingPlayingUpdate),
      },
    ],
  });

  const { meme: token } = app.memes.currentMeme;
  const { tap } = app.tmg;

  if (!token || !tap) {
    return null;
  }

  const { showPoints, isPlaying, gameStartCountdown } = tap;

  // should we render play button?
  // only if game is not active
  const isPlayButtonEnabled = !isPlaying && (!visible || !showPoints);
  let playButtonFadeClass = isPlayButtonEnabled ? 'fade-in-slow' : 'invisible'; // 'fade-out-slow';
  if ((showPoints && canShowTapGame) || gameStartCountdown) {
    playButtonFadeClass = 'invisible';
  }

  const isShowingFarming = getTMGFarmingIsShowing(app.state);

  return (
    <div
      id={`tiktok-playAndFarm`}
      className={`tiktok-playAndFarm ${playButtonFadeClass}`}
      style={{ opacity: uiAlpha }}
    >
      <div className="tiktok-playAndFarm-content">
        <PlayButton />
        {/* // @todo: remove (POST SEASON 2 MIGRATION) */}
        {/* {isShowingFarming && <FarmingWidget />} */}
      </div>
    </div>
  );
};

// ==============================================================
// #region Play Button

type PlayButtonState = 'play' | 'invite';

const PlayButton = () => {
  const { t } = useTranslation();
  const { tap } = app.tmg;

  const tickets = app.tmg.tap?.tickets || 0;
  const tappingMaxTickets = getTMGTappingMaxTickets(app.state);
  const autoTimer = app.tmg.tap?.gameAutoStartCountdown;

  const [buttonState, setButtonState] = useState<PlayButtonState>('play');

  useEffect(() => {
    // The token has changed, or tickets left has changed
    setBtnState();
  }, [app.memes.currentMeme.meme?.id, tickets, autoTimer]);

  const setBtnState = () => {
    if (tickets === 0) {
      setButtonState('invite');
    } else {
      setButtonState('play');
    }
  };

  function getButtonContent() {
    const buttonContent = {
      play: t('tapgame_playbutton_play', {
        ticketCount: tickets,
        maxTicketCount: tappingMaxTickets,
      }),
      invite: t('tapgame_playbutton_invite'),
    };

    if (!(buttonState in buttonContent)) {
      return 'None';
    }

    const localization = buttonContent[buttonState];
    const html = insertIconIntoText(localization);

    return (
      <div
        className={`button-play-content ${buttonState}`}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }

  const onTapButton = () => {
    // console.warn('>>> onTapButton', buttonState);
    // depending on the state of the button, execute a different method
    switch (buttonState) {
      case 'play':
        return tap?.startGame();
      case 'invite':
        return onInviteUser();
    }
  };

  const onInviteUser = () => {
    // open drawerTiktokInvite
    app.ui.drawer.show({
      id: 'drawerTiktokInvite',
      skipBackdropClose: true,
      hideClose: isMobile() || isMobileEmulatedByBrowser(),
      opts: {},
    });
  };

  return (
    <div
      className={`button-play-container-center`}
      // style={{
      //   transform: `scale(${window.innerHeight / 796})`,
      // }}
    >
      <div className="button-play-outer" onClick={onTapButton} />
      <div className={`btn button-play`}>{getButtonContent()}</div>
    </div>
  );
};

// ==============================================================
