import './ButtonBack.scss';
import { app } from '../../../data/Controllers/AppController';
import { assets } from '../../../assets/assets';
import { isTelegramWebview } from '../../../data/device';

export const ButtonBack = () => {
  // This component is only used if we are not on telegram webview
  // otherwise, we will be using native telegram back button on system header
  if (isTelegramWebview()) {
    return null;
  }

  const onClick = () => {
    console.warn('>>> click on back button');
    app.onBack();
  };

  return (
    <div className="btn button-back" onClick={onClick}>
      <img src={assets.button_arrow_back} />
    </div>
  );
};
