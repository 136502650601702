import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
  ReplicantUtilAPI,
  teaHash,
} from '@play-co/replicant';
import { State } from '../../schema';
import {
  Booster,
  energyIncrementPerLevel,
  energyPerEnergyLevel,
  pointPerMultiplierLevel,
  rocketmanConfig,
} from './ruleset/boosters';
import { FREE_ENERGY_RECHARGE_COOLDOWN } from './ruleset/buffs';
import { League, seasonLeagues, tapsPerLeague, SEASON } from './ruleset/league';
import {
  BASE_ENERGY,
  BASE_ENERGY_250,
  BASE_ENERGY_500,
  FALLBACK_PIC,
  MAX_ADDITIONAL_ENERGY,
  MAX_TAPS_PER_SECOND,
} from './ruleset/player';
import { ShopListing, boosterPricePerLevel } from './ruleset/shop';
import { DAY_IN_MS, getDayMidnightInPST, isNextDay } from '../../utils/time';
import { largeIntegerToLetter } from '../../utils/numbers';
import { DAILY_REWARDS } from './ruleset/dailyRewards';

import * as base62 from '../../utils/base62';
import { tests } from '../../ruleset';
import {
  getPowerUpBonusPerHour,
  getPowerUpBonusPerSecond,
} from '../powerups/getters';
import badgeTriggers from '../powerups/airtable/badgeTriggers';
import { BadgeTriggerAirtableItem, BadgeItem } from '../powerups/types';
import { maxNotifsPerPlayerPerDay } from '../chatbot/chatbot.ruleset';
import dailyCodes from '../powerups/airtable/dailyCodes';
import { PU_BONUS_MAX_MINING_DURATION } from '../powerups/ruleset';
import { getMyOffchainTokenIds } from '../tradingMeme/tradingMeme.getters';
import replicantConfig, { ReplicantServer } from '../../config';

export async function fetchPlayerState(
  api: ReplicantAsyncActionAPI<ReplicantServer>,
  playerId: string,
) {
  const playerStateMap = await api.fetchStates([playerId]);
  return playerStateMap?.[playerId]?.state;
}

export function getEarliestNotifiableTime(state: State, now: number): number {
  if (maxNotifsPerPlayerPerDay > 2) {
    // notifications do not have to wait until the next day
    return state.lastNotifTime + DAY_IN_MS / maxNotifsPerPlayerPerDay;
  }

  // e.g if 0.31 notifs/day, then daysToWait is about 3.2
  const daysToWait = 1 / maxNotifsPerPlayerPerDay;

  const lastConvenientTime = state.session_start_time ?? now;

  // notifications might have to wait 1 or more days
  const lastNotifDay = Math.floor(state.lastNotifTime / DAY_IN_MS);
  const today = Math.floor(now / DAY_IN_MS);

  // some users will have to wait 4 days
  const maxDays = Math.ceil(daysToWait);

  if (lastNotifDay + maxDays <= today) {
    // wait enough days for a new notification cycle
    return lastConvenientTime + DAY_IN_MS * maxDays;
  }

  // some users will have to wait only 3 days
  const minDays = Math.floor(daysToWait);

  // % of players waiting 4 days
  const maxDaysAllocationRatio = daysToWait - minDays;

  const minNotificationTime = lastConvenientTime + DAY_IN_MS * minDays;

  // heuristic: splitting players between current day and next day
  // the goal is to keep players notified close to one of their session times
  const waitMinTime =
    teaHash(state.id + minNotificationTime) > maxDaysAllocationRatio;
  return waitMinTime ? minNotificationTime : minNotificationTime + DAY_IN_MS;
}

export function generateUserPayloadKey(userId: string): string {
  const numericUserId = Number(userId);
  userId = Number.isSafeInteger(numericUserId)
    ? base62.encodeInteger(numericUserId)
    : userId;

  return (
    userId +
    '-' +
    base62.encodeInteger(Date.now()) +
    base62.encodeInteger(Number(Math.random().toString().substring(2)))
  );
}

export function isNotExpired(lastStarted: number, limit: number, now: number) {
  return now - lastStarted < limit;
}

export function getTimeLeft(timestamp: number, duration: number, now: number) {
  return timestamp + duration - now;
}

export function getEnergyLimit(state: State) {
  const energyLimitLevel = state.energy_limit_level ?? 0;
  const baseEnergy = getBaseEnergy(state);
  const extraEnergy =
    energyLimitLevel * Math.min(baseEnergy, MAX_ADDITIONAL_ENERGY);

  return getBaseEnergy(state) + extraEnergy;
}

export function getEnergyPerSecond(state: State) {
  return energyPerEnergyLevel[
    (state.energy_recharge_level ?? 0) as keyof typeof energyPerEnergyLevel
  ];
}

export function getBaseEnergy(state: State) {
  return BASE_ENERGY_500;
}

export function getEnergy(state: State, now: number) {
  const energyLimit = getEnergyLimit(state);
  const energyPerSecond = getEnergyPerSecond(state);
  const timeSinceLastRecharge = now - state.last_energy_refresh;
  const energyAfterRefresh =
    state.energy + (energyPerSecond * timeSinceLastRecharge) / 1000;
  return Math.min(energyLimit, Math.round(energyAfterRefresh));
}

export function getLastRocketman(state: State, freeRocketman = false) {
  const list = freeRocketman ? state.free_rocketman_used : state.rocketman_used;

  const [lastRocketman] = [...list].sort((a, b) => b - a);

  return lastRocketman;
}

function getHigherLeague(league1: League, league2: League) {
  return league1 > league2 ? league1 : league2;
}

export function getLeague(state: State, season: number): League {
  const leagueConf = seasonLeagues[season];

  // checking from highest to lowest
  let currentLeagueNameBasedOnBalance = League.league1;
  for (let i = leagueConf.length - 1; i >= 0; i--) {
    const [balanceRequired, leagueName] = leagueConf[i];
    if (state.balance >= balanceRequired) {
      currentLeagueNameBasedOnBalance = leagueName;
      break;
    }
  }

  if (state.league) {
    return getHigherLeague(
      currentLeagueNameBasedOnBalance,
      state.league as League,
    );
  }

  return currentLeagueNameBasedOnBalance;
}

export function getFriendLeague(friendLeague: string): League {
  if (friendLeague in League) {
    return friendLeague as League;
  }

  return League.league1;
}

// export function getTaps(state: State) {
//   const baseTaps = tapsPerLeague[getLeague(state)];

//   const extraTaps =
//     pointPerMultiplierLevel[
//       state.tap_level as keyof typeof pointPerMultiplierLevel
//     ];

//   return baseTaps + extraTaps;
// }

// export function getPointsPerTap(
//   state: State,
//   now: number,
//   ignoreRocketman = false,
// ) {
//   const taps = getTaps(state);

//   if (ignoreRocketman) {
//     return taps;
//   }

//   const { multiplier } = getRocketmanBonus(state, now);

//   // We want to ignore rocketman when we deailing with bot taps
//   const pointsPerTap = taps * multiplier;

//   return pointsPerTap;
// }

export function getRocketmanBonus(state: State, now: number) {
  const lastRocketman = getLastRocketman(state);
  const lastFreeRocketman = getLastRocketman(state, true);

  const isActive = lastRocketman
    ? isNotExpired(lastRocketman, rocketmanConfig.duration, now)
    : false;
  const isFreeActive = lastFreeRocketman
    ? isNotExpired(lastFreeRocketman, rocketmanConfig.duration, now)
    : false;

  // console.log('getRocketmanBonus', {lastFreeRocketman, lastRocketman, isActive, isFreeActive});

  if (isActive || isFreeActive) {
    return {
      multiplier: rocketmanConfig.multiplier,
      free: isFreeActive ? true : false,
      duration: rocketmanConfig.duration,
    };
  }

  return {
    multiplier: 1,
    free: false, // doesnt matter when it's not active
  };
}

export function getConstrainedTaps(taps: number) {
  if (taps > MAX_TAPS_PER_SECOND) {
    // taps limit exceeded
    taps = MAX_TAPS_PER_SECOND;
  }

  return taps;
}

export function getBuffInfo(state: State, now: number) {
  const freeRocketmanList: number[] = [];

  (state.free_rocketman_used || []).forEach((lastUsed) => {
    if (isNotExpired(lastUsed, rocketmanConfig.freeCooldown, now)) {
      freeRocketmanList.push(lastUsed);
    }
  });

  let nextRocketmanExpiry = 0;
  if (freeRocketmanList.length === rocketmanConfig.limitPerDay) {
    const [nextRocketman] = [...state.free_rocketman_used].sort();
    nextRocketmanExpiry = nextRocketman;
  }

  return {
    rocketmanUseCount: freeRocketmanList.length,
    nextRocketmanExpiry,
    lastEnergyUsed: state.free_energy_recharge_timestamp ?? 0,
  };
}

export function resolveProfilePicture(userId: string, photoUrl?: string) {
  if (!photoUrl) {
    // use fallback picture
    const userIdNumber = parseInt(userId);
    const fallbackPicCount = 10;
    const picIndex =
      (isFinite(userIdNumber) ? userIdNumber : userId.length) %
      fallbackPicCount;
    const fallbackPic = `https://notgemz-game.s3.amazonaws.com/media/${FALLBACK_PIC}-${picIndex}.png`;
    return fallbackPic;
  }

  return photoUrl;
}

export function getBoosterLevel(state: State, booster: Booster) {
  switch (booster) {
    case 'AutoTap':
      return state.has_auto_tap ? 1 : 0;
    case 'MultiTap':
      return state.tap_level;
    case 'RechargeLimit':
      return state.energy_limit_level;
    case 'RechargeSpeed':
      return state.energy_recharge_level;
  }
}

export function getBoosterPrice(booster: Booster, currentLevel: number) {
  return boosterPricePerLevel[booster][currentLevel];
}

export function getShopInfo(state: State, now: number): ShopListing {
  const { rocketmanUseCount, lastEnergyUsed, nextRocketmanExpiry } =
    getBuffInfo(state, now);

  const hasAutoTap = Boolean(getBoosterLevel(state, 'AutoTap'));

  const timeLeft = getTimeLeft(
    lastEnergyUsed,
    FREE_ENERGY_RECHARGE_COOLDOWN,
    now,
  );

  const timeLeftRocketman = getTimeLeft(nextRocketmanExpiry, DAY_IN_MS, now);

  const boosterList: Booster[] = ['MultiTap', 'RechargeLimit', 'RechargeSpeed'];
  const levelBoosters = boosterList.reduce((res, cur) => {
    const currentLevel = Number(getBoosterLevel(state, cur));
    const price = boosterPricePerLevel[cur][currentLevel];
    res[cur] = {
      price: price,
      level: currentLevel + 1,
      maxedOut: !price, // if there's no price is because it's reached max level
    };
    return res;
  }, {} as ShopListing['boosters']);

  const listing: ShopListing = {
    buffs: {
      Rocketman: {
        available: 3 - rocketmanUseCount,
        maxUses: 3,
        timeLeft: timeLeftRocketman,
      },
      FullEnergy: {
        timeLeft,
      },
    },
    boosters: {
      ...levelBoosters,
      AutoTap: {
        price: boosterPricePerLevel.AutoTap[0],
        level: Number(hasAutoTap),
        maxedOut: hasAutoTap,
      },
    },
  };

  return listing;
}

export function getTimeToFullRechargeInSecs(state: State) {
  const energyLimit = getEnergyLimit(state);
  const energyPerSecond = getEnergyPerSecond(state);
  const energyGap = energyLimit - state.energy;
  const timeToFullRechargeInSecs = energyGap / energyPerSecond;
  const timeToFullRechargeInMs = Math.ceil(timeToFullRechargeInSecs * 1000);
  return timeToFullRechargeInMs;
}

export function getChatId(
  api: ReplicantUtilAPI<any> | ReplicantEventHandlerAPI<any>,
) {
  return parseInt(api.getUserID());
}

function getFriendReferralBonus(now: number) {
  // 2024-06-03 : 1pm PT
  const start = 1717444800000;
  // 2024-06-10 : 1pm PT
  const end = 1718049600000;
  const applyBonus = now >= start && now <= end;
  const bonusMultiplier = applyBonus ? 5 : 1;
  return bonusMultiplier;
}

const rewardsByLeague = {
  league1: {
    regular: 2_500,
    premium: 50_000,
  },
  league2: {
    regular: 12_000,
    premium: 125_000,
  },
  league3: {
    regular: 25_000,
    premium: 250_000,
  },
  league4: {
    regular: 50_000,
    premium: 500_000,
  },
  league5: {
    regular: 100_000,
    premium: 1_000_000,
  },
  league6: {
    regular: 250_000,
    premium: 2_500_000,
  },
  league7: {
    regular: 500_000,
    premium: 5_000_000,
  },
  league8: {
    regular: 1_000_000,
    premium: 10_000_000,
  },
  league9: {
    regular: 2_500_000,
    premium: 25_000_000,
  },
};

const rewardsByLeagueLow = {
  league1: {
    regular: 12_500,
    premium: 250_000,
  },
  league2: {
    regular: 25_000,
    premium: 50_000,
  },
  league3: {
    regular: 250_000,
    premium: 500_000,
  },
  league4: {
    regular: 2_500_000,
    premium: 5_000_000,
  },
  league5: {
    regular: 12_500_000,
    premium: 25_000_000,
  },
  league6: {
    regular: 25_000_000,
    premium: 50_000_000,
  },
  league7: {
    regular: 37_500_000,
    premium: 75_000_000,
  },
  league8: {
    regular: 55_000_000,
    premium: 112_500_000,
  },
  league9: {
    regular: 125_000_000,
    premium: 225_000_000,
  },
  league10: {
    regular: 250_000_000,
    premium: 500_000_000,
  },
};

const rewardsByLeagueHigh = {
  league1: {
    regular: 25_000,
    premium: 500_000,
  },
  league2: {
    regular: 50_000,
    premium: 100_000,
  },
  league3: {
    regular: 500_000,
    premium: 1_000_000,
  },
  league4: {
    regular: 5_000_000,
    premium: 10_000_000,
  },
  league5: {
    regular: 25_000_000,
    premium: 50_000_000,
  },
  league6: {
    regular: 50_000_000,
    premium: 100_000_000,
  },
  league7: {
    regular: 75_000_000,
    premium: 150_000_000,
  },
  league8: {
    regular: 112_500_000,
    premium: 225_000_000,
  },
  league9: {
    regular: 225_000_000,
    premium: 450_000_000,
  },
  league10: {
    regular: 500_000_000,
    premium: 1_000_000_000,
  },
};

export function getFriendReferralRewards(
  state: State,
  now: number,
  league: League,
) {
  const regular = rewardsByLeague[league].regular;
  const premium = rewardsByLeague[league].premium;
  const bonus = getFriendReferralBonus(now);

  return {
    regular: regular * bonus,
    premium: premium * bonus,
  };
}

export function getReferralBonus(
  state: State,
  now: number,
  isPremium: boolean,
) {
  const reward = getFriendReferralRewards(state, now, League.league1);
  return isPremium ? reward.premium : reward.regular;
}

export function getUnclaimedRewardStartIndex(state: State) {
  if (state.unclaimed_rewards) {
    return state.streak_days - state.unclaimed_rewards;
  }
  return -1;
}

export function getTotalRewards(): number {
  let reward = 0;
  // + 1 because the first reward is 0
  for (let i = 1; i <= 10; i++) {
    reward += DAILY_REWARDS[i];
  }
  return reward;
}

export function getCombinedUnclaimedRewards(state: State): number {
  let reward = 0;
  const unclaimedRewardsStartIndex = getUnclaimedRewardStartIndex(state);
  if (unclaimedRewardsStartIndex === -1) {
    return 0;
  }
  // + 1 because the first reward is 0
  for (let i = unclaimedRewardsStartIndex + 1; i <= state.streak_days; i++) {
    reward += DAILY_REWARDS[i];
  }
  return reward;
}

export function getIsAfter1Day(now: number, timestamp: number) {
  const diff = now - timestamp;
  // console.log('getIsAfter1Day', {isBetween: diff >= DAY_IN_MS, now: new Date(now), last_granted: new Date(timestamp)})
  return diff >= DAY_IN_MS;
}

export function getIsbetween1And2Days(now: number, timestamp: number) {
  const diff = now - timestamp;
  // console.log('getIsbetween1And2Days', {isBetween: diff >= DAY_IN_MS && diff < DAY_IN_MS * 2, now: new Date(now), last_granted: new Date(timestamp)})
  return diff >= DAY_IN_MS && diff < DAY_IN_MS * 2;
}

export function getIsAfter2Days(now: number, timestamp: number) {
  const diff = now - timestamp;
  // console.log('getIsAfter2Days', {isBetween: diff > DAY_IN_MS * 2, now: new Date(now), last_granted: new Date(timestamp)})
  return diff > DAY_IN_MS * 2;
}

export function getBalance(state: State, now: number) {
  const elapsedTime = now - state.powerUps.last_claimed;
  const gainsPerSec = getPowerUpBonusPerSecond(state, now);
  const powerUpGains = Math.floor(
    gainsPerSec * (Math.min(PU_BONUS_MAX_MINING_DURATION, elapsedTime) / 1000),
  );
  return state.balance + powerUpGains;
}

export function getHasAServerDayElapsed(state: State, now: number) {
  return isNextDay(state.powerUps.daily.timestamp, now);
}

export function getBadges(state: State, now: number) {
  const { lastUpdated, whitelisted } = state.badgeControl;
  // only return items which were not present in the last update
  // and which are active
  const items = getFilteredBadges((trigger) => {
    // startTime is a time in a readable format
    if (whitelisted.includes(trigger.id)) {
      return true;
    }
    const startTimestamp = new Date(trigger.startTime).getTime();
    /*
    console.log('debug badges', {
      startTimestamp: new Date(trigger.startTime),
      now: new Date(now),
      lastUpdated: new Date(lastUpdated),
      trigger: trigger.id,
      startedBeforeNow: startTimestamp <= now,
      startedBetweenSessions: startTimestamp > lastUpdated,
    })
    */
    return startTimestamp <= now && startTimestamp > lastUpdated;
  });

  return items;
}

function getFilteredBadges(
  filterFunction: (trigger: BadgeTriggerAirtableItem) => boolean,
): { [key in BadgeItem]: BadgeTriggerAirtableItem[] } {
  return badgeTriggers.filter(filterFunction).reduce((acc, trigger) => {
    if (!acc[trigger.type]) {
      acc[trigger.type] = [];
    }
    acc[trigger.type].push({
      id: trigger.id,
      startTime: trigger.startTime,
    });
    return acc;
  }, {} as any);
}

export function getWhitelistedBadgesIds() {
  return badgeTriggers
    .filter((trigger) => !!trigger.whitelisted)
    .map((trigger) => trigger.id);
}

export function getSourceRealtimeStats(
  state: State,
  now: number,
): {
  sourceRealtimeEnergy: number;
  sourceRealtimeEarningsPerHour: number;
  sourceRealtimeEarningsPerSecond: number;
  sourceRealtimeBalance: number;
  sourceRealtimeScore: number;
} {
  const earningsPerHour = getPowerUpBonusPerHour(state, now);
  return {
    sourceRealtimeEnergy: getEnergy(state, now),
    sourceRealtimeEarningsPerHour: earningsPerHour,
    sourceRealtimeEarningsPerSecond: earningsPerHour / 3600,
    sourceRealtimeBalance: getBalance(state, now),
    sourceRealtimeScore: state.score,
  };
}

export function getDailyCode(now: number) {
  const today = getDayMidnightInPST(now);

  for (let i = 0; i < dailyCodes.length; i++) {
    const daily = dailyCodes[i];
    if (getDayMidnightInPST(daily.starts_at) === today) {
      return daily.code;
    }
  }

  return undefined;
}

export function getHasCompletedDailyCode(state: State) {
  return state.dailyCode.complete;
}

export function isEligibeForFreeMemeToken(state: State, now: number) {
  const hasNotReceivedGift = !state.trading.giftTokenId;
  const hasCreatedAccountMoreThan2DaysAgo = getIsAfter2Days(
    now,
    state.createdAt,
  );
  const hasNotTradedMemes = getMyOffchainTokenIds(state).length === 0;

  return (
    hasNotReceivedGift && hasCreatedAccountMoreThan2DaysAgo && hasNotTradedMemes
  );
}

// Tiktok was introduced as version 4.0.0
export function isFromTiktokOnwards() {
  const [majorVersion] = replicantConfig.version.split('.');
  const majorNumber = Number(majorVersion);
  return majorNumber >= 4;
}

export function getUserStartingSeason(state: State) {
  // return 1; // testing purposes
  return state.startingSeason;
}
