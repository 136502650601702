import { DAY_IN_MS } from '../../../utils/time';

// buffs are paid power-ups
export const pointPerMultiplierLevel = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
};

export const energyPerEnergyLevel = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
};

export const energyIncrementPerLevel = {
  0: 0,
  1: 500,
  2: 1000,
  3: 1500,
  4: 2000,
  5: 2500,
  6: 3000,
  7: 3500,
  8: 4000,
  9: 4500,
  10: 5000,
};

export const rocketmanConfig = {
  duration: 1000 * 20, // 20 seconds
  cooldown: 1000 * 60 * 60, // 1 hour
  limitPerDay: 3,
  multiplier: 5,
  chance: 20,
  freeCooldown: DAY_IN_MS,
};

export type Booster =
  | 'AutoTap'
  | 'MultiTap'
  | 'RechargeSpeed'
  | 'RechargeLimit';
export type BoosterProps =
  | 'has_auto_tap'
  | 'tap_level'
  | 'energy_recharge_level'
  | 'energy_limit_level';

export const boosterProps: Record<Booster, BoosterProps> = {
  AutoTap: 'has_auto_tap',
  MultiTap: 'tap_level',
  RechargeSpeed: 'energy_recharge_level',
  RechargeLimit: 'energy_limit_level',
};
