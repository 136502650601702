export const stage = process.env.REACT_APP_STAGE ?? process.env.STAGE; // undefined in local dev

const prodPlayUrl = 'https://ff.notgemz.gemz.fun';

const botNames: Record<string, string> = {
  alpha: 'pnk_alpha_bot',
  bravo: 'pnk_bravo_bot',
  charlie: 'pnk_charlie_bot',
  dev: 'pnk_develop_bot',
  prod: 'gemzcoin_bot',
};

const playUrls: Record<string, string> = {
  alpha: 'https://notgemz.alpha.pnk.one',
  bravo: 'https://notgemz.bravo.pnk.one',
  charlie: 'https://notgemz.charlie.pnk.one',
  dev: 'https://notgemz.pnk.one',
  prod: prodPlayUrl,
};

export type Env = 'alpha' | 'bravo' | 'charlie' | 'dev' | 'prod' | 'local';

export function getEnvBotName(env: Env) {
  return botNames[env];
}

export function getEnvPlayUrl(env: Env) {
  return playUrls[env];
}

export const config = {
  botName: stage
    ? botNames[stage]
    : // todo: ideally this should be a 'local' prop, but there is no local in stage
      'GemzcoinLocal', // undefined

  playUrl: stage ? playUrls[stage] || prodPlayUrl : prodPlayUrl,

  sentryDNS:
    'https://b30872fe6a5951ca2dd8d9dd82c928f1@o4505230490861568.ingest.us.sentry.io/4507338261659648',
};

export default config;
