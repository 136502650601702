import { ReplicantEventHandlerAPI } from '@play-co/replicant';
import {
  BotActions,
  CmdOpts,
  callToActions,
  cmdReplyCaptions,
  maxNotifDelays,
} from './chatbot.ruleset';
import { MutableState } from '../../schema';
import templates from './chatbot.templates';
import { onHelp, onInvite, onLocal, onUpdateBotMenu } from './chatbot.private';
import { ReplicantServer } from '../../config';
import { TelegramMessage } from './chatbot.schema';
import config from '../game/game.config';
import { sendTelegramMessage } from './chatbot.modifiers';

const onStart = async (
  state: MutableState,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
  message: TelegramMessage,
  cmdOpts?: CmdOpts,
) => {
  sendTelegramMessage(state, api, {
    chatId: message.chat.id,
    message: templates.startCmdGroup({
      args: {
        // @todo: remove message once medias are enabled
        caption: cmdReplyCaptions.startCmdGroup,
        callToAction: callToActions.startCmdGroup,
        botLink: `t.me/${config.botName}`,
      },
      payload: {
        $channel: 'CHATBOT',
        feature: 'bot',
        $subFeature: 'bot_start',
      },
    }),
    receiverId: state.id,
    maxDelayMs: maxNotifDelays.startCmdPrivate,
  });
};

export const groupActions: BotActions = {
  '/start': onStart,
  '/invite': onInvite,
  '/help': onHelp,
  // @note: this command should simply not be configured in the prod chatbot
  '/local': async () => {},
  '/updateBotMenu': async () => {},
  '/generatePayload': async () => {},
  '/devHelp': async () => {},
};
