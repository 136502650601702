import { SB } from '@play-co/replicant';

export const powerupDetailsSchema = {
  name: SB.string(),
  description: SB.string(),
  image: SB.string(),
  effect: SB.string().optional(),
};

export const powerupSchema = {
  id: SB.string(),
  details: SB.object(powerupDetailsSchema),
  earn: SB.number(),
  level: SB.number(),
  cost: SB.number(),
  locked: SB.string().optional(),
  maxLevel: SB.number().optional(),
  category: SB.tuple(['Gear', 'Companions', 'Services', 'Specials']),
  specialState: SB.tuple(['Owned', 'Available', 'Missed']).optional(),
  endTime: SB.number().optional(),
  startTime: SB.number().optional(),
  expireTime: SB.number().optional(),
  isGift: SB.boolean().default(false),
  isFriendGated: SB.boolean().default(false),
  type: SB.string().optional(),
  sort: SB.number().optional(),
};
