import { useEffect, useState } from 'react';
import { app } from '../../../../data/Controllers/AppController';
import {
  getQuestComplete,
  getQuestInitial,
  getQuestOnWait,
  getQuestReadyToClaim,
} from '../../../../replicant/features/quests/getters';
import { Quest } from '../../../../replicant/features/quests/types';
import { getHasCustomAction } from './questConfig';
import { waitFor } from '../../../../data/utils';
import { useTranslation } from 'react-i18next';
import Dots from '../../../shared/Dots/Dots';
import { Optional } from '../../../../data/types';

interface Props {
  quest: Quest;
}
export const QuestCheckFooter = ({ quest }: Props) => {
  const { t } = useTranslation();

  const [isChecking, setIsChecking] = useState(false);
  const [error, setError] = useState<string>();

  const isComplete = getQuestComplete(app.state, quest.id);
  const timeCheckComplete = app.session.hasQuestBeenChecked(quest.id);

  const onCheckClick = async (skipDelay: Optional<'skipDelay'> = undefined) => {
    if (isChecking || isVerifying) {
      return;
    }

    setIsChecking(true);
    app.track('quest_verification_dialog_start_check', {
      quest_name: quest.analytics,
    });

    if (!skipDelay) {
      await waitFor(2000);
    }

    const response = await app.invoke.updateQuest({ questId: quest.id });

    // TODO: Known issue with getting "waiting" error response for fakeCheckWithWait customChecker
    // See, quests/getters.ts questCheckers that erroneously checks globally against "follow_youtube" quest only.
    // getQuestReadyToClaim correctly does check, but isn't used in the custom checker.
    if (response?.error) {
      if (response.code) {
        app.track('quest_verification_dialog_quest_error', {
          quest_name: quest.analytics,
          amount: quest.reward,
          error_message: response.error,
        });
        const errorMessage = response.data?.translation
          ? t(
              response.error,
              response.data?.translationOpts as Record<string, string>,
            )
          : response.error;
        setError(errorMessage);
      } else {
        // Critical error happened
      }
    } else if (response?.data) {
      if (response?.data === 'complete') {
        app.track('quest_verification_dialog_quest_complete', {
          quest_name: quest.analytics,
          amount: quest.reward,
        });
        app.session.onQuestRewarded(quest.id);
        // Make sure parent component updates too
        app.views.EarnPage.rerender();
        app.ui.confetti
          .setData({
            gravity: { from: 0.5, to: 0.9 },
            duration: 1500,
          })
          .show(false);
        await waitFor(1000);
      }
      app.ui.drawer.close();
    }

    setIsChecking(false);
  };

  useEffect(() => {
    if (timeCheckComplete) {
      onCheckClick('skipDelay');
    }
  }, [timeCheckComplete]);

  function getCheckButtonText() {
    if (error) {
      return error;
    }

    if (isVerifying) {
      return '';
    }

    const isReadyToClaim = getQuestReadyToClaim(app.state, quest.id);
    if (isReadyToClaim) {
      return t('claim_reward');
    }

    return t('check_word');
  }

  // If it's the first time we are pressing this button, then we should do the action once

  const isVerifying = app.session.isQuestVerifying(quest.id);
  const isNotReadyToClaim = !getQuestReadyToClaim(app.state, quest.id);
  const shouldDoActionOnce = !isVerifying && isNotReadyToClaim;

  const waitingForVerification = getQuestOnWait(app.state, quest.id);

  const btnClassName = waitingForVerification ? 'disabled' : '';

  const onActionClick = () => {
    app.track('quest_verification_dialog_check_with_visit', {
      quest_name: quest.analytics,
      amount: quest.reward,
    });
    app.session.onQuestChecked(quest.id);
  };

  if (isComplete) {
    return null;
  }

  return (
    <div className="footer-buttons">
      {shouldDoActionOnce ? (
        quest.url && (
          <a
            href={quest.url}
            target="_blank"
            className="btn btn-normal btn-enormus"
            onClick={onActionClick}
          >
            {getCheckButtonText()}
          </a>
        )
      ) : (
        <div
          className={`btn btn-normal btn-enormus ${btnClassName}`}
          onClick={() => onCheckClick}
        >
          {!isChecking && getCheckButtonText()}
          {isChecking || (isVerifying && <Dots />)}
        </div>
      )}
    </div>
  );
};
