import { HOUR_IN_MS } from '../../utils/time';

export const PU_DAILY_BONUS_REWARD = 5_000_000;

export const PU_SPECIALS_DAO_ITEM_ID = 'pu_specials_dao';

export const MAX_GIFT_FRIENDS = 5;
export const DISCOUNT_PCT_PER_FRIEND = 5;

/**
 *  max 3 hours of mining in millseconds
 */
export const PU_BONUS_MAX_MINING_DURATION = HOUR_IN_MS * 3;

/**
 * GIFT ONLY CARD
 * todo: move these to airtable
 */
export const GO_DECREASE_FACTOR = 0.9967;
export const GO_INITIAL_VALUE = 5000;
export const GO_LEVEL1_INCREMENTAL_EARNINGS = 6;
export const GO_LEVEL2_JUMP_EARNINGS = 10;
export const GO_GIFTS_MULTIPLIER = 3;
export const GO_LEVELS_BETWEEN_STEP_CHANGE = 5;
export const GO_MINIMUM_EARNINGS = 10;

/**
 * CONSTS FOR EARN ALGORITHM WITH DECREASE FACTOR
 * todo: move these to airtable
 */
export const DF_DECREASE_FACTOR = 0.985;
export const DF_INITIAL_VALUE = 5000; // this value also comes from the airtable
export const DF_LEVEL1_INCREMENTAL_EARNINGS = 6;
export const DF_LEVEL2_JUMP_EARNINGS = 10;
export const DF_MINIMUM_EARNINGS = 10;

/**
 * HIDDEN CARD REWARD
 *
 */
export const HIDDEN_CARD_REWARD = 10_000_000;
