import {
  createSharedStateMessages,
  createSharedStateMessage,
  SB,
} from '@play-co/replicant';
import { followingsSharedStateSchema } from './followings.schema';
import { maxFollowingsCount } from './followings.ruleset';

// @warning: never remove/rename shared state messages
export const followingsMessages = createSharedStateMessages(
  followingsSharedStateSchema,
)({
  addFollowing: createSharedStateMessage(
    SB.object({
      followingId: SB.string(),
    }),
    (state, { followingId }, meta) => {
      const followings = state.global.followings;

      // make sure the following id is not already included
      if (followings.includes(followingId)) {
        return;
      }

      // the player cannot have more than a given amount of followings
      if (followings.length < maxFollowingsCount) {
        followings.push(followingId);
      }
    },
  ),
  removeFollowing: createSharedStateMessage(
    SB.object({
      followingId: SB.string(),
    }),
    (state, { followingId }, meta) => {
      const followings = state.global.followings;

      // make sure the following id is included
      const followingIdx = followings.indexOf(followingId);
      if (followingIdx === -1) {
        return;
      }

      state.global.followings.splice(followingIdx, 1);
    },
  ),
});
