import {
  createSharedStateComputedProperties,
  searchableComputedProperty,
  GetIndexSchema,
  SB,
} from '@play-co/replicant';
import {
  holdersSchema,
  onchainHoldersSharedStateSchema,
} from './onchainHolders.schema';
import { MAX_DISPLAYED_TOP_HOLDERS } from './onchainHolders.ruleset';

export const onchainHoldersComputedProperties =
  createSharedStateComputedProperties(onchainHoldersSharedStateSchema)({
    memeId: searchableComputedProperty(SB.string(), (state) => {
      return state.global.memeId;
    }),
    holders: searchableComputedProperty(holdersSchema, (state) => {
      // only add top 100 holders to indexed page
      return state.global.holders.slice(0, MAX_DISPLAYED_TOP_HOLDERS);
    }),
  });

export type TradingIndexSchema = GetIndexSchema<
  typeof onchainHoldersComputedProperties
>;
