import { AnalyticsProperties } from '@play-co/gcinstant';
import {
  createChatbotConfig,
  createChatbotEvents,
  Fetcher,
  FetchOptions,
  ReplicantEventHandlerAPI,
  TelegramWebhookEvent,
  WebhookEvent,
} from '@play-co/replicant';
import asyncGetters from './asyncGetters';
import computedProperties from './computedProperties';
import { ReplicantServer } from './config';
import {
  getChat,
  makeBotRequest,
  sendMessage,
} from './features/chatbot/chatbot.api';
import { groupActions } from './features/chatbot/chatbot.group';
import { privateActions } from './features/chatbot/chatbot.private';
import {
  callToActions,
  Cmd,
  CmdOpts,
  teamCreationTtl,
} from './features/chatbot/chatbot.ruleset';
import {
  CallbackQuery,
  callbackQuerySchema,
  TelegramMessage,
  updateSchema,
} from './features/chatbot/chatbot.schema';
import {
  createPlayButton,
  createPlayButtonContent,
  templates,
} from './features/chatbot/chatbot.templates';
import config from './features/game/game.config';
import {
  handleFirstEntry,
  spendCoins,
  updateTelegramProps,
} from './features/game/game.modifiers';
import { roundBalanceScoreAndTaps } from './features/game/migration.modifiers';
import { teamProfileFromChat } from './features/teams/teams.getters';
import { joinTeam } from './features/teams/teams.modifiers';
import { CREATE_TEAM_COST } from './features/teams/teams.ruleset';
import messages from './messages';
import ruleset from './ruleset';
import scheduledActions from './scheduledActions';
import stateSchema, { MutableState } from './schema';
import sharedStates from './sharedStates';
import * as base62 from './utils/base62';
import { getBalance } from './features/game/game.getters';

export function updateBotMenu(fetch: Fetcher['fetch']) {
  const payload = {
    $channel: 'CHATBOT' as AnalyticsProperties.Channel,
    feature: 'bot',
    $subFeature: 'bot_menu',
  };

  return makeBotRequest(
    'setChatMenuButton',
    {
      menu_button: createPlayButtonContent(
        callToActions.menuPlayButton,
        payload,
      ),
    },
    fetch,
  );
}

const chatbotEvents = createChatbotEvents(stateSchema, {
  asyncGetters,
  computedProperties,
  messages,
  ruleset,
  scheduledActions,
  sharedStates,
})({
  async onWebhook(state, webhookEvent, api) {
    if (!isTelegramWebhookEvent(webhookEvent)) {
      // skip any webhook events that aren't from Telegram
      return;
    }

    const event = webhookEvent as unknown; // strip off the types that Replicant assigns so they don't interfere

    if (callbackQuerySchema.isValid(event)) {
      await handleCallbackQuery(event, api.fetch);
    } else if (updateSchema.isValid(event)) {
      await handleMessage(state, event.message, api);
    }

    // always update the score and balance to be integers
    roundBalanceScoreAndTaps(state);
  },
});

export const chatbot = createChatbotConfig(templates, chatbotEvents);

export function extractPayloadUserId(referralPayloadKey?: string) {
  if (!referralPayloadKey) {
    return;
  }

  const isNewFormat = referralPayloadKey.includes('-');
  if (isNewFormat) {
    try {
      const referralBrkdwn = referralPayloadKey.split('-');
      const referrerId = referralBrkdwn[0];
      const payloadKey = referralBrkdwn[1];
      // safer to test the payloadKey as its size most likely is consistent (unlike Telegram user ids)
      if (payloadKey.length > 20) {
        // suppose it's the long form version
        return referrerId;
      }

      return base62.decodeInteger(referrerId).toString();
    } catch {
      return;
    }
  }

  return referralPayloadKey;
}

function isTelegramWebhookEvent(
  webhookEvent: WebhookEvent,
): webhookEvent is TelegramWebhookEvent {
  return 'platform' in webhookEvent && webhookEvent.platform === 'telegram';
}

async function handleMessage(
  state: MutableState,
  message: TelegramMessage,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
) {
  const type = message.chat.type === 'private' ? 'private' : 'group';
  const [metadata] = message.entities || [];

  const fromUser = message.from;
  if (fromUser?.is_bot) {
    // avoid infinite loops by not answering to bots
    return;
  }

  if (metadata?.type !== 'bot_command') {
    const now = api.date.now();
    const teamCreationStartTime = state.team_creation_start_time;
    if (now - teamCreationStartTime < teamCreationTtl) {
      await createTeam(state, message, api);
    }
    return;
  }

  const [cmd] = (message.text || '').split(`@${config.botName}`);
  // Make sure the command is for our own bot
  if (cmd.includes('@')) {
    return;
  }

  const fullCmd = cmd.split(' ');
  const cmdName = fullCmd[0] as Cmd;
  const cmdOpts = parseCommandArguments(fullCmd[1]);

  if (fromUser) {
    if (state.first_interaction) {
      const referralPayloadKey = cmdOpts?.ref ?? cmdOpts?.referral;
      const referrerId = extractPayloadUserId(referralPayloadKey);

      await handleFirstEntry(
        state,
        {
          telegramUser: fromUser,
          referrer: referrerId,
        },
        api,
      );

      api.sendAnalyticsEvents([
        {
          eventType: 'JoinBot',
          eventProperties: {},
        },
      ]);
    } else {
      updateTelegramProps(state, fromUser);
    }
  }

  // handle cmd
  const actions = type === 'private' ? privateActions : groupActions;
  const action = actions[cmdName];
  if (action) {
    await action(state, api, message, cmdOpts);
  }
}

function parseCommandArguments(cmdArgs: string | undefined): CmdOpts {
  if (cmdArgs === undefined || cmdArgs === '') {
    return {};
  }
  // @note:
  // for some reason passing encoded URI as an argument of the "start" command make the command fails to trigger
  // therefore command arguments are constructed this way:
  // "referral_12345_name_john_profession_plumber"

  // we also want to be able to parse arguments that contain underscores
  // those can be supported if they are escaped, e.g:
  // "$channel_COMMUNITY\\_CHAT_feature_marketing\\_test"
  return cmdArgs
    .split(/(?<!\\)_/)
    .reduce((acc: CmdOpts, val: string, index: number, arr: string[]) => {
      if (index % 2 === 0 && arr[index + 1]) {
        // assign value and remove escapes
        acc[val] = arr[index + 1].replace(/\\/g, '');
      }
      return acc;
    }, {});
}

async function handleCallbackQuery(
  event: CallbackQuery,
  fetch: Fetcher['fetch'],
) {
  await makeBotRequest(
    'answerCallbackQuery',
    {
      callback_query_id: event.callback_query.id,
      text: 'Game started!',
      url: `${config.playUrl}?uid=${event.callback_query.from.id}`,
    },
    fetch,
  );
}

async function createTeam(
  state: MutableState,
  message: TelegramMessage,
  api: ReplicantEventHandlerAPI<ReplicantServer>,
): Promise<void> {
  const reply = message.text;

  if (!reply) {
    return;
  }

  try {
    const chat = await getChat(reply, api.fetch);
    const teamId = chat.id.toString();

    const teamExists = await api.sharedStates.teams.fetch(teamId);

    if (teamExists) {
      await sendMessage({
        chatId: message.chat.id,
        text: `This team already exists. Please enter a different address in the format:
        <i>@Telegram</i>`,
        parseMode: 'HTML',
        fetch: api.fetch,
      });
      return;
    }

    const now = api.date.now();
    if (getBalance(state, now) < CREATE_TEAM_COST) {
      await sendMessage({
        chatId: message.chat.id,
        text: `This player does not meet the requirements to create a team.\nCreating teams cost ${CREATE_TEAM_COST.toLocaleString()} points`,
        parseMode: 'HTML',
        fetch: api.fetch,
      });
      return;
    }

    api.sharedStates.teams.create(teamId);
    spendCoins(state, CREATE_TEAM_COST, now);

    api.sendAnalyticsEvents([
      {
        eventType: 'CreateTeam',
        eventProperties: { teamId },
      },
    ]);

    joinTeam(state, { teamId }, api);

    const profile = teamProfileFromChat(chat, undefined); // TODO: upload profile photo.
    api.sharedStates.teams.postMessage.updateTeamProfile(teamId, {
      profile,
      timestamp: now,
    });

    await sendMessage({
      chatId: message.chat.id,
      text: `Team ${profile.name} has been created!`,
      replyMarkup: {
        inline_keyboard: createPlayButton('Start earning coins!', state.id, {
          $channel: 'CHATBOT',
          feature: 'team',
          $subFeature: 'create_team',
        }),
      },
      fetch: api.fetch,
    });
  } catch (e: any) {
    api.reportError(e);

    await sendMessage({
      chatId: message.chat.id,
      text: `Can only create a team for a public channel or chat`,
      fetch: api.fetch,
    });
  }
}
