import {
  SB,
  createSharedStateComputedProperties,
  searchableComputedProperty,
  GetIndexSchema,
} from '@play-co/replicant';
import { followingsSharedStateSchema } from './followings.schema';

export const followingsComputedProperties = createSharedStateComputedProperties(
  followingsSharedStateSchema,
)({
  followings: searchableComputedProperty(SB.array(SB.string()), (state) => {
    return state.global.followings;
  }),
});

export type TradingIndexSchema = GetIndexSchema<
  typeof followingsComputedProperties
>;
