import { createSharedState } from '@play-co/replicant';
import { followingsSharedStateSchema } from './followings.schema';
import { followingsMessages } from './followings.messages';
import { followingsComputedProperties } from './followings.properties';

export const followingsSharedStates = createSharedState(
  followingsSharedStateSchema,
  followingsMessages,
  followingsComputedProperties,
);

export type FollowingsSharedStates = typeof followingsSharedStates;
