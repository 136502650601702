import { createAsyncGetters } from '../../createAsyncGetters';
import { constructFriendSubStateId } from '../friends/friends.getters';
import { Friend } from '../game/ruleset/friends';
import {
  getFriendLeague,
  getReferralBonus,
  resolveProfilePicture,
} from '../game/game.getters';

export const friendsAsyncGetters = createAsyncGetters({
  getFriends: async (payload: { maxCount: number }, api) => {
    const ownStateRequest = api.getOwnState();
    const friendsSubStateRequest = api.sharedStates.friends.fetch(
      constructFriendSubStateId(api.getUserID()),
    );

    // parallelized requests
    const ownState = await ownStateRequest;
    const friendsSubState = await friendsSubStateRequest;

    if (!friendsSubState) {
      return {
        friendCount: 0,
        friends: [],
      };
    }

    const maxCount = payload.maxCount;
    const friendIds = friendsSubState.global.friendIds;
    const friendCount = friendIds.length;
    const latestFriendIds = friendIds.slice(
      friendCount - maxCount,
      friendCount,
    );

    const friendProps = (
      await api.searchPlayers({
        where: {
          id: {
            isOneOf: latestFriendIds,
          },
          banned: {
            isNot: true,
          },
        },
        limit: maxCount,
        sort: [{ field: 'score', order: 'desc' }],
      })
    ).results;

    let playerFriends: Friend[] = [];
    friendProps.forEach((friend) => {
      const friendId = friend.id;
      const contribution =
        friendId === ownState.referrer_id
          ? getReferralBonus(ownState, ownState.createdAt, ownState.is_premium)
          : friend.referrerContribution;
      playerFriends.push({
        id: friend.id,
        username: friend.name,
        contribution: contribution ?? 0,
        picture: resolveProfilePicture(friendId, friend.photo),
        score: friend.score,
        // @todo: remove (POST SEASON 2 MIGRATION)
        // team: friend.teamId,
        // league: getFriendLeague(friend.league),
      });
    });

    return {
      friendCount,
      friends: playerFriends.sort((a, b) => b.contribution - a.contribution),
    };
  },
});
