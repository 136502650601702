import { t } from 'i18next';
import { assets } from '../../../assets/assets';
import { TutorialModule } from './TutorialModule';
import { getReferralBonus } from '../../../replicant/features/game/game.getters';
import { formatPrice } from '../../../replicant/utils/numbers';
import { isTelegramWebview } from '../../device';
import { invite } from '../../sharing';
import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UITypes';

export class FriendEqualsCoins extends TutorialModule {
  id = 'FriendEqualsCoins' as 'FriendEqualsCoins';

  onTapInviteFriend = async () => {
    this.app.ui.drawer.close();

    invite({
      screen_location: 'drawer_friends_equal_coins',
      data: {
        feature: 'friend',
        $subFeature: 'tutorial_friends_equal_coins',
      },
      trackPressInvite: true,
    });

    // todo: ideally, we should be able to call onComplete only after invite api drawer ahs been closed
    // todo: however, we have no way to detect when this drawer is being closed
    this.onComplete();
  };

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
      league: '1',
    });

    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
      btnLeague: ElementUIState.Inactive,
      energyGauge: ElementUIState.Normal,

      footer: ElementUIState.Hidden,
      btnBooster: ElementUIState.Hidden,
      btnTeam: ElementUIState.Hidden,
    });

    this.app.ui.drawer.show({
      id: 'generic',
      opts: {
        image: assets.sloth_teams,
        title: t('tut_friends_coins_title'),
        subtitle: t('tut_friends_coins', {
          invite_coins: formatPrice(
            getReferralBonus(this.app.state, this.app.now(), false),
          ),
          invite_premium: formatPrice(
            getReferralBonus(this.app.state, this.app.now(), true),
          ),
        }),
        buttons: [
          {
            cta: t('friends_invite_button'),
            onClick: () => {
              this.onTapInviteFriend();
            },
          },
        ],
        onClose: () => {
          this.onComplete();
        },
      },
    });

    return super.execute();
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_friends_coins_dialog',
    };
  }
}
