import {
  ReplicantEventHandlerAPI,
  ReplicantSyncActionAPI,
} from '@play-co/replicant';
import { MutableState } from '../../schema';
import { ReplicantServer } from '../../config';
import { Team } from '../../../data/types';
import { clampScore } from '../../utils/numbers';

type API =
  | ReplicantEventHandlerAPI<ReplicantServer>
  | ReplicantSyncActionAPI<ReplicantServer>;

/**
 * Join a team.
 * @param state
 * @param payload
 * @param api
 */
export function joinTeam(
  state: MutableState,
  payload: { teamId: string },
  api: API,
) {
  const teamId = payload.teamId;

  state.team_id = teamId;
  state.unsynced_team_score = 0;

  api.sharedStates.teams.postMessage.addMember(teamId, {
    score: state.score,
    timestamp: api.date.now(),
  });

  api.sendAnalyticsEvents([
    {
      eventType: 'JoinTeam',
      eventProperties: {
        teamId,
        feature: 'team',
        $subfeature: 'team_join',
        originFeature: 'team',
        originSubFeature: 'team_view',
      },
      userProperties: { teamId },
    },
  ]);
}

/**
 * Leave the current team.
 */
export function leaveTeam(state: MutableState, api: API) {
  const teamId = state.team_id;

  // User is not in the team.
  if (!teamId) return;

  const playerTeamScore = state.score - state.unsynced_team_score;
  state.unsynced_team_score = 0;
  api.sharedStates.teams.postMessage.removeMember(teamId, {
    score: playerTeamScore,
    timestamp: api.date.now(),
    reduceMemberCount: true,
  });

  delete state.team_id;

  api.sendAnalyticsEvents([
    {
      eventType: 'LeaveTeam',
      eventProperties: {
        teamId,
        feature: 'team',
        $subfeature: 'team_leave',
        originFeature: 'team',
        originSubFeature: 'team_view',
      },
      userProperties: { teamId: undefined },
    },
  ]);
}

/**
 * This is for blacklisted players, they stay in the team but their score is not counted.
 * @param state
 * @param api
 */
export function shadowLeaveTeam(state: MutableState, api: API) {
  const teamId = state.team_id;

  // User is not in the team.
  if (!teamId) return;

  const playerTeamScore = state.score - state.unsynced_team_score;
  state.unsynced_team_score = 0;
  api.sharedStates.teams.postMessage.removeMember(teamId, {
    score: playerTeamScore,
    timestamp: api.date.now(),
    reduceMemberCount: false,
  });

  api.sendAnalyticsEvents([
    {
      eventType: 'shadowLeaveTeam',
      eventProperties: {
        teamId,
        feature: 'team',
        $subfeature: 'team_leave_shadow',
        originFeature: 'team',
        originSubFeature: 'team_view',
      },
      userProperties: { teamId: undefined },
    },
  ]);
}

/**
 * Post any unsynced team score to the team shared state.
 * @param state
 * @param api
 */
export function syncTeamScore(
  state: MutableState,
  api: API,
  maxSynceableScore: number,
) {
  const teamId = state.team_id;

  // User is not in the team.
  if (!teamId) return;

  // If user is banned return
  if (state.banned) return;

  api.sharedStates.teams.counters.score.increase(
    teamId,
    Math.min(Math.round(maxSynceableScore), state.unsynced_team_score),
  );

  // Reset the unsynced team score.
  state.unsynced_team_score = 0;
}

export function fixNegativeTeamScore(
  state: MutableState,
  args: { team: Team },
  api: API,
) {
  const { team } = args;

  // if no team, no score to fix
  if (!team) {
    return;
  }

  // if team score is zero or positive, nothing to fix
  if (team.score >= 0) {
    return;
  }

  // if not in a team, don't do anything
  if (!state.team_id) {
    return;
  }

  // if user banned from the team, don't do anything
  if (state.banned) {
    return;
  }

  // only allow team members to fix score
  // if user not in the specified team, don't do anything
  if (team.id !== state.team_id) {
    return;
  }

  const oldScore = team.score;

  // recalculate team score (sum up player scores)
  const newScore =
    team.players?.reduce((sum, player) => sum + player.score, 0) ?? 0;

  // if newScore is negative, we have other issues
  if (newScore < 0) {
    // log via analytics
    api.sendAnalyticsEvents([
      {
        eventType: 'fix_negative_team_score_failed',
        eventProperties: {
          message: 'Players with negative scores exist in this team',
          teamId: team.id,
          oldScore,
          newScore,
          feature: 'team',
          $subfeature: 'team_score_fix',
          originFeature: 'team',
          originSubFeature: 'team_view',
        },
      },
    ]);

    // stop here
    return;
  }

  // override with fixed score
  team.score = clampScore(newScore);

  api.sharedStates.teams.counters.score.increase(
    team.id,
    clampScore(-oldScore + newScore), // cancel out the old negative score
  );

  api.sendAnalyticsEvents([
    {
      eventType: 'fix_negative_team_score',
      eventProperties: {
        teamId: team.id,
        oldScore,
        newScore,
        feature: 'team',
        $subfeature: 'team_score_fix',
        originFeature: 'team',
        originSubFeature: 'team_view',
      },
    },
  ]);
}

export function addSearchToProfile(api: API, { team }: { team: Team }) {
  api.sharedStates.teams.postMessage.updateTeamProfile(team.id, {
    profile: {
      search: team.name,
    },
    timestamp: api.date.now(),
  });
}
