import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { app } from '../../../../../data/Controllers/AppController';
import {
  TxType,
  CurrencyType,
} from '../../../../../replicant/features/tradingMeme/types';
import {
  largeIntegerToLetter,
  displayPointAmount,
} from '../../../../../replicant/utils/numbers';
import { getCurrencyIcon, getCurrencyName } from '../helpers';

export interface PropsFooterBuySellButton {
  footerMode: 'normal' | 'create';
  txType: TxType;
  currencyType: CurrencyType;
  onClick: () => void;
}

// note: this is used also from DrawerTradingTransactionConfirm
export const TradingTokenFooterButton = ({
  txType,
  currencyType,
  onClick,
}: PropsFooterBuySellButton) => {
  const { tx, txCurrency } = app.memes.trading;

  const { t } = useTranslation();

  const [estimate, setEstimate] = useState('0');

  const receiveStr = tx?.receive.toString();

  const contractAddress = app.memes.currentMeme.meme?.jettonContractAddress;

  useEffect(() => {
    app.memes.trading.getTxEstimate().then((newEstimate) => {
      setEstimate(newEstimate.toString());
    });
  }, [contractAddress, receiveStr]);

  if (!tx) {
    return null;
  }

  const { receive, isValid } = tx;

  const tickerName = app.memes.currentMeme.meme?.ticker ?? '#';

  const receiveNum = receive.toNumber();

  let amount = '-';

  if (receive.gte(0)) {
    amount = estimate;
    // if (txType === 'sell') {
    //   amount = largeIntegerToLetter(receiveNum, 4);
    // } else {
    //   const pointAmount = displayPointAmount(receiveNum, 4, true);
    //   amount = txCurrency === 'tokens' ? tokenAmount : pointAmount;
    // }
  }
  const onConnectWallet = () => {
    app.ton.connect();
  };

  if (!app.ton.connected) {
    return (
      <div
        className="btn btn-normal profile-button-wallet connect-wallet"
        onClick={onConnectWallet}
      >
        <div className="column">{'Connect Wallet'}</div>
      </div>
    );
  }

  return (
    <div
      className={`btn btn-normal button-buy ${!isValid && 'disabled'}`}
      onClick={onClick}
    >
      <div className="content-buy">
        {txType.includes('buy') && (
          <>
            {/* <img src={assets.icon_arrow_buy} className="icon-arrow" /> */}
            <div className="label">{`${t('trade_token_button_buy')}`}</div>
            <div
              className="value"
              dangerouslySetInnerHTML={{ __html: amount }}
            ></div>
            <div className="label">${tickerName}</div>

            <div className="icon">
              <img src={app.memes.currentMeme.meme?.image} />
            </div>
          </>
        )}

        {txType === 'sell' && (
          <>
            {/* <img src={assets.icon_arrow_sell} className="icon-arrow" /> */}
            <div className="label">{`${t('trade_token_button_sell_for')}`}</div>

            <div className="value">{amount} </div>
            <div className="label">{getCurrencyName(currencyType)}</div>
            <div className="icon">
              <img src={getCurrencyIcon(currencyType)} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
