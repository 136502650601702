import { TutorialModule } from '../TutorialModule';

export class SlideshowStart extends TutorialModule {
  id = 'SlideshowStart' as 'SlideshowStart';

  onAction = (id?: string) => {
    if (id === 'slideshow') {
      this.onComplete();
    }
  };

  execute = async () => {
    this.app.ui.setTutorialUIState({
      slideshow: true,
    });

    return super.execute().then(() => {
      this.app.ui.setTutorialUIState({
        slideshow: false,
      });
    });
  };
}
