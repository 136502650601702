// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Meme-feed-ads"

import { Value } from './memeFeedAds.types';

// prettier-ignore
const memeFeedAds: Value[] = [
  {
    "contentUrl": "https://notgemz-game.s3.amazonaws.com/media/cover-3.jpg",
    "ctaLabel": "mfa_invite_cta",
    "description": "mfa_invite_description",
    "feature": "cross_promo",
    "format": "image",
    "frequency": 0,
    "id": "test2",
    "lockInDuration": 3000,
    "test": true,
    "title": "mfa_invite_title"
  },
  {
    "contentUrl": "https://notgemz-game.s3.amazonaws.com/media/notifications/iron man light.mp4",
    "ctaLabel": "mfa_invite_cta",
    "description": "mfa_invite_description",
    "feature": "invite",
    "format": "video",
    "frequency": 0,
    "id": "test1",
    "lockInDuration": 1500,
    "test": true,
    "title": "mfa_invite_title"
  },
  {
    "contentUrl": "https://notgemz-game.s3.us-east-1.amazonaws.com/media/notifications/invite_connection.jpg",
    "ctaLabel": "mfa_invite_cta",
    "description": "mfa_invite_description",
    "feature": "invite",
    "format": "image",
    "frequency": 10,
    "id": "invite",
    "lockInDuration": 750,
    "title": "mfa_invite_title"
  },
  {
    "contentUrl": "https://notgemz-game.s3.us-east-1.amazonaws.com/media/notifications/MFA+test+2x.png",
    "ctaLabel": "mfa_invite_cta",
    "description": "mfa_invite_description",
    "feature": "invite",
    "format": "image",
    "frequency": 0,
    "id": "contentTest1",
    "lockInDuration": 0,
    "test": true,
    "title": "2x"
  },
  {
    "contentUrl": "https://notgemz-game.s3.us-east-1.amazonaws.com/media/notifications/MFA+test+3x.png",
    "ctaLabel": "mfa_invite_cta",
    "description": "mfa_invite_description",
    "feature": "invite",
    "format": "image",
    "frequency": 0,
    "id": "contentTest2",
    "lockInDuration": 3000,
    "test": true,
    "title": "3x"
  },
  {
    "contentUrl": "https://notgemz-game.s3.us-east-1.amazonaws.com/media/notifications/MFA+Test+percents.png",
    "ctaLabel": "mfa_invite_cta",
    "description": "mfa_invite_description",
    "feature": "invite",
    "format": "image",
    "frequency": 0,
    "id": "contentTest3",
    "lockInDuration": 3000,
    "test": true,
    "title": "safe_areas_test"
  },
  {
    "contentUrl": "https://notgemz-game.s3.amazonaws.com/media/notifications/recharged.gif",
    "ctaLabel": "mfa_invite_cta",
    "description": "mfa_invite_description",
    "feature": "gift",
    "format": "gif",
    "frequency": 0,
    "id": "test3",
    "lockInDuration": 2000,
    "test": true,
    "title": "mfa_invite_title"
  }
];
export default memeFeedAds;
