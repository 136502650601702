// @todo: remove (POST SEASON 2 MIGRATION)
// import { AnimateBalance1500 } from './AnimateBalance1500';
// import { BoosterButton } from './BoosterButton';
// import { BoosterRocketman } from './BoosterRocketman';
// import { ClickerOnly22s } from './ClickerOnly22s';
// import { EnergyRecovery } from './EnergyRecovery';
// import { FreeGiftRocketman } from './FreeGiftRocketman';
// import { FullEnergy } from './FullEnergy';
// import { FullEnergyState } from './FullEnergyState';

// import { HideMineBadgeState } from './HideMineBadgeState';
// import { IntroducingClicker } from './IntroducingClicker';
// import { LeagueLevel2 } from './LeagueLevel2';
// import { MineButton } from './MineButton';
// import { MineCardFriends } from './MineCardFriends';
// import { MinePageCard } from './MinePageCard';
// import { MoreCoins15k } from './MoreCoins15k';
// import { NextCard } from './NextCard';
// import { RechargeRockermanState } from './RechargeRockermanState';
// import { ShowMineBadgeState } from './ShowMineBadgeState';
// import { TeamsButton } from './TeamsButton';
// import { TeamsPage } from './TeamsPage';
// import { WaitForTapsOrTimeout } from './WaitForTapsOrTimeout';
// import { WaitForCoinTaps } from './WaitForCoinTaps';
// import { YouAreDoingGreat15k } from './YouAreDoingGreat15k';
// import { Grant1500State } from './Grant1500State';
// import { Grant1000State } from './Grant1000State';

// import { YourEnergyIsLow } from './YourEnergyIsLow';
// import { LeagueButton } from './LeagueButton';
// import { LeaguePage } from './LeaguePage';

import { EarnButton } from './EarnButton';
import { EndOfTutorial } from './EndOfTutorial';
import { FriendEqualsCoins } from './FriendEqualsCoins';
import { FriendsButton } from './FriendsButton';
import { FriendsPage } from './FriendsPage';
import { EarnPage } from './EarnPage';
import { WaitFiveSeconds } from './WaitFiveSeconds';
import { SlideshowStart } from './slideshow/SlideshowStart';
import { SlideshowEnd } from './slideshow/SlideshowEnd';
import { Grant100State } from './slideshow/Grant100State';
import { WaitForEndOfEnergy } from './energy/WaitForEndOfEnergy';
import { OutOfEnergyShareBtn } from './energy/OutOfEnergyShareBtn';

export const tutorialModules = {
  // @todo: remove (POST SEASON 2 MIGRATION)
  // IntroducingClicker,
  // EnergyRecovery,
  // YouAreDoingGreat15k,
  // MineButton,
  // MinePageCard,
  // MineCardFriends,
  // MoreCoins15k,
  // AnimateBalance1500,
  // TeamsButton,
  // TeamsPage,
  // FullEnergy,
  // BoosterButton,
  // BoosterRocketman,
  // ClickerOnly22s,
  // FreeGiftRocketman,
  // LeagueLevel2,
  // WaitForTapsOrTimeout: WaitForTapsOrTimeout,
  // NextCard,
  // WaitForCoinTaps,

  // //
  // YourEnergyIsLow,

  // LeagueButton,
  // LeaguePage,

  // // State mutator
  // ShowMineBadgeState,
  // RechargeRockermanState,

  // HideMineBadgeState,
  // FullEnergyState,
  // Grant1000State,
  // Grant1500State,

  FriendsButton,
  FriendsPage,
  EarnButton,
  EarnPage,
  FriendEqualsCoins,
  EndOfTutorial,
  SlideshowStart,
  SlideshowEnd,
  WaitFiveSeconds,
  WaitForEndOfEnergy,
  OutOfEnergyShareBtn,
  Grant100State,
};

export type TutorialModuleId = keyof typeof tutorialModules;
