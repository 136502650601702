import { createSharedStateSchema, SB, WithMeta } from '@play-co/replicant';

export const friendsSharedStateSchema = createSharedStateSchema({
  global: {
    schema: SB.object({
      friendIds: SB.array(SB.string()),
    }),
  },
});

export type FriendsState = SB.ExtractType<
  typeof friendsSharedStateSchema.global.schema
>;

export type MutableFriendsState = WithMeta<FriendsState, {}>;
