import { t } from 'i18next';
import { Quest } from '../../../../replicant/features/quests/types';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { useState } from 'react';
import { formatPrice } from '../../../../replicant/utils/numbers';
import {
  getQuestOnWait,
  getQuestReadyToClaim,
} from '../../../../replicant/features/quests/getters';
import { executeQuestAction, getHasCustomAction } from './questConfig';

interface Props {
  quest: Quest;
}

export const QuestCheckerHeader = ({ quest }: Props) => {
  // @TODO: Check how this works
  const [readyToClaim, setReadyToClaim] = useState(false);

  const onActionClick =
    (executeAction = true) =>
    () => {
      const isNotChecked = !app.session.hasQuestBeenChecked(quest.id);
      const isNotReadyToClaim = !getQuestReadyToClaim(app.state, quest.id);
      const shouldDoActionOnce = isNotChecked && isNotReadyToClaim;
      app.track('quest_verification_dialog_task', {
        quest_name: quest.analytics,
        amount: quest.reward,
        isFirst: shouldDoActionOnce,
      });
      app.session.onQuestChecked(quest.id);
      if (executeAction) {
        executeQuestAction(app, quest);
      }
      // @TODO: Maybe some analytics here?
    };

  const isConnectWallet = quest.actionCTA === 'connect_wallet';

  const isWalletConnectedAndReady =
    isConnectWallet &&
    (app.state.wallet.length ||
      readyToClaim ||
      app.ton.tonConnectUI?.wallet?.account);

  const btnClassName = isWalletConnectedAndReady ? 'disabled' : '';

  const actionBtn = isWalletConnectedAndReady
    ? t('wallet_connected')
    : t(quest.actionCTA);

  const isWaiting = getQuestOnWait(app.state, quest.id);
  const isVerifying =
    app.session.isQuestVerifying(quest.id) && isWaiting == false;

  const hasCustomAction = getHasCustomAction(quest);

  return (
    <div className="drawer-earncheck-header">
      <div className="drawer-earncheck-icon">
        <img src={assets.friends} />
      </div>
      <div className="drawer-earncheck-title">{t(quest.title)}</div>
      {quest.description && (
        <div className="drawer-earncheck-description">
          {t(quest.description)}
        </div>
      )}
      {hasCustomAction ? (
        <div
          className={`btn btn-normal subscribe-button ${btnClassName}`}
          onClick={onActionClick()}
        >
          {actionBtn}
        </div>
      ) : (
        quest.url && (
          <a
            href={quest.url}
            target="_blank"
            className="btn btn-normal subscribe-button"
            onClick={onActionClick(false)}
          >
            {actionBtn}
          </a>
        )
      )}

      {isWaiting && !isVerifying && (
        <div className="earn-card-info-points">
          <div className="earn-card-info-points-value">
            {t('come_back_in_1h')}
          </div>
        </div>
      )}

      {isVerifying && (
        <div className="earn-card-info-points">
          <div className="earn-card-info-points-value">
            {t('complete_to_claim')}
          </div>
        </div>
      )}

      <div className="score">
        <div className="icon">
          <img src={assets.coin} />
        </div>
        <div className="text">{formatPrice(quest.reward)}</div>
      </div>
    </div>
  );
};
