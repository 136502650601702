import { TutorialModule } from '../TutorialModule';

export class WaitForEndOfEnergy extends TutorialModule {
  id = 'WaitForEndOfEnergy' as 'WaitForEndOfEnergy';

  stateUpdateId = '50Energy';

  onAction = (id?: string) => {
    if (id === 'outOfEnergy') {
      this.onComplete();
    }
  };

  execute = async () => {
    this.app.ui.setTutorialUIState({
      energyLimit: 50,
    });

    return super.execute().then(() => {
      this.app.ui.setTutorialUIState({
        energyLimit: undefined,
      });
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_wait_for_out_of_energy',
    };
  }
}
