import './TradingInputNumeric.scss';
import { assets } from '../../../../assets/assets';
import { useTranslation } from 'react-i18next';
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { setUserScrolling } from '../../../../data/utils';
import {
  isAndroid,
  isIOS,
  isMobileEmulatedByBrowser,
  isTelegramWebview,
} from '../../../../data/device';

interface Props {
  type: 'numeric' | 'decimal';
  value?: number;
  icon?: string;
  pushContentUp?: number;
  autoFocus?: boolean;
  onAmountChange?: (value: number) => void;
  onInputFocus?: (focused: boolean) => void;
  integerOnly?: boolean;
  maxValue?: number;
  validator?: (value: string) => boolean;
  decimalPlaces?: number; // Added this line
}

export const TradingInputNumeric = ({
  type,
  value,
  icon,
  pushContentUp = 0,
  autoFocus = false,
  onAmountChange,
  onInputFocus,
  integerOnly,
  maxValue,
  validator,
  decimalPlaces = 2, // default to 2 decimal places if not specified
}: Props) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputFocused, setInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const clampNumber = (num: number) => {
    if (maxValue !== undefined) {
      num = Math.min(num, maxValue);
    }
    return num;
  };

  useEffect(() => {
    // Update inputValue when not focused to prevent overwriting user input
    if (!isInputFocused) {
      if (value !== undefined && !isNaN(value)) {
        const num = clampNumber(value);
        const formattedValue = integerOnly
          ? Number(num).toLocaleString()
          : Number(num).toLocaleString(undefined, {
              maximumFractionDigits: decimalPlaces,
            });
        setInputValue(formattedValue);
      } else {
        setInputValue('');
      }
    }
  }, [value, isInputFocused, integerOnly, decimalPlaces]);

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    inputRef.current?.blur();
  };

  const handleInputFocus = (focused: boolean) => {
    setInputFocused(focused);

    onInputFocus && onInputFocus(focused);

    setUserScrolling(!focused);

    // =====================================================================================
    // push-up feature
    // important: this handles pushing page up and down when opening/closing keyboard in iOS
    // note that this behaviour happens automatically in android, but unfortunately not in iOS

    function setPageHeightStyle(heightStyle: string) {
      if (isAndroid()) return;

      const page = document.querySelector(
        '.page.modal-team-list',
      ) as HTMLDivElement;

      if (page) page.style.minHeight = heightStyle;
    }

    // when keyboard opens and close, needs some time to finish it's own transition
    // because it seems to weirdly alter page height and scroll parameters after some time

    // after waiting, we need to push content up so search bar renders at the top of the screen
    // while doing this, we need to make sure that both body and page
    // have enough height for window scroll to work properly

    // should we be using push-up feature?
    const usePushUpFeature =
      pushContentUp > 0 &&
      isTelegramWebview() &&
      !isMobileEmulatedByBrowser() &&
      !isAndroid();

    if (usePushUpFeature) {
      if (focused) {
        setTimeout(() => {
          // adjust body height
          document.body.style.height = window.innerHeight + 1000 + 'px';
          document.body.style.transition = 'unset';
          // adjust page height
          setPageHeightStyle('1600px');
          // adjust scroll up
          window.scrollTo({ top: pushContentUp, behavior: 'smooth' });
        }, 500);
      } else {
        setTimeout(() => {
          // reset body height
          document.body.style.height = '100svh';
          document.body.style.transition = 'height 250ms ease-in-out';
          // reset page height
          setPageHeightStyle('unset');
          // reset scroll
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 0);
      }
    }

    if (!focused) {
      // Input loses focus, format the input value
      let num = parseFloat(inputValue.replace(/,/g, ''));
      if (!isNaN(num)) {
        num = clampNumber(num);
        const formattedValue = integerOnly
          ? Number(num).toLocaleString()
          : Number(num).toLocaleString(undefined, {
              maximumFractionDigits: decimalPlaces,
            });
        setInputValue(formattedValue);
        onAmountChange && onAmountChange(num);
      } else {
        setInputValue('');
        onAmountChange && onAmountChange(0);
      }
    }

    // =====================================================================================
  };

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let newValue = e.currentTarget.value;

      console.log('TIN-change', { newValue });

      // For integer-only inputs, remove non-digit characters
      if (integerOnly) {
        newValue = newValue.replace(/[^0-9]/g, '');
      } else {
        // For decimal inputs, allow digits and one decimal point
        newValue = newValue.replace(/[^0-9.]/g, '');
        // const parts = newValue.split('.');
        // if (parts.length > 2) {
        //   newValue = parts[0] + '.' + parts.slice(1).join('');
        // }
      }

      if (validator && !validator(newValue)) {
        return;
      }

      // Parse the input value to a number
      const num = parseFloat(newValue.replace(/,/g, ''));

      // Check if num exceeds maxValue
      if (!isNaN(num) && maxValue !== undefined && num > maxValue) {
        // Clamp to maxValue
        const clampedNum = clampNumber(num);
        // Format the clampedNum
        const formattedValue = integerOnly
          ? Number(clampedNum).toLocaleString()
          : Number(clampedNum).toLocaleString(undefined, {
              maximumFractionDigits: decimalPlaces,
            });

        setInputValue(formattedValue);
        onAmountChange && onAmountChange(clampedNum);
        return;
      }

      // For integer-only, format the integer part
      if (integerOnly && newValue !== '') {
        const formattedValue = Number(
          newValue.replace(/,/g, ''),
        ).toLocaleString();
        setInputValue(formattedValue);
      } else {
        // Update inputValue without formatting
        setInputValue(newValue);
      }

      // Call onAmountChange if valid number
      if (!isNaN(num)) {
        onAmountChange && onAmountChange(num);
      } else {
        onAmountChange && onAmountChange(0);
      }
    },
    [integerOnly, validator, onAmountChange, maxValue, decimalPlaces],
  );

  return (
    <div className="trading-input-container">
      <form onSubmit={onFormSubmit}>
        <input
          className="trading-input"
          ref={inputRef}
          type="text"
          inputMode={type} // "numeric" | "decimal"
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          enterKeyHint="done"
          placeholder={'0'}
          value={inputValue}
          onChange={handleInputChange}
          autoFocus={autoFocus}
          onFocus={() => handleInputFocus(true)}
          onBlur={() => handleInputFocus(false)}
        />

        {icon && (
          <img
            className="btn trading-input-icon"
            src={icon}
            onClick={() => {
              setInputValue('');
              onAmountChange && onAmountChange(0);
            }}
          />
        )}
      </form>
    </div>
  );
};
