import {
  SB,
  createSharedStateComputedProperties,
  searchableComputedProperty,
} from '@play-co/replicant';
import { teamProfileSchema, teamSharedStateSchema } from './teams.schema';
import { clampScore, floorToSignificantDigits } from '../../utils/numbers';

export const teamsComputedProperties = createSharedStateComputedProperties(
  teamSharedStateSchema,
)({
  profile: searchableComputedProperty(
    teamProfileSchema,
    (state) => state.global.profile,
  ),

  membersCount: searchableComputedProperty(
    SB.number(),
    (state) => state.global.membersCount,
  ),

  score: searchableComputedProperty(SB.number(), (state) => {
    // clamp score
    const score = clampScore(state.global.score + state.counters.score.value);
    return floorToSignificantDigits(score, 3); // `score` was migrated to a counter
  }),

  modifiedAt: searchableComputedProperty(SB.int(), (state) => {
    return state.global.updatedAt;
  }),

  createdAt: searchableComputedProperty(SB.int(), (state) => {
    return state.global.createdAt ?? 0;
  }),
});

export type TeamComputedProperties = {
  [T in keyof typeof teamsComputedProperties]: SB.ExtractType<
    (typeof teamsComputedProperties)[T]['type']
  >;
} & { id: string };
