import {
  getEnergyLimit,
  // getEnergyPerSecond,
  // getLastRocketman,
  // getLeague,
  // getPointsPerTap,
  getRocketmanBonus,
  // getShopInfo,
} from '../replicant/features/game/game.getters';
import { State } from '../replicant/schema';
import { app } from './Controllers/AppController';
import { qpConfig, env } from './config';
import { mockGameApi, mockSocialApi } from './mockApi';
import {
  Booster,
  Buff,
  GameResponse,
  PlayerGame,
  StartSessionResponse,
  // Team,
  // TeamPlayer,
} from './types';
import { League } from '../replicant/features/game/ruleset/league';
import { getTelegramUserName, getTelegramUserPhoto } from './telegramUser';
import { tests } from '../replicant/ruleset';

// @todo: remove (POST SEASON 2 MIGRATION)
// enum Endpoints {
//   TEAMS_LIST = 'teams',
//   TEAMS_TOP_100 = 'teams/top100',
//   TEAMS_GET = 'teams/get',
//   TEAMS_JOIN = 'teams/join',
//   TEAMS_LEAVE = 'teams/leave',
//   START_SESSION = 'start-game',
//   SUBMIT_POINTS = 'add-points',
//   BUY_BOOSTER = 'player/booster',
//   BUY_BUFF = 'player/buff',
//   SHOP_GET = 'shop',
//   PLATFORM_STORAGE = 'player/platform-storage',
//   PLATFORM_STORAGE_SET = 'player/platform-storage-set',
// }

enum ReplicantBaseUrl {
  DEV = 'https://gemzcoin-dev.us-east-1.replicant.gc-internal.net',
  PROD = 'https://gemzcoin.us-east-1.replicant.gc-internal.net',
}

// @todo: remove (POST SEASON 2 MIGRATION)
// @hack to redirect to shiny new prod
// const gameApiEnv = env === 'prod' ? 'prod2' : env;

// let gameApiUrl = qpConfig.useLocal
//   ? 'http://localhost:8080'
//   : `https://notgemz.game.${env}.pnk.one`;

// const getGameUrl = (endpoint: Endpoints) => {
//   return `${gameApiUrl}/${endpoint}`;
// };

export const apiRequest = <T>(url: string) => {
  const request = async (url: string, opts?: RequestInit) => {
    const response = await fetch(url, opts);

    if (response.status === 200) {
      return response.json() as T;
    } else {
      const error = await response.text();
      throw new Error(`Woops something went wrong: ${error}`);
    }
  };

  return {
    get: () => request(url),
    post: (body: Record<string, any>) =>
      request(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
  };
};

function buildPlayerFromState(state: State, now: number): PlayerGame {
  const rocketman = getRocketmanBonus(state, now);

  const energyLimit = getEnergyLimit(state);

  return {
    // @todo: remove (POST SEASON 2 MIGRATION)
    // pointsPerTap: getPointsPerTap(state, now),
    // league: getLeague(state) as League,
    // energy: state.energy,
    // energyPerSecond: getEnergyPerSecond(state),
    // energyLimit,
    // rocketmanDuration: rocketman.duration,
    // rocketmanMultiplier: rocketman.multiplier,
    // lastRocketmanStart: getLastRocketman(state, rocketman.free) ?? 0,
    // score: state.score,

    balance: state.balance,
    isFirstSession: state.first_session,
  };
  // as PlayerGame;
}

function buildGameResponseFromState(
  state: State,
  // teamState: Team | undefined,
  now: number,
) {
  return {
    player: buildPlayerFromState(state, now),
    // @todo: remove (POST SEASON 2 MIGRATION)
    // player: buildPlayerFromState(state, teamState, now),
    // team: teamState,
  } as GameResponse;
}

function buildStartSessionResponseFromState(
  state: State,
  // teamState: Team | undefined,
  now: number,
  // @todo: remove (POST SEASON 2 MIGRATION)
  // botEarnings: number,
  // inviteDrawerDuration: number,
  // powerUpBonus: number,
  // unclaimedReferralRewards: number,
): StartSessionResponse {
  return {
    ...buildGameResponseFromState(state, now),
    startTime: now,
    // botEarnings,
    // inviteDrawerDuration,
    // powerUpBonus,
    // unclaimedReferralRewards,
  };
}

export const gameApi = qpConfig.useMock
  ? mockGameApi
  : {
      startSession: async () => {
        const startSessionResponse = await app.invoke.startSession({
          profile: {
            name: getTelegramUserName(app.replicant.state),
            photo: getTelegramUserPhoto(
              app.replicant.state,
              app.replicant.userId,
            ),
          },
        });

        // let team = app.clicker.playerTeam;
        // if (!team && app.state.team_id) {
        //   team = await gameApi.getTeam(app.state.team_id);
        // }

        return buildStartSessionResponseFromState(
          app.state,
          // team,
          app.now(),
          // @todo: remove (POST SEASON 2 MIGRATION)
          // startSessionResponse.botEarnings,
          // startSessionResponse.inviteDrawerDuration,
          // startSessionResponse.powerUpBonus,
          // startSessionResponse.unclaimedReferralRewards,
        );
      },

      // @todo: remove (POST SEASON 2 MIGRATION)
      // addBadges: async () => {
      //   await app.invoke.addBadges();
      // },
      // listTeams: () => app.replicant.asyncGetters.getRecommendedTeams({}),
      // leaderboard: () => app.replicant.asyncGetters.getTeamsLeaderboard({}),
      // getTeam: async (teamId: string) => {
      //   let team: Team | undefined =
      //     await app.replicant.asyncGetters.getTeamWithMembers({
      //       teamId,
      //     });

      //   // if team exists, user is in this team, and team score is negative, invoke the negative score fix
      //   if (
      //     team?.id &&
      //     app.clicker.playerTeam?.id &&
      //     team.id === app.clicker.playerTeam.id &&
      //     team.score < 0
      //   ) {
      //     team = await app.replicant.invoke.getTeamWithMembersWithScoreFix({
      //       teamId,
      //     });
      //   }

      //   // just return undefined right here
      //   if (!team) {
      //     return undefined;
      //   }

      //   // optimistically display being in the joined team list, since joining a team has a considerable delay
      //   if (
      //     team.id &&
      //     app.clicker.playerTeam?.id &&
      //     team.id === app.clicker.playerTeam.id
      //   ) {
      //     const existingTeamPlayer = team.players?.find(
      //       (player) =>
      //         player.name.toLowerCase() === app.state.username.toLowerCase(),
      //     );
      //     if (existingTeamPlayer) {
      //       // player is already here, so just return team as-is
      //       return team;
      //     }

      //     const teamPlayer: TeamPlayer = {
      //       name: app.state.username,
      //       photo: app.state.profile?.photo,
      //       score: app.state.score,
      //       rank: 1,
      //     };
      //     if (team.players) {
      //       // add player, re-sort, then re-assign rank according to new order
      //       team.players.push(teamPlayer);
      //       team.players.sort((a, b) => {
      //         return b.score - a.score;
      //       });
      //       team.players.forEach((player, index) => {
      //         player.rank = index + 1;
      //       });
      //     } else {
      //       team.players = [teamPlayer];
      //     }
      //   }
      //   return team;
      // },
      // leaveTeam: async () => {
      //   await app.invoke.leaveTeam();
      //   return buildGameResponseFromState(app.state, undefined, app.now());
      // },
      // joinTeam: async (_: string, teamId: string) => {
      //   await app.invoke.joinTeam({ teamId });
      //   return buildGameResponseFromState(
      //     app.state,
      //     await fetchCurrentTeamState(),
      //     app.now(),
      //   );
      // },
      // buyBooster: async (userId: string, booster: Booster) => {
      //   const maybeError = await app.invoke.buyBooster({ booster });
      //   if (maybeError) {
      //     return maybeError;
      //   }

      //   return buildGameResponseFromState(
      //     app.state,
      //     app.clicker.playerTeam,
      //     app.now(),
      //   ) as Pick<GameResponse, 'player'>;
      // },
      // getShop: async (userId: string) => {
      //   app.track('OpenShop', {});

      //   return getShopInfo(app.state, app.now());
      // },
      // buyBuff: async (userId: string, buff: Buff) => {
      //   // this actually calls the replicant action
      //   const maybeError = await app.invoke.buyBuff({ buff });
      //   if (maybeError) {
      //     return maybeError;
      //   }

      //   return buildGameResponseFromState(
      //     app.state,
      //     app.clicker.playerTeam,
      //     app.now(),
      //   ) as Pick<GameResponse, 'player'>;
      // },
      // getPlatformStorage: (userId: string) =>
      //   apiRequest<Object>(getGameUrl(Endpoints.PLATFORM_STORAGE)).post({
      //     userId,
      //   }),
      // setPlatformStorage: (userId: string, platformStorage: Object) =>
      //   apiRequest<Object>(getGameUrl(Endpoints.PLATFORM_STORAGE_SET)).post({
      //     userId,
      //     platformStorage,
      //   }),
      // @todo: remove (POST SEASON 2 MIGRATION)
      // getLeagueLeaderboard: (league: League, currentUserId: string) => {
      //   const sortField = 'balance';
      //   return app.replicant.asyncGetters.getPlayersByLeague({
      //     season: app.state.season,
      //     league,
      //     sortField,
      //     currentUserId,
      //   });
      // },
    };

export const socialApi = qpConfig.useMock
  ? mockSocialApi
  : {
      getFriends: async () => {
        const friends = await app.asyncGetters.getFriends({ maxCount: 100 });
        return friends;
      },
    };

// @todo: remove (POST SEASON 2 MIGRATION)
// function fetchCurrentTeamState() {
//   return app.state.team_id
//     ? app.replicant.asyncGetters.getTeamWithMembers({
//         teamId: app.state.team_id,
//       })
//     : undefined;
// }

export const getFormDataParams = (
  fields: Record<string, string>,
  fileData?: { file: File; filename?: string },
) => {
  const formData = new FormData();
  Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
  if (fileData) {
    if (fileData.filename) {
      formData.append('file', fileData.file, fileData.filename);
    } else {
      formData.append('file', fileData.file);
    }
  }

  return {
    method: 'POST',
    body: formData,
  };
};

export const getRemoteVersion = async () => {
  try {
    let url = `${getReplicantUrl()}/latest/_healthcheck`;
    const response = await apiRequest<{ version: string }>(url).get();
    return response.version;
  } catch (e: any) {
    // if it fails return local version
    return process.env.REACT_APP_APP_VERSION;
  }
};

export const getReplicantUrl = () => {
  if (['alpha', 'bravo', 'charlie'].includes(env)) {
    return `${ReplicantBaseUrl.DEV}/gemzcoin-${env}`;
  } else if (env === 'prod') {
    return `${ReplicantBaseUrl.PROD}/gemzcoin`;
  } else if (env === 'dev') {
    return `${ReplicantBaseUrl.PROD}/gemzcoin-dev`;
  } else {
    return '';
  }
};
