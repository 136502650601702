import { Chat } from '../../telegram.bot.types';
import { TeamProfile } from './teams.schema';

export function teamProfileFromChat(
  chat: Chat,
  photo: string | undefined,
): TeamProfile {
  return {
    name: chat.title ?? `Team ${chat.id}`,
    description: chat.description ?? '',
    photo,
    inviteLink: chat.username
      ? `https://t.me/${chat.username}`
      : chat.invite_link ?? '',
  };
}
