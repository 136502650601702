import './TradingTokenFooter.scss';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';
import { TradingTokenFooterForm } from './components/TTFForm';

// ============================================================================
// region TokenFooter
// note: this is used by TradingTokenPage and DrawerCreateTradingConfirm

interface FooterProps {
  footerMode: 'normal' | 'create';
}

export const TradingTokenFooter = ({ footerMode }: FooterProps) => {
  useAppUpdates({
    id: 'OffchainTokenFooter',
    listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
  });

  // -----------------------------------------------
  // render buy/sell footer component in create mode

  if (footerMode === 'create') {
    return (
      <div className={`trading-token-footer-container noTabs`}>
        <TradingTokenFooterForm
          footerMode={'create'}
          txType="buy"
          currencyType={'tokens'} // todo carles review
        />
      </div>
    );
  }

  // -----------------------------------------------
  //  render buy/sell footer component in normal mode

  return (
    <div className="trading-token-footer-container">
      <div className="trading-token-footer-header sell-header">
        <TradingTokenFooterForm
          footerMode={'normal'}
          txType={'sell'}
          currencyType={'tokens'} // todo carles review
        />
      </div>
    </div>
  );
};
