import {
  createSharedStateMessages,
  createSharedStateMessage,
  SB,
} from '@play-co/replicant';
import { onchainTxsSharedStateSchema } from './onchainTxs.schema';
import { MAX_TXS_PER_PAGE } from './onchainTxs.ruleset';

// @warning: never remove/rename shared state messages
export const onchainTxsMessages = createSharedStateMessages(
  onchainTxsSharedStateSchema,
)({
  addTransactions: createSharedStateMessage(
    SB.object({
      txHashes: SB.array(SB.string()),
    }),
    (state, { txHashes }, meta) => {
      const recordedTxHashes = state.global.txHashes;

      if (recordedTxHashes.length + txHashes.length > MAX_TXS_PER_PAGE) {
        throw new Error('');
      }

      state.global.txHashes = recordedTxHashes.concat(txHashes);
    },
  ),
});
