import { League } from './league';

export const REFERRAL_BONUS_REGULAR = 25000;
export const REFERRAL_BONUS_PREMIUM = 500000;

export const REFERRAL_REFILL_BONUS = 1;

export const REFERRAL_LEVEL_UPS_REGULAR: Record<League, number> = {
  league1: 0,
  league2: 20_000,
  league3: 200_000,
  league4: 2_000_000,
  league5: 10_000_000,
  league6: 50_000_000,
  league7: 100_000_000,
  league8: 200_000_000,
  league9: 1_000_000_000,
};

export const REFERRAL_LEVEL_UPS_PREMIUM: Record<League, number> = {
  league1: 0,
  league2: 10_000,
  league3: 10_0000,
  league4: 1_000_000,
  league5: 5_000_000,
  league6: 25_000_000,
  league7: 50_000_000,
  league8: 100_000_000,
  league9: 500_000_000,
};

// DO NOT CHANGE!
export const RESERVED_REFERRAL_ID = '0';
