import { useTranslation } from 'react-i18next';
import { app } from '../../../data/Controllers/AppController';

export const LeagueTitle = ({ league }: { league?: string }) => {
  const { t } = useTranslation();

  return (
    <span>
      {/* @todo: remove (POST SEASON 2 MIGRATION) */}
      {/* {t(`league_title`)} {app.ui.getLeagueTitle(league)} */}
    </span>
  );
};
