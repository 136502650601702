import { Fn } from './types';

export type SendEventFn = (events: string[] | string) => void;

export class EventListener {
  protected events: Record<string, Fn<void>[]> = {};

  protected eventListeners: ((evt: any) => void)[] = [];

  /**
   *
   * @param eventName
   * @param callback
   * @returns removeEventListener function
   */
  addEventListener = (eventName: string, callback: Fn<void>) => {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
    return () => {
      this.events[eventName] = this.events[eventName].filter(
        (cb) => cb !== callback,
      );
    };
  };

  listen = <T extends string>(callback: (evt: T) => void) => {
    if (this.eventListeners.includes(callback)) {
      return () => {
        this.eventListeners = this.eventListeners.filter(
          (cb) => cb !== callback,
        );
      };
    }
    this.eventListeners.push(callback);
    return () => {
      this.eventListeners = this.eventListeners.filter((cb) => cb !== callback);
    };
  };

  // Just sugar sytanx to make it easier to attach to components re-render hook
  attachEventListener = (eventName: string) => (callback: Fn<void>) => {
    return this.addEventListener(eventName, callback);
  };

  /**
   * Pass only event name when no params are required. Otherwise use EventTrigger
   */
  protected sendEvents = (events: string[] | string, debug = false) => {
    const normalisedList: string[] =
      typeof events === 'string' ? [events] : events;

    if (debug) {
      console.warn(`EventListener.sendEvents`, { events });
    }

    normalisedList.forEach((eventName) => {
      if (this.events[eventName]) {
        this.events[eventName].forEach((cb) => {
          cb();
        });
      }
    });

    normalisedList.forEach((eventName) => {
      this.eventListeners.forEach((cb) => {
        cb(eventName);
      });
    });
  };
}
