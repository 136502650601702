import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './TradingTokenImageUploader.scss';
import { assets } from '../../../../assets/assets';
import { Uploader } from '../../../../CMSApp/components/Uploader';

interface Props {
  useTrashCan?: boolean;
  preview?: string;
  onFileChange: (file?: File) => void;
  uploadState: number;
}

export const TradingTokenImageUploader = ({
  useTrashCan,
  onFileChange,
  uploadState,
  preview,
}: Props) => {
  const uploader = useRef<HTMLInputElement>(null);

  const imgPreviewRef = useRef<any>();
  const [previewVerticalAspectRatio, setPreviewVerticalAspectRatio] =
    useState(true);

  const [file, setFile] = useState<File>();

  useEffect(() => {
    setFile(undefined);

    if (preview && imgPreviewRef.current) {
      const isVertical =
        imgPreviewRef.current.naturalWidth <
        imgPreviewRef.current.naturalHeight;
      setPreviewVerticalAspectRatio(isVertical);
    }
  }, [uploadState]);

  const onFileUploaded = useCallback(async (f: File) => {
    setFile(f);
    onFileChange(f);
  }, []);

  const openFileUploader = useCallback(
    (e: any) => {
      if (!uploader?.current) {
        return;
      }
      onClearImage(e);
      uploader.current.click();
    },
    [uploader.current],
  );

  const onClearImage = useCallback((e: any) => {
    setFile(undefined);
    onFileChange(undefined);
    e.stopPropagation();
  }, []);

  const imageUrl = useMemo(() => {
    if (preview) {
      if (imgPreviewRef.current) {
        const isVertical =
          imgPreviewRef.current.naturalWidth <
          imgPreviewRef.current.naturalHeight;
        setPreviewVerticalAspectRatio(isVertical);
      }

      return preview;
    }
    if (file) {
      // console.warn('>>> file', file);
      return URL.createObjectURL(file);
    }
  }, [file, preview]);

  return (
    <div className="container" onClick={openFileUploader}>
      <div className="img-container">
        <div className="img-box">
          <div className="btn btn-add">
            {imageUrl ? (
              <div className="image-container">
                <img
                  ref={imgPreviewRef}
                  src={imageUrl}
                  style={{
                    width: previewVerticalAspectRatio ? '100%' : 'unset',
                    height: previewVerticalAspectRatio ? 'unset' : '100%',
                  }}
                />
              </div>
            ) : (
              <img
                src={assets.create_icon_camera}
                style={{ width: '40px', height: '40px' }}
              />
            )}
          </div>
        </div>

        {!file && !imageUrl && (
          <div className="warning">
            <div className="title">Tap to select image</div>
            {/* <div className="required">*</div> */}
          </div>
        )}
      </div>

      <Uploader
        ref={uploader}
        id={'image-uploader'}
        onFileUploaded={onFileUploaded}
        accept="image/*"
      />

      {useTrashCan && (
        <div
          className="btn trading-create-trash"
          onClick={(e: any) => onClearImage(e)}
        >
          <img src={assets.create_icon_trash} />
        </div>
      )}
    </div>
  );
};
