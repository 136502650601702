import { SB } from '@play-co/replicant';
import {
  createScheduledActions,
  extractScheduledActionHandlers,
} from '../../createScheduledActions';

const schema = {};

const actions = createScheduledActions(schema)({});

export const onchainHoldersScheduledActions = {
  schema,
  actions: extractScheduledActionHandlers(actions),
};
