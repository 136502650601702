import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ProgressBarCircle.scss';

interface ProgresBarProps {
  progress: number;
  caption?: string;
  height?: number;
  color?: string;
  onComplete?: () => void;
}

export const ProgressBarCircle = ({
  progress,
  caption,
  height,
  color,
  onComplete,
}: ProgresBarProps) => {
  const { t } = useTranslation();

  height = height || 10;
  color = color || 'white';

  if (progress > 100) progress = 100;

  // progress
  const [percentageComplete, setPercentageComplete] = useState(progress);

  useMemo(() => {
    setPercentageComplete(progress);
    // console.warn('>>> ProgressBarCircle', progress);
  }, [progress]);

  // render

  const sz = 32;

  return (
    <svg
      width={sz}
      height={sz}
      viewBox={`0 0 ${sz} ${sz}`}
      className="circular-progress-bar"
      style={
        { '--size': `${sz}px`, '--progress': progress } as React.CSSProperties
      }
    >
      <circle className="bg" />
      {progress > 0 && <circle className="fg" />}
    </svg>
  );
};

// ===================================================================================
