import './TradingGemzCoinItem.scss';
import { assets } from '../../../../assets/assets';
import { largeIntegerToLetter } from '../../../../replicant/utils/numbers';

// New component for displaying balance
export const TradingGemzCoinItem = ({ balance }: { balance: number }) => {
  return (
    <div className="trading-token-item-profile gemz-coin-item">
      <div className="trading-token-item-profile-container">
        <div className="trading-token-item-profile-image">
          <img src={assets.coin} />
        </div>
        <div className="trading-token-item-profile-content">
          <div className="left">
            <div className="coinname">Coins</div>
          </div>
          <div className="right">
            <div className="price-container">
              <div className="coin">
                <img src={assets.coin} />
              </div>
              <div className="value">{largeIntegerToLetter(balance, 3)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
