import './DrawerCriticalError.scss';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { isTelegramWebview } from '../../../data/device';
import { useEffect } from 'react';
import {
  CriticalErrors,
  errorResolution,
  userFacingErrorMessages,
} from '../../../data/errors';
import { app } from '../../../data/Controllers/AppController';

/*
Critical error drawers are non closable
Most of them force the user to reload the app
One of them forces the user to close it
*/
export const DrawerCriticalError = () => {
  const { t } = useTranslation();

  const errorType = app.criticalError as CriticalErrors;

  const onReload = () => {
    if (errorResolution[errorType] === 'reload') {
      window.location.reload();
    } else {
      if (isTelegramWebview()) {
        Telegram.WebApp.close();
      } else {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    Telegram.WebApp.isClosingConfirmationEnabled = false;
  }, []);

  return (
    <div className="auth-error">
      <div className="drawer-layout powerup">
        <div className="drawer-header">
          {/* image */}
          <div className="drawer-header-icon">
            <img src={assets.sloth_gift_invalid} />
          </div>

          <div className="drawer-header-title">
            {t(userFacingErrorMessages[errorType].title)}
          </div>
          <div className="drawer-header-subtitle powerup">
            <div className="text">
              {t(userFacingErrorMessages[errorType].message)}
            </div>
          </div>
        </div>
      </div>

      {/* buy button */}
      <div className="footer-buttons">
        <div className={`btn btn-normal`} onClick={onReload}>
          {t(userFacingErrorMessages[errorType].button)}
        </div>
      </div>
    </div>
  );
};
