import { SB } from '@play-co/replicant';
import { powerupSchema } from './powerup.schema';
import { powerUpSchema } from '../game/player.schema';

export type PowerUpCategory = SB.ExtractType<typeof powerupSchema.category>;

export type PowerUpDetails = SB.ExtractType<typeof powerupSchema.details>;

export type SpecialPowerUpState = SB.ExtractType<
  typeof powerupSchema.specialState
>;

// computed data
const powerupSBSchema = SB.object(powerupSchema);
export type PowerUp = SB.ExtractType<typeof powerupSBSchema>;
export type PowerUpUI = PowerUp & {
  hasBadge?: boolean;
};

export interface ConditionCfg {
  key: string;
  description: string;
  targetPowerUp?: string;
  targetLevel?: number;
  targetInvites?: number;
  targetEarnings?: string[];
}

// Make sure that all items in the pick are reflected in the powerUp object in getters.ts
export interface PowerUpCfg
  extends Pick<PowerUp, 'id' | 'maxLevel' | 'category' | 'type'> {
  details: PowerUpDetails;
  curveLevel: number;
  initialEarn: number;
  earnAlgorithm?: string;
  initialCost: number;
  costAlgorithm?: string;
  conditions: ConditionCfg[];
  // Used for timed specials
  availability?: {
    startTime: number;
    endTime: number;
  };
  expireTime?: number;
  sort?: number;
  type?: string;
}

export interface PowerUpAirtableItem {
  id: string;
  name: string;
  description: string;
  image?: string;
  curveLevel?: number;
  initialEarn: number;
  earnAlgorithm?: string;
  initialCost: number;
  costAlgorithm?: string;
  category: string;
  startTime?: number;
  endTime?: number;
  maxLevel?: number;
  expireTime?: number;
  condition?: string;
  test?: boolean;
  type?: string;
  sort?: number;
}

export type ConditionAirtableItem = Omit<ConditionCfg, 'targetEarnings'> & {
  id: string;
  targetEarnings?: string;
};

export interface PowerUpDailyAirtableItem {
  starts_at: number;
  power_up_1: string;
  power_up_2: string;
  power_up_3: string;
  /**
   * Not for runtime use; use `starts_at` instead
   */
  date?: string; // We don't use this field, only for product to view data in a different shape
}

export interface PUDailyLevelUserCondition {
  dailyGifts: string[];
  discountList: string[];
}

export interface BadgeTriggerAirtableItem {
  id: string;
  startTime: string;
  type: string;
  whitelisted: number;
}

export type BadgeItem = 'friends' | 'boosters' | 'cards' | 'earn';

export enum PowerUpCardType {
  GIFT_ONLY = 'giftOnly',
  GIFT_DAILY_WITH_DISCOUNT = 'giftWithDiscount',
  TUTORIAL_1 = 'tutorial_1',
  HIDDEN = 'hidden',
}

export interface QuestVideoAirtableItem {
  id: string;
  startTime: string;
  endTime: string;
  url: string;
}

export interface PowerUpBondingCurves {
  polynomialCost: {
    constant: number;
    multiplier1: number;
    multiplier2: number;
  };
  polynomialEarn: {
    constant: number;
    multiplier1: number;
    multiplier2: number;
    multiplier3: number;
  };
  giftOnlyCost: {
    levelsBetweenStepChange: number;
    multiplier: number;
  };
}
