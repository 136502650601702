import { asyncAction } from '@play-co/replicant';
import { createActions } from '../../createActions';
import { addFollowing, removeFollowing } from './followings.modifiers';

export const followingsActions = createActions({
  asyncUpdateFollow: asyncAction(
    async (
      state,
      { follow, unfollow }: { follow?: string; unfollow?: string },
      api,
    ) => {
      try {
        // If we are not following the user yet
        if (follow) {
          await addFollowing(state, follow, api);
        }

        if (unfollow) {
          await removeFollowing(state, unfollow, api);
        }
      } catch (error) {
        return {
          expectedError: true,
          errorMessage: (error as Error).message,
        };
      }
    },
  ),
});
