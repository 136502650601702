interface Props {
  url?: string;
  title: string;
  icon: string;
  className: string;
}

export const MemeDetailsSocialBtn = ({
  className,
  icon,
  title,
  url,
}: Props) => {
  const onClick = () => {
    window.open(url, '_blank')?.focus();
  };

  return (
    <div
      className={`btn about-social-button ${className} ${
        url ? '' : 'disabled'
      }`}
      onClick={onClick}
    >
      <div className="icon">
        <img src={icon} />
      </div>
      {title}
    </div>
  );
};
