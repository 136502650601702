import { tradingMemeSharedStateSchema } from './tradingMeme.schema';
import { createSharedState } from '@play-co/replicant';
import { tradingMemeMessages } from './tradingMeme.messages';
import { tradingMemeComputedProperties } from './tradingMeme.properties';

export const tradingMemeSharedState = createSharedState(
  tradingMemeSharedStateSchema,
  tradingMemeMessages,
  tradingMemeComputedProperties,
);

export type TradingSharedStates = typeof tradingMemeSharedState;
