import { createSharedState } from '@play-co/replicant';
import { friendsSharedStateSchema } from './friends.schema';
import { friendsMessages } from './friends.messages';
import { friendsComputedProperties } from './friends.properties';

export const friendsSharedStates = createSharedState(
  friendsSharedStateSchema,
  friendsMessages,
  friendsComputedProperties,
);

export type FriendsSharedStates = typeof friendsSharedStates;
