import { QuestCheckFooter } from './QuestCheckFooter';
import { QuestCheckerHeader } from './QuestCheckHeader';
import './DrawerQuestCheck.scss';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import { SessionEvents } from '../../../../data/Controllers/SessionController';

export const DrawerQuestCheck = () => {
  useAppUpdates({
    id: 'DrawerQuestCheck',
    listener: app.session.attachEventListener(SessionEvents.OnUpdate),
  });

  const currentQuest = app.ui.drawer.view.data?.props?.quest;

  if (!currentQuest) {
    return null;
  }

  return (
    <div className="drawer-earncheck with-two-buttons">
      <QuestCheckerHeader quest={currentQuest} />
      <QuestCheckFooter quest={currentQuest} />
    </div>
  );
};
