import './FeedMeme.scss';
import { useRef } from 'react';
import { app } from '../../../../data/Controllers/AppController';
import { isIOS } from '../../../../data/device';
import { TradingTokenListing } from '../../../../replicant/features/tradingMeme/types';
import { TiktokTapGame } from '../TiktokTapGame/TiktokTapGame';
import { FeedMemeBottomUI } from './FeedMemeBottomUI';
import { TiktokPlayAndFarmUI } from '../TiktokPlayAndFarmUI/TiktokPlayAndFarmUI';

interface FeedMemeProps {
  index: number;
  currentSlideIndex: number;
  token: TradingTokenListing;
  uiAlpha: number;
  canSwipe: boolean;
  canShowTapGame: boolean;
}

export const FeedMeme = ({
  index,
  currentSlideIndex,
  token,
  uiAlpha,
  canSwipe,
  canShowTapGame,
}: FeedMemeProps) => {
  // ----------------------------------------------
  // Make token image react and jiggle during tap game

  const imageRef = useRef<HTMLDivElement>(null);

  const onJiggle = () => {
    const elm = imageRef.current;
    if (!elm) return;

    const sc = 1.01 + Math.random() * 0.1;
    const x = -5 + Math.random() * sc * 10;
    const y = -5 + Math.random() * sc * 10;
    const rot = 0; // -1 + Math.random() * 2;

    elm.style.transform = `scale(${sc}) translate(${x}px, ${y}px) rotate(${rot}deg)`;
    setTimeout(() => {
      elm.style.transform = 'scale(1) translate(0px, 0px) rotate(0deg)';
    }, 150);
  };

  // ----------------------------------------------
  // if this item is out of rendering range,
  // avoid filling it with content in order to increase performance
  // note that the root container still needs to be rendered for carousel to work correctly
  const selected = index === currentSlideIndex;
  const renderZone = app.memes.currentList.getItemRenderZone(index);
  const shouldRenderLight = !selected && renderZone === 'Cold';

  if (shouldRenderLight) {
    return <div className={`feed-meme ${isIOS() ? 'telegram-ios' : ''}`} />;
  }

  // ----------------------------------------------

  // full item if is meant to be rendered

  return (
    <div className={`feed-meme ${isIOS() ? 'telegram-ios' : ''}`}>
      {/* full screen image */}
      <div className="feed-meme-image" ref={imageRef}>
        <img src={token.image} />
      </div>

      {/* bottom gradient */}
      <div className="feed-meme-bottom-gradient" />

      {/* debug label - for testing purposes */}
      {/* <div
        className={`debug-label`}
      >{`canShowTapGame: ${canShowTapGame} ${uiAlpha}`}</div> */}

      {/* Tiktok Tap Game */}
      <TiktokTapGame
        visible={selected}
        canSwipe={canSwipe}
        uiAlpha={uiAlpha}
        canShowTapGame={canShowTapGame}
        onJiggle={onJiggle}
      />

      {/* PlayAndFarm ui */}
      <TiktokPlayAndFarmUI
        visible={selected}
        uiAlpha={uiAlpha}
        canShowTapGame={canShowTapGame}
      />

      {/* item ui */}
      <FeedMemeBottomUI
        token={token}
        selected={selected}
        uiAlpha={uiAlpha}
        slideIndex={currentSlideIndex}
        canShowTapGame={canShowTapGame}
      />
    </div>
  );
};
