import { createAsyncGetters } from '../../createAsyncGetters';
import {
  getOnchainTxsPageCount,
  getOnchainTxsStateId,
} from './onchainTxs.getters';
import { ChainName } from './onchainTxs.schema';

export const onchainTxsAsyncGetters = createAsyncGetters({
  getOnchainTxsPageCount: async (
    { memeId, chain }: { memeId: string; chain: ChainName },
    api,
  ) => {
    const pageCount = (await getOnchainTxsPageCount(
      api,
      memeId,
      chain,
    )) as number;
    return pageCount;
  },

  getLastRecordedTxHash: async (
    { memeId, chain }: { memeId: string; chain: ChainName },
    api,
  ) => {
    const pageCount = (await getOnchainTxsPageCount(
      api,
      memeId,
      chain,
    )) as number;

    if (pageCount === 0) {
      return;
    }

    const lastPageId = getOnchainTxsStateId(memeId, chain, pageCount - 1);

    const lastPage = await api.sharedStates.onchainTxs.fetch(lastPageId);

    if (!lastPage) {
      throw new Error(
        `Could not find last page of records for ${memeId} on ${chain}`,
      );
    }

    const txHashes = lastPage.global.txHashes;
    return txHashes[txHashes.length - 1];
  },
});
