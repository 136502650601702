import './DrawerTradingTransaction.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';

export const DrawerTradingTransactionError = () => {
  const { t } = useTranslation();

  // get error message
  const errorMessage =
    app.ui.drawer.view.data?.props?.transactionError?.errorMessage ||
    t('trading_transaction_error_message');

  return (
    <div className="drawer-trading-transaction">
      <div className="drawer-trading-transaction-header">
        <img
          className="drawer-trading-transaction-icon"
          src={assets.trading_transaction_error}
        />

        <div className="drawer-trading-transaction-title">{`Transaction Failed`}</div>
        <div className="drawer-trading-transaction-subtitle">
          {errorMessage}
        </div>
      </div>

      <div className="footer-buttons">
        <div
          className={`btn btn-normal`}
          onClick={() => {
            app.ui.confetti.hide();
            app.ui.drawer.close();
          }}
        >
          {t('trading_transaction_error_button')}
        </div>
      </div>
    </div>
  );
};
