import { t } from 'i18next';
import { assets } from '../../../../assets/assets';
import { TutorialModule } from '../TutorialModule';
import { waitFor } from '../../../utils';

export class SlideshowEnd extends TutorialModule {
  id = 'SlideshowEnd' as 'SlideshowEnd';

  execute = async () => {
    await waitFor(250);
    this.app.ui.confetti.show();
    this.app.ui.drawer.show({
      id: 'generic',
      hideClose: true,
      opts: {
        image: assets.coin,
        title: t('tut_slide_reward_title'), // Recieve a gift',
        score: '100',
        buttons: [
          {
            cta: t('tut_slide_reward_cta'), // Click here!',
            onClick: async () => {
              this.onComplete();
            },
          },
        ],
      },
    });
    return super.execute().then(() => {
      this.app.ui.drawer.close();
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_slide_gift',
    };
  }
}
