import './DrawerGeneric.scss';
import { assets } from '../../../assets/assets';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { SessionEvents } from '../../../data/Controllers/SessionController';
import { ModalEvents } from '../../../data/Controllers/UIController/ModalManager';

export const DrawerGeneric = () => {
  useAppUpdates({
    id: 'DrawerQuestCheck',
    listener: app.ui.drawer.attachEventListener(ModalEvents.OnUpdate),
  });

  const { data } = app.ui.drawer.view;

  return (
    <>
      <div className="drawer-layout">
        <div className="drawer-header">
          {data?.opts?.image && (
            <div className="drawer-header-icon">
              <img src={data?.opts?.image} />
            </div>
          )}
          <div className="drawer-header-title">{data?.opts?.title}</div>
          <div
            className={`drawer-header-subtitle ${
              data?.opts?.isSubtitleLeft ? 'left' : ''
            }`}
          >
            {data?.opts?.subtitle}

            {data?.opts?.score && (
              <div className="score">
                <div className="icon">
                  <img src={assets.coin} />
                </div>
                <div className="text">{data?.opts?.score}</div>
              </div>
            )}
          </div>
          {data?.opts?.footnote && (
            <sub style={{ color: 'grey' }}>{data?.opts?.footnote}</sub>
          )}
          <div className="drawer-header-subtitle-2">
            {data?.opts?.subtitle2}
          </div>
        </div>
      </div>

      <div className="footer-buttons">
        {data?.opts?.buttons?.map((button, index) =>
          button.url ? (
            <ExternalLinkBtn key={index} {...button} />
          ) : (
            <GameBtn key={index} {...button} />
          ),
        )}
      </div>
    </>
  );
};

const ExternalLinkBtn = ({
  url,
  onClick,
  cta,
}: {
  url?: string;
  onClick?: () => void;
  cta: string;
}) => {
  return (
    <a
      href={url}
      target="_blank"
      className="btn btn-normal btn-enormus"
      onClick={onClick}
    >
      {cta}
    </a>
  );
};

const GameBtn = ({ onClick, cta }: { onClick?: () => void; cta: string }) => {
  return (
    <div className="btn btn-normal" onClick={onClick}>
      {cta}
    </div>
  );
};
