import { createAsyncGetters } from '../../createAsyncGetters';
import { MAX_DISPLAYED_TOP_HOLDERS } from './onchainHolders.ruleset';

export const onchainHoldersAsyncGetters = createAsyncGetters({
  getTopHolders: async (
    { memeId, count }: { memeId: string; count?: number },
    api,
  ) => {
    const holders = await api.sharedStates.onchainHolders.search({
      where: {
        memeId: {
          isAnyOf: [memeId],
        },
      },
      limit: count ?? MAX_DISPLAYED_TOP_HOLDERS,
    });

    const memeHolders = holders.results[0];
    if (!memeHolders) {
      return [];
    }

    return memeHolders.holders;
  },
});
