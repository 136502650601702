import { SB, renderTemplate, renderTemplates } from '@play-co/replicant';

export const chatbotMessageTemplates = renderTemplates({
  simpleNotification: renderTemplate({
    args: SB.object({
      text: SB.string(),
      buttons: SB.array(
        SB.object({
          text: SB.string(),
          url: SB.string(),
        }),
      ).optional(),
    }),
    renderers: {
      telegram: ({ args }) => {
        const { text, buttons } = args;
        return {
          text: text.replaceAll('\\n', '\n'),
          parse_mode: 'MarkdownV2',
          reply_markup: buttons && {
            inline_keyboard: buttons.map((button) => [
              {
                text: button.text,
                url: button.url,
              },
            ]),
          },
        };
      },
    },
  }),
});
