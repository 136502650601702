import {
  createSharedStateMessages,
  createSharedStateMessage,
  SB,
} from '@play-co/replicant';
import {
  holdersSchema,
  Holding,
  onchainHoldersSharedStateSchema,
} from './onchainHolders.schema';
import { HP } from '../../lib/HighPrecision';
import { MAX_TOP_HOLDERS } from './onchainHolders.ruleset';

// @warning: never remove/rename shared state messages
export const onchainHoldersMessages = createSharedStateMessages(
  onchainHoldersSharedStateSchema,
)({
  updateHolders: createSharedStateMessage(
    SB.object({
      holdingUpdates: holdersSchema,
    }),
    (
      state,
      {
        holdingUpdates,
      }: {
        holdingUpdates: {
          playerId: string;
          holdingAmount: string;
        }[];
      },
      meta,
    ) => {
      // Create a map for quick access to holdingUpdates by playerId
      const updatesMap = new Map(
        holdingUpdates.map(({ playerId, holdingAmount }) => [
          playerId,
          holdingAmount,
        ]),
      );

      const updatedNonZeroHolders: Holding[] = [];

      // Add new holders from holdingUpdates that were not in the current holders
      updatesMap.forEach((holdingAmount, playerId) => {
        if (HP(holdingAmount).gt(0)) {
          updatedNonZeroHolders.push({ playerId, holdingAmount });
        }
      });

      updatedNonZeroHolders.sort((a, b) =>
        HP(b.holdingAmount).minus(a.holdingAmount).toNumber(),
      );

      // Apply updates and create a new holders array
      const updatedHoldersList: Holding[] = [];
      let updatedIndex = 0;

      state.global.holders.forEach((holder) => {
        const holdingAmount = updatesMap.get(holder.playerId);
        if (holdingAmount !== undefined) {
          // player holdings updated, will be added at different position
          return;
        }

        // push updatedNonZeroHolders with holdings greater than holder
        while (
          updatedIndex < updatedNonZeroHolders.length &&
          HP(updatedNonZeroHolders[updatedIndex].holdingAmount).gt(
            HP(holder.holdingAmount),
          )
        ) {
          updatedHoldersList.push(updatedNonZeroHolders[updatedIndex]);
          updatedIndex++;
        }

        // player holdings unchanged
        updatedHoldersList.push(holder);
      });

      // Limit the size of the holders array
      state.global.holders = updatedHoldersList.slice(0, MAX_TOP_HOLDERS);
    },
  ),
});
