import {
  SB,
  createSharedStateComputedProperties,
  searchableComputedProperty,
  GetIndexSchema,
} from '@play-co/replicant';
import { chainSchema, onchainTxsSharedStateSchema } from './onchainTxs.schema';

export const onchainTxsComputedProperties = createSharedStateComputedProperties(
  onchainTxsSharedStateSchema,
)({
  memeId: searchableComputedProperty(SB.string(), (state) => {
    return state.global.memeId;
  }),
  chain: searchableComputedProperty(chainSchema, (state) => {
    return state.global.chain;
  }),
  // txCount: searchableComputedProperty(SB.int(), (state) => {
  //   return state.global.txHashes.length;
  // }),
});

export type TradingIndexSchema = GetIndexSchema<
  typeof onchainTxsComputedProperties
>;
