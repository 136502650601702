import './DrawerTradingWarning.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';

export const DrawerTradingWarning = () => {
  const { t } = useTranslation();

  const tradingWarning = app.ui.drawer.view.data?.props?.tradingWarning;

  // get error message
  const warningTitle = tradingWarning?.warningTitle;
  const warningMessage = tradingWarning?.warningMessage;
  const warningCta = tradingWarning?.warningCta;
  const warningIcon = tradingWarning?.icon;

  return (
    <div className="drawer-trading-warning">
      <div className="drawer-trading-warning-header">
        <img
          className="drawer-trading-warning-icon"
          src={warningIcon || assets.trading_transaction_error}
        />

        <div className="drawer-trading-warning-title">{warningTitle}</div>
        <div className="drawer-trading-warning-subtitle">{warningMessage}</div>
      </div>

      <div className="footer-buttons">
        <div
          className={`btn btn-normal`}
          onClick={() => {
            app.ui.confetti.hide();
            app.ui.drawer.close();
          }}
        >
          {warningCta}
        </div>
      </div>
    </div>
  );
};
