import { SB } from '@play-co/replicant';
import {
  createScheduledActions,
  extractScheduledActionHandlers,
} from '../../createScheduledActions';
import { sendBotNotification } from './chatbot.modifiers';
import { scheduleFriendJoinedMessage } from '../friends/friends.modifiers';

const schema = {
  sendBotNotification: SB.unknown(),
  sendFriendJoinedMessage: SB.object({
    chatId: SB.number(),
    friendName: SB.string(),
    bonus: SB.int(),
  }),
};

const actions = createScheduledActions(schema)({
  sendBotNotification: (state, _, api) => sendBotNotification(state, api),
  sendFriendJoinedMessage: (state, { friendName, bonus }, api) => {
    scheduleFriendJoinedMessage(state, api, friendName, bonus);
  },
});

export const chatbotScheduledActions = {
  schema,
  actions: extractScheduledActionHandlers(actions),
};
