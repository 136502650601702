import { TutorialModule } from '../TutorialModule';

/**
 * This step just call state update via id and moves to the next
 */
export class Grant100State extends TutorialModule {
  stateUpdateId = '100FreeGift';

  execute = async () => {
    this.app.ui.animateBalance(100);

    setTimeout(() => {
      this.onComplete();
      this.app.ui.confetti.hide();
    }, 500);
    return super.execute();
  };
}
