export const SEASON = 3;

// Define the League enum
export enum League {
  league1 = 'league1',
  league2 = 'league2',
  league3 = 'league3',
  league4 = 'league4',
  league5 = 'league5',
  league6 = 'league6',
  league7 = 'league7',
  league8 = 'league8',
  league9 = 'league9',
}

export type TapsToUnlock = number;
export type LeagueTuple = [TapsToUnlock, League];

export const seasonLeagues: Record<number, LeagueTuple[]> = {
  1: [
    [0, League.league1],
    [20_000, League.league2],
    [200_000, League.league3],
    [2_000_000, League.league4],
    [10_000_000, League.league5],
    [50_000_000, League.league6],
    [250_000_000, League.league7],
    [500_000_000, League.league8],
    [1_000_000_000, League.league9],
  ],
  2: [
    [0, League.league1],
    [2_000, League.league2],
    [5_000, League.league3],
    [25_000, League.league4],
    [100_000, League.league5],
    [1_000_000, League.league6],
    [50_000_000, League.league7],
    [1_000_000_000, League.league8],
    [1_000_000_000_000, League.league9],
  ],
};

export const SEASON_LEAGUE_REWARDS: Record<number, Record<League, number>> = {
  1: {
    league1: 500,
    league2: 1_250,
    league3: 6_250,
    league4: 25_000,
    league5: 250_000,
    league6: 2_500_000,
    league7: 6_250_000,
    league8: 12_500_000,
    league9: 25_000_000,
  },
};

export const tapsPerLeague: Record<League, number> = {
  [League.league1]: 1,
  [League.league2]: 2,
  [League.league3]: 4,
  [League.league4]: 8,
  [League.league5]: 16,
  [League.league6]: 32,
  [League.league7]: 64,
  [League.league8]: 128,
  [League.league9]: 256,
};

export const LEVEL_UP_BONUSES: Record<League, number> = {
  league1: 0,
  league2: 10_000,
  league3: 100_000,
  league4: 1_000_000,
  league5: 5_000_000,
  league6: 25_000_000,
  league7: 50_000_000,
  league8: 100_000_000,
  league9: 500_000_000,
};

export const getEnumValues = <T extends object>(enumObj: T): T[keyof T][] => {
  return Object.values(enumObj) as T[keyof T][];
};

export const getLeagueValues = () => getEnumValues(League);

export const LeagueToTitle: { [key: string]: string } = {
  league1: '1',
  league2: '2',
  league3: '3',
  league4: '4',
  league5: '5',
  league6: '6',
  league7: '7',
  league8: '8',
  league9: '9',
};
