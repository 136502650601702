import {
  AsyncGettersAPI,
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../../config';
import { ChainName } from './onchainTxs.schema';

export function getOnchainTxsStateId(
  memeId: string,
  chain: string,
  page: number,
) {
  return `onchain-txs-${memeId}-${chain}-${page}`;
}

export async function getOnchainTxsPageCount(
  api:
    | AsyncGettersAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
  memeId: string,
  chain: ChainName,
) {
  const pageCount = await api.sharedStates.onchainTxs.count({
    where: {
      memeId: {
        isAnyOf: [memeId],
      },
      chain: {
        isAnyOf: [chain],
      },
    },
  });

  return pageCount;
}
