import { app } from '../../../data/Controllers/AppController';
import { getUserStartingSeason } from '../../../replicant/features/game/game.getters';
import './CreatorImage.scss';

interface CreatorImageProps {
  size: number;
  image: string;
}
export const CreatorImage = ({ size, image }: CreatorImageProps) => {
  const startingSeason = getUserStartingSeason(app.state);
  const startingSeasonClass = `s${startingSeason}`;
  // console.warn('>>> startingSeasonClass', startingSeasonClass);

  return (
    <div
      className={`creator-image ${startingSeasonClass}`}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <img src={image} />
    </div>
  );
};
