import { ForwardedRef, forwardRef } from 'react';
import './TradingTokenPlaceholder.scss';
import { getUIStateClassName } from '../../../../data/utils';
import { ElementUIState } from '../../../../data/Controllers/UIController/UITypes';

export const TradingTokenPlaceholder = forwardRef<HTMLDivElement, {}>(
  ({}: {}, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div
        className={`btn trading-token-item ${getUIStateClassName(
          ElementUIState.Normal,
        )}`}
      >
        <div className="token-loading" ref={ref}>
          <div className="token-image-loading"></div>
          <div className="info-section">
            <div className="title-placeholder loading-line"></div>
            <div className="author-placeholder loading-line"></div>
            <div className="stats-placeholder">
              <div className="stat-item-placeholder loading-line"></div>
              <div className="stat-item-placeholder loading-line"></div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
