import { Sha256 } from '@aws-crypto/sha256-js';
import {
  Dictionary,
  beginCell,
  Cell,
  Builder,
  Slice,
  Address,
  storeMessage,
  Transaction,
} from '@ton/core';
import { sha256_sync } from '@ton/crypto';
import { ContractMetadata } from './types';
import { TonClient } from '@ton/ton';
import { waitFor } from '../utils';
import { Optional } from '../types';

export type JettonDeploy = {
  $$type: 'JettonDeploy';
  queryId: bigint;
  metadata: Cell;
  nonce: Cell;
  signature: Slice;
};
export function storeJettonDeploy(src: JettonDeploy) {
  return (builder: Builder) => {
    let b_0 = builder;
    b_0.storeUint(389861951, 32);
    b_0.storeUint(src.queryId, 64);
    b_0.storeRef(src.metadata);
    b_0.storeRef(src.nonce);
    b_0.storeRef(src.signature.asCell());
  };
}

export type AdminCallTransferJetton = {
  $$type: 'AdminCallTransferJetton';
  queryId: bigint;
  nonce: Cell;
  signature: Slice;
  metadata: Cell | null;
};
export function storeAdminCallTransferJetton(src: AdminCallTransferJetton) {
  return (builder: Builder) => {
    let b_0 = builder;
    b_0.storeUint(260734632, 32);
    b_0.storeUint(src.queryId, 64);
    b_0.storeRef(src.nonce);
    b_0.storeRef(src.signature.asCell());
    if (src.metadata !== null && src.metadata !== undefined) {
      b_0.storeBit(true).storeRef(src.metadata);
    } else {
      b_0.storeBit(false);
    }
  };
}

export type AdminCallSellJetton = {
  $$type: 'AdminCallSellJetton';
  queryId: bigint;
  nonce: Cell;
  signature: Slice;
};
export function storeAdminCallSellJetton(src: AdminCallSellJetton) {
  return (builder: Builder) => {
    let b_0 = builder;
    b_0.storeUint(260734633, 32);
    b_0.storeUint(src.queryId, 64);
    b_0.storeRef(src.nonce);
    b_0.storeRef(src.signature.asCell());
  };
}

export type Ping = {
  $$type: 'Ping';
  ping: string;
};
export function storePing(src: Ping) {
  return (builder: Builder) => {
    let b_0 = builder;
    b_0.storeUint(1267924010, 32);
    b_0.storeStringRefTail(src.ping);
  };
}

export const storeBuilders = {
  createJettonContract: storeJettonDeploy,
  buyToken: storeAdminCallTransferJetton,
  sellToken: storeAdminCallSellJetton,
  ping: storePing,
};

const ONCHAIN_CONTENT_PREFIX = 0x00;
const SNAKE_PREFIX = 0x00;
const CELL_MAX_SIZE_BYTES = Math.floor((1023 - 8) / 8);

const sha256 = (str: string) => {
  const sha = new Sha256();
  sha.update(str);
  return Buffer.from(sha.digestSync());
};

const toKey = (key: string) => {
  return BigInt(`0x${sha256(key).toString('hex')}`);
};

export function buildOnchainMetadata(data: ContractMetadata): Cell {
  console.log('buildOnchainMetadata', { data });
  let dict = Dictionary.empty(
    Dictionary.Keys.BigUint(256),
    Dictionary.Values.Cell(),
  );

  // Store the on-chain metadata in the dictionary
  Object.entries(data).forEach(([key, value]) => {
    dict.set(toKey(key), makeSnakeCell(Buffer.from(value, 'utf8')));
  });

  return beginCell()
    .storeInt(ONCHAIN_CONTENT_PREFIX, 8)
    .storeDict(dict)
    .endCell();
}

export function makeSnakeCell(data: Buffer) {
  // Create a cell that package the data
  let chunks = bufferToChunks(data, CELL_MAX_SIZE_BYTES);

  const b = chunks.reduceRight((curCell, chunk, index) => {
    if (index === 0) {
      curCell.storeInt(SNAKE_PREFIX, 8);
    }
    curCell.storeBuffer(chunk);
    if (index > 0) {
      const cell = curCell.endCell();
      return beginCell().storeRef(cell);
    } else {
      return curCell;
    }
  }, beginCell());
  return b.endCell();
}

function bufferToChunks(buff: Buffer, chunkSize: number) {
  let chunks: Buffer[] = [];
  while (buff.byteLength > 0) {
    chunks.push(buff.slice(0, chunkSize));
    buff = buff.slice(chunkSize);
  }
  return chunks;
}

export function stringToBigInt(input: string): bigint {
  // Hash the string using SHA-256
  const hash = sha256_sync(input);

  // Convert the hash to a bigint
  return BigInt('0x' + hash.toString('hex'));
}

interface WithRetryOpts {
  maxRetries: number;
  interval?: number;
}
export const withRetry = async <T>(
  promise: () => Promise<T>,
  opts: Optional<WithRetryOpts> = {
    maxRetries: 10,
    interval: 1000,
  },
  retry = 0,
): Promise<T> => {
  const { maxRetries, interval = 1000 } = opts;
  if (retry === maxRetries) {
    throw new Error('TIMEOUT');
  }
  try {
    console.log('withRetry', { promise, retry });
    const value = await promise();
    console.log('withRetry', { value, retry });
    if (value) {
      return value;
    }
    await waitFor(interval);
    return withRetry(promise, opts, ++retry);
  } catch (e) {
    throw e;
  }
};

function calculatePrice(
  currentSupply: bigint,
  initialPrice: bigint,
  maxTargetPrice: bigint,
  totalSupply: bigint,
): bigint {
  /**
   * Calculate the market price at a given current supply.
   *
   * @param currentSupply Current token supply.
   * @param initialPrice Initial price of the token (in nanoton).
   * @param maxTargetPrice Maximum target price of the token (in nanoton).
   * @param totalSupply Maximum total token supply.
   * @return Market price as an integer (in nanoton).
   */
  const price =
    ((maxTargetPrice - initialPrice) * currentSupply ** BigInt(2)) /
      totalSupply ** BigInt(2) +
    initialPrice;
  return price; // Integer in nanoton
}

// Estimate Token amount for Ton
export function calculateTokensReceived(
  currentSupply: bigint,
  tonPaid: bigint,
  initialPrice: bigint,
  maxTargetPrice: bigint,
  totalSupply: bigint,
): bigint {
  /**
   * Calculate the number of tokens received for a given TON paid.
   *
   * @param currentSupply Current token supply (S_start).
   * @param tonPaid TON the user will pay (in nanoton).
   * @param initialPrice Initial price of the token (in nanoton).
   * @param maxTargetPrice Maximum target price of the token (in nanoton).
   * @param totalSupply Maximum total token supply.
   * @return Number of tokens received.
   */
  let tokensToBuy = BigInt(0);
  let totalCost = BigInt(0);

  while (totalCost < tonPaid) {
    // Stop if current supply reaches total supply
    if (currentSupply >= totalSupply) {
      console.log('Reached maximum token supply.');
      break;
    }

    // Calculate the price of the next token
    const price = calculatePrice(
      currentSupply,
      initialPrice,
      maxTargetPrice,
      totalSupply,
    );
    if (totalCost + price > tonPaid) {
      break;
    }

    // Update totals
    totalCost += price;
    tokensToBuy += BigInt(1);
    currentSupply += BigInt(1);
  }

  return tokensToBuy;
}

type CurveConfig = {
  maxTargetPrice: bigint;
  initialPrice: bigint;
  maxSupply: bigint;
};
// Estimate Ton for Token amount
export function calculateTotalMintPrice(
  currentSupply: bigint,
  tokensToBuyOrSell: bigint,
  curveConfig: CurveConfig,
  isBuy: boolean,
): bigint {
  /**
   * Calculate the total mint price for buying or selling tokens based on a curve.
   *
   * @param currentSupply Current token supply.
   * @param tokensToBuyOrSell Number of tokens to buy or sell.
   * @param curveConfig Curve configuration (maxTargetPrice, initialPrice, maxSupply).
   * @param isBuy Boolean indicating if it is a buy (true) or sell (false) operation.
   * @return Total mint price as an integer (in nanoton).
   */
  const b: bigint = curveConfig.maxTargetPrice - curveConfig.initialPrice;
  const F: bigint = curveConfig.maxSupply ** BigInt(2);
  const precisionFactor: bigint = BigInt(1_000_000_000); // Large factor to maintain precision

  const power = BigInt(3);

  let price: bigint;

  if (isBuy) {
    const buyTerm =
      (((currentSupply + tokensToBuyOrSell) ** power - currentSupply ** power) *
        b *
        precisionFactor) /
      (power * F);
    price =
      curveConfig.initialPrice * tokensToBuyOrSell * precisionFactor + buyTerm;
  } else {
    const sellTerm =
      ((currentSupply ** power - (currentSupply - tokensToBuyOrSell) ** power) *
        b *
        precisionFactor) /
      (power * F);
    price =
      curveConfig.initialPrice * tokensToBuyOrSell * precisionFactor + sellTerm;
  }

  // Divide by the precision factor to scale down to intended integer range
  return price / precisionFactor;
}
