import { createActions as _createActions } from '@play-co/replicant';
import { asyncGetters } from './asyncGetters';
import { computedProperties } from './computedProperties';
import messages from './messages';
import { ruleset } from './ruleset';
import scheduledActions from './scheduledActions';
import stateSchema from './schema';
import { sharedStates } from './sharedStates';

export const createActions = _createActions(stateSchema, {
  messages,
  ruleset, // note: this includes abTests
  scheduledActions,
  computedProperties,
  asyncGetters,
  sharedStates,
});
