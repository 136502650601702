// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Ftue Meme Selection"

import { Value } from './ftueMemeSelection.types';

// prettier-ignore
const ftueMemeSelection: Value[] = [
  "offchainTrading-0021f9db-a410-4c63-a2d0-7e982eb4ba35",
  "offchainTrading-081079a8-6d5b-4366-b177-c8ad89d94399",
  "offchainTrading-095ea01b-4415-439f-9b15-baaf52d1dc86",
  "offchainTrading-0c844281-ba30-4ec7-b534-299f9696e7d8",
  "offchainTrading-196d54f9-d26e-483f-8a53-4b3c5f1690bb",
  "offchainTrading-1ad2d008-e851-4e77-b440-ca99783cbd52",
  "offchainTrading-1cc63559-156e-40c0-bbdd-139f97a90f94",
  "offchainTrading-21dfd146-ab3b-4257-9425-383979929666",
  "offchainTrading-23808be4-fc0d-4e2f-a824-65813a6f2cca",
  "offchainTrading-24c7e997-128e-46f7-ad4b-9969f67562b0",
  "offchainTrading-253d4c29-b84a-426b-baf7-709f8160a7b5",
  "offchainTrading-226ba145-6a00-4a7c-8342-779f6e4cf567",
  "offchainTrading-293f07f5-0271-4d6b-a45c-2d0f916a9b1c",
  "offchainTrading-2983c8f3-acb3-47f6-888c-09149ae3e73c",
  "offchainTrading-300708ee-0bd7-4778-8982-fe31d56f37bf",
  "offchainTrading-30e2bdec-ee39-426f-b121-e6a6506d951f",
  "offchainTrading-32ae1596-f239-423e-9000-bfbea6c1dc6f",
  "offchainTrading-36a9362b-3cf1-4014-b514-a7b3e2aa0c04",
  "offchainTrading-3651194b-3e91-471d-b8a0-c9481dcc1db0",
  "offchainTrading-39f5b250-b1d1-424e-a4b7-1d12db0bb6e1",
  "offchainTrading-3d881950-abb7-415e-971e-94efef34d58c",
  "offchainTrading-4020fc5c-c11c-4151-8a6b-c1e2660cca58",
  "offchainTrading-41dc27c1-1e49-43e8-bd80-466fc79d3535",
  "offchainTrading-4a1ea2d9-6c16-4f43-ab34-340db08f74e6",
  "offchainTrading-49de360d-edbd-430d-a7fe-6945eba21651",
  "offchainTrading-4a78c2ff-041b-4270-ab50-9f31d585e756",
  "offchainTrading-4a338127-1da1-4559-904a-9961f9308e92",
  "offchainTrading-5862d136-238f-4d66-ba22-6a0bf5a54d43",
  "offchainTrading-58b2e6b6-80f4-4f47-8a78-d9c1fd478daf",
  "offchainTrading-5cc50358-ecb6-4e29-bbdd-9a8b825a1ce2",
  "offchainTrading-5bfa577d-2600-4029-a707-25463cd2ce42",
  "offchainTrading-69491138-6773-4eea-8cf5-304c5272814a",
  "offchainTrading-6d3e905b-5cfc-4001-b796-df1591c13e19",
  "offchainTrading-6eefcd97-c1be-4cc3-bbb9-20088cd4ba13",
  "offchainTrading-7167b5a2-64b3-48cd-b718-e809f8faa401",
  "offchainTrading-731aa218-1dda-4f6e-b1d1-e73be53cbc91",
  "offchainTrading-754f2371-70b2-4d3e-a313-966113989eea",
  "offchainTrading-75a42e45-9b01-489b-b1dc-e4dd41f9d601",
  "offchainTrading-76a29dad-27ab-40be-9581-17edf87c7db8",
  "offchainTrading-7881c653-cb44-4041-8d78-6ddb5e6a3b0a",
  "offchainTrading-7a718028-ea17-407b-96cc-59e9723fe53b",
  "offchainTrading-7b018514-a59d-42cb-9097-1ad9110d13a8",
  "offchainTrading-8458cf82-c117-499c-a997-d43810a29c87",
  "offchainTrading-84b72d63-7997-41f9-80a1-f08e1fef228b",
  "offchainTrading-8d503a1e-9583-4bda-b672-4e79c313a5c2",
  "offchainTrading-914dc682-c460-4361-b939-43a984a55e99",
  "offchainTrading-97057c09-d4c0-40c1-8c6a-5a87be933745",
  "offchainTrading-971d4cd9-7401-4933-a586-c3cd45d00edc",
  "offchainTrading-98041a69-7c3c-496e-ab15-2fef04f1d636",
  "offchainTrading-9d1703ab-161e-4b29-9d29-704381dbd095",
  "offchainTrading-a16141a8-06a3-4297-816a-0418a1a7664d",
  "offchainTrading-a9427f63-4b49-454e-85d4-f360c3c4025f",
  "offchainTrading-a9c55f6e-ac5d-4fb2-b61c-c9a8eeb2ae9b",
  "offchainTrading-aca13090-cff4-4e6c-a975-20a044eba20d",
  "offchainTrading-acd7cec8-bbf2-490a-b193-e0055ab77fb5",
  "offchainTrading-b1ff952e-e5f8-4e75-b7ba-0e585fc49ab6",
  "offchainTrading-b7e28e00-21f5-484d-b3ba-dcd01a80c49b",
  "offchainTrading-b97ba548-ba68-4b49-948a-ff7e6362b9df",
  "offchainTrading-bcb8020d-f38b-4545-b593-59b002057c78",
  "offchainTrading-bd10d285-a9df-4f76-ae72-1bdf7e570589",
  "offchainTrading-c5f63f03-ed86-4391-a18e-7b66983929ce",
  "offchainTrading-d438cffc-49ea-40ab-afef-32646963e967",
  "offchainTrading-d6d6f5a5-838e-4fd5-866b-09ce7f527989",
  "offchainTrading-d953820c-d9fc-4580-a23a-18f58f7adf60",
  "offchainTrading-dc21162a-6f40-4ce4-bbc0-ebbce2d24c79",
  "offchainTrading-e725d16d-b9ba-41cc-8f6e-d583b7d63e34",
  "offchainTrading-eb9169e2-88ab-4c24-8eb6-0f070c02659a",
  "offchainTrading-ec7d36c0-05f2-4e12-a211-4e29d0a3fa1b",
  "offchainTrading-ee2968fe-e66d-42ab-8940-26b0288cf69f",
  "offchainTrading-f2e361fc-ee5a-4a8f-9191-b5d8e1254f5f",
  "offchainTrading-f377dcee-f6ee-4547-8a52-0e2beb86cec9",
  "offchainTrading-fbc8454e-7817-4485-be3d-a7fac8741819",
  "offchainTrading-fc6163ee-1281-4480-8579-2c38ac1353ea"
];
export default ftueMemeSelection;
