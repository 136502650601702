import { League } from '../replicant/features/game/ruleset/league';
import { PowerUpCardType } from '../replicant/features/powerups/types';

export type Fn<T> = (param?: any) => T;

export interface Group {
  chatId: string;
  name: string;
  creatorId: string;
  photo?: string;
  description?: string;
  inviteLink?: string;
}

export interface Game {
  multiplier: number;
  bonus: number;
  score: number;
}

export interface TeamPlayer {
  photo?: string;
  name: string;
  score: number;
  rank: number;
}

export interface Team {
  id: string;
  name: string;
  photo?: string;
  description: string;
  inviteLink?: string;
  players?: TeamPlayer[];
  members: number;
  score: number;
  rank: number;
  search?: string;
}

export interface TTeamPageShowOpts {
  eventProps?: Record<string, any>;
}

export enum EarnAchievementType {
  tutorialRocketQuest = 'first-quest',
  InviteBonus = 'invite-bonus',
  DailyReward = 'daily-reward',
  DailyContractCheckin = 'daily-contract-checkin',
}

export interface GamePlayer {
  multiplier: number;
  bonus: number;
  energy: number;
  lastEnergyRefresh: number;
}
export interface SessionData {
  player: GamePlayer;
  team?: Team;
}

export interface GameConfig {
  energyRefreshDelay: number;
}

export interface StartResponse {
  playerData: SessionData;
  playerScore?: {
    id: string;
    s: number;
  };
  startTime: number;
  config: GameConfig;
}

export interface SubmitTapResponse {
  playerData: GamePlayer;
  points: number;
}

export enum AppColor {
  Blue = 'blue',
  Purple = 'purple',
  Green = 'green',
}

export interface PlayerGame {
  // @todo: remove (POST SEASON 2 MIGRATION)
  // // energy
  // energy: number; // current energy
  // energyPerSecond: number; //
  // energyLimit: number;
  // // rocketman
  // rocketmanDuration?: number;
  // rocketmanMultiplier: number;
  // lastRocketmanStart: number;

  // pointsPerTap: number;
  // score: number;
  // league: League;

  balance: number;
  isFirstSession: boolean;
}

export interface GameResponse {
  player: PlayerGame;
  // team?: Team;
}

export interface StartSessionResponse extends GameResponse {
  startTime: number;
  // botEarnings: number;
  // inviteDrawerDuration: number;
  // powerUpBonus: number;
  // unclaimedReferralRewards: number;
}

export enum Booster {
  AutoTap = 'AutoTap',
  MultiTap = 'MultiTap',
  RechargeSpeed = 'RechargeSpeed',
  RechargeLimit = 'RechargeLimit',
}

export enum Buff {
  Rocketman = 'Rocketman',
  FullEnergy = 'FullEnergy',
  AutoBotReward = 'AutoBotReward', // Not an actual buff, just being lazy (modal that shows when user returns to the game with auto bot on)
  AutoBotFirstSession = 'AutoBotFirstSession', // Not an actual buff, just being lazy (modal that shows when user returns to the game with auto bot on)
}

export interface BoosterListing {
  price: number;
  level: number;
  maxedOut: boolean;
}

export interface ShopListing {
  buffs: {
    [Buff.Rocketman]: {
      available: number;
      maxUses: number;
      timeLeft: number;
    };
    [Buff.FullEnergy]: {
      timeLeft: number;
    };
  };
  boosters: Record<Booster, BoosterListing>;
}

export interface LeaguePlayer {
  rank: number;
  picture: string;
  username: string;
  score: number;
  league: string;
  isCurrentUser?: boolean;
}

export interface LeagueLeaderboard {
  ranked: LeaguePlayer[];
  outRank: LeaguePlayer;
  userRank?: number;
}

export type ModalComponentQueue = {
  id: string;
  condition: Fn<boolean>;
  onClose?: Fn<void>;
  data?: any;
  useConfettiEffect?: boolean;
};
export interface ModalComponent {
  queue: ModalComponentQueue[]; // id of chained popups
}

export interface GiftCardInitInfo {
  card: string;
  isNew: boolean;
  name: string;
  cardType: PowerUpCardType;
  /**
   * Original expired card before using a backup gift. If the same as `card`,
   * then there's no backup gift.
   */
  originalCard: string;
  discount?: number;
  sender?: string;
  level?: number;
}

export interface PromoCardInitInfo {
  card: string;
  origin: string;
}

export interface Gravity {
  from?: number;
  to?: number;
}

export type Optional<T> = T | undefined;

export interface WithProxy<T> {
  sendEvtProxy: (evt: T) => void;
}
