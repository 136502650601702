import React, { useCallback, useEffect, useState, useRef } from 'react';
import './DottedSlider.scss';
import { app } from '../../../data/Controllers/AppController';

interface Props {
  initialValue?: number;
  labels?: string[];
  onSliderChange: (value: number) => void;
}

export const DottedSlider: React.FC<Props> = ({
  initialValue = 0,
  labels = ['0', '25%', '50%', '75%', '100%'],
  onSliderChange,
}) => {
  const [value, setValue] = useState(initialValue);
  const [isDragging, setIsDragging] = useState(false);
  const trackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = useCallback(
    (newValue: number) => {
      const clampedValue = Math.max(0, Math.min(1, newValue));
      setValue(clampedValue);
      onSliderChange(clampedValue);
    },
    [onSliderChange],
  );

  const updateValue = useCallback(
    (clientX: number) => {
      if (trackRef.current) {
        const rect = trackRef.current.getBoundingClientRect();
        const x = Math.min(rect.width, Math.max(0, clientX - rect.left));
        const newValue = x / rect.width;
        handleChange(newValue);
      }
    },
    [handleChange],
  );

  const handleStart = useCallback(
    (clientX: number) => {
      setIsDragging(true);
      updateValue(clientX);
    },
    [updateValue],
  );

  const handleMove = useCallback(
    (clientX: number) => {
      if (isDragging) {
        updateValue(clientX);
      }
    },
    [isDragging, updateValue],
  );

  const handleEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      handleStart(event.clientX);
    },
    [handleStart],
  );

  const handleTouchStart = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      handleStart(event.touches[0].clientX);
    },
    [handleStart],
  );

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => handleMove(event.clientX);
    const handleTouchMove = (event: TouchEvent) =>
      handleMove(event.touches[0].clientX);

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleEnd);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleEnd);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [isDragging, handleMove, handleEnd]);

  const getHighestActiveDot = () => {
    const dotValues = [0.25, 0.5, 0.75];
    return Math.max(...dotValues.filter((dotValue) => value >= dotValue));
  };

  const shouldHide =
    app.state.balance <= 0 && app.memes.trading.tx?.txType === 'buy';

  const noCoinsClass = shouldHide ? 'invisible squashed' : '';

  return (
    <div className={`dotted-slider ${noCoinsClass}`}>
      <div className="dotted-slider-labels">
        {labels.map((label, index) => {
          if (index === 0 || index === labels.length - 1) {
            return (
              <span
                key={index}
                className={`dotted-slider-label ${
                  index === 0
                    ? 'left'
                    : index === labels.length - 1
                    ? 'right'
                    : ''
                }`}
                style={{ left: `${index * 25}%` }}
              >
                {label}
              </span>
            );
          }
        })}
      </div>
      <div
        className="dotted-slider-track"
        ref={trackRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <div
          className="dotted-slider-handler"
          style={{ left: `calc(${value * 98}% - 8px)` }}
        >
          <div className="dotted-slider-handler-inner-dot"></div>
        </div>

        <div
          className="dotted-slider-fill"
          style={{ width: `${value * 100}%` }}
        />
        {[0, 0.25, 0.5, 0.75, 1].map((dotValue, index) => (
          <div
            key={dotValue}
            className={`dotted-slider-dot ${
              dotValue === getHighestActiveDot() ? 'active' : ''
            } ${index === 0 || index === 4 ? 'end-dot' : ''}`}
            style={{ left: `${dotValue * 100}%` }}
          >
            <div className="dotted-slider-dot-inner" />
          </div>
        ))}
      </div>
    </div>
  );
};
