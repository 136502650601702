import './TiktokFooter.scss';
import { assets } from '../../../../assets/assets';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import { useTranslation } from 'react-i18next';
import { isAndroid, isIOS, isTelegramWebview } from '../../../../data/device';
import {
  isTiktokEnabled,
  isTiktokOnlyExperience,
} from '../../../../data/utils';
import { NavEvents } from '../../../../data/Controllers/NavController';
import { TMGEvents } from '../../../../data/Controllers/TokenMiniGames/TMGController';

interface TiktokFooterProps {}

export const TiktokFooter = ({}: TiktokFooterProps) => {
  const { t } = useTranslation();

  useAppUpdates({
    id: 'TiktokFooter/OnNavigate',
    listener: app.nav.attachEventListener(NavEvents.OnNavigate),
  });

  useAppUpdates({
    id: 'TiktokFooter/OnTappingPlayingUpdate',
    listener: app.tmg.attachEventListener(TMGEvents.OnTappingPlayingUpdate),
  });

  const route = app.nav.currentRoute;

  const targetRoutes = isTiktokOnlyExperience()
    ? [
        'TiktokPage',
        'TiktokSearchPage',
        'TiktokMemeDetailsPage',
        'ProfilePage',
        'Friends',
        'EarnPage',
      ]
    : [
        'TiktokPage',
        'TiktokSearchPage',
        'TiktokMemeDetailsPage',
        'ProfilePage',
      ];

  const canShow = () => {
    if (!isTiktokEnabled()) {
      return false;
    }
    const { currentRoute, prevRoute } = app.nav;
    if (targetRoutes.includes(currentRoute)) {
      return true;
    }
    if (currentRoute === 'Modal' && targetRoutes.includes(prevRoute)) {
      return true;
    }
    return false;
  };

  if (!canShow()) {
    return null;
  }

  const goToProfile = () => {
    app.nav.goToProfile();
  };

  // are we playing the tap game? if so, disable footer buttons
  const isTapGameEnabled = app.tmg.isTapping();

  return (
    <div className={`tiktok-footer`}>
      {/* footer buttons */}
      <div
        className={`tiktok-footer-buttons 
          ${isTelegramWebview() && isIOS() ? 'telegram-ios' : ''}
          ${isTelegramWebview() && isAndroid() ? 'telegram-android' : ''}
        `}
      >
        <div className={`tiktok-footer-buttons-centered-container`}>
          <TiktokFooterButton
            type="home"
            asset={
              route === 'TiktokPage'
                ? assets.tiktok_footer_home_active
                : assets.tiktok_footer_home_inactive
            }
            onClick={async () => {
              await app.nav.goToTiktokFeed(undefined, 'Hot');
            }}
            disabled={isTapGameEnabled}
          />
          {isTiktokOnlyExperience() && (
            <TiktokFooterButton
              type="friends"
              asset={
                route === 'Friends'
                  ? assets.tiktok_footer_friends_active
                  : assets.tiktok_footer_friends_inactive
              }
              onClick={() => app.nav.goTo('Friends')}
              disabled={isTapGameEnabled}
            />
          )}
          <TiktokFooterButton
            type="create"
            asset={assets.tiktok_footer_create}
            onClick={app.memes.factory.createNewToken}
            disabled={isTapGameEnabled}
          />
          {isTiktokOnlyExperience() && (
            <TiktokFooterButton
              type="earn"
              asset={
                route === 'EarnPage'
                  ? assets.tiktok_footer_earn_active
                  : assets.tiktok_footer_earn_inactive
              }
              onClick={() => app.nav.goTo('EarnPage')}
              disabled={isTapGameEnabled}
            />
          )}
          <TiktokFooterButton
            type="profile"
            asset={
              route === 'ProfilePage'
                ? assets.tiktok_footer_profile_active
                : assets.tiktok_footer_profile_inactive
            }
            onClick={goToProfile}
            disabled={isTapGameEnabled}
          />
        </div>
      </div>
    </div>
  );
};

// =================================================
// Tiktok Footer Button

type BtnType = 'home' | 'friends' | 'create' | 'earn' | 'profile';

export const TiktokFooterButton = ({
  type,
  asset,
  onClick,
  disabled,
}: {
  type: BtnType;
  asset: string;
  onClick: () => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();

  const caption = type === 'create' ? '' : t(`tiktok_footer_${type}`);

  return (
    <div
      className={`btn tiktok-footer-button ${type} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={onClick}
    >
      <img src={asset} className={isIOS() ? 'telegram-ios' : ''} />

      <div className="tiktok-footer-button-label">{caption}</div>
    </div>
  );
};

// =================================================
