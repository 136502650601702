import { createAsyncGetters } from '../../createAsyncGetters';

export const followingsAsyncGetters = createAsyncGetters({
  getFollowings: async (
    { followingsSubStateId }: { followingsSubStateId: string },
    api,
  ) => {
    const followingsSubStateRequest =
      api.sharedStates.followings.fetch(followingsSubStateId);

    const followingsSubState = await followingsSubStateRequest;
    if (!followingsSubState) {
      return [];
    }

    return followingsSubState.global.followings;
  },
  getFollowingProfiles: async (
    { followingsSubStateId }: { followingsSubStateId: string },
    api,
  ) => {
    const followingsSubStateRequest =
      api.sharedStates.followings.fetch(followingsSubStateId);

    const followingsSubState = await followingsSubStateRequest;
    if (!followingsSubState) {
      return [];
    }

    const limit = 100;
    const followingIds = followingsSubState.global.followings;
    const latestFollowingIds = followingIds.slice(
      followingIds.length - limit,
      followingIds.length,
    );

    const followings = await api.searchPlayers({
      where: { id: { isOneOf: latestFollowingIds } },
      limit,
    });

    return followings.results;
  },
  getFollowerProfiles: async (_, api) => {
    const limit = 100;

    // @todo: optimize this
    // this is very unefficient as the whole list of followings needs to be fetched for every user
    // just so that we can keep their id
    const followersFollowings = (
      await api.sharedStates.followings.search({
        where: {
          followings: {
            containsAnyOf: [api.getUserID()],
          },
        },
        fields: [],
        limit,
      })
    ).results;

    const followerIds = followersFollowings.map((followingsData) => {
      return followingsData.id;
    });

    const followers = await api.searchPlayers({
      where: { id: { isOneOf: followerIds } },
      limit,
    });

    return followers.results;
  },
});
