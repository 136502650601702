import './LoadingScreen.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { app } from '../../../data/Controllers/AppController';
import { assets } from '../../../assets/assets';

export const LoadingScreen = (opts: { visible: boolean }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const transition = document.querySelector('.loading-screen');
    transition?.addEventListener('transitionend', () => {
      if (opts.visible == false) setIsVisible(false);
    });
  }, [opts.visible]);

  const loadingRipple = (
    <>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <p className="loading-label">{t('loading')}</p>
    </>
  );

  if (isVisible == false) {
    return null;
  }

  return (
    <>
      <div className={`loading-screen ${opts.visible ? 'fadein' : 'fadeout'}`}>
        {loadingRipple}
      </div>
    </>
  );
};
