import { apiRequest, getFormDataParams } from '../data/api';

const BASE_URL = 'https://pmt-backend.fodaeh.net';

interface WithSecret {
  secret: string;
}

export const uploadFileWithSignedPost = async (
  opts: {
    file: File;
    path: string;
    filename?: string;
  } & WithSecret,
) => {
  const filename = opts.filename || opts.file.name;
  let signedPostData: any;
  console.log('uploadFileWithSignedPost', { filename });
  try {
    // @TODO: get url
    signedPostData = await apiRequest(`${BASE_URL}/gemz/upload/power_up`).post({
      filename,
      path: opts.path,
      auth: opts.secret,
    });
  } catch (e) {
    console.log(`Error (uploadFileWithSignedPost):`, e);
    throw new Error(`Error: Could not sign post for '${filename}'.`);
  }

  // console.log('uploadFileWithSignedPost', {signedPostData});
  try {
    const uploadResponse = await fetch(
      signedPostData.url,
      getFormDataParams(signedPostData.fields, { file: opts.file, filename }),
    );
    if (uploadResponse.status >= 200 && uploadResponse.status < 400) {
      console.log('success!');
    } else {
      throw new Error(uploadResponse.statusText);
    }

    return filename;
  } catch (e) {
    console.error(e);
  }
};

export const auth = async (secret: string) => {
  try {
    await apiRequest(`${BASE_URL}/gemz/auth`).post({
      auth: secret,
    });
  } catch (e) {
    console.log(`Error (auth):`, e);
    throw new Error(`Failed to Authenticate`);
  }
};
