import './TradingNotification.scss';
import { useTranslation } from 'react-i18next';
import { msToAgo } from '../../../../data/utils';

export interface TradingNotificationData {
  visible: boolean;
  info: {
    action: string;
    user: {
      image: string;
      name: string;
    };
    offchainToken: {
      name: string;
      time: number;
    };
  };
}

export const TradingNotification = ({
  visible,
  info,
  onClick,
}: TradingNotificationData & { onClick: () => void }) => {
  const { t } = useTranslation();
  const { user, offchainToken, action } = info;

  const anim =
    offchainToken.time === 0
      ? 'trading-notification-hidden'
      : visible
      ? 'trading-notification-fade-in'
      : 'trading-notification-fade-out';

  return (
    <div className={`trading-notification ${anim}`} onClick={onClick}>
      <div className="left">
        <div className="image">
          <img src={user.image} />
        </div>
        <div className="message">
          <div className="user-name">{user.name}</div>
          <div className="just-created">{action}</div>
          <div className="token-name">{offchainToken.name}</div>
        </div>
      </div>

      <div className="right">
        <div className="token-time">{msToAgo(offchainToken.time)}</div>
      </div>
    </div>
  );
};
