import { memePointsDisplayMultiplier } from '../features/tradingMeme/tradingMeme.ruleset';

export const floorToSignificantDigits = (num: number, digits: number) => {
  if (num === 0) return 0;

  const d = Math.ceil(Math.log10(num < 0 ? -num : num));
  const power = d - digits;
  const magnitude = Math.pow(10, power);
  if (power < 0) {
    return Math.floor(num / magnitude) * magnitude;
  }

  // @note: this is how to round to the 0.5 decimal
  // const shifted = Math.floor(num * magnitude * 2) / 2;
  const shifted = Math.floor(num / magnitude);
  return Math.round(shifted * magnitude);
};

export const formatPrice = (value: number | undefined) => {
  if (value === undefined) return '';
  return value.toLocaleString();
};

export function numberToShortString(num: number) {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  } else {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
}

const units: [number, string][] = [
  [1_000, 'K'],
  [1_000_000, 'M'],
  [1_000_000_000, 'B'],
  [1_000_000_000_000, 'T'],
  [1_000_000_000_000_000, 'Q'],
  [1_000_000_000_000_000_000, 'Qa'],
  [1_000_000_000_000_000_000_000, 'Sx'],
  [1_000_000_000_000_000_000_000_000, 'Sp'],
];

export const largeNumberToLetter = (
  value: number,
  significantDigits: number = 3,
): string => {
  const negative = value < 0;
  if (negative) {
    value = -value;
  }

  const significantValue = floorToSignificantDigits(value, significantDigits);
  if (significantValue === 0) {
    return '0';
  }

  let letter = '';
  let magnitude = 1;

  for (const [unitValue, unitLetter] of units) {
    if (significantValue < unitValue) {
      break;
    }
    letter = unitLetter;
    magnitude = unitValue;
  }

  const result = significantValue / magnitude;

  let formattedResult = result.toString();

  const isSmallNumber = value < 1;
  if (formattedResult.length > significantDigits + 1 || isSmallNumber) {
    // there might have been a rounding error
    const log = Math.log10(Math.abs(result));
    const wholeDigits = log > 0 ? Math.ceil(log) : Math.floor(log);
    const decimalPlaces = Math.max(0, significantDigits - wholeDigits);

    const resultWithDecimals = result.toFixed(decimalPlaces);
    if (resultWithDecimals.length < formattedResult.length || isSmallNumber) {
      formattedResult = resultWithDecimals;
    }
  }

  if (negative) {
    formattedResult = `-${formattedResult}`;
  }

  return `${formattedResult}${letter}`;
};

export const largeIntegerToLetter = (
  value: number,
  significantDigits?: number,
): string => {
  return largeNumberToLetter(Math.round(value), significantDigits);
};

export function roundDecimals(value: number, significantDigits: number = 3) {
  if (value === 0) {
    return '0';
  }

  const absValue = Math.abs(value);
  const exponent = Math.floor(Math.log10(absValue)) - significantDigits;

  const decimalPlaces = Math.max(0, -exponent);
  return value.toFixed(decimalPlaces);
}

export const displayPointAmount = (
  value: number,
  significantDigits: number = 3,
  shortDecimals: boolean = false,
  roundDecimalsOnly: boolean = false,
): string => {
  value *= memePointsDisplayMultiplier;

  // Convert to string with fixed number of significant digits
  const numStr = roundDecimalsOnly
    ? roundDecimals(value, significantDigits)
    : largeNumberToLetter(value, significantDigits);

  // Split into integer and decimal parts
  const [intPart, decPart] = numStr.split('.');

  // Add commas to integer part
  const formattedIntPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Trim ending zeroes from decimal part
  const trimmedDecPart = decPart ? decPart.replace(/0+$/, '') : '';

  if (!trimmedDecPart) {
    return formattedIntPart;
  }

  if (shortDecimals) {
    const isLetterNotation = Number.isNaN(
      parseInt(trimmedDecPart[trimmedDecPart.length - 1]),
    );

    // Combine integer and decimal parts, omitting decimal point if no decimals
    if (isLetterNotation) {
      return `${formattedIntPart}<span style="font-size: 0.9em; color: color-mix(in srgb, currentColor 60%, transparent);">.${trimmedDecPart.slice(
        0,
        trimmedDecPart.length - 1,
      )}</span>${trimmedDecPart[trimmedDecPart.length - 1]}`;
    }

    return `${formattedIntPart}<span style="font-size: 0.9em; color: color-mix(in srgb, currentColor 60%, transparent);">.${trimmedDecPart}</span>`;
  }

  return `${formattedIntPart}.${trimmedDecPart}`;
};

export const displayPrice = (
  price: number,
  significantDigits?: number,
): string => {
  return largeIntegerToLetter(
    price / memePointsDisplayMultiplier,
    significantDigits,
  );
};

export function clampScore(score: number) {
  return Math.max(Math.min(Math.round(score), Number.MAX_SAFE_INTEGER), 0);
}

export function ceiling(num: number, significance: number) {
  return Math.ceil(num / significance) * significance;
}

export function getPrettyDecimalsUpTo(num: number, decimals: number) {
  const pretty = num.toFixed(decimals);
  let zeros = 0;
  for (let i = pretty.length - 1; i >= 0; i--) {
    if (pretty[i] !== '0') {
      break;
    }
    zeros++;
  }
  return pretty.substring(0, pretty.length - zeros);
}

export const prettyDecimals = {
  strict4: (num: number) => num.toFixed(4),
  strict8: (num: number) => num.toFixed(8),
  upTo4: (num: number) => getPrettyDecimalsUpTo(num, 4),
  upTo8: (num: number) => getPrettyDecimalsUpTo(num, 8),
};

export function getRoi(investment: number, valuation: number) {
  if (Math.abs(investment) < 1e-7) {
    return 0;
  }

  return (100 * valuation) / investment - 100;
}

export function booleanRoll(oddsOfTrue: number) {
  return Math.random() < oddsOfTrue;
}
