import { useEffect, useState } from 'react';
import { assets } from '../../../../assets/assets';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import {
  TradingNotification,
  TradingNotificationData,
} from '../TradingNotification/TradingNotification';
import './TradingHeader.scss';
import { t } from 'i18next';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';
import { MIN_IN_MS } from '../../../../replicant/utils/time';
import { resolveProfilePicture } from '../../../../replicant/features/game/game.getters';
import { TradingProfileUser } from '../TradingProfileUser/TradingProfileUser';

const tradingTutorialEnabled = true;

const defaultNotifData: TradingNotificationData = {
  visible: false,
  info: {
    action: 'created',
    user: {
      image: assets.default_profile,
      name: '',
    },
    offchainToken: {
      name: '',
      time: 0,
    },
  },
};

export const TradingHeader = () => {
  const { visible } = app.views.TradingPage;

  useAppUpdates({
    id: 'TradingHeader/MemesEvents.TradingOnTxUpdate',
    listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
  });

  const [notification, setNotification] =
    useState<TradingNotificationData>(defaultNotifData);

  const onTapCreateNew = () => {
    app.memes.factory.createNewToken();
  };

  const onTapShowNotification = () => {
    if (app.memes.trading.notification) {
      setNotification(defaultNotifData);

      // navigate to tiktok feed instead of token details
      // app.nav.goToTradingToken(app.trading.notification.id);
      app.nav.goToTiktokFeed(
        app.memes.trading.notification.id,
        undefined,
        'update',
      );
    }
  };

  useEffect(() => {
    app.memes.trading.setNotificationActivity(visible);
  }, [visible]);

  useEffect(() => {
    const notifSearch = app.memes.trading.notification;
    if (notifSearch) {
      // hide notification to animate in the new one
      setNotification({
        ...notification,
        visible: false,
      });

      // check what is newer, the offchainToken creation or the last transaction
      const isOffchainTokenCreation =
        notifSearch.availableAt + MIN_IN_MS >
        (notifSearch.lastTx?.createdAt ?? 0);

      const actorPhoto = isOffchainTokenCreation
        ? resolveProfilePicture(
            notifSearch.profile.creatorId,
            notifSearch.profile.creatorImage,
          )
        : resolveProfilePicture(
            notifSearch.lastTx.userId,
            notifSearch.lastTx.userImage,
          );

      const actorName = isOffchainTokenCreation
        ? notifSearch.profile.creatorName
        : notifSearch.lastTx.userName;

      const actionTime = isOffchainTokenCreation
        ? notifSearch.availableAt
        : notifSearch.lastTx?.createdAt ?? 0;

      // wait a small amount of time in order to allow for the transition out to complete
      const waitABit = new Promise((resolve) => setTimeout(resolve, 250));
      waitABit.then(() => {
        setNotification({
          visible: true,
          info: {
            action: isOffchainTokenCreation
              ? 'created'
              : notifSearch.lastTx?.txType === 'buy'
              ? 'purchased'
              : 'sold',
            user: {
              image: actorPhoto || assets.default_profile,
              name: actorName || 'Someone',
            },
            offchainToken: {
              name: notifSearch.profile.name,
              time: app.now() - actionTime,
            },
          },
        });
      });
    }
  }, [app.memes.trading.notification]);

  return (
    <div className="modal-header trading">
      <div className="trading-header-bg" />

      <div className="trading-header-row-container title">
        <div className="trading-header-title">{t('trading_banner_title')}</div>
      </div>

      <div className="trading-header-row-container">
        <TradingProfileUser />
        <div
          className="btn btn-normal trading-header-button-new"
          onClick={onTapCreateNew}
        >
          {t('trading_header_button_new')}
        </div>
      </div>

      <TradingNotification
        visible={notification.visible}
        info={notification.info}
        onClick={onTapShowNotification}
      />
    </div>
  );
};
