import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../../assets/assets';
import {
  useAppUpdates,
  app,
} from '../../../../../data/Controllers/AppController';
import { MemesEvents } from '../../../../../data/Controllers/Memes/MemesController';
import { memePointsDisplayMultiplier } from '../../../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import {
  TxType,
  CurrencyType,
} from '../../../../../replicant/features/tradingMeme/types';
import { HP, HighPrecision } from '../../../../../replicant/lib/HighPrecision';
import {
  largeIntegerToLetter,
  roundDecimals,
} from '../../../../../replicant/utils/numbers';
import { DottedSlider } from '../../../../shared/DottedSlider/DottedSlider';
import { TradingInputNumeric } from '../../../TradingPage/TradingInputNumeric/TradingInputNumeric';
import { getCurrencyIcon, getCurrencyName } from '../helpers';
import { TradingTokenFooterButton } from './TTFBuySellBtn';
import { TradingTokenFooterCreateButton } from './TTFCreateBtn';
import { TradingTokenFooterFeeMessage } from './TTFFeeMessage';

interface PropsFooterForm {
  footerMode: 'normal' | 'create';
  txType: TxType;
  currencyType: CurrencyType;
}

export const TradingTokenFooterForm = ({
  footerMode,
  txType,
  currencyType,
}: PropsFooterForm) => {
  useAppUpdates({
    id: 'OffchainTokenFooterForm',
    listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
  });

  const { t } = useTranslation();
  const [sliderValue, setSliderValue] = useState(0);

  const [balance, setBalance] = useState('0');

  const { trading, currentMeme } = app.memes;

  useEffect(() => {
    if (currentMeme.meme?.id) {
      app.memes.trading.getBalance(currentMeme.meme.id).then((balance) => {
        setBalance(balance.toString());
      });
    }
  }, [currentMeme.meme?.id, trading.txCurrency]);

  // const points = Number(app.tmg.points);

  const points = app.ton.balance;

  const pointAmount = currentMeme.myTokenState?.pointAmount ?? 0;

  const maxPointAmount = pointAmount ? HP(pointAmount).toNumber() : 0;

  const useCoins = currencyType === 'points';
  const maxAmount = Number(balance);

  const setSpendAmount = (spendAmount: HighPrecision) => {
    const percentage =
      maxAmount > 0 ? spendAmount.div(maxAmount).toNumber() : 0;

    setSliderValue(Math.max(0, Math.min(percentage, 1)));
  };

  useEffect(() => {
    const amount = trading.tx?.send ?? HP(0);
    setSpendAmount(amount);
  }, [
    trading.tx?.send,
    trading.isBuy,
    app.state.balance,
    points,
    pointAmount,
    txType,
  ]);

  console.log('TradingTokenFooter', { tx: app.memes.trading.tx });

  if (!app.memes.trading.tx) {
    return null;
  }

  // const balance = useCoins ? app.state.balance : points;
  const prettyBalance = largeIntegerToLetter(HP(balance).toNumber());

  const onSliderChange = (percentage: number) => {
    setSliderValue(percentage);

    if (app.memes.trading.isBuy) {
      const maxAmount = useCoins ? app.state.balance : HP(points).toNumber();

      app.memes.trading.updateAmount(HP(Math.round(maxAmount * percentage)));
      return;
    }

    // selling
    const token = app.memes.currentMeme.myTokenState;
    if (!token) {
      app.memes.trading.updateAmount(HP(0));
      return;
    }

    if (percentage >= 1.0) {
      app.memes.trading.updateAmount(HP(token.pointAmount));
      return;
    }

    const maxPointAmount = HP(token.pointAmount).toNumber();
    const amount = HP(roundDecimals(maxPointAmount * percentage));
    const max = HP(token.pointAmount);

    if (amount.gt(max)) {
      app.memes.trading.updateAmount(max);
    } else {
      app.memes.trading.updateAmount(amount);
    }
  };

  // execute buy/sell -> close current drawer, refresh token and display condirmation drawer
  const onTapPurchase = () => {
    app.ui.drawer.close();
    app.memes.trading.goToTxConfirmation();
  };

  const isBuy = trading.tx?.txType === 'buy';
  const spendAmount = trading.tx?.send.toNumber() ?? 0;
  const noCoinsClass = app.state.balance <= 0 ? 'invisible squashed' : '';

  // const tickerName = currentMeme.token?.ticker ?? '#';

  const onInputUpdate = (value: number) => {
    console.log('onInputUpdate', { value });

    if (trading.tx?.txType === 'buy') {
      trading.updateAmount(HP(value));
    } else {
      const amount = Math.round(value / memePointsDisplayMultiplier);
      trading.updateAmount(HP(value));
    }

    // when changin input value, we must update slider bar too
    setSpendAmount(HP(value));
  };

  const isNumericInput = currencyType === 'points';
  // ==============================================================================
  // #region Form Elms

  return (
    <div className="trading-token-footer-form-area">
      {/* header */}
      <div className="buysell-header">
        {/* balance + slippage */}
        <div className="buysell-header-row">
          <div className="buysell-header-balance">
            <div className="buysell-header-balance-label">
              {t('buysell_available_balance')}
            </div>
            <div className="buysell-header-balance-value">{prettyBalance}</div>
            <div className="buysell-header-balance-icon">
              <img src={getCurrencyIcon(currencyType, txType)} />
            </div>
          </div>
          <div className="buysell-header-slippage">{'Set max slippage'}</div>
        </div>

        {/* ticker or coin icon */}
        <div className={`trading-token-footer-form ${noCoinsClass}`}>
          {/* numeric input */}
          <TradingInputNumeric
            type={isNumericInput ? 'numeric' : 'decimal'}
            value={
              isBuy ? spendAmount : spendAmount // * memePointsDisplayMultiplier
            }
            maxValue={
              isBuy ? maxAmount : maxAmount // * memePointsDisplayMultiplier
            }
            icon={assets.button_x}
            pushContentUp={280}
            onAmountChange={onInputUpdate}
            integerOnly={txType === 'sell' || currencyType === 'points'}
            // onInputFocus={(focused: boolean) => setSelectedAmount(0)}
          />

          {/* ton | points */}
          <div className="currency-type">
            <div className="currency-type-label">
              {getCurrencyName(currencyType, txType)}
            </div>
            <div className="currency-type-icon">
              <img src={getCurrencyIcon(currencyType, txType)} />
            </div>
          </div>
        </div>
      </div>

      <DottedSlider
        initialValue={sliderValue}
        labels={['0%', '25%', '50%', '75%', '100%']}
        onSliderChange={onSliderChange}
      />

      <div className={`trading-token-footer-fees centered ${noCoinsClass}`}>
        <TradingTokenFooterFeeMessage currencyType={currencyType} />
      </div>

      {footerMode === 'normal' ? (
        <TradingTokenFooterButton
          footerMode={footerMode}
          txType={txType}
          currencyType={currencyType}
          onClick={onTapPurchase}
        />
      ) : (
        <TradingTokenFooterCreateButton
          noCoinsClass={noCoinsClass && 'no-coins'}
        />
      )}
    </div>
  );
};
