// DO NOT EDIT MANUALLY! This file is generated from Airtable: "PowerUp-Conditions"

import { Value } from './powerUpConditions.types';

// prettier-ignore
const powerUpConditions: Value[] = [
  {
    "description": "pu_gear_gas_mask_lvl4",
    "id": "pu_gear_gas_mask_lvl4",
    "key": "requireLevel",
    "targetLevel": 4,
    "targetPowerUp": "pu_gear_gas_mask"
  },
  {
    "description": "pu_companions_intern_lvl5",
    "id": "pu_companions_intern_lvl5",
    "key": "requireLevel",
    "targetLevel": 5,
    "targetPowerUp": "pu_companions_intern"
  },
  {
    "description": "pu_gear_o2_tank_lvl1",
    "id": "pu_gear_o2_tank_lvl1",
    "key": "requirePowerUp",
    "targetPowerUp": "pu_gear_o2_tank"
  },
  {
    "description": "pu_gear_backup_inv3",
    "id": "pu_gear_backup_inv3",
    "key": "requireFriendInvite",
    "targetInvites": 3
  },
  {
    "description": "pu_services_rig_menders_lvl5",
    "id": "pu_services_rig_menders_lvl5",
    "key": "requireLevel",
    "targetLevel": 5,
    "targetPowerUp": "pu_services_rig_menders"
  },
  {
    "description": "pu_companions_wired_pm_lvl1",
    "id": "pu_companions_wired_pm_lvl1",
    "key": "requirePowerUp",
    "targetPowerUp": "pu_companions_wired_pm"
  },
  {
    "description": "pu_companions_reclusive_backend_dev_inv5",
    "id": "pu_companions_reclusive_backend_dev_inv5",
    "key": "requireFriendInvite",
    "targetInvites": 5
  },
  {
    "description": "pu_gear_water_bottle_lvl8",
    "id": "pu_gear_water_bottle_lvl8",
    "key": "requireLevel",
    "targetLevel": 8,
    "targetPowerUp": "pu_gear_water_bottle"
  },
  {
    "description": "pu_gear_first_aid_kit_lvl7",
    "id": "pu_gear_first_aid_kit_lvl7",
    "key": "requireLevel",
    "targetLevel": 7,
    "targetPowerUp": "pu_gear_first_aid_kit"
  },
  {
    "description": "pu_companions_wired_pm_lvl10",
    "id": "pu_companions_wired_pm_lvl10",
    "key": "requireLevel",
    "targetLevel": 10,
    "targetPowerUp": "pu_companions_wired_pm"
  },
  {
    "description": "pu_companions_awkward_ceo_inv10",
    "id": "pu_companions_awkward_ceo_inv10",
    "key": "requireFriendInvite",
    "targetInvites": 10
  },
  {
    "description": "pu_services_hash_hounds_inv7",
    "id": "pu_services_hash_hounds_inv7",
    "key": "requireFriendInvite",
    "targetInvites": 7
  },
  {
    "description": "pu_gear_pickaxe_lvl14",
    "id": "pu_gear_pickaxe_lvl14",
    "key": "requireLevel",
    "targetLevel": 14,
    "targetPowerUp": "pu_gear_pickaxe"
  },
  {
    "description": "pu_gear_hand_radio_lvl15",
    "id": "pu_gear_hand_radio_lvl15",
    "key": "requireLevel",
    "targetLevel": 15,
    "targetPowerUp": "pu_gear_hand_radio"
  },
  {
    "description": "pu_services_token_tutors_inv2",
    "id": "pu_services_token_tutors_inv2",
    "key": "requireFriendInvite",
    "targetInvites": 2
  },
  {
    "description": "pu_companions_early_adopter_sibling_lvl5",
    "id": "pu_companions_early_adopter_sibling_lvl5",
    "key": "requireLevel",
    "targetLevel": 5,
    "targetPowerUp": "pu_companions_early_adopter_sibling"
  },
  {
    "description": "pu_specials_bitcoin_etf_inv5",
    "id": "pu_specials_bitcoin_etf_inv5",
    "key": "requireFriendInvite",
    "targetInvites": 5
  },
  {
    "description": "pu_services_rig_rentals_lvl20",
    "id": "pu_services_rig_rentals_lvl20",
    "key": "requireLevel",
    "targetLevel": 20,
    "targetPowerUp": "pu_services_rig_rentals"
  },
  {
    "description": "pu_gear_tool_bag_lvl9",
    "id": "pu_gear_tool_bag_lvl9",
    "key": "requireLevel",
    "targetLevel": 9,
    "targetPowerUp": "pu_gear_tool_bag"
  },
  {
    "description": "pu_gear_pickaxe_inv1",
    "id": "pu_gear_pickaxe_inv1",
    "key": "requireFriendInvite",
    "targetInvites": 1
  },
  {
    "description": "pu_services_hash_hackers_lvl8",
    "id": "pu_services_hash_hackers_lvl8",
    "key": "requireLevel",
    "targetLevel": 8,
    "targetPowerUp": "pu_services_hash_hackers"
  },
  {
    "description": "pu_companions_techbro_lvl4",
    "id": "pu_companions_techbro_lvl4",
    "key": "requireLevel",
    "targetLevel": 4,
    "targetPowerUp": "pu_companions_techbro"
  },
  {
    "description": "pu_services_rig_menders_lvl1",
    "id": "pu_services_rig_menders_lvl1",
    "key": "requirePowerUp",
    "targetPowerUp": "pu_services_rig_menders"
  },
  {
    "description": "pu_gear_thermal_paste_lvl6",
    "id": "pu_gear_thermal_paste_lvl6",
    "key": "requireLevel",
    "targetLevel": 6,
    "targetPowerUp": "pu_gear_thermal_paste"
  },
  {
    "description": "pu_special_airdrop_gift",
    "id": "pu_special_airdrop_gift",
    "key": "requireGift",
    "targetPowerUp": "pu_specials_airdrop"
  },
  {
    "description": "pu_specials_gemz_on_x_quest",
    "id": "pu_specials_gemz_on_x_quest",
    "key": "requireEarnings",
    "targetEarnings": "followOnX",
    "targetPowerUp": "pu_specials_gemz_on_x"
  },
  {
    "description": "pu_specials_dao_friends_description",
    "id": "pu_specials_dao_friends",
    "key": "requireMoreFriendsFromLast",
    "targetInvites": 1
  },
  {
    "description": "pu_specials_friendship_chain_gift",
    "id": "pu_specials_friendship_chain_gift",
    "key": "requireGift",
    "targetInvites": 1,
    "targetPowerUp": "pu_specials_friendship_chain"
  },
  {
    "description": "pu_specials_socialfi_gift_description",
    "id": "pu_specials_socialfi_gift",
    "key": "requireGift",
    "targetPowerUp": "pu_specials_socialfi"
  },
  {
    "description": "pu_specials_ETH_vs_SEC_friends",
    "id": "pu_specials_ETH_vs_SEC_friends",
    "key": "requireMoreFriendsFromLast",
    "targetInvites": 2,
    "targetPowerUp": "pu_specials_ETH_vs_SEC"
  },
  {
    "description": "pu_specials_stablecoins_gift",
    "id": "pu_specials_stablecoins_gift",
    "key": "requireGift",
    "targetPowerUp": "pu_specials_stablecoins"
  },
  {
    "description": "pu_specials_memecoins_friends",
    "id": "pu_specials_memecoins_friends",
    "key": "requireMoreFriendsFromLast",
    "targetInvites": 1,
    "targetPowerUp": "pu_specials_memecoins"
  },
  {
    "description": "pu_specials_bull_market_gift",
    "id": "pu_specials_bull_market_gift",
    "key": "requireGift",
    "targetPowerUp": "pu_specials_bull_market"
  },
  {
    "description": "pu_companions_require_worker",
    "id": "pu_companions_require_worker",
    "key": "requireWorker"
  },
  {
    "description": "pu_specials_ethereum_merge_15friends",
    "id": "pu_specials_ethereum_merge_15friends",
    "key": "requireFriendInvite",
    "targetInvites": 15
  },
  {
    "description": "pu_specials_ethereum_merge_lvl1",
    "id": "pu_specials_ethereum_merge_lvl1",
    "key": "requireLevel",
    "targetLevel": 1,
    "targetPowerUp": "pu_specials_ethereum_merge"
  },
  {
    "description": "pu_specials_layer2s_1friend",
    "id": "pu_specials_layer2s_1friend",
    "key": "requireMoreFriendsFromLast",
    "targetInvites": 1
  },
  {
    "description": "pu_specials_gaming_NFTs_gift",
    "id": "pu_specials_gaming_NFTs_gift",
    "key": "requireGift",
    "targetPowerUp": "pu_specials_gaming_NFTs"
  },
  {
    "description": "pu_specials_derivatives_8friends",
    "id": "pu_specials_derivatives_8friends",
    "key": "requireFriendInvite",
    "targetInvites": 8,
    "targetPowerUp": "pu_specials_derivatives"
  },
  {
    "description": "pu_specials_poaps_2friends",
    "id": "pu_specials_poaps_2friends",
    "key": "requireMoreFriendsFromLast",
    "targetInvites": 2,
    "targetPowerUp": "pu_specials_poaps"
  },
  {
    "description": "pu_specials_metaverse_gift",
    "id": "pu_specials_metaverse_gift",
    "key": "requireGift",
    "targetPowerUp": "pu_specials_metaverse"
  },
  {
    "description": "pu_specials_satoshis_silence_gift",
    "id": "pu_specials_satoshis_silence_gift",
    "key": "requireGift",
    "targetPowerUp": "pu_specials_satoshis_silence"
  },
  {
    "description": "pu_specials_bitcoin_etf_lvl5",
    "id": "pu_specials_bitcoin_etf_lvl5",
    "key": "requireLevel",
    "targetLevel": 5,
    "targetPowerUp": "pu_specials_bitcoin_etf"
  },
  {
    "description": "pu_services_token_tutors_lvl 3",
    "id": "pu_services_token_tutors_lvl 3",
    "key": "requireLevel",
    "targetLevel": 3,
    "targetPowerUp": "pu_services_token_tutors"
  },
  {
    "description": "pu_companions_nft_influencer_lvl4",
    "id": "pu_companions_nft_influencer_lvl4",
    "key": "requireLevel",
    "targetLevel": 4,
    "targetPowerUp": "pu_companions_nft_influencer"
  },
  {
    "description": "pu_services_crypto_cloud_boost_lvl1",
    "id": "pu_services_crypto_cloud_boost_lvl1",
    "key": "requireLevel",
    "targetLevel": 1,
    "targetPowerUp": "pu_services_crypto_cloud_boost"
  },
  {
    "description": "pu_gear_satellite_modem_lvl2",
    "id": "pu_gear_satellite_modem_lvl2",
    "key": "requireLevel",
    "targetLevel": 2,
    "targetPowerUp": "pu_gear_satellite_modem"
  },
  {
    "description": "pu_gear_water_cooled_pc_lvl10",
    "id": "pu_gear_water_cooled_pc_lvl10",
    "key": "requireLevel",
    "targetLevel": 10,
    "targetPowerUp": "pu_gear_water_cooled_pc"
  },
  {
    "description": "pu_services_crypto_compliance_check_1friend",
    "id": "pu_services_crypto_compliance_check_1friend",
    "key": "requireMoreFriendsFromLast",
    "targetInvites": 1,
    "targetPowerUp": "pu_services_crypto_compliance_check"
  },
  {
    "description": "pu_services_crypto_cloud_boost_1friend",
    "id": "pu_services_crypto_cloud_boost_1friend",
    "key": "requireMoreFriendsFromLast",
    "targetInvites": 1,
    "targetPowerUp": "pu_services_crypto_cloud_boost"
  },
  {
    "description": "pu_gear_ups_backup_lvl4",
    "id": "pu_gear_ups_backup_lvl4",
    "key": "requireLevel",
    "targetLevel": 4,
    "targetPowerUp": "pu_gear_ups_backup"
  },
  {
    "description": "pu_specials_phygitals_gift",
    "id": "pu_specials_phygitals_gift",
    "key": "requireGift",
    "targetPowerUp": "pu_specials_phygitals"
  },
  {
    "description": "pu_specials_lambo_gift",
    "id": "pu_specials_lambo_gift",
    "key": "requireGift",
    "targetPowerUp": "pu_specials_lambo"
  },
  {
    "description": "pu_specials_bear_market_blues_gift",
    "id": "pu_specials_bear_market_blues_gift",
    "key": "requireGift",
    "targetPowerUp": "pu_specials_bear_market_blues"
  },
  {
    "description": "pu_gear_thermal_paste_lvl10",
    "id": "pu_gear_thermal_paste_lvl10",
    "key": "requireLevel",
    "targetLevel": 10,
    "targetPowerUp": "pu_gear_thermal_paste"
  },
  {
    "description": "pu_specials_denver_eth_conference_lvl1",
    "id": "pu_specials_denver_eth_conference_lvl1",
    "key": "requireLevel",
    "targetLevel": 1,
    "targetPowerUp": "pu_specials_denver_eth_conference"
  },
  {
    "description": "pu_gear_hand_radio_lvl8",
    "id": "pu_gear_hand_radio_lvl8",
    "key": "requireLevel",
    "targetLevel": 8,
    "targetPowerUp": "pu_gear_hand_radio"
  },
  {
    "description": "pu_gear_cold_weather_gear_1morefriend",
    "id": "pu_gear_cold_weather_gear_1morefriend",
    "key": "requireMoreFriendsFromLast",
    "targetInvites": 1,
    "targetPowerUp": "pu_gear_cold_weather_gear"
  },
  {
    "description": "pu_companions_tech_journalist_lvl10",
    "id": "pu_companions_tech_journalist_lvl10",
    "key": "requireLevel",
    "targetLevel": 10,
    "targetPowerUp": "pu_companions_tech_journalist"
  },
  {
    "description": "pu_gear_blockchain_blaster_inv8",
    "id": "pu_gear_blockchain_blaster_inv8",
    "key": "requireFriendInvite",
    "targetInvites": 8,
    "targetPowerUp": "pu_gear_blockchain_blaster"
  },
  {
    "description": "pu_gear_nano_node_network_lvl4",
    "id": "pu_gear_nano_node_network_lvl4",
    "key": "requireLevel",
    "targetLevel": 4,
    "targetPowerUp": "pu_gear_nano_node_network"
  },
  {
    "description": "pu_gear_quantum_quota_1more",
    "id": "pu_gear_quantum_quota_1more",
    "key": "requireMoreFriendsFromLast",
    "targetInvites": 1,
    "targetPowerUp": "pu_gear_quantum_quota"
  },
  {
    "description": "pu_services_hash_haven_lvl5",
    "id": "pu_services_hash_haven_lvl5",
    "key": "requireLevel",
    "targetLevel": 5,
    "targetPowerUp": "pu_services_hash_haven"
  },
  {
    "description": "pu_gear_ups_backup_lvl10",
    "id": "pu_gear_ups_backup_lvl10",
    "key": "requireLevel",
    "targetLevel": 10,
    "targetPowerUp": "pu_gear_ups_backup"
  },
  {
    "description": "pu_specials_bitcoin_etf_lvl9",
    "id": "pu_specials_bitcoin_etf_lvl9",
    "key": "requireLevel",
    "targetLevel": 9,
    "targetPowerUp": "pu_specials_bitcoin_etf"
  },
  {
    "description": "pu_gear_quantum_quota_lvl6",
    "id": "pu_gear_quantum_quota_lvl6",
    "key": "requireLevel",
    "targetLevel": 6,
    "targetPowerUp": "pu_gear_quantum_quota"
  },
  {
    "description": "pu_gear_hashrate_hammer_lvl1",
    "id": "pu_gear_hashrate_hammer_lvl1",
    "key": "requireLevel",
    "targetLevel": 1,
    "targetPowerUp": "pu_gear_hashrate_hammer"
  },
  {
    "description": "pu_specials_memetoken_madness_gift",
    "id": "pu_specials_memetoken_madness_gift",
    "key": "requireGift",
    "targetPowerUp": "pu_specials_memetoken_madness"
  }
];
export default powerUpConditions;
