import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../config';
import { MutableState } from '../schema';
import { ModalLabels } from '../ruleset';

/**
 * Adds a modal label to show modal once
 */
export function addModalLabel(label: ModalLabels) {
  return async (
    state: MutableState,
    _:
      | ReplicantAsyncActionAPI<ReplicantServer>
      | ReplicantEventHandlerAPI<ReplicantServer>,
  ) => {
    state.labels.push(label);
  };
}
