import { ReplicantMigrator } from '@play-co/replicant';

// IMPORTANT: Do *NOT* import anything.

export const migrator = new ReplicantMigrator();

// Do *NOT* modify existing migrations.

/**
 * Remove the `createdAt` and `modifiedAt` properties, replaced by shared state system fields.
 */
migrator.addMigration(1, (state) => {
  delete state.createdAt;
  delete state.modifiedAt;
});
