import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../../config';
import { ChainName } from './onchainTxs.schema';
import {
  getOnchainTxsPageCount,
  getOnchainTxsStateId,
} from './onchainTxs.getters';
import { MAX_TXS_PER_PAGE } from './onchainTxs.ruleset';

export async function recordOnchainTxs(
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
  memeId: string,
  chain: ChainName,
  txHashes: string[],
) {
  // get number of onchain states
  let pageCount = await getOnchainTxsPageCount(api, memeId, chain);

  // get the last page id
  let lastPageId = getOnchainTxsStateId(memeId, chain, pageCount - 1);

  // get the last page state
  const lastPage = await api.sharedStates.onchainTxs.fetch(lastPageId);

  if (!lastPage) {
    throw new Error(
      `Error fetching last ${chain} transaction page for meme ${memeId}`,
    );
  }

  // add tx hashes to last page within available space
  const lastPageTxCount = lastPage.global.txHashes.length;
  const availableSpace = MAX_TXS_PER_PAGE - lastPageTxCount;
  const txHashesToRecord = txHashes.slice(0, availableSpace);

  api.sharedStates.onchainTxs.postMessage.addTransactions(lastPageId, {
    txHashes: txHashesToRecord,
  });

  // add remaining transactions into new shared states
  let unrecordedTxs = txHashes.slice(availableSpace);
  while (unrecordedTxs.length > 0) {
    pageCount += 1;
    lastPageId = getOnchainTxsStateId(memeId, chain, pageCount - 1);

    api.sharedStates.onchainTxs.create(lastPageId);

    api.sharedStates.onchainTxs.postMessage.addTransactions(lastPageId, {
      txHashes: txHashes.slice(0, MAX_TXS_PER_PAGE),
    });

    unrecordedTxs = unrecordedTxs.slice(MAX_TXS_PER_PAGE);
  }
}
