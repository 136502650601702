import { createSharedState } from '@play-co/replicant';
import { onchainTxsSharedStateSchema } from './onchainTxs.schema';
import { onchainTxsMessages } from './onchainTxs.messages';
import { onchainTxsComputedProperties } from './onchainTxs.properties';

export const onchainTxsSharedStates = createSharedState(
  onchainTxsSharedStateSchema,
  onchainTxsMessages,
  onchainTxsComputedProperties,
);

export type OnchainTxsSharedStates = typeof onchainTxsSharedStates;
