import { TelegramUser } from '../../replicant/features/chatbot/chatbot.schema';
import { BusinessController } from './BusinessController';

export class TGController extends BusinessController<''> {
  public init = async () => {
    Telegram.WebApp.expand();
    Telegram.WebApp.setBackgroundColor('#000000');
    try {
      Telegram.WebApp.setHeaderColor('#000000');
    } catch (error) {
      Telegram.WebApp.setHeaderColor('bg_color');
    }
    Telegram.WebApp.ready();

    // display confirmation message when telegram app is about to be closed
    Telegram.WebApp.isClosingConfirmationEnabled = true;

    // @workaround: This doesn't seem to register if done in-sync with
    // constructor, and delaying the setting of this onClick works.
    setTimeout(() => {
      Telegram.WebApp.BackButton.onClick(() => {
        this.app.onBack();
      });
    }, 1000);

    this.onInitComplete();
  };

  public get user() {
    if (process.env.REACT_APP_ENV === 'local') {
      return {
        id: this.app.replicant ? parseInt(this.app.replicant.state.id) : 0,
        first_name: 'foo',
        last_name: 'bar',
        is_bot: false,
        is_premium: false,
      };
    }
    return Telegram?.WebApp?.initDataUnsafe?.user as TelegramUser;
  }
}
