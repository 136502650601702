import { SB } from '@play-co/replicant';
import {
  createScheduledActions,
  extractScheduledActionHandlers,
} from '../../createScheduledActions';

import { savePortfolioPricePoint } from './tradingMeme.modifiers';
import { longestPortofolioUpdateInterval } from './tradingMeme.ruleset';

const schema = {
  savePortfolioPricePoint: SB.object({ delay: SB.number() }),
};

const actions = createScheduledActions(schema)({
  savePortfolioPricePoint: async (state, { delay }: { delay: number }, api) => {
    await savePortfolioPricePoint(state, api);

    // doubling the delay of next price point
    delay *= 2;
    if (delay > longestPortofolioUpdateInterval) {
      // stop updating the portfolio
      return;
    }

    api.scheduledActions.schedule.savePortfolioPricePoint({
      args: { delay },
      notificationId: 'savePortfolioPricePoint',
      delayInMS: delay,
    });
  },
});

export const offchainMemeScheduledActions = {
  schema,
  actions: extractScheduledActionHandlers(actions),
};
