import {
  GetIndexSchema,
  SB,
  createSharedStateComputedProperties,
  matchableString,
  payloadComputedProperty,
  searchableComputedProperty,
} from '@play-co/replicant';
import {
  TradingTx,
  tradingMemeSharedStateSchema,
  txSchema,
} from './tradingMeme.schema';
import { HOUR_IN_MS, MIN_IN_MS } from '../../utils/time';
import {
  getCoefficientOfVariation,
  getCurvePrice,
  getPriceBackThen,
  getValueChange,
} from './tradingMeme.getters';
import { HP } from '../../lib/HighPrecision';

export const tradingMemeComputedProperties =
  createSharedStateComputedProperties(tradingMemeSharedStateSchema)({
    profile: searchableComputedProperty(
      SB.object({
        name: matchableString(),
        creatorName: matchableString(),
        creatorImage: matchableString(),
        creatorId: SB.string(),
        image: SB.string(),
        ticker: SB.string(),
      }),
      (state) => ({
        name: state.global.details.name,
        creatorName: state.global.details.creatorName,
        creatorImage: state.global.details.creatorImage || '',
        creatorId: state.global.details.creatorId,
        image: state.global.details.image,
        ticker: state.global.details.ticker,
      }),
    ),
    availableAt: searchableComputedProperty(
      SB.int(),
      (state) => state.global.details.availableAt ?? 0,
    ),
    jettonContractAddress: payloadComputedProperty(
      SB.string(),
      (state) => state.global.jettonContractAddress || '',
    ),
    dexListingTime: searchableComputedProperty(
      SB.int(),
      (state) => state.global.dexListingTime ?? 0,
    ),
    dailyPoints: payloadComputedProperty(
      SB.array(
        SB.object({
          date: SB.int(),
          totalDailyPoints: SB.string(),
        }),
      ),
      (state) => {
        const dailyPoints = state.global.dailyPoints;

        // reversing dates to make array searches more optimal (latest date will often be the one searched for)
        const dates = Object.keys(dailyPoints).reverse();
        return dates.map((date) => {
          return {
            date: parseInt(date),
            totalDailyPoints: dailyPoints[date],
          };
        });
      },
    ),
    lastTx: searchableComputedProperty(txSchema, (state) => {
      // Make sure there is always a transaction event if no txs has been specified yet
      // (there is a short time interval during which a offchainToken may not have any transaction although each offchainToken is created with a buy transaction)
      const dummyTx: TradingTx = {
        createdAt: state.global.createdAt,
        txType: 'buy',
        userId: state.global.details.creatorId,
        currencyAmount: '0',
        pointAmount: '0',
      };

      return state.global.txs[state.global.txs.length - 1] || dummyTx;
    }),
    holderCount: searchableComputedProperty(
      SB.int(),
      (state) => state.global.holderCount,
    ),
    distribution: searchableComputedProperty(SB.number(), (state) =>
      getCoefficientOfVariation(state.global),
    ),
    priceChange: searchableComputedProperty(
      SB.object({
        last1hour: SB.number(),
        last24hours: SB.number(),
        // last7days: SB.number(),
        // last30days: SB.number(),
      }),
      (state, api) => {
        try {
          const now = api.date.now();

          const price1hAgo = getPriceBackThen(
            state.global.trends.hour24,
            now - 1 * HOUR_IN_MS,
          );
          const price24hAgo = getPriceBackThen(
            state.global.trends.hour24,
            now - 24 * HOUR_IN_MS,
          );
          const priceNow = getCurvePrice(HP(state.global.supply));

          return {
            last1hour: getValueChange(priceNow, price1hAgo),
            last24hours: getValueChange(priceNow, price24hAgo),
            // last7days: 0,
            // last30days: 0,
          };
        } catch (error) {
          console.error(error);
          return {
            last1hour: 0,
            last24hours: 0,
          };
        }
      },
    ),
    hotTxsCount: searchableComputedProperty(SB.int(), (state, api) => {
      const txs = state.global.txs;
      if (txs.length === 0) {
        return 0;
      }

      const now = api.date.now();
      const fiveMinAgo = now - MIN_IN_MS * 5;

      const last5MinTxs = txs.filter((tx) => {
        return tx.createdAt <= fiveMinAgo;
      });

      return last5MinTxs.length;
    }),
    supply: searchableComputedProperty(SB.number(), (state) => {
      if (!state.global.supply || state.global.supply === '0') {
        return 0;
      }
      return HP(state.global.supply).toNumber();
    }),
    status: searchableComputedProperty(
      SB.string(),
      (state) => state.global.status,
    ),
    shares: searchableComputedProperty(
      SB.number(),
      (state) => state.global.shares,
    ),
  });

export type TradingIndexSchema = GetIndexSchema<
  typeof tradingMemeComputedProperties
>;

export type TradingSearchResult = {
  [T in keyof typeof tradingMemeComputedProperties]: SB.ExtractType<
    (typeof tradingMemeComputedProperties)[T]['type']
  >;
} & { id: string };
