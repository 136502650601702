import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Uploader } from './Uploader';
import './ImageUploader.scss';

interface Props {
  label?: string;
  onFileChange: (file?: File) => void;
  previewUrl?: string;
  uploadState: number;
}

export const ImageUploader = ({
  onFileChange,
  previewUrl,
  label = 'Upload Image',
  uploadState,
}: Props) => {
  const uploader = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File>();

  const [previewImage, setPreviewImage] = useState(previewUrl);

  useEffect(() => {
    setPreviewImage(undefined);
    setFile(undefined);
  }, [uploadState]);

  useEffect(() => {
    setPreviewImage(previewUrl);
  }, [previewUrl]);

  const onFileUploaded = useCallback(async (f: File) => {
    setFile(f);
    onFileChange(f);
  }, []);

  const openFileUploader = useCallback(() => {
    if (!uploader?.current) {
      return;
    }
    onClearImage();
    uploader.current.click();
  }, [uploader.current]);

  const onClearImage = useCallback(() => {
    setFile(undefined);
    onFileChange(undefined);
    setPreviewImage(undefined);
  }, []);

  const imageUrl = useMemo(() => {
    console.log({ previewImage });
    if (previewImage) {
      return previewImage;
    }
    if (file) {
      return URL.createObjectURL(file);
    }
  }, [previewImage, file]);

  return (
    <div className="container">
      <p>{label}</p>
      <div className="img-container">
        <div className="img-box">
          {imageUrl ? (
            <img src={imageUrl} />
          ) : (
            <div className="btn btn-add" onClick={openFileUploader}>
              +
            </div>
          )}
        </div>
        {imageUrl && (
          <div className="btn-clear">
            <button onClick={onClearImage}>Clear Image</button>
          </div>
        )}
      </div>
      <Uploader
        ref={uploader}
        id={'image-uploader'}
        onFileUploaded={onFileUploaded}
        accept="*"
      />
    </div>
  );
};
