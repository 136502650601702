import './TradingTokenItemHolder.scss';
import { assets } from '../../../../assets/assets';
import { getUIStateClassName, isTiktokEnabled } from '../../../../data/utils';
import { app } from '../../../../data/Controllers/AppController';
import {
  displayPointAmount,
  formatPrice,
  largeIntegerToLetter,
} from '../../../../replicant/utils/numbers';
import { TradingTokenListing } from '../../../../replicant/features/tradingMeme/types';
import { memePointsDisplayMultiplier } from '../../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { HP } from '../../../../replicant/lib/HighPrecision';

interface Props {
  offchainTokenData: TradingTokenListing;
}

export const TradingTokenItemHolder = ({ offchainTokenData }: Props) => {
  const { name, offchainTokenId, image, ticker } = offchainTokenData;

  const tickerName = ticker;

  const onShowOffchainTokenDetails = async () => {
    app.nav.goToTiktokFeed(offchainTokenId, undefined, 'holder-list');
  };

  const myToken = app.state.trading.offchainTokens[offchainTokenId];
  const holdingStats = app.memes.trading.getOffchainTokenHoldingStats(
    offchainTokenId,
    myToken,
  );
  const roi = holdingStats.roi;

  const color = roi === 0 ? 'white' : roi > 0 ? 'green' : 'red';
  const iconArrow = roi > 0 ? assets.arrow_up_green : assets.arrow_down_red;

  const pointAmount = HP(myToken?.pointAmount || 0).toNumber();
  const owned = displayPointAmount(
    pointAmount,
    4,
    pointAmount < 1000 / memePointsDisplayMultiplier,
  );

  return (
    <div
      className={`btn trading-token-item-holder ${getUIStateClassName(
        app.ui.getCardState(offchainTokenId),
      )}`}
      onClick={onShowOffchainTokenDetails}
    >
      <div className="trading-token-item-holder-container">
        <div className="trading-token-item-holder-image">
          <img src={image} />
        </div>

        <div className="trading-token-item-holder-content">
          <div className="left">
            <div className="name-container">
              <div className="tickername">{tickerName}</div>
              <div className="tokenname">{name}</div>
            </div>
            <div className="owned-container">
              <div
                className="value"
                dangerouslySetInnerHTML={{ __html: owned }}
              ></div>
              <div className="label">Owned</div>
            </div>
          </div>

          <div className="right">
            <div className="price-container">
              <div className="coin">
                <img src={assets.coin} />
              </div>
              <div className="value">
                {largeIntegerToLetter(holdingStats.valuation, 3)}
              </div>
            </div>
            <div className="percent-container">
              <div className={`arrow ${color}`}>
                {roi !== 0 && <img src={iconArrow} />}
              </div>
              <div className={`percent ${color}`}>
                {formatPrice(Math.round(roi))}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
