import { SB } from "@play-co/replicant";

/** @see https://core.telegram.org/bots/api#user */
const userSchema = SB.object({
  id: SB.int(),
  is_bot: SB.boolean(),
  first_name: SB.string(),
  last_name: SB.string().optional(),
  username: SB.string().optional(),
  // @note: "is_premium" property is missing from Telegram.User type
  is_premium: SB.boolean().optional(),
}).additionalProperties();

export type TelegramUser = SB.ExtractType<typeof userSchema>;

/** @see https://core.telegram.org/bots/api#chat */
const chatSchema = () =>
  SB.object({
    id: SB.int(),
    type: SB.string(),
  }).additionalProperties();

/** @see https://core.telegram.org/bots/api#messageentity */
const messageEntitySchema = () =>
  SB.object({
    type: SB.string(),
    offset: SB.int(),
    length: SB.int(),
  }).additionalProperties();

/** @see https://core.telegram.org/bots/api#message */
const messageSchema = SB.object({
    message_id: SB.int(),
    from: userSchema.optional(),
    chat: chatSchema(),
    text: SB.string().optional(),
    entities: SB.array(messageEntitySchema()).optional(),
  }).additionalProperties();

export type TelegramMessage = SB.ExtractType<typeof messageSchema>;

/** @see https://core.telegram.org/bots/api#update */
export const updateSchema = SB.object({
  update_id: SB.int(),
  message: messageSchema,
}).additionalProperties();

export type Update = SB.ExtractType<typeof updateSchema>;

/** @see https://core.telegram.org/bots/api#callbackquery */
export const callbackQuerySchema = SB.object({
  update_id: SB.int(),
  callback_query: SB.object({
    id: SB.string(),
    from: userSchema,
  }).additionalProperties(),
}).additionalProperties();

export type CallbackQuery = SB.ExtractType<typeof callbackQuerySchema>;
