import { TutorialModule } from '../TutorialModule';

export class OutOfEnergyShareBtn extends TutorialModule {
  id = 'OutOfEnergyShareBtn' as 'OutOfEnergyShareBtn';

  onAction = (id?: string) => {
    if (id === 'outOfEnergyShare') {
      this.onComplete();
    }
  };

  execute = async () => {
    this.app.ui.setTutorialUIState({
      outOfEnergyShare: true,
    });

    return super.execute().then(() => {
      this.app.ui.setTutorialUIState({
        outOfEnergyShare: false,
      });
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_energy_invite_dialog',
    };
  }
}
