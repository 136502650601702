import { t } from 'i18next';
import {
  getTTGFarmingStatus,
  getTTGCanFarm,
  getTTGFarmingPoints,
  getTTGFarmProgress,
  getTTGFarmingSpotsAvailable,
  getTTGCanClaim,
} from '../../../replicant/features/tradingMeme/tradingMeme.getters';
import { tmgRuleset } from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { TMGEvents } from './TMGController';
import { TokenMiniGame } from './TokenMiniGame';
import { TMGFarmingStatus } from '../../../replicant/features/tradingMeme/types';
import {
  displayPointAmount,
  largeIntegerToLetter,
} from '../../../replicant/utils/numbers';
import { HP } from '../../../replicant/lib/HighPrecision';

interface FarmingState {
  status: TMGFarmingStatus;
  reward: number;
  onFarmAction: () => Promise<void>;
  progress: {
    hoursLeft: number;
    minutesLeft: number;
  };
  actionDisabled: boolean;
  canFarm: boolean;
  // life cycle - isIdle > isFarming > isReadyToClaim
  isIdle: boolean;
  isFarming: boolean;
  isReadyToClaim: boolean;
  labels: {
    btn: string;
    points: string;
  };
}
/**
 * This class runs on the current selected token in the trading controller;
 * Use to get and set any token tap game state for the given selected token
 */
export class TMGFarming extends TokenMiniGame<FarmingState> {
  public get state() {
    if (!this.tokenId) {
      return undefined;
    }

    const { state, now } = this.app;

    const status = getTTGFarmingStatus(state, this.tokenId, now());

    const canFarm = getTTGCanFarm(state, this.tokenId, now());
    const isIdle = status === 'Idle';
    const isReadyToClaim = status === 'Ready to Claim';
    const isFarming = status === 'Farming';
    const actionDisabled = !canFarm && !isReadyToClaim;

    const reward = getTTGFarmingPoints(state, this.tokenId, now());

    let btnText = t('tmg_farming_start');

    if (isFarming) {
      btnText = t('tmg_farming');
    } else if (isReadyToClaim) {
      btnText = t('tmg_farming_claim');
    }

    return {
      status,
      reward,
      onFarmAction: this.onFarmAction,
      progress: getTTGFarmProgress(state, this.tokenId, now()),
      actionDisabled,
      canFarm,
      // life cycle - isIdle > isFarming > isReadyToClaim
      isIdle,
      isFarming,
      isReadyToClaim,
      labels: {
        btn: btnText,
        points: largeIntegerToLetter(reward),
      },
      claimReward: this.claimReward,
    };
  }

  private claimReward = async (tokenId: string) => {
    const cannotClaim = !getTTGCanClaim(
      this.app.state,
      tokenId,
      this.app.now(),
    );
    if (cannotClaim) {
      return;
    }
    const { meme: token } = this.app.memes.currentMeme;
    const rewardStr = await this.app.invoke.tmgClaimFarmingReward({
      tokenId,
    });
    const reward = HP(rewardStr);
    if (reward.eq(0)) {
      return;
    }
    this.track('farm_claim', {
      points_farmed: reward.toNumber(),
    });

    this.showFarmingClaimSuccess(reward.toNumber());
    this.sendEvent(TMGEvents.OnPointsUpdate);
    this.sendEvent(TMGEvents.OnFarmingUpdate);
  };

  private get canFarm() {
    return getTTGFarmingSpotsAvailable(this.app.state) > 0;
  }

  private onFarmAction = async () => {
    if (this.state?.isFarming) {
      // Go to own user profile with farming tab selected
      return this.app.nav.goToProfile(undefined, { tab: 'Farming' });
    }
    if (!this.canFarm && !this.state?.isReadyToClaim) {
      return this.showFarmingLimitReachedDrawer();
    }
    if (!this.tokenId || !this.state) {
      return;
    }

    const tokenId = this.tokenId;

    // Start farming
    if (this.state.isIdle) {
      const success = await this.app.invoke.tmgStartFarmingToken({ tokenId });

      this.track('farm_start');

      if (!success) {
        // Could not start farming. Either because it's already farming or no slots available
        return this.showFarmingLimitReachedDrawer();
      } else {
        await this.app.memes.myMemes.refreshTargetList('Farming');
      }
    }

    // Claim farming reward
    if (this.state.isReadyToClaim) {
      await this.claimReward(tokenId);
    }

    this.sendEvent(TMGEvents.OnFarmingUpdate);
  };

  private showFarmingLimitReachedDrawer = () => {
    this.app.ui.drawer.show({
      id: 'errorGeneric',
      hideClose: true,
      opts: {
        title: t('tmg_farming_limit_reached_title'),
        subtitle: t('tmg_farming_limit_reached_subtitle', {
          farmCount: tmgRuleset.farmingLimit,
        }),
        buttons: [
          {
            cta: t('tmg_farming_limit_reached_cta'),
            onClick: () => this.app.ui.drawer.close(),
          },
        ],
      },
    });
  };

  private showFarmingClaimSuccess = (reward: number) => {
    if (!this.tokenId) {
      return;
    }

    const { meme: token } = this.app.memes.currentMeme;

    const image = token?.image;
    const ticker = token?.ticker || '???';

    this.app.ui.confetti.show();

    this.app.ui.drawer.show({
      id: 'successGeneric',
      hideClose: true,
      onClose: () => {
        this.app.ui.confetti.hide();
      },
      opts: {
        title: t('tmg_farming_claim_title'),
        image,
        subtitle: t('tmg_farming_claim_reward', {
          ticker,
          points: displayPointAmount(reward, 4, true),
        }),
        buttons: [
          {
            cta: t('tmg_farming_limit_reached_cta'),
            onClick: () => this.app.ui.drawer.close(),
          },
        ],
      },
    });
  };
}
