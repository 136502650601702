import { AppController } from './AppController';
import { Fn } from '../types';
import { EventController } from './EventController';

export enum BaseEvents {
  OnReady = 'OnReady',
}
/**
 * Business Controller are those that have app specific logic and can have dependency on other Controllers
 * And therefore need to go through app init pipeline; This abstract wrapper make sure all conform to the pattern
 */
export abstract class BusinessController<
  EventNames extends string,
  InitOpts extends Record<string, any> | undefined = undefined,
> extends EventController<EventNames | BaseEvents> {
  // @TODO2: Do this in the next round
  // abstract ID: string;

  // When implementing 'init' always call 'onInitComplete' at the end of the function
  abstract init(args?: InitOpts): Promise<void>;

  protected ready = false;
  protected initResolver!: Fn<void>;
  public isReady = new Promise((resolve) => {
    this.initResolver = resolve;
  });

  constructor(protected app: AppController) {
    super();
  }

  // Call this at the end of the implementation of 'init'
  protected onInitComplete() {
    this.ready = true;
    this.initResolver();
  }

  // @TODO2: Do this in the next round
  // protected log() {
  //   console.log(this.name);
  // }
}
