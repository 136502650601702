import './FeedMemeBottomUI.scss';
import { useState, useEffect } from 'react';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { TMGEvents } from '../../../../data/Controllers/TokenMiniGames/TMGController';
import { useManyAppUpdates } from '../../../../data/hooks';
import {
  getMyToken,
  Meme,
  getMarketCap,
} from '../../../../replicant/features/tradingMeme/tradingMeme.getters';
import { TradingTokenListing } from '../../../../replicant/features/tradingMeme/types';
import { largeIntegerToLetter } from '../../../../replicant/utils/numbers';
import { FeedMemeFooter } from './FeedMemeFooter';
import { SidebarUI } from './FeedMemeSidebar';
import { t } from 'i18next';
import { ProgressBar } from '../../../shared/ProgressBar/ProgressBar';
import { HP } from '../../../../replicant/lib/HighPrecision';

interface FeedMemeBottomUIProps {
  token: TradingTokenListing;
  uiAlpha: number;
  selected: boolean;
  slideIndex: number;
  canShowTapGame: boolean;
}

export const FeedMemeBottomUI = ({
  token,
  uiAlpha,
  selected,
  slideIndex,
  canShowTapGame,
}: FeedMemeBottomUIProps) => {
  useManyAppUpdates({
    id: 'FeedMemeBottomUI',
    events: [
      {
        listener: app.tmg.attachEventListener(TMGEvents.OnTappingTap),
        dep: selected,
      },
      {
        listener: app.tmg.attachEventListener(TMGEvents.OnPointsUpdate),
        dep: selected,
      },
    ],
  });

  const { offchainTokenId } = token;

  const priceChange = 100 * (token.priceChange?.last24hours ?? 0);
  const color = priceChange === 0 ? 'white' : priceChange > 0 ? 'green' : 'red';
  const iconArrow =
    priceChange > 0 ? assets.arrow_up_green : assets.arrow_down_red;

  const [offchainTokenState, setOffchainTokenState] = useState<Meme>();
  const [pointAmountOwned, setPointAmountOwned] = useState<string>(
    HP(0).toString(),
  );

  const { currentMeme } = app.memes;

  const holdersCount = currentMeme.meme?.holderCount || 0;
  const myToken = getMyToken(app.state, offchainTokenId);

  useEffect(() => {
    setPointAmountOwned(myToken?.pointAmount ?? 0);

    if (offchainTokenId) {
      app.memes
        .getToken(offchainTokenId, 'fetch')
        .then((offchainTokenState) => {
          setOffchainTokenState(offchainTokenState);
        });
    }
  }, [
    uiAlpha,
    offchainTokenId,
    currentMeme.meme?.id,
    currentMeme.meme?.buyPrice,
    myToken?.pointAmount,
  ]);

  const marketCap = getMarketCap(HP(offchainTokenState?.supply ?? 0));

  // todo: evaluate progress state of current token
  const [progressBarValue, setProgressBarValue] = useState<number>(
    Math.random() * 110,
  );

  const onTapDetails = () => {
    app.nav.goTo('TiktokMemeDetailsPage');
  };

  // decide if normal bottom-ui is going to fade in or out
  const isDisabled = app.tmg.isTapping(); // selected && app.ttg.tap?.showPoints && canShowTapGame;
  let fadeClass = isDisabled ? 'fade-out' : 'fade-in-slow';

  return (
    <div className="feed-meme-ui-bottom" style={{ opacity: uiAlpha }}>
      <div className={`feed-meme-ui-content ${fadeClass}`}>
        <SidebarUI token={token} />

        {/* token row */}
        <div className="btn tap-details" onClick={onTapDetails}>
          <div className="row token btn tap-details">
            {/* left - token info */}
            <div className="item">
              {/* token image */}
              <div className="item-circle">
                <div className="item-image">
                  <img className="image" src={token.image} />
                </div>
              </div>

              {/* token name and market cap value */}
              <div className="info">
                <div className="row left">
                  <div className="label tickerName">{token.ticker}</div>
                  <div className="label bullet-point">•</div>
                  <div className="label tickerName">
                    {t('trading_token_item_market_cap')} $
                    {largeIntegerToLetter(marketCap.toNumber())}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* token progress bar */}
          <div className="row progress-bar">
            <ProgressBar
              progress={progressBarValue}
              onComplete={() => {
                // console.warn('>>> progress bar at 100%');
              }}
              color="white"
              height={10}
            />
          </div>
        </div>

        {/* buy/sell buttons */}
        <FeedMemeFooter onTapPurchaseButton={app.ui.onTapPurchaseButton} />
      </div>
    </div>
  );
};
