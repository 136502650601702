import { createSharedStateSchema, SB, WithMeta } from '@play-co/replicant';
import { migrator } from './offchainTrading.migrator';

const reportsSchema = SB.object({
  createdAt: SB.int(),
  reason: SB.string().optional(),
});

export const offchainTokenDetailsSchema = SB.object({
  version: SB.number().default(1),
  availableAt: SB.int().optional(),
  creatorId: SB.string(),
  creatorName: SB.string(),
  creatorImage: SB.string().optional(),
  image: SB.string(),
  name: SB.string(),
  description: SB.string(),
  telegramChannelLink: SB.string().optional(),
  telegramChatLink: SB.string().optional(),
  twitterLink: SB.string().optional(),
  websiteLink: SB.string().optional(),
  ticker: SB.string(),
});

export const txSchema = SB.object({
  txType: SB.tuple(['buy', 'sell']),
  userId: SB.string(),
  userName: SB.string().optional(),
  userImage: SB.string().optional(),
  currency: SB.tuple(['coins', 'points', 'gift']).optional(),
  createdAt: SB.int(),
  pointAmount: SB.string(),
  currencyAmount: SB.string(),
});

const failedTxReasonSchema = SB.tuple(['concurrencyIssue']).optional();

export const failedTxSchema = SB.object({
  userId: SB.string(),
  createdAt: SB.int(),
  reason: failedTxReasonSchema,
});

export type FailedTxReason = SB.ExtractType<typeof failedTxReasonSchema>;

const trendSliceSchema = SB.object({
  price: SB.string(),
  time: SB.int(),
});

export type PriceSlice = SB.ExtractType<typeof trendSliceSchema>;

const trendSchema = SB.object({
  hour1: SB.array(trendSliceSchema).optional(),
  hour6: SB.array(trendSliceSchema).optional(),
  hour24: SB.array(trendSliceSchema),
  day7: SB.array(trendSliceSchema),
  day30: SB.array(trendSliceSchema),
  allTime: SB.array(trendSliceSchema),
});

export type PriceTrends = SB.ExtractType<typeof trendSchema>;

const statsSliceSchema = SB.object({
  time: SB.int(),
  volume: SB.string(),
  holderCount: SB.int(),
});

export type StatsSlice = SB.ExtractType<typeof statsSliceSchema>;

const statsSchema = SB.object({
  // only 24h window for now
  hour24: SB.array(statsSliceSchema),
});

export type OffchainTokenStats = SB.ExtractType<typeof statsSchema>;

export enum OffchainTokenStatus {
  Created = 'created',
  Moderated = 'moderated',
  ModeratedOS = 'moderatedOS',
  Reported = 'reported',
  Deleted = 'deleted',
}

const allowedStatuses: [string, ...string[]] = Object.values(
  OffchainTokenStatus,
) as [string, ...string[]];

export const offchainTradingSharedStateSchema = createSharedStateSchema({
  global: {
    schema: SB.object({
      details: offchainTokenDetailsSchema,
      reports: SB.array(reportsSchema),
      txs: SB.array(txSchema),
      failedTxs: SB.array(failedTxSchema),
      holderCount: SB.int(),
      sumOfHoldingsSquare: SB.string().default('0'),
      status: SB.tuple(allowedStatuses as [string, ...string[]]),
      supply: SB.string().default('0'),
      trends: trendSchema,
      stats: statsSchema,
      pointsDistributed: SB.string().default('0'),
      shares: SB.number(),
    }),
    migrator,
  },
});

export type TradingState = SB.ExtractType<
  typeof offchainTradingSharedStateSchema.global.schema
>;

export type TradingTx = SB.ExtractType<typeof txSchema>;

export type MutableTradingState = WithMeta<TradingState, {}>;
