import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../../config';
import { DEFAULT_FLAGGED_IMAGE } from './tradingMeme.ruleset';
import { TradingMemeStatus } from './tradingMeme.schema';

export async function updateStatus(
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
  offchainTokenIds: string[],
  tokenStatus?: string,
  image?: string,
) {
  const promises = offchainTokenIds.map(async (offchainTokenId) => {
    return await api.sharedStates.tradingMeme
      .fetch(offchainTokenId)
      .then((offchainTokenState) => {
        if (!offchainTokenState) {
          throw new Error('OffchainToken not found');
        }

        let status;
        if (tokenStatus) {
          status = tokenStatus;
        } else {
          // if token image is default image, then the image has been flagged by the moderation system
          status =
            offchainTokenState.global.details.image === DEFAULT_FLAGGED_IMAGE
              ? TradingMemeStatus.Reported
              : TradingMemeStatus.ModeratedOS;
        }

        api.sharedStates.tradingMeme.postMessage.updateStatus(offchainTokenId, {
          image,
          status,
        });

        return {
          offchainTokenId,
          status,
        };
      })
      .catch((e) => {
        return {
          offchainTokenId,
          status: e.message,
        };
      });
  });

  return Promise.all(promises);
}
