import './BannedScreen.scss';
import { useTranslation } from 'react-i18next';

export const BannedScreen = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`ban-screen`}>{t('user_banned_message')}</div>
    </>
  );
};
