import {
  SB,
  createSharedStateComputedProperties,
  searchableComputedProperty,
  GetIndexSchema,
} from '@play-co/replicant';
import { friendsSharedStateSchema } from './friends.schema';

export const friendsComputedProperties = createSharedStateComputedProperties(
  friendsSharedStateSchema,
)({
  friendCount: searchableComputedProperty(SB.int(), (state) => {
    return state.global.friendIds.length;
  }),
});

export type TradingIndexSchema = GetIndexSchema<
  typeof friendsComputedProperties
>;
