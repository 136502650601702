export const DAILY_REWARDS = [
  0, // No reward on day 0
  500,
  1000,
  2500,
  5000,
  15000,
  25000,
  100000,
  500000,
  1000000,
  5000000,
];

export const MAX_DAILY_REWARD_STREAK = DAILY_REWARDS.length - 1; // ignore 0

export const DAILY_CODE_PRIZE = 1_000_000;
