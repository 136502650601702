// DO NOT EDIT MANUALLY! This file is generated from Airtable: "OffchainTrading-IAP"

import { Value } from './offchainTradingIAP.types';

// prettier-ignore
const offchainTradingIAP: Value[] = [
  {
    "ab_test": "sees_offchainTrading_1_dollar",
    "description": "A fee that you pay to be able to create a trading token in gemz",
    "priceInStars": 50,
    "productId": "trading_token_create_fee_1",
    "title": "Trading Token Creation Fee"
  },
  {
    "ab_test": "sees_offchainTrading_3_dollar",
    "description": "A fee that you pay to be able to create a trading token in gemz",
    "priceInStars": 150,
    "productId": "trading_token_create_fee_3",
    "title": "Trading Token Creation Fee"
  },
  {
    "ab_test": "sees_offchainTrading_5_dollar",
    "description": "A fee that you pay to be able to create a trading token in gemz",
    "priceInStars": 250,
    "productId": "trading_token_create_fee_5",
    "title": "Trading Token Creation Fee"
  }
];
export default offchainTradingIAP;
