interface ComponentOpts {
  className: string;
  content: any;
  style: Partial<CSSStyleDeclaration>;
}

function createElement({ className, content, style }: ComponentOpts) {
  const component = document.createElement('div');
  component.className = className;
  component.innerHTML = content;
  Object.keys(style).forEach((key: any) => {
    const val = style[key];
    if (val) {
      component.style[key] = val;
    }
  });
  return component;
}

let rootElement: HTMLDivElement | null;
const getClickerRootElement = () => {
  if (rootElement) {
    return rootElement;
  }

  rootElement = document.getElementById('coin-container') as HTMLDivElement;
  return rootElement;
};

export function addClickerPointUp(points: number, boosted = false) {
  const rootElement = getClickerRootElement();
  // console.warn('>>> root element', rootElement);
  if (!rootElement) {
    return;
  }

  const pos = {
    top: `${Math.random() * 50}px`,
    left: `calc(${2 + Math.random() * 30}vh)`,
  };

  //todo: temporary code for weekend reward, remove after weekend
  let pointsMul = Date.now() < 1730134800000 ? 'x2' : '';
  const component = createElement({
    className: `point-up ${boosted && 'boosted'}`,
    content: `+${points}${pointsMul}`,
    style: pos,
  });

  rootElement.append(component);

  // After animation ends, remove element
  setTimeout(() => {
    component.remove();
  }, 400);
}

export function addMorseUp(morse: string) {
  const rootElement = getClickerRootElement();
  if (!rootElement) {
    return;
  }

  const pos = {
    top: `${Math.random() * 50}px`,
    left: `calc(${2 + Math.random() * 30}vh)`,
    fontSize: '100px',
  };

  const component = createElement({
    className: `point-up`,
    content: morse,
    style: pos,
  });

  rootElement.append(component);

  // After animation ends, remove element
  setTimeout(() => {
    component.remove();
  }, 400);
}

let clickerRippleNode: Element | null | undefined;
const getClickerRippleNode = () => {
  if (clickerRippleNode) {
    return clickerRippleNode;
  }
  clickerRippleNode = document.getElementById('ripple-templates')?.children[0];
  return clickerRippleNode;
};

export function addClickerRipple() {
  const rippleNode = getClickerRippleNode();
  const rootElement = getClickerRootElement();

  if (!rippleNode || !rootElement) {
    return;
  }

  const clone = rippleNode.cloneNode(true);
  rootElement.append(clone);

  setTimeout(() => {
    (clone as any).remove();
  }, 500);
}

type MaybeElement = Element | null | undefined;
const tapGameFxs = {
  tiktok: {
    // Used to find and set rippleNode below
    rippleNodeId: 'ripple-templates-tiktok',
    // Reference to the element that will be cloned to make the ripples
    rippleNode: undefined as MaybeElement,
    // Root element id that is used in combination with the current slide in carousel
    rootElementId: 'tiktok-tap-game',
    // point up config
    pointUp: {
      getPos: (mousePos: { x: number; y: number }) => ({
        // top: '15svh',
        // left: `calc(${42 + Math.random() * 16}svw)`,
        left: `${mousePos.x - 25}px`,
        top: `${mousePos.y - 25}px`,
      }),
      className: 'tiktokTapGame',
    },
  },
};

type TapGameFx = keyof typeof tapGameFxs;

export const getTapGameFx = (target: TapGameFx) => {
  const gameFx = tapGameFxs[target];

  // RIPPLE
  const getRootElement = () => {
    const query = `.slide.selected #${gameFx.rootElementId}`;
    return document.querySelector(query);
  };

  const getRippleNode = () => {
    if (!gameFx.rippleNode) {
      gameFx.rippleNode = document.getElementById(`${gameFx.rippleNodeId}`)
        ?.children[0] as HTMLDivElement;
    }
    return gameFx.rippleNode;
  };

  const addRipple = (mousePos: { x: number; y: number }) => {
    const rootElement = getRootElement();
    const rippleNode = getRippleNode();

    if (!rippleNode || !rootElement) {
      return;
    }

    const elm = rippleNode as HTMLDivElement;

    // size
    const size = 300;
    elm.style.width = size + 'px';
    elm.style.height = size + 'px';

    // pos
    const pos = { x: mousePos.x - size / 2, y: mousePos.y - size / 2 };
    elm.style.left = pos.x + 'px';
    elm.style.top = pos.y + 'px';

    const clone = rippleNode.cloneNode(true);
    rootElement.append(clone);

    setTimeout(() => {
      (clone as any).remove();
    }, 500);
  };

  // POINT UP
  const addPointUp = (
    mousePos: { x: number; y: number },
    points: number,
    boosted = false,
  ) => {
    const rootElement = getRootElement();
    if (!rootElement) {
      return;
    }

    const { getPos, className } = gameFx.pointUp;
    const pos = getPos(mousePos);

    const component = createElement({
      className: `point-up ${className} ${boosted && 'boosted'}`,
      content: `+${points}`,
      style: pos,
    });

    rootElement.append(component);

    // After animation ends, remove element
    setTimeout(() => {
      component.remove();
    }, 400);
  };

  return {
    addRipple,
    addPointUp,
  };
};
