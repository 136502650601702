import { TutorialModuleId } from '.';
import { AppController } from '../../Controllers/AppController';
import { Tooltip } from '../../Controllers/UIController/UITypes';
import { Optional } from '../../types';
import { waitFor } from '../../utils';
import { t } from 'i18next';

interface ITutorialModule {
  /**
   * The module identifier. Should be the same as the name of the module;
   * It's used with `stepActive`
   */
  id: TutorialModuleId;
  /**
   * overrides default functionality of the back button
   */
  onBack?: () => void;
  /**
   * overrides default functionality of the clicker button
   */
  onClickerTap?: () => void;
  /**
   * execute the tutorial step
   */
  execute: () => Promise<void>;
  /**
   * should not be overwriten from base class, takes care of completing current step (normally called by TutorialController)
   */
  onComplete: () => void;
  /**
   * sets specific action for tutorial step that can be called outside along other functionality. Example 'buyBuff'
   */
  onAction?: (id?: string) => void;
  /**
   * an optional id which invokes a change of state in replicant; used to set energy, points, etc.
   */
  stateUpdateId?: string;
  /**
   * use this function to know if a specific tutorial step is active
   */
  stepActive: (id: TutorialModuleId) => boolean;
  /**
   * get analytics key and opts to be tracked on step execute
   */
  getAnalyticsOpts: () => Optional<{ key: string; data?: Record<string, any> }>;
}

export class TutorialModule implements ITutorialModule {
  id = 'TutorialModule' as ITutorialModule['id'];

  onBack: ITutorialModule['onBack'] = undefined;

  onClickerTap: ITutorialModule['onClickerTap'] = undefined;

  onAction: ITutorialModule['onAction'] = undefined;

  stateUpdateId: ITutorialModule['stateUpdateId'] = undefined;

  private completeResolver: any;

  constructor(protected app: AppController) {
    this.id = this.constructor.name as TutorialModuleId;
  }

  getAnalyticsOpts(): ReturnType<ITutorialModule['getAnalyticsOpts']> {
    return undefined;
  }

  stepActive(id: TutorialModuleId) {
    return this.id === id;
  }

  onComplete() {
    if (this.completeResolver) {
      // console.warn('>>> onComplete ', this.id, this.app.tutorial.tempStep);
      this.completeResolver();
      this.completeResolver = undefined;
    }
  }

  execute(): Promise<void> {
    console.warn('>>> execute ', this.id);
    const analytics = this.getAnalyticsOpts();
    if (analytics) {
      this.app.track(analytics.key, {
        ...analytics.data,
        ...this.app.tutorial.analyticsTracking,
      });
      this.app.tutorial.resetStepTapTrack();
    }
    return new Promise((resolve) => {
      this.completeResolver = resolve;
    });
  }

  async closeDrawerAndContinue() {
    this.app.ui.drawer.close();
    await waitFor(500);
    this.onComplete();
  }

  addBackTooltip(duration = 5000) {
    // Load hand if the user is still in page after 5 seconds
    // todo: this causes next tooltip to change to back because the timeout is never cleared
    const timeout = setTimeout(() => {
      this.app.ui.tooltip
        .setData({
          text: t('tut_tooltip_back'), // 'back',
          origin: { x: '0', y: '0' },
          offset: { x: '52px', y: '78px' },
          align: 'top',
        })
        .show(false);
    }, duration);

    return timeout;
  }
}
