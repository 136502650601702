import './TradingSearchBar.scss';
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { setUserScrolling } from '../../../../data/utils';
import { isMobileEmulatedByBrowser } from '../../../../data/device';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';

const SEARCH_DEBOUNCE_MS = 500;
const MAX_SEARCH_CHARS = 50;

interface Props {
  initialSearchTerm: string;
  onInputChange: (searchTerm: string) => void;
}

export const TradingSearchBar = ({
  initialSearchTerm,
  onInputChange,
}: Props) => {
  const { t } = useTranslation();

  const inputRef = useRef<any>();
  const [isInputFocused, setInputFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setSearchTerm(initialSearchTerm);
  }, [initialSearchTerm]);

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    // react will reload the page for some reason when submitting a form
    // this will prevent it to happen
    event.preventDefault();

    // close the keyboard
    inputRef.current?.blur();

    // clear user input after submitting
    // setSearchTerm('');
  };

  const onInputFocus = (focused: boolean) => {
    setInputFocused(focused);

    // prevent scrolling while window open
    setUserScrolling(!focused);

    function setPageHeightStyle(heightStyle: string) {
      const page = document.querySelector(
        '.page.modal-team-list',
      ) as HTMLDivElement;

      if (page) page.style.minHeight = heightStyle;
    }

    setPageHeightStyle('unset');
  };

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const term = e.currentTarget.value;
    if (term.length <= MAX_SEARCH_CHARS) {
      setSearchTerm(term);
      onInputChange(term);
    }
  }, []);

  // debounce
  useEffect(() => {
    let stale = false;
    const timeoutId = setTimeout(async () => {
      app.memes.searchToken(searchTerm);
    }, SEARCH_DEBOUNCE_MS);

    return () => {
      stale = true;
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  return (
    <div className="trading-search-bar">
      <form onSubmit={onFormSubmit}>
        <input
          className="trading-search-bar-input"
          ref={inputRef}
          type="input"
          //
          inputMode="text"
          autoCapitalize="on"
          autoComplete="on"
          autoCorrect="on"
          enterKeyHint="search"
          //

          placeholder={t('trading_search_placeholder')}
          value={searchTerm}
          onChange={handleInputChange}
          autoFocus={false}
          onFocus={() => onInputFocus(true)}
          onBlur={() => onInputFocus(false)}
        />

        <div className="icon-search">
          <img src={assets.icon_search} />
        </div>
      </form>
    </div>
  );
};
