import { Immutable, SB, WithMeta } from '@play-co/replicant';
import * as player from './features/game/player.schema';
import ruleset from './ruleset';

const schema = SB.object({
  ...player.playerSchema,
  ...player.botUserSchema,
  ...player.botNotifSchema,
  ...player.profileSchema,
  ...player.migrationSchema,
  ...player.earningsSchema,
  ...player.powerUpSchema,
  ...player.badgesSchema,
  ...player.offchainTradingSchema,
});

export default schema;

export type StateFromSchema = SB.ExtractType<typeof schema>;

/**
 * Mutable state. Use for modifiers, messages, actions.
 */
export type MutableState = WithMeta<StateFromSchema, typeof ruleset>;

/**
 * Immutable state. Use in getters and outside replicant.
 */
export type State = Immutable<MutableState>;
