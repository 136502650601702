import { createSharedState } from '@play-co/replicant';
import { onchainHoldersSharedStateSchema } from './onchainHolders.schema';
import { onchainHoldersMessages } from './onchainHolders.messages';
import { onchainHoldersComputedProperties } from './onchainHolders.properties';

export const onchainHoldersSharedStates = createSharedState(
  onchainHoldersSharedStateSchema,
  onchainHoldersMessages,
  onchainHoldersComputedProperties,
);

export type onchainHoldersSharedStates = typeof onchainHoldersSharedStates;
