import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../config';
import { MutableState } from '../schema';
import { ModalLabels } from '../ruleset';

/**
 * Adds a tutorial tracking label to be consumed the first time the tutorial shows
 */
export function tutorialTracking(event: string) {
  return async (
    state: MutableState,
    _:
      | ReplicantAsyncActionAPI<ReplicantServer>
      | ReplicantEventHandlerAPI<ReplicantServer>,
  ) => {
    state.labels.push(`${ModalLabels.TUTORIAL_TRACKING}_${event}`);
  };
}
