import './Badge.scss';

export const Badge = ({
  visible = false,
  amount,
  hasNoText = false,
  checked = false,
}: {
  visible?: boolean;
  amount?: number | string;
  hasNoText?: boolean;
  checked?: boolean;
  worker?: boolean;
}) => {
  const hasAmount = visible && (amount || hasNoText);
  const isHiding = hasAmount == false || checked;
  const isAlert = amount === '!';

  const className = `whats-new-badge ${isHiding ? 'hide' : ''} ${
    isAlert ? 'alert' : ''
  }`;

  return (
    <div className={className}>
      <div className="badge-label">
        {amount && !hasNoText ? `${amount}` : ''}
      </div>
    </div>
  );
};
