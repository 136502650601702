import { createAsyncGetters as _createAsyncGetters } from '@play-co/replicant';
import { computedProperties } from './computedProperties';
import { ruleset } from './ruleset';
import stateSchema from './schema';
import { sharedStates } from './sharedStates';

export const createAsyncGetters = _createAsyncGetters(stateSchema, {
  computedProperties,
  ruleset,
  sharedStates,
});
