export function isMobile() {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}

export function isMobileEmulatedByBrowser() {
  const isMobileEmulated = window.navigator.userAgent.indexOf('Mobile') !== -1;
  return isMobileEmulated && navigator.maxTouchPoints == 1;
}

export function isDesktop() {
  return !isMobile();
}

export function isTelegramWebview() {
  // console.warn('>>>window.Telegram.WebApp', window.Telegram.WebApp.platform);
  return window.Telegram.WebApp.platform !== 'unknown';
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows', or 'Unknown'.
 */

export enum OS {
  Android = ' Android',
  iOS = ' iOS',
  Windows = ' Windows',
  Unknown = ' Unknown',
}

export function getMobileOS() {
  //@ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return OS.Windows;
  }

  if (/android/i.test(userAgent)) {
    return OS.Android;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  //@ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OS.iOS;
  }

  return OS.Unknown;
}

export function isAndroid() {
  if (isMobileEmulatedByBrowser()) return false;
  return getMobileOS() === OS.Android;
}

export function isIOS() {
  if (isMobileEmulatedByBrowser()) return false;
  return getMobileOS() === OS.iOS;
}

// todo: if the above detection method does not work,
// todo: we will need to use storyverse package below instead

// import userAgent from '@storyverseco/user-agent';

// export function isAndroid() {
//   return userAgent.os.isAndroid;
// }

// export function isIOS() {
//   return userAgent.os.isIOS;
// }
