// Keep track of existing handlers for 'unhandledrejection'
const unhandledRejectionHandlers: any[] = [];

// Store the original addEventListener method
const originalAddEventListener = EventTarget.prototype.addEventListener;

export function isTonConnectSdkError(error: Error | string) {
  const tonConnectError = 'TON_CONNECT_SDK';
  if (typeof error === 'string') {
    return error.includes(tonConnectError);
  }

  return error.message?.includes(tonConnectError);
}

const unhandledPromiseHijacker = function (rejection: PromiseRejectionEvent) {
  const error = rejection.reason;
  if (error && isTonConnectSdkError(error)) {
    // ignore TonConnect sdk errors, they are handlded by sentry
    return;
  }

  // Call all previously captured handlers
  for (const handler of unhandledRejectionHandlers) {
    handler(rejection);
  }
};

// Override addEventListener to capture 'unhandledrejection' handlers
EventTarget.prototype.addEventListener = function (type, listener, options) {
  if (type === 'unhandledrejection' && listener !== unhandledPromiseHijacker) {
    unhandledRejectionHandlers.push(listener);
    return;
  }
  // Call the original addEventListener method
  originalAddEventListener.call(this, type, listener, options);
};

export function hijackUnhandledPromiseErrors() {
  window.addEventListener('unhandledrejection', unhandledPromiseHijacker);
}
