export const SEC_IN_MS = 1000;
export const MIN_IN_MS = SEC_IN_MS * 60;
export const HOUR_IN_MS = MIN_IN_MS * 60;
export const DAY_IN_MS = HOUR_IN_MS * 24;
export const WEEK_IN_MS = DAY_IN_MS * 7;
export const MONTH_IN_MS = DAY_IN_MS * 30;
export const YEAR_IN_MS = DAY_IN_MS * 365;

type Timezones = 'utc' | 'pst';

export function getDayMidnightInUTC(timestamp: number, nextDays: number = 0) {
  // round down to the day's midnight in GMT
  const adjustedTimestamp = timestamp + nextDays * DAY_IN_MS;
  const dayMidnight = adjustedTimestamp - (adjustedTimestamp % DAY_IN_MS);
  return dayMidnight;

  // date = date - date % (24 * 60 * 60);
}

export function getDayMidnightInPST(timestamp: number, nextDays: number = 0) {
  // Adjust the timestamp to PST by subtracting the PST offset
  const pstOffset = getOffsetPST(timestamp);
  const adjustedTimestamp = timestamp - pstOffset;
  // Add the nextDays offset
  const adjustedTimestampWithDays = adjustedTimestamp + nextDays * DAY_IN_MS;
  // Calculate midnight in PST
  const dayMidnightPST =
    adjustedTimestampWithDays - (adjustedTimestampWithDays % DAY_IN_MS);
  // Convert back to GMT
  const dayMidnightGMT = dayMidnightPST + pstOffset;
  return dayMidnightGMT;
}

export function getOffsetPST(timestamp: number): number {
  // Create a Date object from the timestamp
  const date = new Date(timestamp);

  // Use Intl.DateTimeFormat to determine if we're in PST or PDT
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'America/Los_Angeles',
    timeZoneName: 'short',
  };
  const timeZoneString = new Intl.DateTimeFormat('en-US', options)
    .formatToParts(date)
    .find((part) => part.type === 'timeZoneName')?.value;

  // Determine the offset based on the time zone abbreviation
  if (timeZoneString === 'PDT') {
    // PDT (Pacific Daylight Time) is UTC-7
    return HOUR_IN_MS * 7;
  } else {
    // PST (Pacific Standard Time) is UTC-8
    return HOUR_IN_MS * 8;
  }
}

const midnightByTimezone = {
  utc: getDayMidnightInUTC,
  pst: getDayMidnightInPST,
};

export function getTimeUntilNextDay(now: number, timezone: Timezones = 'utc') {
  return (midnightByTimezone[timezone](now, 1) - now) % DAY_IN_MS;
}
/**
 * Use this function to get the time left until 24 hours have elapsed since timestamp
 * @param timestamp
 * @param now
 * @returns
 * @example `Aug 05 2024 14:00:00` - `Aug 06 13:00:00` = `1 hour left`
 */
export function getTimeLeftTo24Hours(timestamp: number, now: number) {
  return DAY_IN_MS - ((now - timestamp) % DAY_IN_MS);
}

export function isNextDay(
  timestamp: number,
  now: number,
  timezone: Timezones = 'utc',
) {
  const fn = midnightByTimezone[timezone];
  return fn(now, 0) > fn(timestamp, 0);
}

// Normalise both timestamps to midnight and them check for equality
export function isSameDay(
  timestamp: number,
  now: number,
  timezone: Timezones = 'utc',
) {
  const fn = midnightByTimezone[timezone];
  return fn(now) === fn(timestamp);
}
