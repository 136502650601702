import { offchainTradingSharedStateSchema } from './offchainTrading.schema';
import { createSharedState } from '@play-co/replicant';
import { offchainTradingMessages } from './offchainTrading.messages';
import { offchainTradingComputedProperties } from './offchainTrading.properties';

export const offchainTradingSharedStates = createSharedState(
  offchainTradingSharedStateSchema,
  offchainTradingMessages,
  offchainTradingComputedProperties,
);

export type TradingSharedStates = typeof offchainTradingSharedStates;
