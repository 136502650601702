import React from 'react';
import { useTimer, useTime } from 'react-timer-hook';

export default function Timer({
  expiryTimestamp,
  onExpire,
}: {
  expiryTimestamp: Date;
  onExpire: () => void;
}) {
  const { seconds, minutes, hours } = useTimer({ expiryTimestamp, onExpire });

  function format(num: number) {
    return num < 10 ? `0${num}` : num;
  }

  function formatHours(num: number) {
    return num < 1 ? '' : `0${num}:`;
  }

  return (
    <>
      <span>
        {formatHours(hours)}
        {format(minutes)}
      </span>
      :<span>{format(seconds)}</span>
    </>
  );
}
