import { SB } from '@play-co/replicant';
import {
  createScheduledActions,
  extractScheduledActionHandlers,
} from '../../createScheduledActions';
import { addEarningReward, addVideoQuestReward } from './game.modifiers';
import { EarningKey } from './ruleset/earnings';
import { isExpectedError } from '../../types';
import { shadowLeaveTeam } from '../teams/teams.modifiers';
import { SEASON } from './ruleset/league';
import { HP } from '../../lib/HighPrecision';
import { sendReengagementBroadcast } from '../chatbot/chatbot.modifiers';

const schema = {
  grantYoutubeReward: SB.object({ earningKey: SB.string() }),
  runReengagementBroadcast: SB.unknown(),
  grantVideoQuestReward: SB.object({ id: SB.string() }),
  blackListUser: SB.object({ reason: SB.string() }),
  whiteListUser: SB.unknown(),
  startNewSeason: SB.object({ season: SB.int() }),
  resetOffchainTokensHoldingDistribution: SB.unknown(),
};

const actions = createScheduledActions(schema)({
  grantVideoQuestReward: (state, { id }: { id: string }, api) => {
    addVideoQuestReward(state, { id }, api);
  },
  grantYoutubeReward: (state, { earningKey }: { earningKey: string }, api) => {
    const maybeError = addEarningReward(state, {
      earningKey: earningKey as EarningKey,
    });
    if (isExpectedError(maybeError)) {
      api.sendAnalyticsEvents([
        {
          eventType: 'quest_error',
          eventProperties: {
            quest_name: 'follow_youtube',
            error_message: maybeError.errorMessage,
            feature: 'quest',
            $subfeature: 'quest_follow_youtube',
            originFeature: 'earn',
            originSubFeature: 'earn_specials',
          },
        },
      ]);
    }
  },
  blackListUser: async (state, { reason }: { reason: string }, api) => {
    state.banned = {
      reason: reason,
      timestamp: api.date.now(),
    };
    shadowLeaveTeam(state, api);
  },
  whiteListUser: async (state, _, api) => {
    delete state.banned;
  },
  runReengagementBroadcast: async (state, _, api) => {
    sendReengagementBroadcast(state, api);
  },
  startNewSeason: async (state, { season }: { season: number }, api) => {
    if (SEASON !== season) {
      // cannot terminate given season
      return;
    }

    const seasonIdx = season - 1;
    if (state.seasonScores[seasonIdx] === undefined) {
      // make sure the saved score is an integer
      state.seasonScores[seasonIdx] = Math.round(state.score);

      // reset the score
      state.score = 0;
    }
  },
  resetOffchainTokensHoldingDistribution: async (state, _, api) => {
    const createdTokens = (
      await api.sharedStates.tradingMeme.search({
        where: {
          profile: {
            creatorId: {
              isAnyOf: [state.id],
            },
          },
        },
        // no player should have created close to 1000 tokens
        limit: 1000,
      })
    ).results;

    for (let i = 0; i < createdTokens.length; i += 1) {
      const createdToken = createdTokens[i];
      const holders = await api.asyncGetters.getOffchainTokenHolders({
        offchainTokenIds: [createdToken.id],
        // the distribution coefficient is most influenced by the top holders
        // therefore a limit of 10,000 should give a good approximation
        count: 10_000,
      });

      let sumOfHoldingsSquare = HP(0);
      holders.forEach((holder) => {
        const offchainTokens = holder.memes;
        const offchainToken = offchainTokens.find(
          (token) => token?.id === createdToken.id,
        );

        if (!offchainToken) {
          // note that it should not be possible
          return;
        }

        sumOfHoldingsSquare.add(HP(offchainToken.pointAmount).pow(2));
      });

      api.sharedStates.tradingMeme.postMessage.setSumOfHoldingsSquare(
        createdToken.id,
        { sumOfHoldingsSquare: sumOfHoldingsSquare.toString() },
      );
    }
  },
});

export const gameScheduledActions = {
  schema,
  actions: extractScheduledActionHandlers(actions),
};
