import {
  BADGE_VALUES,
  ElementUIState,
} from '../../Controllers/UIController/UITypes';
import { isTelegramWebview } from '../../device';
import { invite } from '../../sharing';
import { waitFor } from '../../utils';
import { TutorialModule } from './TutorialModule';
import { t } from 'i18next';

export class FriendsPage extends TutorialModule {
  id = 'FriendsPage' as 'FriendsPage';

  onBack = () => {
    this.onComplete();
  };

  execute = async () => {
    this.app.ui.setTutorialUIState({
      badges: {
        mine: 0,
        earn: 0,
        friends: 0,
        boosters: 0,
        trading: BADGE_VALUES.TRADING_ALERT,
      },
    });

    this.app.ui.setClickerUIState({
      btnClicker: ElementUIState.Normal,
      btnLeague: ElementUIState.Inactive,

      footer: ElementUIState.Normal,

      btnFriends: ElementUIState.Normal,
      btnMine: ElementUIState.Mystery,
      btnEarn: ElementUIState.Mystery,
      btnBooster: ElementUIState.Normal,
      btnTeam: ElementUIState.Hidden,
    });

    // navigate to friends page
    this.app.nav.goTo('Friends');

    await waitFor(500);

    this.app.tutorial.showTooltip({
      text: t('tut_tooltip_friendsinvite'), //'Friends make\neverything better',
      origin: { x: '40%', y: '0%' },
      offset: { x: '0px', y: '500px' },
      align: 'top',
    });

    // todo: remove?
    // auto-open invite api system drawer
    // if (isTelegramWebview()) {
    //   invite({
    //     data: {
    //       feature: 'friend',
    //       $subFeature: 'tutorial_invite_friend',
    //     },
    // trackPressInvite: true,
    //   });
    // }

    // todo: remove?
    // Load hand if the user is still in page after 5 seconds
    // remember to clear timeout on super.execute
    // const timeout = this.addBackTooltip();

    return super.execute().then(() => {
      // clearTimeout(timeout);
      this.app.ui.tooltip.hide();

      this.app.ui.setClickerUIState({
        btnClicker: ElementUIState.Normal,
        btnLeague: ElementUIState.Inactive,

        footer: ElementUIState.Normal,

        btnFriends: ElementUIState.Normal,
        btnMine: ElementUIState.Mystery,
        btnEarn: ElementUIState.Mystery,
        btnBooster: ElementUIState.Normal,
        btnTeam: ElementUIState.Hidden,
      });
    });
  };

  getAnalyticsOpts() {
    return {
      key: 'FTUE_friends_load_friend',
      data: {
        has_friends: this.app.state.friendCount > 0,
      },
    };
  }
}
