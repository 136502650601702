import { ChangeEvent, useEffect, useState } from 'react';
import { ImageUploader } from './components/ImageUploader';
import { auth, uploadFileWithSignedPost } from './lib';
import { copyToClipboard } from '../data/utils';
import { WipeUser } from './components/WipeUser/WipeUser';
import './Cms.scss';

const BASE_URL = 'https://notgemz.cms.gemz.fun/media/powerups';
const CACHE = 'gc_cms';

export const Cms = () => {
  const [powerUpImage, setPowerUpImage] = useState<File>();

  const [previewCounter, setPreviewCounter] = useState(0);
  const [puImageUrl, setPuImageUrl] = useState<string>();

  const [powerUpId, setPowerUpId] = useState('');

  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  const [secret, setSecret] = useState(localStorage.getItem(CACHE));

  useEffect(() => {
    document.body.style.overflowY = 'scroll';
  }, []);

  const onSave = async () => {
    if (loading || !secret) {
      return;
    }
    if (!powerUpId) {
      setError(`Cannot save without mine card name`);
      return;
    }
    if (!powerUpImage) {
      setError(`Cannot save without selecting image to upload`);
      return;
    }

    setError('');
    try {
      const imgName = `${powerUpId}.png`;
      setLoading(true);
      await uploadFileWithSignedPost({
        file: powerUpImage,
        path: 'site',
        filename: imgName,
        secret,
      });
      setPuImageUrl(`${BASE_URL}/${imgName}`);
      setPowerUpImage(undefined);
      setPreviewCounter(previewCounter + 1);
      setPowerUpId('');
    } catch (e: any) {
      console.error(e);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const onPowerUpIdInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPowerUpId(e.target.value);
  };

  const copyUrl = () => {
    if (puImageUrl) {
      console.log(puImageUrl);
      copyToClipboard(puImageUrl);
    }
  };

  const login = () => {
    const secretInput = prompt('Password');
    if (secretInput) {
      auth(secretInput)
        .then(() => {
          setSecret(secretInput);
          localStorage.setItem(CACHE, secretInput);
        })
        .catch(() => {});
    }
  };

  if (!secret) {
    return <button onClick={login}>Login</button>;
  }

  return (
    <div className="cms">
      {/* <h1>Gemzcoin CMS</h1> */}
      <WipeUser />
      <div className="cms-card ">
        <h1>Mine Card CMS</h1>
        <p>Card Id</p>
        <input value={powerUpId} onChange={onPowerUpIdInput} />
        <ImageUploader
          label={'Upload Mine Card Image'}
          onFileChange={setPowerUpImage}
          uploadState={previewCounter}
          // previewUrl={puImageUrl}
        />
        <button onClick={onSave}>Save</button>

        {puImageUrl && (
          <div className="result">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <p>Image uploaded successfully to: </p>
                <div className="link">
                  <div>{puImageUrl}</div>
                  <button onClick={copyUrl}>copy</button>
                </div>
                <div className="img-box">
                  <img src={puImageUrl} />
                </div>
              </>
            )}
          </div>
        )}

        {error && (
          <div className="error">
            Something went wrong, please try again
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};
