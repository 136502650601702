import { asyncAction } from '@play-co/replicant';
import { createActions } from '../../createActions';
import { ChainName } from './onchainTxs.schema';
import { recordOnchainTxs } from './onchainTxs.modifiers';

export const onchainTxsActions = createActions({
  asyncRecordOnchainTxs: asyncAction(
    async (
      _state,
      {
        memeId,
        chain,
        txHashes,
      }: { memeId: string; chain: ChainName; txHashes: string[] },
      api,
    ) => {
      await recordOnchainTxs(api, memeId, chain, txHashes);
    },
  ),
});
