import { PeriodicUpdate } from '../replicant/features/tradingMeme/tradingMeme.messages';
import { BusinessController } from './Controllers/BusinessController';
import { Optional } from './types';

type TokenId = string;
type InputOpts = Optional<{ collectionCooldown: number }>;
export class ReplicantCollector extends BusinessController<'', InputOpts> {
  private state: Record<TokenId, PeriodicUpdate> = {};

  private loop?: NodeJS.Timeout;

  private collectionCooldown = 10;

  public init = async (opts: InputOpts = undefined) => {
    if (this.ready) {
      return;
    }
    if (opts?.collectionCooldown) {
      this.collectionCooldown = opts.collectionCooldown;
    }
    clearInterval(this.loop);
    this.loop = setInterval(() => {
      this.onLoop();
    }, this.collectionCooldown * 1000);
    this.onInitComplete();
  };

  private onLoop = () => {
    // Collect any unsync points and put in our state for batch update
    this.submitState();
  };

  private shouldSubmit = () => {
    const hasCollectedInfo = Object.keys(this.state).length > 0;
    return hasCollectedInfo;
  };

  private submitState = () => {
    if (this.shouldSubmit()) {
      console.warn(`ReplicantCollector: Submitting periodic update`, {
        state: this.state,
      });
      this.app.invoke.periodicUpdate(this.state);
      this.state = {};
    }
  };

  private getState = (tokenId: string) => this.state[tokenId];

  private updateState = (tokenId: string, update: Partial<PeriodicUpdate>) => {
    this.state[tokenId] = {
      ...this.getState(tokenId),
      ...update,
    };
  };

  public updateShare = (tokenId: string) => {
    const sharesToAdd = (this.getState(tokenId)?.sharesToAdd ?? 0) + 1;
    this.updateState(tokenId, { sharesToAdd });
  };
}
