import { useTranslation } from 'react-i18next';
import { app } from '../../../../data/Controllers/AppController';
import { getUIStateClassName } from '../../../../data/utils';
import { TradingSearchResult } from '../../../../replicant/features/offchainTrading/offchainTrading.properties';
import {
  formatPrice,
  largeIntegerToLetter,
} from '../../../../replicant/utils/numbers';
import { ProgressBarCircle } from '../../../shared/ProgressBarCircle/ProgressBarCircle';
import { ProfileTabV2 } from '../ProfilePage';
import './ProfileItem.scss';
import { FarmingSearchResult } from '../../../../data/Controllers/ProfileController';

interface Props {
  category: ProfileTabV2;
  offchainTokenData: TradingSearchResult | FarmingSearchResult;
}

// =================================================================
// #region Item

export const ProfileItem = ({ category, offchainTokenData }: Props) => {
  const { t } = useTranslation();

  const { id: offchainTokenId, profile } = offchainTokenData;

  const tickerName = profile.ticker;

  const onShowOffchainTokenDetails = async () => {
    // navigate to tiktok feed from profile, with category set to either Owned or Created
    // get which tab category are we going to display in tiktok page
    const { ownedOrCreatedOrFarming } = app.ui.state.profile;
    const ownedOrCreated =
      ownedOrCreatedOrFarming === 'Farming' ? 'Owned' : ownedOrCreatedOrFarming;
    app.nav.goToTiktokFeed(offchainTokenId, ownedOrCreated, 'profile-list');
  };

  if (!app.views.ProfilePage.visible) {
    return null;
  }

  // =================================================================
  // #region Data

  // retrieving data from profile controller
  const myToken = app.profile.getTokenHolding(offchainTokenId);

  const holdingStats = app.profile.getOffchainTokenHoldingStats(
    offchainTokenId,
    myToken,
  );

  const marketCap = holdingStats?.marketCap || 0;
  const valuation = holdingStats?.valuation || 0;
  const roi = holdingStats?.roi || 0;
  const roiAbsoluteValue = (roi * valuation) / 100;

  const color = roi === 0 ? 'white' : roi > 0 ? 'green' : 'red';

  // console.warn('>>> item', name, offchainTokenId, holdingStats);

  // =================================================================
  // #region Base

  return (
    <div
      className={`btn item-profile ${getUIStateClassName(
        app.ui.getCardState(offchainTokenId),
      )}`}
      onClick={onShowOffchainTokenDetails}
    >
      <div className="item-profile-container">
        <div className="item-profile-image">
          <img src={profile.image} />
        </div>

        {category === 'Points' && (
          <ProfileItemContentPoints
            name={profile.name}
            tickerName={tickerName}
            color={color}
            marketCap={marketCap}
            valuation={valuation}
            roiAbsoluteValue={roiAbsoluteValue}
          />
        )}

        {category !== 'Points' && (
          <ProfileItemContent
            name={profile.name}
            tickerName={tickerName}
            color={color}
            marketCap={marketCap}
            valuation={valuation}
            roiAbsoluteValue={roiAbsoluteValue}
          />
        )}
      </div>
    </div>
  );
};

interface ProfileItemData {
  name: string;
  tickerName: string;
  color: string;
  marketCap: number;
  valuation: number;
  roiAbsoluteValue: number;

  onShowOffchainTokenDetails?: () => void;
}

// =================================================================
// #region Normal

const ProfileItemContent = ({
  name,
  tickerName,
  color,
  marketCap,
  valuation,
  roiAbsoluteValue,
}: ProfileItemData) => {
  return (
    <div className="item-profile-content">
      {/* left info */}
      <div className="left">
        <div className="name-container">{name}</div>

        <div className="owned-container">
          {largeIntegerToLetter(marketCap, 3)} ${tickerName}
        </div>
      </div>

      <div className="right">
        {/* valuation */}
        <div className="price-container">
          ${largeIntegerToLetter(valuation, 3)}
        </div>

        {/* change */}
        <div className={`change-container ${color}`}>
          ${formatPrice(roiAbsoluteValue)}
        </div>
      </div>
    </div>
  );
};

// =================================================================
// #region Points

const ProfileItemContentPoints = ({
  name,
  marketCap,
  onShowOffchainTokenDetails,
}: ProfileItemData) => {
  // todo carles: figure out what is the correct type for event here
  const onTapClaim = (event: any) => {
    // prevent bubbling to item
    event.preventDefault();
    event.stopPropagation();

    // todo carles: TiktokMemeDetailsPage needs to accept a token parameter
    // todo carles: and use that token as content instead of app.memes.currentMeme
    // todo carles: for now, solving the issue by first navigating to given token in tiktok feed
    // todo carles: then, navigating to details once token has been rendered in feed
    onShowOffchainTokenDetails && onShowOffchainTokenDetails();

    setTimeout(() => {
      app.nav.goTo('TiktokMemeDetailsPage');
    }, 350);
  };

  const progress = Math.random() * 200;
  const canClaim = progress >= 100;

  return (
    <div className="item-profile-content">
      <div className="left">
        {/* token name */}
        <div className="name-container">{name}</div>

        {/* token market cap */}
        <div className="owned-container">
          {largeIntegerToLetter(marketCap, 3)} Points
        </div>
      </div>

      <div className="right">
        {/* claim button */}
        {canClaim && (
          <div className="btn btn-normal button-claim" onClick={onTapClaim}>
            {'Claim'}
          </div>
        )}

        {!canClaim && (
          // circular progress bar
          <div className="progress-points">
            <ProgressBarCircle progress={progress} />
          </div>
        )}
      </div>
    </div>
  );
};
