import './DrawerTradingTransaction.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';

export const DrawerTradingCreationSuccess = () => {
  const { t } = useTranslation();

  // get offchainToken props and buy/sell mode
  const props = app.ui.drawer.view.data?.props?.transactionSuccess;

  const onShare = () => {
    app.memes.shareOffchainToken('creation_success_drawer', props);
  };

  return (
    <div className="drawer-trading-transaction">
      <div className="drawer-trading-transaction-header">
        <img
          className="drawer-trading-transaction-icon"
          src={assets.trading_transaction_success}
        />

        <div className="drawer-trading-transaction-title yellow">
          {props?.offchainTokenName}
        </div>
        <div className="drawer-trading-transaction-title">
          {t('trading_create_success_title')}
        </div>
        <div className="drawer-trading-transaction-subtitle">
          {t('trading_create_success_subtitle')}
        </div>
      </div>

      <div className="footer-buttons">
        {/* share */}
        <div
          className={`btn btn-normal`}
          onClick={() => {
            app.ui.confetti.hide();
            app.ui.drawer.close();
            onShare();
          }}
        >
          Share
          {/* {t('trading_create_success_button')} */}
        </div>
      </div>
    </div>
  );
};
