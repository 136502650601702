import { CSSProperties } from 'react';
import { app, useAppUpdates } from '../../data/Controllers/AppController';
import { TutorialEvents } from '../../data/tutorial/TutorialController';

const style: CSSProperties = {
  position: 'absolute',
  zIndex: 99999999,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0.1,
  // background: 'red',
  // pointerEvents: 'none',
};

export const TutorialOverlay = () => {
  useAppUpdates({
    id: 'TeamPage/Tutorial',
    listener: app.tutorial.attachEventListener(TutorialEvents.onUpdate),
  });

  if (!app.tutorial.overlayEnabled) {
    return null;
  }

  return (
    <div
      id="tutorial-overlay"
      style={style}
      onClick={app.tutorial.onOverlayClick}
    />
  );
};
