import './TiktokHeader.scss';
import { useState, useEffect } from 'react';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import {
  MemeFilters,
  UserMemeFilters,
} from '../../../../data/Controllers/Memes/types';
// import {
//   largeIntegerToLetter,
//   formatPrice,
// } from '../../../../replicant/utils/numbers';
import { TiktokFilterPicker } from './TiktokFilterPicker';
import { isMobile } from '../../../../data/device';

export const TiktokHeader = () => {
  // const portfolioValue = app.memes.trading.portfolioValue;
  // const portfolioChange = app.memes.trading.getPortfolioRoi();
  // const color = portfolioChange >= 0 ? 'green' : 'red';
  // const onTapUser = () => {
  //   app.nav.goToProfile();
  // };

  const isTapGameEnabled = app.tmg.isTapping();
  // console.warn('>>> header -isTapGameEnabled', isTapGameEnabled);

  const [selectedFilter, setSelectedFilter] = useState<MemeFilters>(
    app.memes.currentFilter,
  );

  // we need to make sure to update selectedCategory
  // whenever app.memes.currentFilter changes
  useEffect(() => {
    setSelectedFilter(app.memes.currentFilter);
  }, [app.memes.currentFilter]);

  const onTapFilter = async (filter: MemeFilters) => {
    setSelectedFilter(filter);

    await app.memes.setCurrent({ filter });

    const isSearchFilter = app.memes.market.getIsMyFilter(filter);
    if (isSearchFilter) {
      return app.nav.goToTiktokFeed(undefined, undefined, filter);
    }

    // If not a market then it's a user meme
    app.nav.goToUserMemeToken(
      filter as UserMemeFilters,
      app.memes.currentMeme.meme?.id ||
        app.memes.currentList.firstItem?.offchainTokenId ||
        '',
      filter,
    );
  };

  const onTapSearch = () => {
    app.nav.goToTiktokSearchPage();
  };

  return (
    <div className={`feed-ui-top ${isTapGameEnabled ? 'invisible' : ''}`}>
      <div
        className={`feed-ui-top-gradient ${isMobile() ? 'mobile' : 'desktop'}`}
      />
      <div className="feed-ui-top-content">
        {/* left - empty area */}
        <div className="feed-ui-top-left-area" />
        {/* user - coins - left */}
        {/* <div className={`btn feed-item-user`} onClick={onTapUser}>
          <div className="feed-item-user-left">
            <div className="image">
              <img src={assets.coin} />
            </div>
          </div>

          <div className="feed-item-user-right">
            <div className="user-coins">
              {largeIntegerToLetter(portfolioValue)}
            </div>

            <div className={`user-percent ${color}`}>
              {formatPrice(Math.round(portfolioChange))}%{' '}
            </div>
          </div>
        </div> */}

        {/* center - categories / filters */}
        <TiktokFilterPicker
          selectedFilter={selectedFilter}
          onTapFilter={onTapFilter}
        />

        {/* right - search */}
        <div className="btn feed-ui-top-search">
          <div className="btn feed-ui-top-search-button" onClick={onTapSearch}>
            <img src={assets.icon_search_white} />
          </div>
        </div>
      </div>
    </div>
  );
};
