import qr_gemzcoin_local from './qr/qr_gemzcoin_local.png';
import qr_gemzcoin_dev from './qr/qr_gemzcoin_dev.png';
import qr_gemzcoin_staging from './qr/qr_gemzcoin_staging.png';
import qr_gemzcoin_prod from './qr/qr_gemzcoin.png';

import menu from './menu.svg';
import example from './example/1.svg';

import sloth_createteam from './sloth/sloth_createteam.png';
import sloth_earn from './sloth/sloth_earn.png';
import sloth_earning_summary from './sloth/sloth_earning_summary.png';
import sloth_outofenergy from './sloth/sloth_outofenergy.png';
import sloth_teams from './sloth/sloth_teams.png';
import sloth_gift_invalid from './sloth/sloth_gift_invalid.png';
import sloth_hacker from './sloth/sloth_hacker.png';
import sloth_rocket from './sloth/sloth_rocket.png';
import sloth_businessman from './sloth/sloth_businessman.png';
import sloth_meme_token from './sloth/sloth_meme_token.png';
import sloth_astronaut from './sloth/sloth_astronaut.png';
import sloth_reporter from './sloth/sloth_reporter.png';

import btn_clicker_circle from './buttons/btn_clicker_circle.svg';
import btn_clicker_dark from './buttons/btn_clicker_dark.svg';
import btn_clicker_light from './buttons/btn_clicker_light.svg';
import btn_clicker_transparent from './buttons/btn_clicker_transparent.svg';

import button_x from './buttons/button_x.svg';
import button_arrow_back from './buttons/button_arrow_back.svg';
import button_arrow_right from './buttons/button_arrow_right.svg';

import button_arrow_down from './buttons/button_arrow_down.svg';

import arrow_up_green from './icons/arrow_up_green.svg';
import arrow_down_red from './icons/arrow_down_red.svg';
import arrow_down_red_bright from './icons/arrow_down_red_bright.svg';

// import gem_dust from './effects/gem_dust_white.svg';

// import gem_bronze_png from './png/gems/gem_bronze.png';
// import gem_silver_png from './png/gems/gem_silver.png';
// import gem_gold_png from './png/gems/gem_gold.png';
// import gem_platinum_png from './png/gems/gem_platinum.png';
// import gem_diamond_png from './png/gems/gem_diamond.png';
// import gem_league_6_png from './png/gems/gem_league_6.png';
// import gem_league_7_png from './png/gems/gem_league_7.png';
// import gem_league_8_png from './png/gems/gem_league_8.png';
// import gem_league_9_png from './png/gems/gem_league_9.png';

import sloth_league_1 from './png/sloth_leagues/sloth_league_1.png';
import sloth_league_2 from './png/sloth_leagues/sloth_league_2.png';
import sloth_league_3 from './png/sloth_leagues/sloth_league_3.png';
import sloth_league_4 from './png/sloth_leagues/sloth_league_4.png';
import sloth_league_5 from './png/sloth_leagues/sloth_league_5.png';
import sloth_league_6 from './png/sloth_leagues/sloth_league_6.png';
import sloth_league_7 from './png/sloth_leagues/sloth_league_7.png';
import sloth_league_8 from './png/sloth_leagues/sloth_league_8.png';
import sloth_league_9 from './png/sloth_leagues/sloth_league_9.png';

import booster_bot from './icons/boosters/robot.png';
import booster_energyFull from './icons/boosters/battery.png';
import booster_energyRecharge from './icons/boosters/zap.png';
import booster_rocket from './icons/boosters/rocket.png';
import booster_tap from './icons/boosters/tap.png';

import earn_check from './icons/earn/icon_check.svg';
import earn_x from './icons/earn/icon_x.svg';
import earn_community from './icons/earn/community.png';
import earn_inviteBonus from './icons/earn/invite_bonus.png';
import earn_invite2 from './icons/earn/invite2.png';
import earn_invite5 from './icons/earn/invite5.png';
import earn_invite10 from './icons/earn/invite10.png';
import earn_invite100 from './icons/earn/invite100.png';
import earn_announcement from './icons/earn/announcement.png';
import earn_daily_reward from './icons/earn/daily_reward.png';
import earn_wallet_connect100 from './icons/earn/wallet_connect100.png';
import earn_promo from './icons/earn/promo.png';
import meme from './icons/trading.svg';

import powerup_check_on from './icons/powerups/powerup_check_on.svg';
import powerup_check_off from './icons/powerups/powerup_check_off.svg';
import powerup_locked from './icons/powerups/powerup_locked.svg';
import powerup_timer from './icons/powerups/powerup_timer.svg';
import powerup_daily_info from './icons/powerups/powerup_daily_info.svg';

import coin from './icons/coin.svg';
import icon_ton from './icons/icon_ton.svg';
import finger_up from './icons/finger_up.svg';
import copy from './icons/copy.svg';
import copy_white from './icons/copy_white.svg';
import copy_success from './icons/copy_success.svg';
import friends from './icons/friends.svg';
import booster from './icons/booster.svg';
import mine from './icons/mine.svg';
import earn from './icons/earn.svg';
import trading from './icons/trading.svg';
import leaderboard from './icons/leaderboard.svg';
import king from './icons/king.svg';
import telegram_premium from './icons/telegram_premium.svg';
import telegram_star from './icons/telegram_star.png';

import mystery from './icons/mystery.svg';

import energy_white from './icons/energy_white.svg';
import energy_yellow from './icons/energy_yellow.svg';

import friends_yellow from './icons/friends_yellow.svg';
import booster_yellow from './icons/booster_yellow.svg';
import mine_yellow from './icons/mine_yellow.svg';
import earn_yellow from './icons/earn_yellow.svg';
import trading_yellow from './icons/earn_yellow.svg';

import medal_silver from './icons/medals/silver_medal.svg';
import medal_gold from './icons/medals/gold_medal.svg';
import medal_bronze from './icons/medals/bronze_medal.svg';

import mock_portrait from './mockups/mock_portrait.svg';

import { Booster, Buff } from '../data/types';
import { League } from '../replicant/features/game/ruleset/league';

import share from './icons/share.svg';
import share_white from './icons/share_white.svg';
import share_black from './icons/share_black.svg';
import paperplane from './icons/paperplane.svg';
import twitter from './icons/twitter.svg';
import website from './icons/website.svg';
import pencil from './icons/pencil.svg';

import daily_combo from './powerups/daily_combo.svg';
import daily_item_unknown from './powerups/daily_item_unknown.png';
import green_tick from './powerups/green_tick.png';
import timer from './powerups/timer.png';

import tutorial_hand from './tutorial/hand.png';
import tutorial_free_gift from './tutorial/free_gift_gold.png';
import tutorial_next_card from './tutorial/next_card.png';
import tutorial_end from './tutorial/tutorial_end.png';

// import sl_clicker from './tutorial/slideshow/ftue_slide_clicker.png';
// import sl_league from './tutorial/slideshow/ftue_slide_league.png';
// import sl_mine from './tutorial/slideshow/ftue_slide_mining.png';
// import sl_friends from './tutorial/slideshow/ftue_slide_friends.png';
// import sl_community from './tutorial/slideshow/ftue_slide_community.png';
// import sl_rewards from './tutorial/slideshow/ftue_slide_rewards.png';

import slideshowSeason2_step1 from './tutorial/slideshow/slideshowSeason2/step1.jpg';
import slideshowSeason2_step2 from './tutorial/slideshow/slideshowSeason2/step2.jpg';
import slideshowSeason2_step3 from './tutorial/slideshow/slideshowSeason2/step3.jpg';

import slideshowTrading_step1 from './tutorial/slideshow/slideshowTrading/step1.jpg';
import slideshowTrading_step2 from './tutorial/slideshow/slideshowTrading/step2.jpg';
import slideshowTrading_step3 from './tutorial/slideshow/slideshowTrading/step3.jpg';

import slideshowTiktok_step1 from './tutorial/slideshow/slideshowTiktok/step1.jpg';
import slideshowTiktok_step2 from './tutorial/slideshow/slideshowTiktok/step2.jpg';

import slideshowTiktokOnly_step1 from './tutorial/slideshow/slideshowTiktokOnly/step1.jpg';
import slideshowTiktokOnly_step2 from './tutorial/slideshow/slideshowTiktokOnly/step2.jpg';
import slideshowTiktokOnly_step3 from './tutorial/slideshow/slideshowTiktokOnly/step3.jpg';

import arrow_left from './icons/arrow_left.svg';
import arrow_right from './icons/arrow_right.svg';

import icon_sidebar_share from './icons/sidebar_share.svg';
import icon_sidebar_chat from './icons/sidebar_chat.svg';

import ig from './socials/ig.svg';
import youtube from './socials/youtube.svg';
import tiktok from './socials/tiktok.svg';
import facebook from './socials/facebook.png';

import default_profile from './png/default_profile.png';

import icon_daily_green_tick from './buttons/daily/daily_green_tick.svg';
import icon_daily_rewards from './buttons/daily/icons/daily_rewards.png';
import icon_daily_code from './buttons/daily/icons/daily_code.png';
import icon_daily_combo from './buttons/daily/icons/daily_combo.png';

import trading_header_bg from './mockups/trading/trading_header_bg.png';
import trading_token_image from './mockups/trading/trading_token_image.png';
import trading_token_author from './mockups/trading/trading_token_author.png';
import trading_chart from './mockups/trading/trading_chart.png';
import trading_price_area_icon from './mockups/trading/trading_price_area_icon.png';
import icon_holders from './icons/holders.svg';
import icon_search from './icons/search.svg';
import icon_search_white from './icons/search_white.svg';
import icon_megaphone from './icons/megaphone.svg';

import icon_farming_logo_small from './icons/farming_logo.svg';
import icon_farming_logo_big from './icons/farming_logo_new.svg';

// ---
import tiktok_footer_home_active from './icons/tiktok_footer_home_active.svg';
import tiktok_footer_home_inactive from './icons/tiktok_footer_home_inactive.svg';

import tiktok_footer_friends_active from './icons/tiktok_footer_friends_active.svg';
import tiktok_footer_friends_inactive from './icons/tiktok_footer_friends_inactive.svg';

import tiktok_footer_create from './icons/tiktok_footer_create.svg';

import tiktok_footer_earn_active from './icons/tiktok_footer_earn_active.svg';
import tiktok_footer_earn_inactive from './icons/tiktok_footer_earn_inactive.svg';

import tiktok_footer_profile_active from './icons/tiktok_footer_profile_active.svg';
import tiktok_footer_profile_inactive from './icons/tiktok_footer_profile_inactive.svg';
// ---

import tiktok_ticket from './icons/tiktok_ticket.svg';
import tiktok_points from './icons/tiktok_points.svg';
import tiktok_tap_convert from './icons/tiktok_tap_convert.svg';

import tmg_tap_refill from './icons/tmg_tap_refill.svg';

import tiktok_holders from './icons/tiktok_holders.svg';

import icon_arrow_buy from './icons/arrow_buy.svg';
import icon_arrow_sell from './icons/arrow_sell.svg';

import tap_to_earn from './tapping/tapToEarn.png';
import tap_timer_frame from './tapping/tap_timer_frame.svg';

import trading_transaction_success from './mockups/trading/trading_transaction_success.svg';
import trading_transaction_error from './mockups/trading/trading_transaction_error.svg';
import trading_coming_soon from './mockups/trading/trading_coming_soon.png';

import s2_migration_warning_loading from './mockups/trading/s2_migration_warning_loading.svg';
import tiktok_teaser from './mockups/trading/tiktok_teaser.png';

import ftue_icon_check from './ftue/ftue_icon_check.svg';

import create_card_front from './create/card_front.svg';
import create_icon_arrow_blue from './create/icon_arrow_blue.svg';
import create_icon_trash from './create/icon_trash.svg';
import create_icon_upload from './create/icon_upload.svg';
import create_icon_camera from './create/icon_camera.svg';
import create_icon_green_tick from './create/icon_green_tick.svg';

import gift_icon from './icons/gift.svg';
import gift_white_icon from './icons/gift_white.svg';
import airdrop from './icons/airdrop.svg';
import gradient_red from './glows/gradient_red.svg';

// statistics
import stat_buys from './icons/statistics/buys.svg';
import stat_contract from './icons/statistics/contract.svg';
import stat_deployer from './icons/statistics/deployer.svg';
import stat_holders from './icons/statistics/holders.svg';
import stat_liq from './icons/statistics/liq.svg';
import stat_sells from './icons/statistics/sells.svg';
import stat_txns from './icons/statistics/txns.svg';
import stat_volume from './icons/statistics/volume.svg';

// tabs
import tab_icon_coin from './icons/tabs/tab_icon_coin.svg';
import tab_icon_token from './icons/tabs/tab_icon_token.svg';

import icon_info from './icons/icon_info.svg';
import icon_link_off_black from './icons/icon_link_off_black.svg';
import button_ton from './buttons/button_ton.svg';

import coins_500k from './png/coins_500k.png';

import link_button_arrow from './icons/link_button_arrow.svg';

export const assets = {
  qrcode: {
    local: qr_gemzcoin_local,
    dev: qr_gemzcoin_dev,
    alpha: qr_gemzcoin_dev,
    bravo: qr_gemzcoin_dev,
    charlie: qr_gemzcoin_dev,
    staging: qr_gemzcoin_staging,
    prod: qr_gemzcoin_prod,
  },

  sloth_createteam,
  sloth_earn,
  sloth_earning_summary,
  sloth_outofenergy,
  sloth_teams,
  sloth_gift_invalid,
  sloth_hacker,
  sloth_rocket,
  sloth_businessman,
  sloth_meme_token,
  sloth_astronaut,
  sloth_reporter,

  btn_clicker_circle,
  btn_clicker_dark,
  btn_clicker_light,
  btn_clicker_transparent,

  button_x,
  button_arrow_back,
  button_arrow_right,
  button_arrow_down,

  coin,
  icon_ton,
  finger_up,
  copy,
  copy_white,
  copy_success,
  menu,
  example,
  friends,
  booster,
  mine,
  earn,
  trading,
  leaderboard,
  king,

  mystery,

  friends_yellow,
  booster_yellow,
  mine_yellow,
  earn_yellow,
  trading_yellow,

  telegram_premium,
  telegram_star,

  energy_white,
  energy_yellow,
  mock_portrait,
  medal_silver,
  medal_gold,
  medal_bronze,

  // gem_dust,

  // league: {
  //   [League.league1]: gem_bronze_png,
  //   [League.league2]: gem_silver_png,
  //   [League.league3]: gem_gold_png,
  //   [League.league4]: gem_platinum_png,
  //   [League.league5]: gem_diamond_png,
  //   [League.league6]: gem_league_6_png,
  //   [League.league7]: gem_league_7_png,
  //   [League.league8]: gem_league_8_png,
  //   [League.league9]: gem_league_9_png,
  // },

  league: {
    [League.league1]: sloth_league_1,
    [League.league2]: sloth_league_2,
    [League.league3]: sloth_league_3,
    [League.league4]: sloth_league_4,
    [League.league5]: sloth_league_5,
    [League.league6]: sloth_league_6,
    [League.league7]: sloth_league_7,
    [League.league8]: sloth_league_8,
    [League.league9]: sloth_league_9,
  },

  boosters: {
    [Booster.AutoTap]: booster_bot,
    [Booster.MultiTap]: booster_tap,
    [Booster.RechargeSpeed]: booster_energyRecharge,
    [Booster.RechargeLimit]: booster_energyFull,
    [Buff.Rocketman]: booster_rocket,
    [Buff.FullEnergy]: booster_energyFull,
  },

  quests: {
    earn_announcement,
    youtube,
    earn_x,
    tiktok,
    ig,
    earn_wallet_connect100,
    earn_invite2,
    earn_invite5,
    earn_invite10,
    earn_invite100,
    meme,
  },

  earn_check,
  earn_x,
  earn_community,
  earn_inviteBonus,
  earn_invite2,
  earn_invite5,
  earn_invite10,
  earn_invite100,
  earn_announcement,
  earn_daily_reward,
  earn_wallet_connect100,
  earn_promo,
  meme,
  socials: {
    youtube,
    ig,
    tiktok,
    facebook,
  },

  powerup_check_on,
  powerup_check_off,
  powerup_locked,
  powerup_timer,
  powerup_daily_info,

  share,
  share_white,
  share_black,
  paperplane,
  twitter,
  website,
  pencil,

  daily_combo,
  daily_item_unknown,
  green_tick,
  timer,

  tutorial_hand,
  tutorial_free_gift,
  tutorial_next_card,
  tutorial_end,

  // sl_clicker,
  // sl_league,
  // sl_mine,
  // sl_friends,
  // sl_community,
  // sl_rewards,

  slideshowSeason2_step1,
  slideshowSeason2_step2,
  slideshowSeason2_step3,

  slideshowTrading_step1,
  slideshowTrading_step2,
  slideshowTrading_step3,

  slideshowTiktok_step1,
  slideshowTiktok_step2,

  slideshowTiktokOnly_step1,
  slideshowTiktokOnly_step2,
  slideshowTiktokOnly_step3,

  arrow_left,
  arrow_right,

  icon_sidebar_share,
  icon_sidebar_chat,

  arrow_up_green,
  arrow_down_red,
  arrow_down_red_bright,

  default_profile,

  icon_daily_green_tick,
  icon_daily_rewards,
  icon_daily_code,
  icon_daily_combo,

  trading_header_bg,
  trading_token_image,
  trading_token_author,
  trading_chart,
  trading_price_area_icon,

  s2_migration_warning_loading,

  icon_holders,
  icon_search,
  icon_search_white,
  icon_megaphone,

  icon_farming_logo_small,
  icon_farming_logo_big,

  tiktok_footer_home_active,
  tiktok_footer_home_inactive,
  tiktok_footer_friends_active,
  tiktok_footer_friends_inactive,
  tiktok_footer_create,
  tiktok_footer_earn_active,
  tiktok_footer_earn_inactive,
  tiktok_footer_profile_active,
  tiktok_footer_profile_inactive,

  tiktok_ticket,
  tiktok_points,
  tiktok_tap_convert,

  tmg_tap_refill,

  tiktok_holders,

  icon_arrow_buy,
  icon_arrow_sell,

  trading_transaction_success,
  trading_transaction_error,
  trading_coming_soon,

  tap_to_earn,
  tap_timer_frame,
  tiktok_teaser,

  ftue_icon_check,

  create_card_front,
  create_icon_arrow_blue,
  create_icon_trash,
  create_icon_upload,
  create_icon_camera,
  create_icon_green_tick,

  gift_icon,
  gift_white_icon,
  airdrop,
  gradient_red,

  // statistics
  stat_buys,
  stat_contract,
  stat_deployer,
  stat_holders,
  stat_liq,
  stat_sells,
  stat_txns,
  stat_volume,

  // tabs
  tab_icon_coin,
  tab_icon_token,

  icon_info,
  icon_link_off_black,
  button_ton,

  coins_500k,
};
