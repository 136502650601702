// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Bot-NotifManager"

import { Value } from './botNotifManager.types';

// prettier-ignore
const botNotifManager: Value[] = [
  {
    "dailyLimitRate": 2592000,
    "maxNotifsPerPlayerPerDay": 6,
    "name": "BotNotifManager"
  }
];
export default botNotifManager;
