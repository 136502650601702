// set online to true by default if API not supported
let online = navigator.onLine === undefined ? true : navigator.onLine;

let resolveBackOnline: ((value?: any) => void) | null = null;
let waitUntilOnline = new Promise((resolve) => {
  resolveBackOnline = resolve;
});
if (window.ononline !== undefined) {
  // online/offline events supported
  // @note: waitUntilOnline promise never resolves if events not supported
  if (online) {
    resolveBackOnline!();
  }

  window.addEventListener('offline', () => {
    online = false;
    waitUntilOnline = new Promise((resolve) => {
      resolveBackOnline = resolve;
    });
  });

  window.addEventListener('online', () => {
    online = true;
    resolveBackOnline!();
  });
}

export function loadImage(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      img.onload = null;
      img.onerror = null;

      resolve(img);
    };

    img.onerror = () => {
      img.onload = null;
      img.onerror = null;

      reject();
    };
  });
}

export function loadVideo(url: string) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    console.error('loading video!', url);

    // When the video is fully preloaded
    video.oncanplaythrough = () => {
      console.error('video loaded 1!', url);
      video.oncanplaythrough = null;
      video.oncanplay = null;
      video.onerror = null;

      video.remove(); // Clean up the element after preloading
      resolve(video);
    };

    // When video is likely playable
    video.oncanplay = () => {
      console.error('video loaded 2!', url);
      video.oncanplaythrough = null;
      video.oncanplay = null;
      video.onerror = null;

      video.remove(); // Clean up the element after preloading
      resolve(video);
    };

    video.onerror = () => {
      console.error('failed to load!', url);
      video.oncanplaythrough = null;
      video.oncanplay = null;
      video.onerror = null;

      reject();
    };

    video.src = url;
    video.autoplay = false;
    video.preload = 'auto'; // Ensures the video is preloaded
    video.muted = true; // Optional: mute in case it autoplays in some browsers
    video.load;
  });
}

const MAX_RETRY = 4;

export async function loadContent(
  contentUrl: string,
  onlineRetries: number = 0,
  offlineRetries: number = 0,
): Promise<void> {
  try {
    // @note: "fetch" api might not always work properly since failures might be undetected
    // const urlBreakdown = contentUrl.split('.');
    // const extension = urlBreakdown[urlBreakdown.length - 1];
    // if (extension === 'mp4') {
    //   await loadVideo(contentUrl);
    // } else {
    //   await loadImage(contentUrl);
    // }
    await fetch(contentUrl, { mode: 'no-cors' });
  } catch (error) {
    if (navigator.onLine === false || online === false) {
      // no cap on offline retries
      offlineRetries += 1;

      // waiting for the user to come online
      await new Promise((resolve) => {
        // wait until whatever comes first:

        // 1 - either event handler notified as online
        waitUntilOnline.then(resolve);

        // 2 - or timeout elapsed (in case online/offline events not supported/unreliable)
        const waitTime = Math.min(offlineRetries * 100, 1000);
        setTimeout(resolve, waitTime);
      });

      return loadContent(contentUrl, onlineRetries, offlineRetries);
    }

    if (onlineRetries < MAX_RETRY) {
      onlineRetries += 1;

      const waitTime = 300;
      await new Promise((resolve) => setTimeout(resolve, waitTime));

      return loadContent(contentUrl, onlineRetries, offlineRetries);
    }

    throw new Error(
      `Failed to fetch ${contentUrl}: ${(error as Error).message}`,
    );
  }
}
