import './Slideshow.scss';
import { useEffect, useState } from 'react';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { t } from 'i18next';
import { TutorialEvents } from '../../../data/tutorial/TutorialController';
import { UIEvents } from '../../../data/Controllers/UIController/UITypes';
import { Tutorials } from '../../../data/tutorial/types';
import { insertIconIntoText } from '../../../data/utils';

export interface SlideshowItem {
  id: string;
  title: string;
  subtitle: string;
  icon?: string;
  image: string;
  telemetry: string;
  cta?: string;
}

interface FooterProps {
  onTapPlay: () => void;
  cta?: string;
  substitutes?: Record<string, string>;
}

export const Slideshow = () => {
  useAppUpdates({
    id: 'Slideshow/TutorialEvents.onUpdate',
    listener: app.tutorial.attachEventListener(TutorialEvents.onUpdate),
  });
  useAppUpdates({
    id: 'Slideshow/UIEvents.TutorialUpdate',
    listener: app.ui.attachEventListener(UIEvents.TutorialUpdate),
  });

  const [animateOut, setAnimateOut] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);

  const slide = app.ui.slideshow?.slide;
  const tutorial = app.ui.slideshow?.tutorial;

  useEffect(() => {
    if (!app.ui.slideshow) {
      return;
    }

    // reset hasFinished state
    setAnimateOut(false);
    setHasFinished(false);

    // track analytics on each step
    app.track(app.ui.slideshow.slide.telemetry);
  }, [slide?.id]);

  // tap to tend slideshow
  const onTapPlay = () => {
    setAnimateOut(true);
    setTimeout(() => setHasFinished(true), 250);
    app.tutorial.step?.onAction?.('slideshow');
  };

  // slideshow has ended
  if ((tutorial && !app.tutorial.getIsActive(tutorial)) || !slide) {
    if (hasFinished || !slide) return null;
  }

  const transparentBg = app.tutorial.transparentBg;

  return (
    <div className={`slideshow ${animateOut ? 'slide-down' : 'slide-up'}`}>
      <div
        className="backdrop"
        style={transparentBg ? { opacity: 0.8 } : undefined}
      />

      <div
        className="content"
        style={{
          justifyContent: transparentBg ? 'flex-end' : 'flex-start',
        }}
      >
        <div
          className={
            transparentBg ? 'image-container-fit-width' : 'image-container-tall'
          }
        >
          <img src={slide.image} />
        </div>
      </div>

      {tutorial === Tutorials.SlideshowTutorial ? (
        <FooterTutorial onTapPlay={onTapPlay} />
      ) : (
        <FooterTrading
          // height={height}
          onTapPlay={onTapPlay}
          cta={slide.cta}
          substitutes={app.tutorial.tutorialOpts?.substitutes}
        />
      )}
    </div>
  );
};

// =====================================================
// Footer Tutorial
// Has left and right column layout, with sloith icons

const FooterTutorial = ({ onTapPlay, substitutes }: FooterProps) => {
  if (!app.ui.slideshow) return null;

  const slide = app.ui.slideshow?.slide;
  const isLastItem = app.ui.slideshow?.isLast;
  const items = app.ui.slideshow?.items || [];

  const title = t(slide.title, substitutes);
  const titleHtml = insertIconIntoText(title);

  const subtitle = t(slide.subtitle, substitutes);
  const subtitleHtml = insertIconIntoText(subtitle);

  return (
    <div className="slideshow-footer-tutorial">
      <div className="layout">
        {/* top - icon and step information */}
        <div className="top">
          <div className="left">{slide.icon && <img src={slide.icon} />}</div>
          <div className="right">
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: titleHtml,
              }}
            />
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: subtitleHtml,
              }}
            />
          </div>
        </div>

        {/* bottom - dots and next button */}
        {!isLastItem && (
          <div className="bottom">
            <div className="left">
              <div className="dots">
                {items.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`dot ${item.id === slide.id ? 'active' : ''}`}
                    />
                  );
                })}
              </div>
            </div>

            <div className="right">
              <div
                className="btn btn-normal btn-slideshow"
                onClick={app.ui.nextSlide}
              >
                {t('tut_slide_next')}
              </div>
            </div>
          </div>
        )}

        {/* full play button */}
        {isLastItem && (
          <div
            className="btn btn-normal btn-slideshow full"
            onClick={onTapPlay}
          >
            {t('tut_slide_play')}
          </div>
        )}
      </div>
    </div>
  );
};

// =====================================================
// Footer Trading
// Everything centered, no sloth icons

const FooterTrading = ({ onTapPlay, cta, substitutes }: FooterProps) => {
  if (!app.ui.slideshow) return null;

  const slide = app.ui.slideshow?.slide;
  const isLastItem = app.ui.slideshow?.isLast;
  const items = app.ui.slideshow?.items || [];

  const title = t(slide.title, substitutes);
  const titleHtml = insertIconIntoText(title);

  const subtitle = t(slide.subtitle, substitutes);
  const subtitleHtml = insertIconIntoText(subtitle);

  return (
    <div className="slideshow-footer-trading">
      <div className="layout">
        {/* title and description */}
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: titleHtml,
          }}
        />
        <div className="description">
          <span
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html: subtitleHtml,
            }}
          />
        </div>

        {/* dots */}
        <div className="dots">
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className={`dot ${item.id === slide.id ? 'active' : ''}`}
              />
            );
          })}
        </div>

        {/* full width button */}
        {isLastItem ? (
          <div
            className="btn btn-normal btn-slideshow full"
            onClick={onTapPlay}
          >
            {t(cta ? cta : 'tut_trading_slide_play', substitutes)}
          </div>
        ) : (
          <div
            className="btn btn-normal btn-slideshow full"
            onClick={app.ui.nextSlide}
          >
            {t(cta ? cta : 'tut_slide_next', substitutes)}
          </div>
        )}
      </div>
    </div>
  );
};
