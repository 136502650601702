export type RetryOptions = {
  attempts: number;
  shouldRetryError?: (error: Error) => boolean;
  backoff?:
    | { exponential: { delay: number; base?: number; fuzz?: number } }
    | { linear: { delay: number; fuzz?: number } }
    | { fixed: { delay: number; fuzz?: number } };
};

/**
 * Fuzzes a value by a given amount. The fuzz is a percentage of the value. For example, if value is 1000 and fuzz is 0.1, the result will be a value between 950 and 1050.
 * @param value The base value
 * @param fuzz The percentage to fuzz the value by
 * @returns The fuzzed value
 */
export function fuzz(value: number, fuzz: number): number {
  return value * (1 + Math.random() * fuzz - fuzz / 2);
}

export async function retry<T>(
  fn: () => Promise<T>,
  options: RetryOptions,
): Promise<T> {
  let attempts = 0;
  let latestError!: Error;

  while (attempts < options.attempts) {
    attempts += 1;

    try {
      return await fn();
    } catch (error: any) {
      latestError = error;
      if (options.shouldRetryError && !options.shouldRetryError(error)) {
        throw error;
      }
    }

    if (attempts >= options.attempts) {
      break;
    }

    if (options.backoff) {
      if ('exponential' in options.backoff) {
        const initial = options.backoff.exponential.delay;
        const base = options.backoff.exponential.base ?? 2;
        await delay(
          fuzz(
            initial * base ** (attempts - 1),
            options.backoff.exponential.fuzz ?? 0,
          ),
        );
      } else if ('linear' in options.backoff) {
        const initial = options.backoff.linear.delay;
        await delay(fuzz(initial * attempts, options.backoff.linear.fuzz ?? 0));
      } else if ('fixed' in options.backoff) {
        await delay(
          fuzz(options.backoff.fixed.delay, options.backoff.fixed.fuzz ?? 0),
        );
      }
    }
  }

  throw latestError;
}

export async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
