import { League } from './league';

export interface Friend {
  id: string;
  username: string;
  contribution: number;
  picture: string;
  score: number;
  // @todo: remove (POST SEASON 2 MIGRATION)
  // league: League;
  // team?: string;
}

export interface FriendList {
  friendCount: number;
  friends: Friend[];
}

export const MAX_FRIEND_COUNT = 30_000;
