import './TradingTokenItem.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { getUIStateClassName } from '../../../../data/utils';
import { app } from '../../../../data/Controllers/AppController';
import { largeIntegerToLetter } from '../../../../replicant/utils/numbers';
import { TradingTokenListing } from '../../../../replicant/features/tradingMeme/types';
import { HighPrecision } from '../../../../replicant/lib/HighPrecision';
import { ProgressBarCircle } from '../../../shared/ProgressBarCircle/ProgressBarCircle';
import { CreatorImage } from '../../../shared/CreatorImage/CreatorImage';

interface Props {
  offchainTokenData: TradingTokenListing;
}

let hasClickedCreator = false;

export const TradingTokenItem = ({ offchainTokenData }: Props) => {
  const { t } = useTranslation();

  const {
    name,
    offchainTokenId,
    image,
    creatorId,
    creatorName,
    creatorImage,
    marketCap,
    ticker,
  } = offchainTokenData;

  const tickerName = ticker;

  const onShowOffchainTokenDetails = async () => {
    if (hasClickedCreator) {
      return;
    }

    // navigate to tiktok feed instead of token details
    // app.nav.goToTradingToken(offchainTokenId);
    app.nav.goToTiktokFeed(offchainTokenId, undefined, 'feed-list');
  };

  let marketCapValue =
    marketCap instanceof HighPrecision ? marketCap.toNumber() : marketCap;

  const onCreatorClick = () => {
    // Use 'hasClickedCreator' to prevent the click on the name to open the token page
    hasClickedCreator = true;
    app.nav.goToProfile(creatorId);
    setTimeout(() => {
      hasClickedCreator = false;
    });
  };

  const onTapProgress = (event: any) => {
    // just prevent bubbling to the item
    event.preventDefault();
    event.stopPropagation();
  };

  // todo carles: figure out what is the correct type for event here
  const onTapClaim = (event: any) => {
    // prevent bubbling to item
    event.preventDefault();
    event.stopPropagation();

    // todo carles: TiktokMemeDetailsPage needs to accept a token parameter
    // todo carles: and use that token as content instead of app.memes.currentMeme
    // todo carles: for now, solving the issue by first navigating to given token in tiktok feed
    // todo carles: then, navigating to details once token has been rendered in feed
    onShowOffchainTokenDetails && onShowOffchainTokenDetails();

    setTimeout(() => {
      app.nav.goTo('TiktokMemeDetailsPage');
    }, 350);
  };

  const progress = Math.random() * 200;
  const canClaim = progress >= 100;

  return (
    // container
    <div
      className={`btn item-trading ${getUIStateClassName(
        app.ui.getCardState(offchainTokenId),
      )}`}
      onClick={onShowOffchainTokenDetails}
    >
      {/* image */}
      <div className="item-trading-container">
        <div className="item-trading-image">
          <img src={image} />
        </div>

        {/* content */}
        <div className="item-trading-content">
          <div className="left">
            {/* token name */}
            <div className="name-container">
              {name} {`($${ticker})`}
            </div>

            {/* token market cap */}
            <div className="owned-container">
              {`Market Cap`} {largeIntegerToLetter(marketCapValue, 3)} Points
            </div>

            {/* token creator */}
            <div className="creator-container">
              <div className="creator-label">{'By'}</div>
              <CreatorImage size={14} image={creatorImage} />
              <div className="creator-label">{creatorName}</div>
            </div>
          </div>

          <div className="right">
            {/* claim button */}
            {canClaim && (
              <div className="btn btn-normal button-claim" onClick={onTapClaim}>
                <img src={assets.button_ton} />
              </div>
            )}

            {!canClaim && (
              // circular progress bar
              <div className="btn progress-points" onClick={onTapProgress}>
                <ProgressBarCircle progress={progress} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`btn trading-token-item ${getUIStateClassName(
        app.ui.getCardState(offchainTokenId),
      )}`}
      onClick={onShowOffchainTokenDetails}
    >
      {/* <Badge
        visible={hasBadge}
        hasNoText
        checked={app.ui.powerupOffchainTokensBadgesState[id]}
      /> */}

      <div className="trading-token-item-container">
        <div className="trading-token-item-image">
          <img src={image} />
        </div>

        <div className="trading-token-item-content">
          <div className="trading-token-item-top">
            <div className="trading-token-item-title">{name}</div>

            {/* market cap */}
            <div className="trading-token-item-market-cap">
              <div className="trading-token-item-market-cap-label">
                {t('trading_token_item_market_cap')}
              </div>
              <div className="trading-token-item-market-cap-value">
                <div className="icon">
                  <img src={assets.coin} />
                </div>
                <div className="label">
                  {largeIntegerToLetter(marketCapValue, 3)}
                </div>
              </div>
              {/* todo(Cai): hook this with real data */}
              {/* <div className="trading-token-item-market-cap-percent">+300%</div> */}
            </div>
          </div>

          <div className="trading-token-item-bottom">
            <div className="trading-token-item-author">
              {/* ticker name */}
              {tickerName && (
                <>
                  <div className="trading-token-item-author-label">
                    {tickerName}
                  </div>
                  <div className="trading-token-item-author-label">•</div>
                </>
              )}

              {/* author */}
              <div
                className="trading-token-item-author-tappable"
                onClick={onCreatorClick}
              >
                <div className="trading-token-item-author-label">
                  {t('tiktok_by')}
                </div>
                <div className="trading-token-item-author-image">
                  <img src={creatorImage} />
                </div>
                <div className="trading-token-item-author-name">
                  {creatorName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
